import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import AgentProfileAvatar from './AgentProfileAvatar';
import moment from 'moment';
import validator from 'validator';
import AlertBox from '../AlertBox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { Switch, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const EditAgentModal = (props) => {
    const [agent, setAgent] = useState(props.agent);
    const [queues, setQueues] = useState(props.agent._id ? props.getAgentQueues(props.agent) : []);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (props.agent !== agent) {
            setAgent(props.agent);
        }

        if (props.errorMessage !== errorMessage) {
            setErrorMessage(props.errorMessage);
        }
    }, [props.agent, props.errorMessage]);

    const handleAgentSave = async () => {
        props.handleAgentSave(agent, queues);
    };

    const allFieldsFilled = () => {
        let allFill = true;
        if (agent.first_name === '' || !agent.first_name) {
            allFill = false;
        }
        if (agent.last_name === '' || !agent.last_name) {
            allFill = false;
        }
        if (agent.email === '' || !agent.email) {
            allFill = false;
        }
        if (agent.role === '' || !agent.role) {
            allFill = false;
        }

        return allFill;
    };

    const handleFieldChange = (e) => {
        setIsValid(true);
        setErrorMessage('');
        setAgent((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

        if (agent.first_name.trim().length < 2 || agent.last_name.trim().length < 2) {
            setErrorMessage('First and last name must contain at least 2 characters');
            setIsValid(false);
        }

        if (!validator.isEmail(agent.email)) {
            setErrorMessage('Must contain a valid email');
            setIsValid(false);
        }
    };

    const handleTimedAgentChange = (e) => {
        setAgent((prevState) => ({
            ...prevState,
            agent_settings: {
                ...prevState.agent_settings,
                timed_agent: {
                    ...prevState.agent_settings.timed_agent,
                    enabled: !prevState.agent_settings.timed_agent.enabled,
                },
            },
        }));
    };

    const handleTimeAllowedChange = (e) => {
        setAgent((prevState) => ({
            ...prevState,
            agent_settings: {
                ...prevState.agent_settings,
                timed_agent: {
                    ...prevState.agent_settings.timed_agent,
                    allowed_time: e.target.value,
                },
            },
        }));
    };

    const handleRoleChange = (e) => {
        setAgent((prevState) => ({
            ...prevState,
            role: e.target.value,
        }));
    };

    const handleQueueChange = (event) => {
        setQueues(event.target.value);
    };

    const handleDisabled = () => {
        setAgent((prevState) => ({
            ...prevState,
            disabled: !prevState.disabled,
        }));
    };

    const handleExpiryDateChange = (e) => {
        setAgent((prevState) => ({
            ...prevState,
            expiry_date: e,
        }));
    };

    const roleOptions = [
        { value: 'agent', label: 'Agent' },
        { value: 'manager', label: 'Manager' },
    ];
    if (props.auth.agent.agent.role === 'admin') {
        roleOptions.push({ value: 'admin', label: 'Admin' });
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={props.isOpen}
            onRequestClose={() => {
                props.onRequestClose();
            }}
            className="action_modal modal__preloader"
        >
            <div className="modal__title">
                {!agent._id ? 'New' : 'Edit'} Agent
                <AgentProfileAvatar size="60" agent={agent} />
            </div>
            <form onSubmit={handleAgentSave}>
                <div className="form-group__split">
                    <div className="form-group">
                        <TextField
                            fullWidth
                            label="First Name"
                            type="text"
                            name="first_name"
                            inputProps={{ maxLength: 30 }}
                            onChange={handleFieldChange}
                            value={agent.first_name}
                            placeholder="Enter agent first name"
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            fullWidth
                            label="Last Name"
                            type="text"
                            name="last_name"
                            inputProps={{ maxLength: 30 }}
                            onChange={handleFieldChange}
                            value={agent.last_name}
                            placeholder="Enter agent last name"
                        />
                    </div>
                </div>
                <div className="form-group__split">
                    <div className="form-group">
                        <TextField
                            fullWidth
                            label="Email"
                            type="email"
                            name="email"
                            onChange={handleFieldChange}
                            value={agent.email}
                            placeholder="Enter agent email"
                        />
                    </div>
                    <div className="form-group">
                        <FormControl fullWidth>
                            <InputLabel id="role-select-label">Role</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                value={agent.role}
                                label="Role"
                                onChange={handleRoleChange}
                            >
                                {roleOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {!props.noCompany && props.queues && (
                    <div className="form-group">
                        <FormControl fullWidth>
                            <InputLabel id="queue-select-label">Queue</InputLabel>
                            <Select
                                labelId="queue-select-label"
                                id="queue-select"
                                multiple
                                value={queues}
                                onChange={handleQueueChange}
                                input={<OutlinedInput label="Queue" />}
                                renderValue={(selected) =>
                                    selected.map((item) => item.name).join(', ')
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {props.queues.map((queue) => (
                                    <MenuItem key={queue.id} value={queue}>
                                        <Checkbox checked={queues.indexOf(queue) > -1} />
                                        <ListItemText primary={queue.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {props.auth.agent.agent.role === 'admin' && (
                    <div>
                        <div className="form-group__split">
                            <div className="form-group">
                                <label>Timed Agent</label>
                                <Switch checked={(agent.agent_settings && agent.agent_settings.timed_agent.enabled) ? true : false} name="timed_agent" onChange={handleTimedAgentChange}/>
                                {agent.agent_settings.timed_agent.enabled && (
                                    <div>
                                        <label>Allowed Time (Hours)</label>
                                        <TextField
                                            type="number"
                                            name="timed_agent.allowed_time"
                                            onChange={handleTimeAllowedChange}
                                            value={agent.agent_settings.timed_agent.allowed_time}
                                            placeholder="Enter agent allowed time"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Expiry Date</label>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={agent.expiry_date ? moment(agent.expiry_date) : null}
                                        onChange={handleExpiryDateChange}
                                        placeholder="Enter agent expiry date"
                                        label="Expiry Date"
                                        format='DD/MM/YYYY'
                                        fullWidth
                                        slotProps={{
                                            field: { clearable: true, onClear: () => {
                                                handleExpiryDateChange(null);
                                            } },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                )}
                {(props.auth.agent.agent.role === "admin" || props.auth.agent.agent.role === "manager") && (
					<div className="form-group">
						<label>Disabled</label>
						<Switch checked={agent.disabled} name="disabled" onChange={handleDisabled} />
					</div>
				)}
                {!agent._id && (
                    <AlertBox type="info" className="mb-medium">
                        Please note that adding this agent will result in an additional
                        charge.
                    </AlertBox>
                )}
                {errorMessage && errorMessage !== '' && (
                    <AlertBox type="error" className="mb-medium">
                        {errorMessage}
                    </AlertBox>
                )}
                <div className="modal__actions">
                    <div
                        className="btn"
                        onClick={(e) => {
                            props.onRequestClose();
                        }}
                    >
                        Cancel
                    </div>
                    <button
                        className="btn"
                        disabled={!isValid || (allFieldsFilled() ? false : true)}
                    >
                        Save
                    </button>
                </div>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditAgentModal);