import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from 'react-router-dom';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/Login/LoginPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ChatsPage from '../components/Chats/ChatsPage';
import LeadsPage from '../components/Leads/LeadsPage';
import SetPasswordPage from '../components/Password Set/SetPasswordPage';
import CompanySelectPage from '../components/CompanySelectPage';
import { history } from '../helpers/history';
import AgentPage from '../components/Agents/AgentPage';
import HelpRequestPage from '../components/HelpRequests/HelpRequestPage';
import MeetingRoom from '../components/Meetings/MeetingRoom';
import SettingsPage from '../components/Settings/SettingsPage';
import AnalyticsPage from '../components/Analytics/AnalyticsPage';
import AdminRoute from './AdminRoute';
import AdminSettingsPage from '../components/AdminSettings/AdminSettingsPage';
import PasswordResetPage from '../components/PasswordReset/PasswordResetPage';
import ResetPassword from '../components/PasswordReset/ResetPassword';
import UnansweredQuestionsPage from '../components/UnansweredQuestions/UnansweredQuestionsPage';
import PendingQuestionsPage from '../components/UnansweredQuestions/PendingQuestionsPage';
import RejectedQuestionsPage from '../components/UnansweredQuestions/RejectedQuestionsPage';
import ErrorPage from '../components/Errors/ErrorPage';
import WhatsAppTemplateSenderPage from '../components/WhatsAppTemplateSender/WhatsAppTemplateSenderPage';
import WhatsAppTemplateCreatePage from '../components/WhatsAppTemplateSender/WhatsAppTemplateCreatePage';
import WhatsAppTemplatePage from '../components/WhatsAppTemplateSender/WhatsAppTemplatePage';
import WhatsAppPushNotifcationNewJobPage from '../components/WhatsAppTemplateSender/WhatsAppPushNotifcationNewJobPage';
import KnowledgeSetManagerPage from '../components/KnowledgeSetManager/KnowledgeSetManagerPage';
import KnowledgeSetPage from '../components/KnowledgeSetManager/KnowledgeSetPage';
import IndexPage from '../components/KnowledgeSetManager/IndexPage';
import EduBotListPage from '../components/EduBot/EduBotListPage';
import EduBotPage from '../components/EduBot/EduBotPage';
import EduBotEditPage from '../components/EduBot/EduBotEditPage';
import QuestionLogPage from '../components/QuestionLogs/QuestionLogPage';

const AppRouter = () => {
    const router = createBrowserRouter(
        createRoutesFromElements([
            <Route path="/" exact={true} element={<PrivateRoute allowedRoles={['admin', 'manager', 'agent']} component={<ChatsPage/>}/>}/>,
            <Route path="/chats" element={<PrivateRoute allowedRoles={['admin', 'manager', 'agent']} component={<ChatsPage/>}/>}/>,
            <Route path="/leads" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<LeadsPage/>}/>}/>,
            <Route path="/profile" element={<PrivateRoute allowedRoles={['admin', 'manager', 'agent']} component={<AgentPage/>}/>}/>,
            <Route path="/help-requests/:helpRequestId" element={<PrivateRoute allowedRoles={['admin', 'manager', 'agent']} component={<HelpRequestPage/>}/>}/>,
            <Route path="/help-requests" element={<PrivateRoute allowedRoles={['admin', 'manager', 'agent']} component={<HelpRequestPage/>}/>}/>,
            <Route path="/unanswered-questions/pending" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<PendingQuestionsPage/>}/>}/>,
            <Route path="/unanswered-questions/rejected" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<RejectedQuestionsPage/>}/>}/>,
            <Route path="/unanswered-questions" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<UnansweredQuestionsPage/>}/>}/>,
            <Route path="/question-logs/:questionLogId" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<QuestionLogPage/>}/>}/>,
            <Route path="/question-logs" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<QuestionLogPage/>}/>}/>,
            <Route path="/settings" test="test" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<SettingsPage/>}/>}/>,
            <Route path="/analytics" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<AnalyticsPage/>}/>}/>,
            <Route path="/whatsapp-push-notifications/send" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<WhatsAppPushNotifcationNewJobPage/>}/>}/>,
            <Route path="/whatsapp-push-notifications/templates/create" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<WhatsAppTemplateCreatePage/>}/>}/>,
            <Route path="/whatsapp-push-notifications/templates" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<WhatsAppTemplatePage/>}/>}/>,
            <Route path="/whatsapp-push-notifications" element={<PrivateRoute allowedRoles={['admin', 'manager']} component={<WhatsAppTemplateSenderPage/>}/>}/>,
            <Route path="/knowledge-set-manager/:id/index/:indexId/version/:versionId" element={<PrivateRoute allowedRoles={['admin']} component={<IndexPage/>}/>}/>,
            <Route path="/knowledge-set-manager/:id" element={<PrivateRoute allowedRoles={['admin']} component={<KnowledgeSetPage/>}/>}/>,
            <Route path="/knowledge-set-manager" element={<PrivateRoute allowedRoles={['admin']} component={<KnowledgeSetManagerPage/>}/>}/>,
            <Route path="/edubot/:id/edit" element={<PrivateRoute allowedRoles={['admin']} component={<EduBotEditPage/>}/>}/>,
            <Route path="/edubot/:id" element={<PrivateRoute allowedRoles={['admin']} component={<EduBotPage/>}/>}/>,
            <Route path="/edubot" element={<PrivateRoute allowedRoles={['admin']} component={<EduBotListPage/>}/>}/>,
            <Route path="/errors" element={<PrivateRoute allowedRoles={['admin']} component={<ErrorPage/>}/>}/>,
            <Route element={<AdminRoute/>}>,
                <Route path="/admin" element={<AdminSettingsPage/>}/>,
            </Route>,
            <Route element={<PublicRoute/>}>,
                <Route path="/login" exact={true} element={<LoginPage/>}/>,
                <Route path="/reset-password/:id" element={<ResetPassword/>}/>,
                <Route path="/reset-password" element={<PasswordResetPage/>}/>,
            </Route>,
            <Route path="/set-password" element={<SetPasswordPage/>}/>,
            <Route path="/company" element={<CompanySelectPage/>}/>,
            <Route path="/meeting/:meetingId" exact={true} element={<MeetingRoom/>}/>,
            <Route path="/meeting" element={<MeetingRoom/>}/>,
            <Route path="*" element={<NotFoundPage/>}/>,
        ])
    );

    return (
        <RouterProvider router={router} />
    );
};

export default AppRouter;
