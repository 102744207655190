import React, { useState, useEffect } from 'react';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import InternalChatListItem from './InternalChatListItem';
import LoadMore from '../Base/LoadMore';
import { searchAgentChats } from '../../services/internalChats';
import Preloader from '../Preloader';
import { useSelector } from 'react-redux';

const InternalChatSearchResults = (props) => {
    const [searchTerm, setSearchTerm] = useState();
    const [loadingResults, setLoadingResults] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [searchLimit, setSearchLimit] = useState(20);
    const [searchOffset, setSearchOffset] = useState(20);

    const auth = useSelector(state => state.auth);

    const handleSearch = async () => {
        const chats = await searchAgentChats(
            auth.agent.agent._id,
            searchTerm,
            auth.selectedChatbot.token,
            searchLimit + 1
        );

        setHasMore(chats.length > searchLimit);

        if (chats.length > searchLimit) {
            chats.pop();
        }

        setSearchResults(chats);
    };

    const handleLoadMore = async () => {
        setSearchLimit((prevLimit) => prevLimit + searchOffset);
        setLoadingMore(true);
    };

    useEffect(() => {
        if (props.searchTerm !== searchTerm) {
            setLoadingResults(true);
            setSearchTerm(props.searchTerm);
        }
    }, [props.searchTerm]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm])

    useEffect(() => {
        if (loadingResults) {
            setLoadingResults(false);
        }
    }, [searchResults]);

    useEffect(() => {
        if (loadingMore) {
            handleSearch().then(() => setLoadingMore(false));
        }
    }, [loadingMore]);

    return (
        <div className="internal_chats_list">
            {!loadingResults ? (
                searchResults.length > 0 ? (
                    <div>
                        {searchResults.map((internalChat) => (
                            <InternalChatListItem
                                key={internalChat._id}
                                handleSelectChat={props.handleSelectChat}
                                chat={internalChat}
                                currentAgent={auth.agent.agent}
                            />
                        ))}
                        {hasMore && <LoadMore onClick={handleLoadMore} loadingMore={loadingMore} />}
                    </div>
                ) : (
                    <div className="internal_chats_list__message_badge">
                        <HiChatBubbleLeftRight className="internal_chats_list__message_badge__icon" />
                        No results
                    </div>
                )
            ) : (
                <Preloader />
            )}
        </div>
    );
};

export default InternalChatSearchResults;
