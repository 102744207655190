import React, { useState } from 'react';
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

const DynamicFieldModal = (props) => {
    const userTypeOptions = [{ label: 'Agent', value: 'agent' }, { label: 'User', value: 'user' }];
    const agentOptions = [{ label: 'First Name', value: 'first_name' }, { label: 'Last Name', value: 'last_name' }, { label: 'Email', value: 'email' }];
    const userOptions = [{ label: 'Name', value: 'name' }, { label: 'Email', value: 'email' }, { label: 'Phone Number', value: 'phone number' }, { label: 'Custom', value: 'custom' }];

    const [userType, setUserType] = useState(undefined);
    const [fieldName, setFieldName] = useState('');
    const [customFieldOpen, setCustomFieldOpen] = useState(false);

    const handleUserTypeSelect = (e) => {
        setUserType(e.target.value);
    };

    const handleFieldNameSelect = (e) => {
        const value = e.target.value;
        if (value === 'custom') {
            setFieldName('');
            setCustomFieldOpen(true);
        } else {
            setFieldName(value);
        }
    };

    const handleCustomFieldChange = (e) => {
        setFieldName(e.target.value);
    };

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal modal__preloader" onRequestClose={props.handleDyanmicMenuToggle}>
            <div className="modal__title">Dynamic Field</div>
            <div className="modal__content">Insert dynamically populated fields for either the user or the agent.</div>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="user-type-select-label">User Type</InputLabel>
                    <Select
                        labelId="user-type-select-label"
                        id="user-type-select"
                        value={userType}
                        label="User Type"
                        onChange={handleUserTypeSelect}
                    >
                        {userTypeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {userType && (
                <div>
                    <Box sx={{ minWidth: 120, mt: '1rem' }}>
                        <FormControl fullWidth>
                            <InputLabel id="field-name-select-label">Field Name</InputLabel>
                            <Select
                                labelId="field-name-select-label"
                                id="field-name-select"
                                value={fieldName}
                                label="Field Name"
                                onChange={handleFieldNameSelect}
                            >
                                {(userType === 'agent' ? agentOptions : userOptions).map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {customFieldOpen && <TextField sx={{ mt: '1rem' }} fullWidth type="text" className="mt-medium" onChange={handleCustomFieldChange} value={fieldName} placeholder="Enter custom field name" />}
                </div>
            )}
            <div className="modal__actions">
                <button className="btn" onClick={props.handleDyanmicMenuToggle}>Cancel</button>
                <button className="btn" disabled={!fieldName.trim().length > 0} onClick={() => { props.insertDynamicField(userType, fieldName) }}>Insert</button>
            </div>
        </Modal>
    );
};

export default DynamicFieldModal;