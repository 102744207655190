import { HiOutlineTrash, HiPlusCircle } from "react-icons/hi2";
import QuestionLogCommentEditor from "./QuestionLogCommentEditor";
import { toTimestamp } from "../../services/dates";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import ConfirmModal from "../Base/ConfirmModal";
import LoadingModal from "../Base/LoadingModal";

const QuestionLogComments = ({ questionLog, addingComment, handleCommentAdd, handleCommentDelete, handleAddCommentToggle }) => {
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [deletingComment, setDeletingComment] = useState(false);

    const handleCommentDeleteClick = (comment) => {
        setCommentToDelete(comment);
    }

    const handleCommentDeleteConfirm = async (comment) => {
        setCommentToDelete(null);
        setDeletingComment(true);
        await handleCommentDelete(comment);
        setDeletingComment(false);
    }

    return (
        <div className="question_log_details_pane__messages__comments">
            <div className="question_log_details_pane__messages__comments__container">
                {questionLog?.review.comment && (
                    <div className="question_log_details_pane__messages__comment">
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <b>{questionLog.conversation?.user?.user_info?.name ? `${questionLog.conversation.user.user_info.name}:` : 'Unknown user: '}</b>
                        </div>
                        {questionLog.review.comment}
                    </div>
                )}
                {questionLog?.agent_comments && questionLog.agent_comments.map((comment, index) => (
                    <div key={index} className="question_log_details_pane__messages__comment">
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <b>{`${comment.owner ? `${comment.owner.first_name} ${comment.owner.last_name}` : 'Agent'}:`}</b>
                            <div style={{ display: 'flex' }}>
                                <div className="timestamp" style={{ marginRight: '2px' }}>{toTimestamp(comment.createdAt)}</div>
                                <Tooltip title="Delete" arrow><div className="delete_note" onClick={() => { handleCommentDeleteClick(comment._id) }}><HiOutlineTrash/></div></Tooltip>
                            </div>
                        </div>
                        {comment.text}
                    </div>
                ))}
            </div>
            {addingComment ? (
                <QuestionLogCommentEditor handleAddCommentToggle={handleAddCommentToggle} handleCommentAdd={handleCommentAdd}/>
            ) : <button className="btn question_log_details_pane__messages__comment_add_button" style={{ marginRight: '7.5px' }} onClick={handleAddCommentToggle}><HiPlusCircle/>Add comment</button>}
            <ConfirmModal isOpen={commentToDelete} confirmText="Delete" title="Delete Comment" description="Are you sure you want to delete this comment? This cannot be undone." onConfirm={() => { handleCommentDeleteConfirm(commentToDelete); }} onRequestClose={() => { setCommentToDelete(null); }}/>
            <LoadingModal isOpen={deletingComment} title="Deleting Comment" description="Please wait while we delete the comment..."/>
        </div>
    )
}

export default QuestionLogComments;