import { useEffect } from "react";
import { titleCase } from "../../../services/misc";
import { TextareaAutosize, Switch, TextField } from "@mui/material";

const StepSettingValue = ({ step, value, handleStepUpdate }) => {
    switch(typeof(value)){
        case 'string':
             return <TextareaAutosize defaultValue={value} size="small" onChange={(e) => { handleStepUpdate(e.target.value) }}/>;
        case 'boolean':
            return <Switch checked={value} onChange={() => { handleStepUpdate(!value) }}/>
        case 'number':
            return <TextField value={value} fullWidth size="small" onChange={(e) => { handleStepUpdate(e.target.value) }}/>
        default:
            return <div></div>
    }
}


const StepSetting = ({ step, field, value, handleStepUpdate }) => {
    return (
        <div className={`edubot_detail ${['string', 'number'].includes(typeof(value)) ? 'detail_column' : ''}`}>
            <div className="edubot_detail__label">{titleCase(field)}</div>
            <div className="edubot_detail__value"><StepSettingValue step={step} value={value} handleStepUpdate={handleStepUpdate}/></div>
        </div>
    )
}

export default StepSetting;