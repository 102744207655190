import { HiOutlinePlus } from "react-icons/hi2";
import ModuleListItem from "./ModuleListItem";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay, } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { useState } from "react";
import ModuleListItemDraggable from "./ModuleListItemDraggable";

const ModuleManager = ({ modules, selectedModule, handleModuleUpdate, handleAddModuleClick, onModuleSelect, setModules }) => {
    const [activeId, setActiveId] = useState(null);
    
    const sensors = useSensors(
		useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            }
        }),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

    function handleDragStart(event) {
		const {active} = event;
        
		setActiveId(active.id);
	}
	
	function handleDragEnd(event) {
		const {active, over} = event;
		
		if (active.id.toString() !== over.id.toString()) {
            setModules((items) => {
                const oldIndex = items.findIndex(i => i._id == active.id);
				const newIndex = items.findIndex(i => i._id == over.id);
				
				return arrayMove(items, oldIndex, newIndex).map((item, index) => ({...item, order: index}));
            })
		}
		
		setActiveId(null);
	}
    
    return (
        <div className="modules_list">
            {modules?.length > 0 ? (
                <DndContext  sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} >
                    <SortableContext items={modules} strategy={verticalListSortingStrategy} >
                        {modules.map(module => (
                            <ModuleListItem id={module._id} key={module._id} module={module} selectedModule={selectedModule} handleModuleUpdate={handleModuleUpdate} onModuleSelect={onModuleSelect}/>
                        ))}
                    </SortableContext> 
                    <DragOverlay>
                        {activeId ? <ModuleListItemDraggable id={activeId} module={modules.find(i => i._id === activeId)} /> : null}
                    </DragOverlay>
                </DndContext>
            ) : (
                <div className="edubot_detail_message">
                    <div className="message_title">No modules configured</div>
                    Your EduBot currently has no enabled modules. Click the button below to start adding modules.
                    <button className="btn mt-medium" onClick={handleAddModuleClick}><HiOutlinePlus/>Add module</button>
                </div>
            )}
        </div>
    );
}

export default ModuleManager;