import { handleResponse } from './misc';

const getWebchat = (authToken, webchatId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/webchat/' + webchatId, requestOptions).then(handleResponse).then((webchat) => {
        return webchat;
    });
};

const updateWebchat = (authToken, webchatId, webchat) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(webchat)
    };

    return fetch('/api/webchat/' + webchatId, requestOptions).then(handleResponse).then((webchat) => {
        return webchat;
    });
}

export { getWebchat, updateWebchat };