import { setCurrentMessagesValue, setInternalChatsValue, updateInternalChats } from '../reducers/internalChatsSlice';
import { handleResponse } from './misc';
import moment from 'moment';

const getAgentChats = (agentId, authToken, limit) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    return fetch('/api/agents/' + agentId + '/internal/chats' + queryString, requestOptions).then(handleResponse).then((chats) => {
        return chats;
    });
};

const getDirectChat = (agentId, recipientId, authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/agents/' + agentId + '/internal/chats/direct/' + recipientId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const getChat = (chatId, authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/internal/chats/' + chatId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const searchAgentChats = (agentId, searchTerm, authToken, limit) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    return fetch('/api/agents/' + agentId + '/internal/chats/search/' + searchTerm + queryString, requestOptions).then(handleResponse).then((chats) => {
        return chats;
    });
};

const getChatMessages = (chatId, authToken, limit) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    return fetch('/api/internal/chats/' + chatId + '/messages' + queryString, requestOptions).then(handleResponse).then((messages) => {
        return messages;
    });
};

const setCurrentMessages = async (internalChats, auth, dispatch) =>{
    let messages = await getChatMessages(internalChats.selectedChat.chat._id, auth.selectedChatbot.token, internalChats.messagesLimit + 1);

    dispatch(updateInternalChats({
        hasMoreMessages: messages.length > internalChats.messagesLimit ? true : false
    }))

    if(messages.length > internalChats.messagesLimit){
        messages.pop();
    }

    messages = messages.sort((a, b) => moment(a.createdAt) - moment(b.createdAt));
    
    dispatch(setCurrentMessagesValue(messages));
}

const setInternalChats = async (internalChats, auth, dispatch) => {
    const chats = await getAgentChats(auth.agent.agent._id, auth.selectedChatbot.token, internalChats.internalChatsLimit + 1);
    
    dispatch(updateInternalChats({
        hasMore: chats.length > chats.internalChatsLimit ? true : false
    }));

    if(chats.length > chats.internalChatsLimit){
        chats.pop()
    }
    
    dispatch(setInternalChatsValue(chats));
}

const createdMessage = (chatId, message, authToken) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(message)
    };

    return fetch('/api/internal/chats/' + chatId + '/messages', requestOptions).then(handleResponse).then((message) => {
        return message;
    });
}

const createNotification = (chatId, notificationList, authToken) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(notificationList)
    };

    return fetch('/api/internal/chats/' + chatId + '/notification', requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const notificationShown = (chatId, agentId, authToken) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/internal/chats/' + chatId + '/notification/shown/' + agentId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const notificationRead = (chatId, agentId, authToken) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/internal/chats/' + chatId + '/notification/read/' + agentId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const createChat = (participants, authToken, createdBy) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ participants })
    };

    let queryString = '';
    if(createdBy){
        queryString = '?isGroupChat=' + createdBy
    }

    return fetch('/api/internal/chats' + queryString, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const newChatHandle = async (agentId, recipientId, authToken) => {
    try{
        const chat = await getDirectChat(agentId, recipientId, authToken);
        if(chat){ return chat; }
    }catch(e){
        const newChat = await createChat([agentId, recipientId], authToken);
        return newChat;
    }
}

const groupChatCreate = async (participants, authToken, createdBy) => {
    const newChat = await createChat(participants, authToken, createdBy);
    return newChat;
}

export { 
    getAgentChats, 
    getChatMessages, 
    createdMessage, 
    setCurrentMessages, 
    searchAgentChats, 
    setInternalChats, 
    getDirectChat, 
    newChatHandle, 
    createNotification, 
    notificationShown,
    notificationRead,
    getChat,
    groupChatCreate
};