import React from 'react';
import ChunkEditor from './ChunkEditor';
import ChunkContent from './ChunkContent';

const ChunkList = (props) => {
    return (
        props.chunks.map((chunk, index) => (
            <>
                {!(props.currentChunk && (props.currentChunk._id === chunk._id)) && <ChunkContent chunk={chunk} handleChunkEditorToggle={props.handleChunkEditorToggle} deletingChunk={props.deletingChunk} findIndex={props.findIndex} />}
                {(props.currentChunk && ((props.currentChunk._id === chunk._id) || (!props.currentChunk._id && (props.currentChunk.order - 2) === index))) && (
                    <ChunkEditor
                        chunk={props.currentChunk}
                        savingChunk={props.savingChunk}
                        handleChunkSave={props.currentChunk._id ? props.handleChunkSave : props.handleAddChunk}
                        handleChunkEditorToggle={props.handleChunkEditorToggle}
                        handleTextChange={props.handleTextChange}
                        handleDeleteChunkClick={(e) => { props.handleDeleteChunkClick(e, chunk._id) }}
                        handleKeepNewFormatClick={props.handleKeepNewFormatClick}
                        handleChunkAttachmentsAdd={props.handleChunkAttachmentsAdd}
                        handleChunkAttachmentUpdate={props.handleChunkAttachmentUpdate}
                        handleChunkAttachmentRemove={props.handleChunkAttachmentRemove}
                        handleSourceChange={props.handleSourceChange}
                    />
                )}
            </>
        ))
    );
}

export default ChunkList;
