import moment from 'moment';

const expiryTime = () => moment().add(1, 'days')

function handleResponse(response){
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function channelLabel(channel){
    if(channel === 'msteams'){ return 'Microsoft Teams' }
    if(channel === 'facebook'){ return 'Facebook Messenger' }
    if(channel === 'wechat'){ return 'WeChat' }
    return channel;
}

function getInitials(name){
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
}

function removeNull(array){
    return array.filter(item => item !== null);
}

function titleCase(str) {
    str = str.toLowerCase().replace(/_/g, ' ');
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    
    return str.join(' ');
}

function serialize(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

function bold(text){
    var bold = /\*(.*?)\*/gm;
    var html = text.replace(bold, '<strong>$1</strong>');            
    return html;
}

function italic(text){
    var italic = /\b_(?![_\s])(.*?[^_\s])_\b/gm;
    var html = text.replace(italic, '<i>$1</i>');            
    return html;
}  

export { handleResponse, getInitials, titleCase, serialize, removeNull, channelLabel, bold, italic, expiryTime };