import Modal from 'react-modal';
const ConfirmModal = (props) => {
    const confirmText = props.confirmText || 'Confirm';
    const cancelText = props.cancelText || 'Cancel';
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className={`modal modal__preloader ${props.className}`} onRequestClose={props.onRequestClose}>
            <div className="modal__title">{props.title}</div>
            <div className="modal__content">{props.description}</div>
            <div className="modal__actions">
                <button className="btn" onClick={props.onRequestClose}>{cancelText}</button>
                <button className="btn primary" onClick={props.onConfirm}>{confirmText}</button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;