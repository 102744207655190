import React from 'react';
import { connect } from 'react-redux';
import { titleCase } from '../../services/misc';
import { getComponentUpdateBody, getTemplateParameters } from '../../services/whatsAppTemplateSender';
import { TextField } from '@mui/material';

class WhatsAppTemplateFiller extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            parameters: {},
            components: []
        }

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const parameters = getTemplateParameters(this.props.template);
        const defaultUpdateBody = this.props.components || getComponentUpdateBody(parameters);
        this.setState({ parameters, components: defaultUpdateBody })
    }

    handleFieldChange(type, fieldName, index, value){
        let components = this.state.components;
        const componentIndex = components.findIndex(component => component.type === type);
        components[componentIndex].parameters[index][fieldName] = value;
        this.setState({ components }) 
    }

    handleSubmit(e){
        e.preventDefault();
        this.props.onSubmit(this.state.components);
    }
    
    render(){
        return (
            <div className='whatsapp_template_composer_items'>
                    <div className="page_title">Enter Parameters</div>
                    <p>This template contains parameters. Please fill in parameters before sending.</p>
                    <form method='POST' onSubmit={this.handleSubmit}>
                        {(this.state.parameters.header && this.state.parameters.header.type.toLowerCase() === 'text' && this.state.parameters.header.numberOfParameters.length > 0) && (
                            <div className="form-group">
                                <div className='whatsapp_template_composer__label' htmlFor="header">Header</div>
                                {this.state.parameters.header.numberOfParameters.map((variable, index) => (
                                    <div className="form-group">
                                        <TextField label="Header Variable" fullWidth type="text" placeholder={`Enter header variable`} onChange={(e) => { this.handleFieldChange('header', 'text', index, e.target.value) }} value={this.state.components.find(c => c.type === 'header').parameters[index].text} required/>
                                    </div>
                                ))}
                            </div>
                        )}
                        {(this.state.parameters.header && (this.state.parameters.header.type.toLowerCase() === 'image' || this.state.parameters.header.type.toLowerCase() === 'image' || this.state.parameters.header.type.toLowerCase() === 'document') && this.state.parameters.header.numberOfParameters.length > 0) && (
                            <div className="form-group">
                                <div className='whatsapp_template_composer__label' htmlFor="header">Header</div>
                                {this.state.parameters.header.numberOfParameters.map((variable, index) => (
                                    <div className="form-group">
                                        <TextField label={`${titleCase(this.state.parameters.header.type)} URL`} sx={{ mt: '8px' }} fullWidth type="text" placeholder={`Enter ${this.state.parameters.header.type.toLowerCase()} URL`} onChange={(e) => { this.handleFieldChange('header', this.state.parameters.header.type.toLowerCase(), index, { link: e.target.value }) }} value={this.state.components.find(c => c.type === 'header').parameters[index].text} required/>
                                    </div>
                                ))}
                            </div>
                        )}
                        {(this.state.parameters.body && this.state.parameters.body.numberOfParameters.length > 0) && (
                            <div className="form-group">
                                <div className='whatsapp_template_composer__label' htmlFor="body">Body</div>
                                {this.state.parameters.body.numberOfParameters.map((variable, index) => (
                                    <div className="form-group">
                                        <TextField fullWidth label={`Body variable ${variable}`} sx={{ mt: '8px' }} type="text" placeholder={`Enter body variable ${variable}`} onChange={(e) => { this.handleFieldChange('body', 'text', index, e.target.value) }} value={this.state.components.find(c => c.type === 'body').parameters[index].text} required/>
                                    </div>
                                ))}
                            </div>
                        )}
                        {(this.state.parameters.buttons && this.state.parameters.buttons.buttons.length > 0 && this.state.parameters.buttons.numberOfParameters > 0) && (
                            <div className="form-group">
                                <div className='whatsapp_template_composer__label' htmlFor="body">Buttons</div>
                                {this.state.parameters.buttons.buttons.map((button, index) => (
                                    <div className="form-group">
                                        {button.buttonUrl && <div className='whatsapp_template_composer__url_preview'>{this.state.components.find(c => c.type === 'button').parameters[index].text.length > 0 ? button.buttonUrl.replace(/\{[^]+\}/g, this.state.components.find(c => c.type === 'button').parameters[index].text) : button.buttonUrl}</div>}
                                        <TextField fullWidth label={`URL variable for <b>"${button.buttonText}"</b> button`} sx={{ mt: '8px' }} type="text" placeholder={`Enter "${button.buttonText}" URL`} onChange={(e) => { this.handleFieldChange('button', 'text', index, e.target.value) }} value={this.state.components.find(c => c.type === 'button').parameters[index].text} required/>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className='whatsapp_template_step__actions'>                                    
                            <button className='btn' onClick={this.props.onCancel}>Previous step</button>
                            <button type='submit' className='btn' onClick={this.handleSubmit}>Next</button>
                        </div>
                    </form>
                </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppTemplateFiller);