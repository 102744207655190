import { Switch } from '@mui/material';
import { HiVideoCamera, HiMicrophone } from 'react-icons/hi2';

const VideoAudioSettings = (props) => {
    return (
        <div className="video_audio_settings">
            <label className="toggle_box">
                <HiVideoCamera/>
                <Switch disabled={props.togglingVideo} onChange={() => { props.handleVideoToggle(!props.videoEnabled) }} checked={props.videoEnabled}/>
            </label>
            <label className="toggle_box">
                <HiMicrophone/>
                <Switch onChange={() => { props.handleAudioToggle(!props.microphoneEnabled) }} checked={props.microphoneEnabled}/>
            </label>
        </div>
    );
};

export default VideoAudioSettings;