import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChatNotesList from './ChatNotesList';
import ChatsAddNote from './ChatsAddNote';
import { addNote, removeNote } from '../../services/conversation';
import moment from 'moment';
import ConfirmModal from '../Base/ConfirmModal';
import { removeNull } from '../../services/misc';
import LoadingModal from '../Base/LoadingModal';

const ChatNotes = (props) => {
    const [noteChat, setNoteChat] = useState(props.chat);
    const [addingNote, setAddingNote] = useState(false);
    const [savingNote, setSavingNote] = useState(false);
    const [noteValue, setNoteValue] = useState('');
    const [noteMaxLength] = useState(300);
    const [deletePromptShown, setDeletePromptShown] = useState(false);
    const [deletingNote, setDeletingNote] = useState(false);
    const [currentNote, setCurrentNote] = useState(undefined);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (props.chat) {
            setNoteChat(props.chat);
        }
    }, [props.chat]);

    useEffect(() => {
        setCurrentNote(undefined);
        setDeletingNote(false);
    }, [noteChat]);

    const handleAddNoteTrigger = () => {
        setAddingNote((prevState) => !prevState);
        setNoteValue('');
    };

    const handleAddNoteChange = (e) => {
        if (e.target.value.length <= noteMaxLength) {
            setNoteValue(e.target.value);
        }
    };

    const handleAddNoteSubmit = (e) => {
        e.preventDefault();
        setSavingNote(true);
        const note = { text: noteValue, owner: auth.agent.agent._id };
        addNote(auth.selectedChatbot.token, props.chat._id, note).then((chat) => {
            setNoteValue('');
            setAddingNote(false);
            setSavingNote(false);
            setNoteChat((prevState) => ({
                ...prevState,
                notes: [
                    ...prevState.notes,
                    {
                        ...note,
                        createdAt: moment(),
                        owner: auth.agent.agent,
                    },
                ],
            }));
        });
    };

    const handleNoteDeleteModalTrigger = (noteId) => {
        setDeletePromptShown((prevState) => !prevState);
        setCurrentNote(noteId || undefined);
    };

    const handleNoteDelete = async () => {
        setDeletePromptShown(false);
        setDeletingNote(true);
        await removeNote(auth.selectedChatbot.token, props.chat._id, currentNote);
        props.handleSetCurrentConversation(props.chat.conversation_id);
    };

    return (
        <div className="notes">
            <ChatNotesList handleNoteDeleteModalTrigger={handleNoteDeleteModalTrigger} notes={removeNull(noteChat.notes)} />
            <ChatsAddNote
                addingNote={addingNote}
                savingNote={savingNote}
                noteMaxLength={noteMaxLength}
                noteValue={noteValue}
                handleAddNoteSubmit={handleAddNoteSubmit}
                handleAddNoteTrigger={handleAddNoteTrigger}
                handleAddNoteChange={handleAddNoteChange}
            />
            <ConfirmModal
                confirmText="Delete"
                title="Delete Note"
                description="Please note you are about to delete this note. This action cannot be undone."
                onRequestClose={handleNoteDeleteModalTrigger}
                isOpen={deletePromptShown}
                onConfirm={handleNoteDelete}
            />
            <LoadingModal text="Deleting note" isOpen={deletingNote} />
        </div>
    );
};

export default ChatNotes;
