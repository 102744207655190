import { useSelector } from "react-redux";
import { getEduBot, updateEduBot } from "../../services/edubot/edubot";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloader from "../Preloader";
import { Divider, Switch, TextField, TextareaAutosize, Autocomplete, Box, IconButton, Select, Snackbar, Alert } from "@mui/material";
import ModuleManager from "./ModuleManager/ModuleManager";
import { deleteModules, getModules, saveModules } from "../../services/edubot/module";
import ModulePage from "./ModuleManager/ModulePage";
import { BiSolidSave } from "react-icons/bi";
import { deleteSections, getSections, saveSections } from "../../services/edubot/section";
import SectionPage from "./SectionManager/SectionPage";
import { deleteSteps, getSteps, saveSteps } from "../../services/edubot/step";
import _ from 'lodash';
import ConfirmModal from "../Base/ConfirmModal";
import LoadingModal from "../Base/LoadingModal";
import { HiArrowUpTray, HiCheck, HiMiniXCircle, HiOutlinePlus, HiOutlineXCircle, HiTrash } from "react-icons/hi2";
import CreateModuleModal from "./ModuleManager/CreateModuleModal";
import { getModulePreview } from "../../services/edubot/module_templates";
import { getStepPreview } from "../../services/edubot/step_template";
import { getSectionPreview } from "../../services/edubot/section_template";
import { RiOpenaiFill } from "react-icons/ri";
import { getKnowledgeSets } from "../../services/knowedgeSetManager";
import { IoLibrary, IoLibraryOutline } from "react-icons/io5";
import { getWebchat, updateWebchat } from "../../services/webchat";
import WebChatThemeEditor from "./WebChatManager/WebChatThemeEditor";
import FileUpload from "../Base/FileUpload";
import FileUploadInput from "../Base/FileUploadInput";
import AlertBox from "../AlertBox";

const openAiModels = [{
    id: 'gpt-35-turbo',
    make: 'OpenAI',
    label: 'ChatGPT 3.5 Turbo',
    description: 'Fastest response time and is recommended for most use cases.'
}, {
    id: 'gpt-4-turbo',
    make: 'OpenAI',
    label: 'ChatGPT 4 Turbo',
    description: 'Slower responses and more expensive messages but higher accuracy and more advanced features.'
}];

const widgetSizeOptions = [
    { id: '', label: 'Normal' }, 
    { id: 'large', label: 'Large' }
];

const EduBotEditPage = () => {
    const auth = useSelector(state => state.auth)
    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    // EduBot, modules, sections and steps
    const [eduBot, setEdubot] = useState(null);
    const [stagingWidget, setStagingWidget] = useState(null);
    const [modules, setModules] = useState(null);
    const [sections, setSections] = useState(null);
    const [steps, setSteps] = useState(null); 

    const [modulesToDelete, setModulesToDelete] = useState([]);
    const [sectionsToDelete, setSectionsToDelete] = useState([]);
    const [stepsToDelete, setStepsToDelete] = useState([]);
    
    // Original EduBot, modules, sections and steps for comparison
    const [originalEduBot, setOriginalEduBot] = useState(null);
    const [originalStagingWidget, setOriginalStagingWidget] = useState(null);
    const [originalModules, setOriginalModules] = useState(null);
    const [originalSections, setOriginalSections] = useState(null);
    const [originalSteps, setOriginalSteps] = useState(null);

    // Selected module and section
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);

    const [confirmSaveChanges, setConfirmSaveChanges] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [changesSaved, setChangesSaved] = useState(false);

    // State for creating and adding modals
    const [creatingModal, setCreatingModal] = useState(false);
    const [addingModal, setAddingModal] = useState(false);

    // State for creating and adding sections
    const [creatingSection, setCreatingSection] = useState(false);
    const [addingSection, setAddingSection] = useState(false);

    const [knowledgeSetOptions, setKnowledgeSetOptions] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        fetchEduBot();
    }, []);

    const fetchEduBot = async () => {
        const eduBotId = params.id;
        const bot = await getEduBot(auth.selectedChatbot.token, eduBotId);
        const stagingWidget = await getWebchat(auth.selectedChatbot.token, bot.bot_config.staging_widget.webchat_id);

        if(!bot){
            // If EduBot not found, return to list page.
            navigate('/edubot', { replace: true })
        }

        // Get modules for the bot.
        const eduBotModules = await getModules(auth.selectedChatbot.token, bot._id)
        const eduBotSections = await getSections(auth.selectedChatbot.token, bot._id);
        const eduBotSteps = await getSteps(auth.selectedChatbot.token, bot._id);

        eduBotSteps.sort((a, b) => a.order - b.order);
        eduBotModules.sort((a, b) => a.order - b.order);

        setEdubot(bot);
        setStagingWidget(stagingWidget);
        setModules(eduBotModules);
        setSections(eduBotSections);
        setSteps(eduBotSteps);

        // Set original values for comparison
        setOriginalEduBot(bot);
        setOriginalStagingWidget(stagingWidget);
        setOriginalModules(eduBotModules);
        setOriginalSections(eduBotSections);
        setOriginalSteps(eduBotSteps);

        // Get knowledge sets for select
        const knowledgeSets = await getKnowledgeSets(auth.selectedChatbot.token);
        setKnowledgeSetOptions(knowledgeSets.map(kb => ({ id: kb._id, label: kb.name })));

        setLoading(false);
    }    

    // Update selected module on modules update
    useEffect(() => {
        if(modules && selectedModule){
            const selectedInList = modules.find(module => module._id === selectedModule._id);
            if(!_.isEqual(selectedModule, selectedInList)){
                setSelectedModule(selectedInList);
            }
        }
    }, [modules])

    // Update selected section on sections update
    useEffect(() => {
        if(sections && selectedSection){
            const selectedInList = sections.find(section => section._id === selectedSection._id);
            if(!_.isEqual(selectedSection, selectedInList)){
                setSelectedSection(selectedInList);
            }
        }
    }, [sections])

    const changesAreUnsaved = () => {
        // Check if any changes are unsaved
        if(!_.isEqual(originalEduBot, eduBot) ||  !_.isEqual(originalStagingWidget, stagingWidget) || !_.isEqual(originalModules, modules) || !_.isEqual(originalSections, sections) || !_.isEqual(originalSteps, steps)){
            return true;
        }

        return false;
    }

    // Block navigating elsewhere when changes are unsaved
    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
        (changesAreUnsaved() && !changesSaved) && currentLocation.pathname !== nextLocation.pathname
    );

    const handleSavesChangesClick = () => {
        setConfirmSaveChanges(prevConfirmSaveChanges => !prevConfirmSaveChanges);
    }

    const handleSavesChanges = async () => {
        setSavingChanges(true);
        setConfirmSaveChanges(false);

        try{
            const modifiedEduBot = eduBot;
            const modifiedModules = [];
            const modifiedSections = [];
            const modifiedSteps = [];

            // Check if EduBot has been modified
            if(!_.isEqual(originalEduBot, eduBot)){
                // Save EduBot
                await updateEduBot(auth.selectedChatbot.token, modifiedEduBot);
                console.log('EduBot Modified: ', modifiedEduBot);
            }

            // Check if webchat has been modified
            if(!_.isEqual(originalStagingWidget, stagingWidget)){
                // Save webchat
                await updateWebchat(auth.selectedChatbot.token, stagingWidget._id, stagingWidget);
                console.log('Webchat Modified: ', stagingWidget);
            }

            // Check if modules have been modified
            if(!_.isEqual(originalModules, modules)){
                modules.forEach(module => {
                    if(!_.isEqual(originalModules.find(originalModule => originalModule._id === module._id), module)){
                        modifiedModules.push(module);
                    }
                });

                await saveModules(auth.selectedChatbot.token, modifiedModules);
                console.log('Modules Modified: ', modifiedModules);
            }

            // Check if sections have been modified
            if(!_.isEqual(originalSections, sections)){
                sections.forEach(section => {
                    if(!_.isEqual(originalSections.find(originalSection => originalSection._id === section._id), section)){
                        modifiedSections.push(section);
                    }
                });

                await saveSections(auth.selectedChatbot.token, modifiedSections);
                console.log('Sections Modified: ', modifiedSections);
            }

            // Check if steps have been modified
            if(!_.isEqual(originalSteps, steps)){
                steps.forEach(step => {
                    if(!_.isEqual(originalSteps.find(originalStep => originalStep._id === step._id), step)){
                        modifiedSteps.push(step);
                    }
                });

                await saveSteps(auth.selectedChatbot.token, modifiedSteps);
                console.log('Steps Modified: ', modifiedSteps);
            }

            // Check if any modules, sections or steps have been deleted
            if(modulesToDelete?.length > 0){
                await deleteModules(auth.selectedChatbot.token, modulesToDelete);
            }

            if(sectionsToDelete?.length > 0){
                await deleteSections(auth.selectedChatbot.token, sectionsToDelete);
            }

            if(stepsToDelete?.length > 0){
                await deleteSteps(auth.selectedChatbot.token, stepsToDelete);
            }

            // Update original values
            await fetchEduBot();

            setChangesSaved(true);
            setSavingChanges(false);
        }catch(e){
            setSavingChanges(false);
            setError('Failed to save changes. Please try again later.');
        }
    }

    useEffect(() => {
        if(changesSaved){ 
            setTimeout(() => {
                setChangesSaved(false);
            }, 3000);
        }
    }, [changesSaved])

    const handleEduBotEdit = (field, value) => {
        setEdubot(prevEduBot => ({
            ...prevEduBot,
            [field]: value
        }));
    }

    const handleStagingWidgetEdit = (field, value) => {
        setStagingWidget(prevStagingWidget => ({
            ...prevStagingWidget,
            [field]: value
        }));
    }

    // Handle module update
    const handleModuleUpdate = (moduleId, field, value) => {
        setModules(prevModules => {
            const updatedModules = prevModules.map(module => {
                if(module._id === moduleId){
                    return {
                        ...module,
                        [field]: value
                    }
                }

                return module;
            });

            return updatedModules;
        });
    }

    // Handle section update
    const handleSectionUpdate = (sectionId, field, value) => {
        setSections(prevSections => {
            const updatedSections = prevSections.map(section => {
                if(section._id === sectionId){
                    return {
                        ...section,
                        [field]: value
                    }
                }

                return section;
            });

            return updatedSections;
        });
    }

    // Handle step update
    const handleStepUpdate = (stepId, field, value) => {
        setSteps(prevSteps => {
            const updatedSteps = prevSteps.map(step => {
                if(step._id === stepId){
                    return {
                        ...step,
                        [field]: value
                    }
                }

                return step;
            });

            return updatedSteps;
        });
    }

    const handleCancelClick = () => {
        navigate(`/edubot/${eduBot._id}`);
    }

    // Click events for modules and sections
    const handleModuleClick = (module) => {
        setSelectedModule(module);
    }

    const handleSectionClick = (section) => {
        setSelectedSection(section);
    }

    // Add a new module
    const handleAddModuleClick = () => {
        setCreatingModal(prevCreatingModal => !prevCreatingModal);
    }

    const handleModuleCreate = async (moduleTemplate) => {
        setAddingModal(true);
        setCreatingModal(false);
        const { module, sections, steps } = await getModulePreview(auth.selectedChatbot.token, eduBot._id, moduleTemplate._id);
        module.order = modules.length;
        setModules(prevModules => [...prevModules, module]);
        setSections(prevSections => [...prevSections, ...sections]);
        setSteps(prevSteps => [...prevSteps, ...steps]);

        setAddingModal(false);
    }

    // Add a new section
    const handleAddSectionClick = () => {
        setCreatingSection(prevCreatingSection => !prevCreatingSection);
    }

    const handleAddSection = async (sectionTemplate, moduleId) => {
        setAddingSection(true);
        setCreatingSection(false);
        const { section, steps } = await getSectionPreview(auth.selectedChatbot.token, eduBot._id, sectionTemplate._id, moduleId);
        section.order = sections.filter(sec => sec.module_id === moduleId).length;
        setSections(prevSections => [...prevSections, section]);
        setSteps(prevSteps => [...prevSteps, ...steps]);

        setAddingSection(false);
    }

    // Add a new step
    const handleStepCreate = async (stepTemplate, sectionId, order) => {
        const stepPreview = await getStepPreview(auth.selectedChatbot.token, eduBot._id, stepTemplate._id, sectionId);
        const sectionSteps = [...steps.filter(step => step.section_id === sectionId)]
        
        const newSteps = [...sectionSteps];
        newSteps.splice(order, 0, stepPreview);

        const otherSteps = steps.filter(step => step.section_id !== sectionId);
        setSteps([...otherSteps, ...newSteps.map((step, index) => ({...step, order: index}))]);
    }

    // Delete a module
    const handleModuleDelete = (moduleToDelete) => {
        setModules(prevModules => prevModules.filter(module => module._id !== moduleToDelete._id));
        setModulesToDelete(prevModulesToDelete => [...prevModulesToDelete, moduleToDelete]);
    }

    // Delete a section
    const handleSectionDelete = (sectionToDelete) => {
        setSections(prevSections => prevSections.filter(section => section._id !== sectionToDelete._id));
        setSectionsToDelete(prevSectionsToDelete => [...prevSectionsToDelete, sectionToDelete]);
    }

    // Delete a step
    const handleStepDelete = (stepToDelete) => {
        setSteps(prevSteps => prevSteps.filter(step => step._id !== stepToDelete._id));
        setStepsToDelete(prevStepsToDelete => [...prevStepsToDelete, stepToDelete]);
    }

    let componentRender = (!loading && eduBot) ? (
        <div className="edubot_page edubot_edit_page">
            <div className="edubot_topbar">
                <h1 className="topbar_title">Edit EduBot</h1>
                <div className="edubot_topbar_actions">
                    <button className="topbar_action_button" onClick={handleCancelClick}>Cancel</button>
                    <button className="topbar_action_button primary" disabled={!changesAreUnsaved() || changesSaved} onClick={handleSavesChangesClick}>{changesSaved ? <>Saved<HiCheck/></> : <>Save<BiSolidSave  /></>}</button>
                </div>
            </div>
            <div className="edubot_page_container">
                <div className="edubot_details_pane">
                    <div className="edubot_details_pane__inner">
                        <div className="edubot_details_section__label">Bot configuration</div>
                        <div className="edubot_details_section">
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">Bot name</div>
                                <div className="edubot_detail__value"><TextField size="small" fullWidth onChange={(e) => { handleEduBotEdit('name', e.target.value) }} value={eduBot.name}/></div>
                            </div>
                            <Divider className="edubot_details_section__divider"/>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Welcome message
                                    <div className="edubot_detail__label__description">Maximum amount of 3 welcome messages permitted.</div>
                                </div>
                                <div className="edubot_detail__value column">
                                    {eduBot.bot_config.welcome_messages.map((message, index) => (
                                        <div className="edubot_detail__value__message_add" key={index}>
                                            <TextField label={`Welcome message ${index + 1}`} minRows={3} size="small" multiline={true} fullWidth value={message} placeholder="Enter welcome message" onChange={(e) => { handleEduBotEdit('bot_config', { ...eduBot.bot_config, welcome_messages: eduBot.bot_config.welcome_messages.map((msg, i) => i === index ? e.target.value : msg) }) }}/>
                                            <button className="edubot_detail__value__message_delete" onClick={() => { handleEduBotEdit('bot_config', { ...eduBot.bot_config, welcome_messages: eduBot.bot_config.welcome_messages.filter((msg, i) => i !== index) }) }}>
                                                <HiOutlineXCircle/>
                                            </button>
                                        </div>
                                    ))}
                                    <button className="btn" disabled={eduBot.bot_config.welcome_messages?.length >= 3} onClick={() => { handleEduBotEdit('bot_config', { ...eduBot.bot_config, welcome_messages: [...eduBot.bot_config.welcome_messages, ''] }) }}><HiOutlinePlus/>Add message</button>
                                </div>
                            </div>
                            <Divider className="edubot_details_section__divider"/>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Initial module
                                    <div className="edubot_detail__label__description">Begin the bot process with a module before sending the user to the module menu. If left empty, the user will proceed straight to the module menu.</div>
                                </div>
                                <div className="edubot_detail__value column">
                                    <Autocomplete
                                        className="edubot_detail__value_select"
                                        options={modules.map(module => ({ id: module._id, label: module.module_template.name, description: module.module_template.description, icon: module.module_template.icon }))}
                                        autoHighlight
                                        fullWidth
                                        value={modules.map(module => ({ id: module._id, label: module.module_template.name, description: module.module_template.description, icon: module.module_template.icon })).find(moduleOption => moduleOption.id === eduBot.bot_config.initial_module) || null}
                                        onChange={(e, value) => { handleEduBotEdit('bot_config', { ...eduBot.bot_config, initial_module: value?.id }) }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} className="edubot_detail__value_select__option">
                                                <div className="edubot_detail__value_select__option__icon" dangerouslySetInnerHTML={{ __html: option.icon }}/>
                                                <div>
                                                    <div className="edubot_detail__value_select__option__label"><strong>{option.label}</strong></div>
                                                    <div className="edubot_detail__value_select__option__description">{option.description}</div>
                                                </div>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Initial Module"
                                                placeholder="Click to select an inital module"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: null
                                                }}
                                                size="small"
                                            />
                                    )}/>
                                </div>
                            </div>
                        </div>
                        <div className="edubot_details_section__label">
                            Modules
                            <div className="edubot_details_section__label__actions">
                                <button className="btn" onClick={handleAddModuleClick}><HiOutlinePlus/>Add Module</button>
                            </div>
                        </div>
                        <div className="edubot_details_section">
                            <ModuleManager eduBot={eduBot} modules={modules} selectedModule={selectedModule} handleModuleUpdate={handleModuleUpdate} handleAddModuleClick={handleAddModuleClick} onModuleSelect={handleModuleClick} setModules={setModules}/>
                        </div>
                        <div className="edubot_details_section__label">ChatGPT</div>
                        <div className="edubot_details_section">
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    ChatGPT Enabled
                                    <div className="edubot_detail__label__description">Enable ChatGPT for this EduBot. Users will be able to query your knowledge set and ask questions about their EduBot journey using the power of ChatGPT models.</div>
                                </div>
                                <div className="edubot_detail__value"><Switch checked={eduBot.gpt_enabled} onChange={() => { handleEduBotEdit('gpt_enabled', !eduBot.gpt_enabled) }}/></div>
                            </div>
                            <Divider className="edubot_details_section__divider"/>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">Model</div>
                                <div className="edubot_detail__value">
                                    <Autocomplete 
                                        className="edubot_detail__value_select"
                                        options={openAiModels} 
                                        autoHighlight 
                                        fullWidth
                                        value={openAiModels.find(model => model.id === eduBot.gpt_config.model) || null}
                                        disableClearable={true}
                                        onChange={(e, value) => { handleEduBotEdit('gpt_config', { ...eduBot.gpt_config, model: value.id }) }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} className="edubot_detail__value_select__option">
                                                <div className="edubot_detail__value_select__option__icon"><RiOpenaiFill/></div>
                                                <div>
                                                    <div className="edubot_detail__value_select__option__label"><strong>{option.make}</strong> {option.label}</div>
                                                    <div className="edubot_detail__value_select__option__description">{option.description}</div>
                                                </div>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select an OpenAI Model"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: null
                                                }}
                                                size="small"
                                            />
                                    )}/>
                                </div>
                            </div>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Custom Instructions
                                    <div className="edubot_detail__label__description">Add custom instructions for the model to consider when answering user queries. This will be added in addition to the base EduBot model programming.</div>
                                </div>
                                <div className="edubot_detail__value"><TextareaAutosize minRows={5} value={eduBot.gpt_config.custom_instructions} placeholder="Enter custom instructions" onChange={(e) => { handleEduBotEdit('gpt_config', { ...eduBot.gpt_config, custom_instructions: e.target.value }) }}/></div>
                            </div>
                            <Divider className="edubot_details_section__divider"/>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Knowledge Set
                                </div>
                                <div className="edubot_detail__value">
                                <Autocomplete 
                                    className="edubot_detail__value_select"
                                    options={knowledgeSetOptions} 
                                    autoHighlight 
                                    fullWidth
                                    value={knowledgeSetOptions.find(kbOption => kbOption.id === eduBot.connected_knowledge_set) || null}
                                    disableClearable={true}
                                    onChange={(e, value) => { handleEduBotEdit('connected_knowledge_set', value.id) }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} className="edubot_detail__value_select__option flex-middle">
                                            <div className="edubot_detail__value_select__option__icon"><IoLibraryOutline/></div>
                                            <div>
                                                <div className="edubot_detail__value_select__option__label"><strong>{option.label}</strong></div>
                                            </div>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Knowledge Set"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: null
                                            }}
                                            size="small"
                                        />
                                )}/>
                                </div>
                            </div>
                        </div>
                        <div className="edubot_details_section__label">Widget settings</div>
                        <div className="edubot_details_section">
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Enabled
                                </div>
                                <div className="edubot_detail__value"><Switch checked={stagingWidget.settings.is_enabled} onChange={() => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, is_enabled: !stagingWidget.settings.is_enabled }) }}/></div>
                            </div>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Name
                                    <div className="edubot_detail__label__description">Configure your EduBot's name. This may be visible on the widget.</div>
                                </div>
                                <div className="edubot_detail__value"><TextField label="EduBot name" size="small" helperText="Max 20 characters" inputProps={{ maxLength: 20 }} fullWidth onChange={(e) => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, name: e.target.value }) }} value={stagingWidget.settings.name}/></div>
                            </div>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Auto start enabled
                                </div>
                                <div className="edubot_detail__value"><Switch checked={stagingWidget.settings.auto_start} onChange={() => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, auto_start: !stagingWidget.settings.auto_start }) }}/></div>
                            </div>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Widget size
                                    <div className="edubot_detail__label__description">Change the size of the widget, you will only be able to view this change in the live preview.</div>
                                </div>
                                <div className="edubot_detail__value">
                                    <Autocomplete
                                        className="edubot_detail__value_select"
                                        options={widgetSizeOptions}
                                        autoHighlight
                                        fullWidth
                                        value={widgetSizeOptions.find(option => option.id === stagingWidget.settings.widget_size) || null}
                                        onChange={(e, value) => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, widget_size: value.id }) }}
                                        disableClearable={true}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} className="edubot_detail__value_select__option">
                                                <div className="edubot_detail__value_select__option__label">{option.label}</div>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Widget Size"
                                                placeholder="Click to select a size"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: null
                                                }}
                                                size="small"
                                            />
                                    )}/>
                                </div>
                            </div>
                            <Divider className="edubot_details_section__divider"/>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Avatar
                                </div>
                                <div className="edubot_detail__value">
                                    <FileUploadInput 
                                        value={stagingWidget.settings.avatar} 
                                        fileTypes={['png', 'jpg']}
                                        modalTitle="Upload avatar" 
                                        modalDescription="Upload your bot avatar by below by clicking upload or dragging your file in the below box." 
                                        onSuccessfulUpload={(url) => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, avatar: url }) }}
                                    />
                                </div>
                            </div>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Avatar as icon
                                    <div className="edubot_detail__label__description">Replace the default icon with your chosen bot avatar.</div>
                                </div>
                                <div className="edubot_detail__value"><Switch checked={stagingWidget.settings.avatar_as_icon} onChange={() => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, avatar_as_icon: !stagingWidget.settings.avatar_as_icon }) }}/></div>
                            </div>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Header image
                                    <div className="edubot_detail__label__description">Add a header image to your widget, this will replace your icon in the header.</div>
                                </div>
                                <div className="edubot_detail__value">
                                    <FileUploadInput 
                                        value={stagingWidget.settings.header_image} 
                                        fileTypes={['png', 'jpg']}
                                        modalTitle="Upload header image" 
                                        modalDescription="Upload your bot header image by below by clicking upload or dragging your file in the below box." 
                                        onSuccessfulUpload={(url) => { handleStagingWidgetEdit('settings', { ...stagingWidget.settings, header_image: url }) }}
                                    />
                                </div>
                            </div>
                            <Divider className="edubot_details_section__divider"/>
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">
                                    Theme
                                </div>
                                <div className="edubot_detail__value">
                                    <WebChatThemeEditor webchat={stagingWidget} handleWidgetUpdate={handleStagingWidgetEdit}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {creatingModal && <CreateModuleModal isOpen={creatingModal} onModuleAdd={handleModuleCreate} addedModules={modules} onRequestClose={handleAddModuleClick}/>}
            {addingModal && <LoadingModal isOpen={addingModal}/>}
        </div>
    ) : <Preloader/>;

    if(selectedModule && !selectedSection){
        componentRender = <ModulePage 
            eduBot={eduBot} 
            module={selectedModule} 
            sections={sections} 
            handleModuleUpdate={handleModuleUpdate}
            handleSectionUpdate={handleSectionUpdate}
            onBackClick={() => { handleModuleClick(null); }}
            onSectionSelect={handleSectionClick}
            creatingSection={creatingSection}
            addingSection={addingSection}
            handleAddSectionClick={handleAddSectionClick}
            handleAddSection={handleAddSection}
            handleModuleDelete={handleModuleDelete}
            handleSavesChangesClick={handleSavesChangesClick}
            changesAreUnsaved={changesAreUnsaved}
            changesSaved={changesSaved}
            setSections={setSections}
        />
    }else if(selectedModule && selectedSection){
        // Show section editor
        componentRender = <SectionPage 
            eduBot={eduBot}
            section={selectedSection}
            steps={steps.filter(step => step.section_id === selectedSection._id)}
            setSteps={setSteps}
            handleSectionUpdate={handleSectionUpdate}
            handleStepUpdate={handleStepUpdate}
            handleStepCreate={handleStepCreate}
            handleStepDelete={handleStepDelete}
            handleSectionDelete={handleSectionDelete}
            onBackClick={() => { handleSectionClick(null); }}
            handleSavesChangesClick={handleSavesChangesClick}
            changesAreUnsaved={changesAreUnsaved}
            changesSaved={changesSaved}
        />
    }
    
    return (
        <>
            {changesSaved && (
                <Snackbar open={changesSaved} 
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }} autoHideDuration={3000} onClose={() => { setChangesSaved(false) }}>
                    <Alert
                            onClose={() => { setChangesSaved(false) }}
                            type="success"
                            sx={{ width: '100%' }}
                        >
                            Changes saved
                    </Alert>
                </Snackbar>
            )
            }
            {confirmSaveChanges && (
                <ConfirmModal 
                    isOpen={confirmSaveChanges} 
                    title="Save changes" 
                    description="You are about to save your changes. Please confirm you would like to proceed."
                    confirmText="Save changes"
                    cancelText="Cancel"
                    onConfirm={handleSavesChanges}
                    onRequestClose={handleSavesChangesClick}
                />
            )}
            {savingChanges && <LoadingModal isOpen={savingChanges} text="Saving changes..." />}
            {blocker.state === 'blocked' && <ConfirmModal isOpen={blocker.state === 'blocked'} title="Unsaved changes" description="You have unsaved changes. Are you sure you want to leave this page?" confirmText="Leave page" cancelText="Stay on page" onConfirm={() => { blocker.proceed() }} onRequestClose={() => { blocker.reset() }} />}
            {componentRender}
        </>
    );
}

export default EduBotEditPage;