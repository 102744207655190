import React, { Component } from 'react';
import { HiMagnifyingGlass, HiXMark } from 'react-icons/hi2';

class IndexSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (event) => {
        this.setState({ searchText: event.target.value });
    }

    handleSearch = () => {
        // Perform search logic here
        this.props.searchChunks(this.state.searchText.trim());
    }

    handleClearSearch = () => {
        this.setState({ searchText: '' });
        this.props.clearSearch();
    }

    render() {
        const searched = this.props.searchResults;
        return (
            <form className='index_search_bar' onSubmit={(e) => { e.preventDefault() }}>
                <input type="text" placeholder='Type to search...' style={{ paddingRight: searched ? '80px' : '40px' }} className='input-field' value={this.state.searchText} onChange={this.handleInputChange} />
                <div className='input_search_buttons'>
                    {searched && <div className='input_button' onClick={this.handleClearSearch}><HiXMark/></div>}
                    <button onClick={this.handleSearch} className='input_button'  type='submit'><HiMagnifyingGlass/></button>
                </div>
            </form>
        );
    }
}

export default IndexSearchBar;
