import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    loadingConversation: false,
    currentConversation: undefined,
    messageLimit: 10,
    messageOffset: 10,
    hasMoreMessages: false,
    loadingMoreMessages: false,
    goToConversation: null
}

export const chatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        goToConversation(state, action){
            const { conversation, back } = action.payload;

            if (back) {
                state.back = {
                    conversation,
                    ...back
                };
            }

            state.goToConversation = conversation;
        },
        updateChats: (state, action) => {
            return {
                ...state,
                ...action.chats
            }
        },
        resetMessageLimit: (state, action) => {
            return {
                ...state,
                ...action.chats,
                messageLimit: initialState.messageLimit,
                messageOffset: initialState.messageOffset,
                hasMoreMessages: initialState.hasMoreMessages,
                loadingMoreMessages: initialState.loadingMoreMessages
            }
        },
        clearChats: (state) => {
            return initialState;
        }
    }
});

export const { updateChats, resetMessageLimit, clearChats, goToConversation } = chatsSlice.actions;

export default chatsSlice.reducer;