import { HiOutlineCheckCircle, HiOutlinePlusCircle } from 'react-icons/hi2';
import TextareaAutosize from 'react-textarea-autosize';
import Preloader from '../Preloader';

const ChatsAddNote = (props) => {
    return (
        <div>
            {props.addingNote ? (
                <div>
                    {!props.savingNote ? (
                        <form onSubmit={props.handleAddNoteSubmit}>
                        <TextareaAutosize placeholder="Enter your note" onChange={props.handleAddNoteChange} className="chat_notes_add" value={props.noteValue}/>
                        <div className="widget_actions">
                            <div className="max_length">{props.noteValue.length}/{props.noteMaxLength}</div>
                            <div className="widget_actions__inner">
                                <div className="btn" onClick={props.handleAddNoteTrigger}>Cancel</div>
                                <button className="btn" disabled={props.noteValue.length > props.noteMaxLength || props.noteValue.length === 0}><HiOutlineCheckCircle/>Save</button>
                            </div>
                        </div>
                    </form>
                    ) : <Preloader text="Adding note..."/>}
                </div>
            ) : (
                <div className="widget_actions">
                    <div className="btn" onClick={props.handleAddNoteTrigger}><HiOutlinePlusCircle/>Add Note</div>
                </div>
            )}
        </div>
    );
};

export default ChatsAddNote;