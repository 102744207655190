import { Divider, Tooltip } from "@mui/material";
import { HiArrowLeft, HiCheck, HiOutlinePlus, HiOutlineTrash } from "react-icons/hi2";
import { FiSave } from "react-icons/fi";
import { MdReorder } from "react-icons/md";
import StepManager from "../StepManager/StepManager";
import SectionSettings from "./SectionSettings";
import { useEffect, useState } from "react";
import StepReOrderModal from "../StepManager/StepReOrderModal";
import StepSettings from "../StepManager/StepSettings";
import _, { create, set } from 'lodash';
import CreateStepModal from "../StepManager/CreateStepModal";
import LoadingModal from "../../Base/LoadingModal";
import { BiSolidSave } from "react-icons/bi";

const SectionPage = ({ eduBot, section, steps, setSteps, handleSectionUpdate, handleStepUpdate, handleStepCreate, handleStepDelete, handleSectionDelete, onBackClick, changesAreUnsaved, handleSavesChangesClick, changesSaved }) => {
    const [reOrderingSteps, setReOrderingSteps] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);
	const [creatingStep, setCreatingStep] = useState(false);
    const [loadingAddStep, setLoadingAddStep] = useState(false);
    const [triggerStepDeselect, setTriggerStepDeselect] = useState(false);

    // Update selected step on steps update
    useEffect(() => {
        if(steps && selectedStep){
            const selectedInList = steps.find(step => step._id === selectedStep._id);
            if(!_.isEqual(selectedStep, selectedInList)){
                setSelectedStep(selectedInList);
            }
        }
    }, [steps])

    const handleReOrderStepsClick = () => {
        setReOrderingSteps((prev) => !prev);
    }

    const handleStepSelect = (step) => {
        setSelectedStep(step);
    }

    const handleStepDeselect = () => {
        setTriggerStepDeselect(true);
    }

	const handleAddStepClick = (step) => {
		setCreatingStep(step);
	}

    const handleStepAdd = async (step) => {
        setLoadingAddStep(true);
        let order = steps.length + 1;

        if(creatingStep?.order >= 0){
            order = creatingStep.order + 1;            
        }

        setCreatingStep(null);
        await handleStepCreate(step, section._id, order);
        setLoadingAddStep(false);
    }

    return (
        <div className="edubot_page edubot_edit_page">
            <div className="edubot_topbar">
                <Tooltip title="Back to module settings" arrow><button className="edubot_topbar__back_button" onClick={onBackClick}><HiArrowLeft/></button></Tooltip>
                <h1 className="topbar_title">Edit {section.section_template.name}</h1>
                <div className="edubot_topbar_actions">
                    <button className="topbar_action_button delete" onClick={() => { handleSectionDelete(section) }}>Delete section<HiOutlineTrash/></button>
                    <Divider orientation="vertical" flexItem/>
                    <button className="topbar_action_button" onClick={handleReOrderStepsClick}>Reorder<MdReorder/></button>
                    <button className="topbar_action_button" onClick={() => { handleAddStepClick({}) }}>Add Step<HiOutlinePlus/></button>
                    <button className="topbar_action_button primary" disabled={!changesAreUnsaved() || changesSaved} onClick={handleSavesChangesClick}>{changesSaved ? <>Saved<HiCheck/></> : <>Save<BiSolidSave /></>}</button>
                </div>
            </div>
            <div className="edubot_page_container">
                <StepManager 
                    steps={steps}
                    section={section} 
                    selectedStep={selectedStep}
                    handleStepSelect={handleStepSelect}
                    handleAddStepClick={handleAddStepClick}
                    triggerStepDeselect={triggerStepDeselect}
                    setTriggerStepDeselect={setTriggerStepDeselect}
                />
                {selectedStep ? (
                    <StepSettings eduBot={eduBot} step={selectedStep} handleStepUpdate={handleStepUpdate} handleStepDeselect={handleStepDeselect} handleStepDelete={handleStepDelete}/>
                ) : <SectionSettings eduBot={eduBot} section={section} handleSectionUpdate={handleSectionUpdate}/>}
            </div>
            {reOrderingSteps && (
                <StepReOrderModal 
                    steps={steps}
                    isOpen={reOrderingSteps}
                    setSteps={setSteps}
                    onRequestClose={handleReOrderStepsClick}
                    onConfirm={handleReOrderStepsClick}
                />
            )}
            {creatingStep && (
                <CreateStepModal 
                    isOpen={creatingStep}
                    onRequestClose={() => setCreatingStep(null)}
                    sectionTemplate={section.section_template}
                    onStepAdd={handleStepAdd}
                />
            
            )}
            {loadingAddStep && <LoadingModal isOpen={loadingAddStep}/>}
        </div>
    )
}

export default SectionPage;