import React, { Component } from 'react';
import { HiChatBubbleLeft, HiDocumentText, HiOutlineCheckCircle } from 'react-icons/hi2';
import Modal from 'react-modal';
import FileUpload from '../Base/FileUpload';
import Preloader from '../Preloader';
import AlertBox from '../AlertBox';
import { TextField } from '@mui/material';

class IndexCreateModal extends Component {
    constructor(props){ 
        super(props);

        this.indexTypes = [{
            icon: <HiDocumentText/>,
            type: 'document',
            label: 'Document',
            description: 'Index based on a Office or PDF document'
        },{
            icon: <HiChatBubbleLeft/>,
            type: 'custom',
            label: 'Custom',
            description: 'Create custom chunks for your knowledge set'
        }]

        this.isValid = this.isValid.bind(this);
    }

    isValid(){
        let valid = true;

        if((this.props.indexToCreate.type === 'document' && !(this.props.indexToCreate.document && this.props.indexToCreate.document.name))){ valid = false; }
        if(!this.props.indexToCreate.name || this.props.indexToCreate.name.trim() === ''){ valid = false; }

        return valid;
    }

    render() {
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal modal__preloader" onRequestClose={this.props.onRequestClose}>
                <div className="modal__title">Create New Index</div>
                <form className='modal_content'>
                    <div className="modal_field modal_type_field">
                            {this.indexTypes.map((indexType, i) => (
                                <div key={i} className={`modal_type_field_item ${this.props.indexToCreate.type === indexType.type ? 'active' : ''}`} onClick={() => this.props.handleIndexCreateUpdate('type', indexType.type)}>
                                    {indexType.icon}
                                    <div>
                                        <div className="modal_type_field_item_label">{indexType.label}</div>
                                        <div className="modal_type_field_item_description">{indexType.description}</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {this.props.indexToCreate.type === 'document' && ((this.props.indexToCreate.document && this.props.indexToCreate.document.name) ? <AlertBox className="mt-medium mb-medium" type="success">Successfully uploaded <b>{this.props.indexToCreate.document.name}</b></AlertBox> : (
                        this.props.uploadingFile ? <Preloader text="Uploading file..."/> : (
                            (
                                <div className='modal_field'>
                                    <label>Document</label>
                                    <FileUpload handleSetFile={this.props.handleSetFile} fileTypes={['pdf', 'doc', 'docx', 'xlsx', 'ppt']}/>
                                </div>
                            )
                        )
                    ))}
                    <div className='modal_field'>
                        <TextField fullWidth label="Name" type='text' value={this.props.indexToCreate.name} onChange={(e) => { this.props.handleIndexCreateUpdate('name', e.target.value) }} required/>
                    </div>
                    <div className="modal__actions">
                        <button className="btn" onClick={this.props.onRequestClose}>Cancel</button>
                        <button className="btn primary" onClick={this.props.onConfirm} disabled={!this.isValid()}>Create Index</button>
                    </div>
                </form>
            </Modal>
        );
    }
}

export default IndexCreateModal;
