import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getQuestionLogs } from "../../services/questionLogs";
import QuestionLogList from "./QuestionLogList";
import { Autocomplete, Box, Divider, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import QuestionLogDetailsPane from "./QuestionLogDetailsPane";
import { useNavigate, useParams } from "react-router-dom";

const QuestionLogPage = () => {
    const auth = useSelector(state => state.auth);
    const params = useParams();
    const navigate = useNavigate();
    const { questionLogId } = params;
    const [questionLogs, setQuestionLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuestionLog, setSelectedQuestionLog] = useState(null);
    
    // Question log limits and filters
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(limit);
    const [filters, setFilters] = useState({
        startDate: moment().subtract(1, 'months'),
        endDate: moment(),
        orderBy: 'desc'
    });

    // On mount, check if ID is in URL and set selected question log
    useEffect(() => {
        if (questionLogId) {
            setSelectedQuestionLog({
                _id: questionLogId
            });
        }
    }, [questionLogId]);


    // Order by options for drop down
    const orderByOptions = [
        { value: 'desc', label: 'Newest to oldest' },
        { value: 'asc', label: 'Oldest to newest' }
    ];

    // Filter options
    const filterOptions = [
        { value: 'downvoted', label: 'Downvoted' },
        { value: 'upvoted', label: 'Upvoted' },
        { value: 'revised', label: 'Revised' },
        { value: 'not_revised', label: 'Not Revised' }
    ];

    const handleSetQuestionLogs = async () => {
        const ql = await getQuestionLogs(auth.selectedChatbot.token, limit + 1, filters, filters.orderBy);

        setHasMore(ql.length > limit ? true : false);

        if (ql.length > limit) {
            ql.pop();
        }

        setQuestionLogs(ql);
        setLoading(false);
        setLoadingMore(false);
    }

    useEffect(() => {
        handleSetQuestionLogs();
    }, [limit, filters]);
    
    const handleQuestionLogSelect = (questionLog) => {
        setSelectedQuestionLog(questionLog);
        navigate(`/question-logs/${questionLog._id}`);
    }

    const handleLoadMore = () => {
        setLimit((prevLimit) => prevLimit + offset);
        setLoadingMore(true);
    };

    const handleFilterChange = (fieldName, value) => {
        setLoading(true);
        setFilters((prevState) => ({ ...prevState, [fieldName]: value }));
    };
    
    return (
        <div className="question_log_page">
            <div className="question_log_top_bar">
                <div className="page_title">Question Logs</div>
                <div className="filters_column">
                    <div className="filters">
                        <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Box sx={{ width: "100%", mb: '15px', display: 'flex', maxWidth: '420px', alignItems: 'center' }}>
                                    <DatePicker
                                        label="Start Date"
                                        value={filters.startDate ? moment(filters.startDate) : null}
                                        disableFuture={true}
                                        format='DD/MM/YYYY'
                                        maxDate={filters.endDate ? moment(filters.endDate) : null}
                                        sx={{ width: '100%' }}
                                        
                                        onChange={(newValue) => {
                                            handleFilterChange('startDate', newValue ? newValue.format('YYYY-MM-DD') : undefined);
                                        }}
                                        slotProps={{
                                            field: { size: 'small', clearable: true, onClear: () => {
                                                handleFilterChange('startDate', undefined);
                                            } },
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <Divider sx={{ minWidth: '20px', margin: '0px 8px'}} />
                                    <DatePicker
                                        label="End Date"
                                        value={filters.endDate ? moment(filters.endDate) : null}
                                        disableFuture={true}
                                        format='DD/MM/YYYY'
                                        minDate={filters.startDate ? moment(filters.startDate) : null}
                                        sx={{ width: '100%' }}
                                        onChange={(newValue) => {
                                            handleFilterChange('endDate', newValue ? newValue.endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined);
                                        }}
                                        slotProps={{
                                            field: { size: 'small', clearable: true, onClear: () => {
                                                handleFilterChange('endDate', undefined);
                                            } },
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </div>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <div className="top_bar_filter">
                                <Autocomplete
                                    disablePortal
                                    size='small'
                                    options={filterOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={filterOptions.find(filterOption => filters.orderBy === filterOption.value)}
                                    onChange={(event, newValue) => { handleFilterChange('status', newValue?.value) }}
                                    renderInput={(params) => <TextField {...params} label="Filter by status" autoFocus />}
                                    sx={{ minWidth: '200px' }}
                                />
                            </div>
                            <div className="top_bar_filter">
                                <Autocomplete
                                    disablePortal
                                    size='small'
                                    options={orderByOptions}
                                    defaultValue={orderByOptions[0]}
                                    disableClearable
                                    getOptionLabel={(option) => option.label}
                                    value={orderByOptions.find(filterOption => filters.orderBy === filterOption.value)}
                                    onChange={(event, newValue) => { handleFilterChange('orderBy', newValue?.value) }}
                                    renderInput={(params) => <TextField {...params} label="Order by" autoFocus />}
                                    sx={{ minWidth: '200px' }}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
            <div className="question_log_page_content">
                <QuestionLogList 
                    loading={loading}
                    questionLogs={questionLogs} 
                    handleQuestionLogSelect={handleQuestionLogSelect}
                    selectedQuestionLog={selectedQuestionLog}
                    handleLoadMoreClick={handleLoadMore} 
                    hasMore={hasMore}
                    loadingMore={loadingMore}
                />
                {selectedQuestionLog && <QuestionLogDetailsPane questionLogId={selectedQuestionLog._id} handleSetQuestionLogs={handleSetQuestionLogs} />}
            </div>
        </div>
    );
}

export default QuestionLogPage;