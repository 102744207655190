import { HiArrowLeft, HiOutlineXMark } from 'react-icons/hi2';
import GroupChatIcon from './GroupChatIcon';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import StatusText from '../Base/StatusText';

const InternalChatRoomTopBar = (props) => {
    return (
        <div className="internal_chat_room_top_bar">
            <div className="internal_chat_room_top_bar__back" onClick={props.handleDeselectChat}><HiArrowLeft/></div>
            <div className="internal_chat_room_top_bar__chatting_to">
                {props.isGroupChat ? <GroupChatIcon size="40"/> : <AgentProfileAvatar size="40" agent={props.chattingTo[0]}/>}
                <div className="internal_chat_room_top_bar__chatting_to_details">
                    <div className="internal_chat_room_top_bar__chatting_to_details__name text-truncate">{props.isGroupChat ? props.chattingTo.map((participant, i) => { const comma = (i + 1) !== props.chattingTo.length ? ', ' : '';return participant.first_name[0] + '. ' + participant.last_name + comma; }) : props.chattingTo[0].first_name + ' ' + props.chattingTo[0].last_name}</div>
                    {props.isGroupChat ? <div className="status_display">You and {props.chattingTo.length} other participants</div> : <StatusText status={props.chattingTo[0].agent_settings.status} lastSeen={props.chattingTo[0].last_seen}/>}
                </div>
            </div>
            <div className="internal_chat_widget__close_chat" onClick={props.handleToggleChat}><HiOutlineXMark/></div>
        </div>
    );
}

export default InternalChatRoomTopBar;