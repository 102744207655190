import Modal from 'react-modal';
import { history } from '../../helpers/history';
import { useSelector } from 'react-redux';
import IndexSearchBar from './IndexSearchBar';
import ChunkList from './ChunkList';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Preloader from '../Preloader';
import ScrollableFeed from 'react-scrollable-feed';
import ChunkEditor from './ChunkEditor';
import { useParams } from 'react-router-dom';
import { addChunk, deleteChunk, getChunks, getDocumentPreview, getIndex, getIndexes, importChunks, searchChunks, updateChunk } from '../../services/knowedgeSetManager';
import ConfirmModal from '../Base/ConfirmModal';

const ChunkSearchModal = (props) => {
    const [, forceRender] = useReducer(p => !p, false);
    const [index, setIndex] = useState(null);
    const [loadingChunks, setLoadingChunks] = useState(false);
    const [savingChunk, setSavingChunk] = useState(false);
    const [chunks, setChunks] = useState([]);
    const [currentChunk, setCurrentChunk] = useState(null);
    const [chunkToDeleteId, setChunkToDeleteId] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [error, setError] = useState(null);
    const [deletingChunk, setDeletingChunk] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [indexes, setIndexes] = useState([]);

    // Search highlights
    const [currentHighlight, setCurrentHighlight] = useState(null);
    const [searchHighlights, setSearchHighlights] = useState([]);

    const scrollableRef = useRef(null);
    const auth = useSelector(state => state.auth);
    const params = useParams();
    
    useEffect(() => {
        async function fetchData() {
            try{
                const indexes = await getIndexes(auth.selectedChatbot.token, props.knowledgeSet._id, props.knowledgeSet.latest_version.id);
                setIndexes(indexes);
                // Get chunks
                //handleLoadChunks(props.knowledgeSet._id, props.knowledgeSet.latest_version._id);
            }catch(e){
                console.log(e)
                history.push('/knowledge-set-manager/' + params.id);
            }
        }
        fetchData();
    }, []);

    const findIndex = (indexId) => {
        return indexes.find(index => index.index_id.toString() === indexId.toString());
    }

    const setFirstHighlight = (hl) => {
        // Set first highlight
        let firstHighlight = null;

        // Loop through the highlight map to find the first highlight
        for (const chunk of hl) {
            const highlight = chunk.highlights.find(h => h.isHighlight);
            if (highlight) {
                firstHighlight = { chunkId: chunk.chunkId, ...highlight };
                break; // Break out of the loop once the first highlight is found
            }
        }

        // Use the firstHighlight as needed
        setCurrentHighlight(firstHighlight);
    }

    // Main method to retrieve and set chunks
    const handleLoadChunks = async (knowledgeSetId, versionId) => {
        let currentChunks = null;
        
        // If search term is present, search chunks
        if(searchTerm?.trim().length > 0){
            currentChunks = await searchChunks(
                auth.selectedChatbot.token, 
                knowledgeSetId, 
                versionId, 
                null, 
                null, 
                searchTerm
            );
        }else{
            currentChunks = [{}]
        }

        // Prepare highlight map
        const hl = currentChunks.map(chunk => {
            return {
                chunkId: chunk._id,
                highlights: chunk.text.split(new RegExp(`(${searchTerm})`, 'gi')).map((part, index) => ({
                    part,
                    index,
                    isHighlight: part.toLowerCase() === searchTerm.toLowerCase()
                }))
            }
        });

        setFirstHighlight(hl);

        setSearchHighlights(hl);

        setChunks(currentChunks);
    };

    // Side effect to disable loadingChunks when chunks are loaded
    useEffect(() => {
        if(chunks && loadingChunks){
            setLoadingChunks(false);
        }
    }, [chunks]);

    // Side effect for when search term is updated to search chunks
    useEffect(() => {
        if(searchTerm?.length > 0){
            handleLoadChunks(props.knowledgeSet._id, props.knowledgeSet.latest_version._id);
        }else{
            setChunks([]);
            setLoadingChunks(false);
        }
    }, [searchTerm]);

    const handleChunkEditorToggle = (chunk) => {
        setCurrentChunk(prevChunk => {
            if (!chunk && prevChunk) {
                return null;
            } else if (chunk) {
                return chunk;
            } else {
                setTimeout(() => {
                    scrollableRef.current.props.animateScroll(scrollableRef.current.wrapperRef.current, (scrollableRef.current.wrapperRef.current.scrollHeight))
                }, 10);
                return { text: '' };
            }
        });
    }

    const handleTextChange = (content) => {
        setCurrentChunk(prevChunk => {
            return { ...prevChunk, text: content };
        });
    }

    const handleSourceChange = (e) => {
        setCurrentChunk(prevChunk => {
            return { ...prevChunk, source: e.target.value };
        });
    }

    const handleChunkAttachmentsAdd = (files) => {
        setCurrentChunk(prevChunk => {
            return { ...prevChunk, attachments: [...prevChunk.attachments, ...files.map(file => ({
                url: file.url,
                title: file.title,
                description: ""
            }))] };
        });
    }

    const handleChunkAttachmentUpdate = (index, attachment) => {
        setCurrentChunk(prevChunk => { 
            return {  
                ...prevChunk,  
                attachments: prevChunk.attachments.map((a, i) => i === index ? attachment : a)  
            };  
        });  
    }

    const handleChunkAttachmentRemove = (index) => {        
        setCurrentChunk(prevChunk => { 
            setTimeout(() => {
                
            forceRender(); 
            }, 2000)
            return {  
                ...prevChunk,  
                attachments: prevChunk.attachments.filter((attachment, i) => i !== index)  
            };  
        });  
    };  
    
    
    const handleKeepNewFormatClick = (formattedText) => {
		handleTextChange(formattedText);
	}

    const handleAddChunk = async () => {
        setSavingChunk(true);
        const chunk = await addChunk(
            auth.selectedChatbot.token, 
            index.knowledge_set_id, 
            index.version_id, 
            index.index_id, 
            currentChunk, 
            auth.agent.agent._id
        );
    
        setChunks(prevChunks => [...prevChunks, chunk]);
        setSavingChunk(false);
        setCurrentChunk(null);
    }

    const handleChunkUpdate = async () => {
        setSavingChunk(true);
        const updatedChunk = await updateChunk(
            auth.selectedChatbot.token, 
            currentChunk._id, 
            currentChunk, 
            auth.agent.agent._id
        );

        
    
        setSavingChunk(false);
        setCurrentChunk(null);
    
        setChunks(prevChunks => {
            return prevChunks.map(chunk => {
                return chunk._id === currentChunk._id ? updatedChunk : chunk;
            });
        });
    }

    const handleDeleteChunkClick = (e, chunkId) => {
        e.stopPropagation();
        setChunkToDeleteId(chunkId);
    }

    const handleSearchChunks = async (searchText) => {  
        if(searchText != searchTerm){
            setLoadingChunks(true);    
            setCurrentChunk(null);
            setSearchTerm(searchText);
        }
    };
    
    const clearSearch = async () => {
        setSearchResults(null);
        setLoadingChunks(true);
        const chunks = await getChunks(
            auth.selectedChatbot.token, 
            index.knowledge_set_id, 
            index.version_id, 
            index.index_id
        );
        setChunks(chunks);
        setLoadingChunks(false);
    };

    const handleDeleteChunk = async () => {
        const chunkId = chunkToDeleteId;
        setChunkToDeleteId(null);
        await deleteChunk(auth.selectedChatbot.token, chunkId, auth.agent.agent._id);
        const filteredChunks = chunks.filter(c => c._id !== chunkId);
        setDeletingChunk(false);
        setChunks(filteredChunks);
    }

    function highlightTerm(text, highlightIndex) {  
        if (!searchTerm) return text;  
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));  
        return parts.map((part, index) =>  
            index === highlightIndex && part.toLowerCase() === searchTerm.toLowerCase() ? (  
                `<span key="${index}" id="searchhighlight" class="highlight">${part}</span>`
            ) : (  
                part  
            )  
        ).join('');
    }

    const nextHighlight = () => {
        if (searchHighlights.length === 0) return;

        let nextHighlight = null;

        if (currentHighlight) {
            const { chunkId } = currentHighlight;
            
            for (const [i, searchHighlight] of searchHighlights.entries()) {
                const currentHighlightIndex = searchHighlights.findIndex(h => h.chunkId === chunkId);

                if(i < currentHighlightIndex){ continue; }

                const isSameChunk = searchHighlight.chunkId === currentHighlight.chunkId;
                const highlight = searchHighlight.highlights.find(h => h.isHighlight && ( h.index > currentHighlight.index || !isSameChunk));

                if (highlight && !(searchHighlight.chunkId === currentHighlight.chunkId && highlight.index === currentHighlight.index)) {
                    nextHighlight = { chunkId: searchHighlight.chunkId, ...highlight };
                    break; // Break out of the loop once the first highlight is found
                }
            }
        }

        if(nextHighlight){
            setCurrentHighlight(nextHighlight);
        }else{
            setFirstHighlight(searchHighlights)
        }
        
        const formattedChunks = chunks.map(c => {
            if(c._id === currentHighlight.chunkId){
                return { ...c, highlightText: highlightTerm(c.text, currentHighlight.index) }
            }else{
                delete c.highlightText;
                return c;
            }
        })

        setChunks(formattedChunks);
        setTimeout(() => {
            const element = document.getElementById('searchhighlight');
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }, 5);
    };

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal chunk_search_log_modal" onRequestClose={props.onRequestClose}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="modal__title">Search All</div>
                <IndexSearchBar
                    searchChunks={handleSearchChunks}
                    searchResults={searchResults}
                    clearSearch={clearSearch}
                />
            </div>
            {chunks && <button className='btn' onClick={nextHighlight}>Highlight next</button>}
            <div className="index_chunks">
                {loadingChunks ? <Preloader/> : (
                    <ScrollableFeed debug={false} className="chunk_list__scroll" ref={scrollableRef}>
                        {chunks && <div className='index_chunks__search_title'>Search results: {chunks.length} chunks</div>}
                        <ChunkList 
                            currentChunk={currentChunk} 
                            chunks={chunks}
                            savingChunk={savingChunk} 
                            handleChunkSave={handleChunkUpdate} 
                            handleChunkEditorToggle={handleChunkEditorToggle} 
                            handleTextChange={handleTextChange}
                            handleDeleteChunkClick={handleDeleteChunkClick}
                            handleKeepNewFormatClick={handleKeepNewFormatClick}
                            handleChunkAttachmentsAdd={handleChunkAttachmentsAdd}
                            handleChunkAttachmentUpdate={handleChunkAttachmentUpdate}
                            handleChunkAttachmentRemove={handleChunkAttachmentRemove}
                            handleSourceChange={handleSourceChange}
                            deletingChunk={deletingChunk}
                            findIndex={findIndex}
                        />
                        {(currentChunk && !currentChunk._id) && (
                            <ChunkEditor 
                                chunk={currentChunk} 
                                savingChunk={savingChunk} 
                                handleChunkSave={handleAddChunk} 
                                handleChunkEditorToggle={handleChunkEditorToggle} 
                                handleTextChange={handleTextChange}
                                handleDeleteChunkClick={handleDeleteChunkClick}
                                handleKeepNewFormatClick={handleKeepNewFormatClick}
                                handleChunkAttachmentsAdd={handleChunkAttachmentsAdd}
                                handleChunkAttachmentUpdate={handleChunkAttachmentUpdate}
                                handleChunkAttachmentRemove={handleChunkAttachmentRemove}
                                handleSourceChange={handleSourceChange}
                                deletingChunk={deletingChunk}
                                findIndex={findIndex}
                            />
                        )}
                    </ScrollableFeed>
                )}
            </div>
            <div className="modal__actions">
                <button className="btn" onClick={props.onRequestClose}>Close</button>
            </div>
            <ConfirmModal 
                isOpen={chunkToDeleteId}
                confirmText="Delete chunk"
                title="Delete Chunk"
                description="Are you sure you want to delete this chunk, this will permanently remove this chunk from the knowledge set."
                onRequestClose={(e) => { handleDeleteChunkClick(e, null) }}
                onConfirm={handleDeleteChunk}
            />
        </Modal>
    )
}

export default ChunkSearchModal;