import EmojiPicker from '../Base/EmojiPicker';
import TextArea from 'react-textarea-autosize';
import { HiOutlinePaperClip, HiPaperAirplane } from 'react-icons/hi2';
import React, { useRef } from 'react';
import ChatMediaPreview from '../Chats/ChatMediaPreview';

const InternalChatRoomInputArea = (props) => {
    const mediaUploadElement = useRef(null);
    return (
        <div>
            {(props.hasMedia && props.mediaPreview) && <ChatMediaPreview handleRemoveMedia={props.handleRemoveMedia} loadingMediaPreview={props.loadingMediaPreview} mediaPreview={props.mediaPreview} mediaFile={props.mediaFile}/>}
            {!(!props.isGroupChat && (props.chattingTo[0].disabled || props.chattingTo[0].deleted)) ? (
                <form className="internal_chat_room__input_area" onSubmit={props.handleMessageSubmit}>
                    <div className="chat_message_bar__message_bar__media" onClick={() => { props.handleMediaTrigger(mediaUploadElement) }}><HiOutlinePaperClip/></div>
                    <TextArea onKeyPress={props.handleTextEnter} value={props.messageValue} autoFocus={true} onChange={props.handleMessageType} type="text" placeholder="Enter your message"/>
                    <EmojiPicker handleEmojiPick={props.handleEmojiPick}/>
                    <button className='send_button' disabled={props.messageValue.trim().length === 0 && !props.hasMedia}><HiPaperAirplane/></button>
                    <input ref={mediaUploadElement} type="file" style={{display: 'none'}} onChange={props.handleMediaChange}/>
                </form>
            ) : (
                <div className="internal_chat_room__input_area disabled">{props.chattingTo[0].deleted ? 'This account was removed' : 'This account is disabled'}</div>
            )}
        </div>
    );
};

export default InternalChatRoomInputArea;
