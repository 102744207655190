import { handleResponse } from './misc';

const createContactList = (authToken, name, creator, numbers) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            name,
            creator,
            numbers
        })
    };

    return fetch('/api/contacts/list', requestOptions).then(handleResponse).then((contactList) => {
        return contactList;
    });
}

const getContactLists = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/contacts/lists', requestOptions).then(handleResponse).then((contactLists) => {
        return contactLists;
    });
}

export { createContactList, getContactLists }
