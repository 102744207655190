import { Divider, Pagination, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getQuestionLog } from '../../services/questionLogs';
import { useSelector } from 'react-redux';
import ChunkContent from '../KnowledgeSetManager/ChunkContent';

const QuestionLogModal = (props) => {
    const [loadingQuestionLog, setLoadingQuestionLog] = useState(true);
    const [questionLog, setQuestionLog] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const auth = useSelector(state => state.auth)

    // Initial hook for render and retrieval of data
    useEffect(() => {
        const fetchData = async () => {
            const ql = await getQuestionLog(auth.selectedChatbot.token, props.questionLogId);
            
            setQuestionLog(ql);
            setLoadingQuestionLog(false);
            setCurrentPage(1);
        }

        fetchData();
    }, [])

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal question_log_modal" onRequestClose={props.onRequestClose}>
            <div className="modal__title">Question Log</div>
            <div className="modal__content">
                <div className="question_log_item">
                    <div className='question_log_item__label'>Search Term Used:</div>
                    <div className='question_log_item__value'>{loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : questionLog.search_term}</div>
                </div>
                <div className="question_log_item" style={{ marginTop: '8px' }}>
                    <div className='question_log_item__label'>Model Used:</div>
                    <div className='question_log_item__value'>{loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : questionLog.model}</div>
                </div>
                <Divider sx={{ my: '20px' }}/>
                <div className='chunks_carousel'>
                    <div className='chunks_carousel__title'>
                        <strong>Chunks Used</strong>
                        {questionLog && <Pagination page={currentPage} onChange={handleChange} count={questionLog.chunks.length}/>}
                    </div>
                    <div className='chunks_carousel__items mt-medium'>
                        {loadingQuestionLog ? <Skeleton variant="rounded" sx={{ width: '100%', height: '300px' }} /> : (
                            <ChunkContent chunk={questionLog.chunks[currentPage - 1]}/>
                        )}
                    </div>
                </div>
            </div>
            <div className="modal__actions">
                <button className="btn" onClick={props.onRequestClose}>Close</button>
            </div>
        </Modal>
    )
}

export default QuestionLogModal;