import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { BiSave } from "react-icons/bi";
import Editor from "./Editor";
import { BsTypeBold } from 'react-icons/bs'
import { IoMdImages } from "react-icons/io";
import { GoListOrdered, GoListUnordered } from "react-icons/go";
import { MdFormatClear } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';
import { HiMiniSparkles, HiTrash } from "react-icons/hi2";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import ConfirmModal from "../Base/ConfirmModal";
import { aiFormatChunk, generateAttachmentAiDescription } from "../../services/knowedgeSetManager";
import LoadingModal from "../Base/LoadingModal";
import ChunkContent from "./ChunkContent";
import FileUploadModal from "../Base/FileUploadModal";
import { uploadMedia } from "../../services/upload";
import AlertBox from "../AlertBox";
import ChunkAttachmentEditor from "./ChunkAttachmentEditor";
import { TextField } from "@mui/material";

export default (props) => {
	const [formattingText, setFormattingText] = useState(false);
	const [formattedText, setFormattedText] = useState(null);
	const [uploadingMedia, setUploadingMedia] = useState(false);
	const [generatingDescriptions, setGeneratingDescriptions] = useState(false);
	const [uploadingAttachments, setUploadingAttachments] = useState(false);
	const [error, setError] = useState(null);

	const initialText = useRef(props.chunk.text);
	const initialSource = useRef(props.chunk.source);
	const initialAttachments = useRef(props.chunk.attachments);

	const auth = useSelector(state => state.auth);
    const onUpdate = (html) => {
		props.handleTextChange(html)
    }

	const [alertMessage, setAlertMessage] = useState(null);

    const quillRef = useRef(null);

	const handleAiDescriptionGenerate = async (imageName, imageUrl) => {
        const imageDescription = await generateAttachmentAiDescription(auth.selectedChatbot.token, props.chunk.knowledge_set_id, imageName, props.chunk.text, imageUrl)
		return imageDescription;
	}	
	
	const handleClickAway = (e) => {
        if (props.chunk.text !== initialText.current || initialAttachments.current !== props.chunk.attachments || initialSource.current !== props.chunk.source) {
			e.preventDefault();
			if(!alertMessage){
				setAlertMessage('You have unsaved changes, please save your changes or discard them before leaving this chunk editor.');
			}
		}
    }

	const handleReformatWithAi = async () => {
		setFormattingText(true);
		const { formatted_text } = await aiFormatChunk(auth.selectedChatbot.token, props.chunk._id);
		setFormattedText(formatted_text);
		setFormattingText(false);
	}

	const generateAllDescriptions = async () => {
		setGeneratingDescriptions(true);
		const attachments = props.chunk.attachments;
		for (let i = 0; i < attachments.length; i++) {
			const attachment = attachments[i];
			const description = await handleAiDescriptionGenerate(attachment.title, attachment.url);
			props.handleChunkAttachmentUpdate(i, { ...attachment, description });
		}
		setGeneratingDescriptions(false);
	
	}

	const handleKeepNewFormatClick = () => {
		quillRef.current.root.innerHTML = formattedText;
		setFormattedText(null );
	}

	const handleMediaUploadClick = () => {
		setUploadingMedia(prevUploadingMedia => !prevUploadingMedia);
	}

	const handleFilesAttach = async (files) => {
		try {
			setUploadingMedia(false);
			setUploadingAttachments(true);
			const attachments = [];	
			for (let i = 0; i < files.length; i++) {
				const file = files[i];

				// Process each file asynchronously
				const url = await uploadMedia(auth.selectedChatbot.token, file);
				attachments.push({
					title: file.name.split('.').slice(0, -1).join('.'),
					url: url.url
				});
			}

			// All files processed successfully
			props.handleChunkAttachmentsAdd(attachments);
			setUploadingAttachments(false);
		} catch (error) {
			// File upload error
			setError('Failed to upload attachments. Please try again.');
		}
	}

    return (
        <>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className="chunk_list_item active">
					{alertMessage && <ConfirmModal 
						isOpen={alertMessage} 	
						title={'You have unsaved changes'} 	
						description={alertMessage} 
						onRequestClose={() => { setAlertMessage(null); }}
						onConfirm={() => { setAlertMessage(null); props.handleChunkSave(); }}
						confirmText={'Save changes'}
						cancelText={'Continue editing'}
					/>}
					{(props.chunk && !props.chunk._id) && <div className="chunk_list_item__title">New Chunk</div>}
					<div className="chunk_editor">
						<div className="chunk_editor_format_bar">
							<div id="toolbars">
								<Tooltip title="Bold" arrow>
									<button className="ql-bold"><BsTypeBold/></button>
								</Tooltip>
								<Tooltip title="Bullet List" arrow>
									<button className="ql-list"><GoListUnordered/></button>
								</Tooltip>
								<Tooltip title="Ordered List" arrow>
									<button className="ql-list" value="ordered"><GoListOrdered/></button>
								</Tooltip>
								<Tooltip title="Clean" arrow>
									<button className="ql-clean"><MdFormatClear/></button>
								</Tooltip>
								{(props.chunk && props.chunk._id) && (
									<>
										<Tooltip title="Format with AI" arrow>
											<button onClick={handleReformatWithAi} ><HiMiniSparkles/></button>
										</Tooltip>
									</>
								)}
							</div>
							<div className="toolbar">
								<TextField onChange={props.handleSourceChange} sx={{ mr: '8px' }} label="Source" type="outline" name="source" size="small" value={props.chunk.source} onFocus={() => { if(!props.chunk.source){ props.handleSourceChange({ target: { value: 'Page: ' } }) } }} placeholder="Source"/>	
								<Tooltip title="Attach images" arrow>
									<button onClick={handleMediaUploadClick} ><IoMdImages /></button>
								</Tooltip>
								{(props.chunk && props.chunk._id) && (
									<>
										<Tooltip title="Delete chunk" arrow>
											<button onClick={props.handleDeleteChunkClick} className="trash"><HiTrash/></button>
										</Tooltip>
									</>
								)}
								<Tooltip title="Save chunk" arrow><button onClick={props.handleChunkSave} disabled={(initialText.current === props.chunk.text && initialAttachments.current === props.chunk.attachments && initialSource.current === props.chunk.source) || props.savingChunk || props.chunk.text.length === 0}><BiSave/></button></Tooltip>
							</div>
						</div>
						<Editor
							ref={quillRef}
							defaultValue={props.chunk.text}
							onTextChange={onUpdate}
						/>
						{props.chunk.attachments && props.chunk.attachments.length > 0 && (
							<>
								<label className="mb-medium">{props.chunk.attachments.length} attachments</label>
								<button className="btn mb-medium" onClick={generateAllDescriptions}><HiMiniSparkles/>{generatingDescriptions ? 'Generating...' : 'Generate description for all'}</button>
								<div className="chunk_editor_attachments">
									{props.chunk.attachments.map((attachment, index) => <ChunkAttachmentEditor 
									attachment={attachment} 
									handleChunkAttachmentUpdate={(attachment) => props.handleChunkAttachmentUpdate(index, attachment)}
									handleAiDescriptionGenerate={handleAiDescriptionGenerate}
									handleChunkAttachmentRemove={() => { props.handleChunkAttachmentRemove(index) }}/>)}
								</div>
							</>
						)}
						<div className="chunk_editor_actions">
							<button className="btn primary" onClick={props.handleChunkSave} disabled={(initialText.current === props.chunk.text && initialAttachments.current === props.chunk.attachments && initialSource.current === props.chunk.source) || props.savingChunk || props.chunk.text.length === 0}><BiSave/>Save</button>
							<button className="btn" onClick={() => { props.handleChunkEditorToggle() }} disabled={props.savingChunk}>Discard</button>
						</div>
					</div>
					{uploadingMedia && <FileUploadModal isOpen={uploadingMedia} handleSetFile={handleFilesAttach} onRequestClose={handleMediaUploadClick} multiple={true} fileTypes={['png', 'jpg']}/>}
					{formattingText && <LoadingModal isOpen={formattingText} text="Preparing AI format preview..."/>}
					{formattedText && <ConfirmModal 
						isOpen={formattedText} 
						title="Confirm New Format"
						cancelText="Discard"
						onRequestClose={() => { setFormattedText(null); }}
						onConfirm={handleKeepNewFormatClick}
						className="question_log_modal"
						description={(
							<ChunkContent chunk={{ text: formattedText }} handleChunkEditorToggle={() => {}} deletingChunk={null}/>
						)}/>}
					{error && <div className="mt-medium"><AlertBox type="error">{error}</AlertBox></div>}
				</div>
			</ClickAwayListener>
			<LoadingModal isOpen={uploadingAttachments}/>
		</>
    )
}