import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import HelpRequestList from './HelpRequestList';
import { sendMessage } from '../../services/humanHandoff';
import { resolveHelpRequest, setHelpRequests, deleteHelpRequest, getHelpRequest, transferHelpRequest } from '../../services/helpRequest';
import HelpRequestFilters from './HelpRequestFilters';
import { getQueues } from '../../services/queues';
import HelpRequestListItem from './HelpRequestListItem';
import { NavLink } from 'react-router-dom';
import { HiEyeSlash, HiOutlineArrowLeft, HiOutlineFunnel } from 'react-icons/hi2';

import socket from '../../services/socket';
import { goToConversation } from '../../reducers/chatsSlice';
import { setHelpRequestFilters, setHelpRequestsLimit, setLoadingHelpRequests, updateSearchTerm } from '../../reducers/helpRequestsSlice';
import HelpRequestSearch from "./HelpRequestSearch";  


const HelpRequestPage = () => {
    const [state, setState] = useState({
        loadingMore: false,
        queues: undefined,
        loadingQueues: true,
        selectedHelpRequest: undefined,
        mobileFiltersShown: false,
        localSearchTerm: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

   const params = useParams();

   const auth = useSelector(state => state.auth);
   const helpRequests = useSelector(state => state.helpRequests);

    useEffect(() => {
        async function fetchData() {
            if(params.helpRequestId){
                try{
                    const helpRequest = await getHelpRequest(auth.selectedChatbot.token, params.helpRequestId)
                    setState(prevState => ({ ...prevState, selectedHelpRequest: helpRequest }));
                }catch(e){
                    navigate('/help-requests');
                }
            }

            const queues = await getQueues(auth.selectedChatbot.token, auth.agent.agent._id);
            setState(prevState => ({ ...prevState, queues, loadingQueues: false }));
            socket.on('helpRequestUpdate', async ({ id }) => {
                if(auth.selectedChatbot._id === id && state.selectedHelpRequest){
                    
                    setHelpRequests(dispatch, auth, helpRequests);
                    try{
                        const helpRequest = await getHelpRequest(auth.selectedChatbot.token, state.selectedHelpRequest._id)
                        setState(prevState => ({ ...prevState, selectedHelpRequest: helpRequest }));
                    }catch(e){
                        navigate('/help-requests');
                    }
                }
            });     
            
            setHelpRequests(dispatch, auth, helpRequests);
        }

        fetchData();

        return () => {
            socket.off('helpRequestUpdate');
        }
    }, []);
    
    const handleViewChatClick = async (conversation) => {
        const conversationId = conversation.conversation_id;

        dispatch(goToConversation({ conversation: conversationId, navigate, location }))
    }
    
    const handleResolveHelpRequest = async (helpRequestId, message = null) => {
        await resolveHelpRequest(auth.selectedChatbot.token, helpRequestId, auth.agent.agent._id, message);
        await setHelpRequests(dispatch, auth, helpRequests);
    }

    const handleDeleteHelpRequest = async (helpRequestId) => {
        await deleteHelpRequest(auth.selectedChatbot.token, helpRequestId);
        await setHelpRequests(dispatch, auth, helpRequests);
    }

    const handleSendQuickReply = async (helpRequestId, message, conversation) => {
        const messageObject = {
            conversation_id: conversation.conversation_id,
            sender: 'agent',
            agent_sender: auth.agent.agent._id,
            agent_sender_object: auth.agent.agent,
            message_data: message
        }

        await sendMessage(auth.selectedChatbot.token, { ...messageObject, message_data: { ...messageObject.message_data, text: `*Submitted Question Response*\n\n_${auth.selectedChatbot.settings.anonymous_quick_resolve ? (auth.selectedChatbot.settings.anonymous_quick_resolve_alias != null && auth.selectedChatbot.settings.anonymous_quick_resolve_alias !== ""  ? auth.selectedChatbot.settings.anonymous_quick_resolve_alias : "An agent") : auth.agent.agent.first_name} has replied to your question saying:_\n\n` + messageObject.message_data.text + '\n\n_Please note you cannot directly reply to this message. Type *HELP* if you wish to speak to an agent._'} }, conversation.channel);
        await handleResolveHelpRequest(helpRequestId, message);
        await setHelpRequests(dispatch, auth, helpRequests);
    }

    const handleSearchChange = (e) => {
        setState(prevState => ({ ...prevState, localSearchTerm: e.target.value }));
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        dispatch(setLoadingHelpRequests(true))
        dispatch(updateSearchTerm(state.localSearchTerm));
        await setHelpRequests(dispatch, auth, helpRequests);
    }

    const clearSearch = async () => {
        dispatch(setLoadingHelpRequests(true));
        dispatch(updateSearchTerm(''));
        setState(prevState => ({ ...prevState, localSearchTerm: '' }));
    }

    useEffect(() => {
		if (helpRequests.searchTerm === "") {
			setHelpRequests(dispatch, auth, helpRequests);
		}
	}, [helpRequests.searchTerm]);

    useEffect(() => {
        if(state.loadingMore && helpRequests.limit > helpRequests.helpRequests.length){
            setHelpRequests(dispatch, auth, helpRequests).then(() => {
                setState({ loadingMore: false });
            });
        }
    }, [helpRequests.limit, state.loadingMore]);

    const loadMoreClick = async () => {
        setState({ loadingMore: true });
        dispatch(setHelpRequestsLimit(helpRequests.limit + helpRequests.offset));
    }
    
    const filterChange = async (name, value) => {
        dispatch(setLoadingHelpRequests(true));
        dispatch(setHelpRequestFilters({
            filter: {
                [name]: value
            }
        }));
    }   
    
    useEffect(() => {
        setHelpRequests(dispatch, auth, helpRequests);
    }, [helpRequests.filters, helpRequests.searchTerm])

    const handleHelpRequestTransfer = async (helpRequestId, queueId) => {
        await transferHelpRequest(auth.selectedChatbot.token, helpRequestId, queueId);
        await setHelpRequests(dispatch, auth, helpRequests);
    }

    const handleFiltersTrigger = () => {
        setState(prevState => ({ mobileFiltersShown: !prevState.mobileFiltersShown }))
    }

    return (
        <div className="container column">
            {params.helpRequestId ? (
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                            <div className="help_request_single">
                                <NavLink to="/help-requests" className="btn"><HiOutlineArrowLeft/>Back to help requests</NavLink>
                                {state.selectedHelpRequest ? <HelpRequestListItem helpRequest={state.selectedHelpRequest} handleResolveHelpRequest={handleResolveHelpRequest} handleDeleteHelpRequest={handleDeleteHelpRequest} handleSendQuickReply={handleSendQuickReply} handleViewChatClick={handleViewChatClick} helpRequests={helpRequests.helpRequests} handleHelpRequestTransfer={handleHelpRequestTransfer}/> : <Preloader/>}
                            </div>
                        </div>
                    </div>
                </PageContainer>
            ) : (
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                            <div className="page_title">Help Requests</div>
                            <div className="help_request_container">
                                {state.mobileFiltersShown ? <button className="btn mobile_filter_trigger" onClick={handleFiltersTrigger}><HiEyeSlash/>Hide Filters</button> : <button className="btn mobile_filter_trigger" onClick={handleFiltersTrigger}><HiOutlineFunnel/>Filters</button> }
                                <div className={`help_request_top_bar ${state.mobileFiltersShown ? 'mobile_filters_shown' : ''}`}>  
                                    <HelpRequestFilters loadingQueues={state.loadingQueues} queues={state.queues} filterChange={filterChange} filters={helpRequests.filters}/>
                                    {<HelpRequestSearch searchTerm={state.localSearchTerm} clearSearch={clearSearch} isSearched={helpRequests.isSearched} handleSearchChange={handleSearchChange} handleSearchSubmit={handleSearchSubmit}/>}                                   
                                </div>
                                {helpRequests.loadingHelpRequests === false ? (
                                    <div>
                                        <HelpRequestList loadingMore={state.loadingMore} loadMoreClick={loadMoreClick} handleHelpRequestTransfer={handleHelpRequestTransfer} hasMore={helpRequests.hasMore} handleResolveHelpRequest={handleResolveHelpRequest} handleDeleteHelpRequest={handleDeleteHelpRequest} handleSendQuickReply={handleSendQuickReply} handleViewChatClick={handleViewChatClick} helpRequests={helpRequests.helpRequests}/>
                                    </div>
                                ) : (
                                    <Preloader/>
                                )}
                            </div>
                        </div>
                    </div>
                </PageContainer>
            )}
        </div>
    );
};

export default HelpRequestPage;