import moment from 'moment';

const toTimestamp = (dateArg) => {
    const today = moment();
    const yesterday = moment().add('-1', 'days');
    const date = moment(dateArg);
    if(today.isSame(date, 'day', 'month')){
        return date.format('hh:mmA');
    }

    if(yesterday.isSame(date, 'day', 'month')){
        return 'Yesterday'
    }
    
    return date.format('Do MMMM, YYYY');
}

const toTimeOnly = (dateArg) => {
    const date = moment(dateArg);
    return date.format('hh:mmA');
};

const toDateOnly = (dateArg) => {
    const today = moment();
    const yesterday = moment().add('-1', 'days');
    const date = moment(dateArg);

    if(today.isSame(date, 'day', 'month')){
        return 'Today';
    }

    if(yesterday.isSame(date, 'day', 'month')){
        return 'Yesterday';
    }

    return date.format('DD/MM/YYYY');
};

const toWordDate = (dateArg) => {
    const today = moment();
    const yesterday = moment().add('-1', 'days');
    const date = moment(dateArg);

    if(today.isSame(date, 'day', 'month')){
        return 'Today';
    }

    if(yesterday.isSame(date, 'day', 'month')){
        return 'Yesterday';
    }

    return date.format('Do MMMM, YYYY');
};

const toChatDate = (dateArg) => {
    const today = moment();
    const yesterday = moment().add('-1', 'days');
    const date = moment(dateArg);

    if(today.isSame(date, 'day', 'month')){
        return 'Today';
    }

    if(yesterday.isSame(date, 'day', 'month')){
        return 'Yesterday';
    }
    
    return date.format('ddd D/M/Y');
};

const timerRenderer = ({ hours, minutes, seconds, completed }) => {
    if(hours === 0){hours = undefined}
        if(minutes < 10 && hours){minutes = '0' + minutes}
        if(minutes === 0 && !hours){minutes = undefined}
        if(seconds < 10 && minutes){seconds = '0' + seconds}
        // Render a countdown
        return <span>{hours && hours + ':'}{minutes && minutes + ':'}{seconds}</span>;
  };

export { toTimestamp, toTimeOnly, toDateOnly, toWordDate, toChatDate, timerRenderer }