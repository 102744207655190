import React from 'react';
import { HiOutlineUserGroup, HiOutlineArrowUpTray, HiUserGroup, HiFunnel } from 'react-icons/hi2';
import { TbFileSpreadsheet } from 'react-icons/tb'
import { TiContacts } from 'react-icons/ti'
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import { titleCase } from '../../services/misc';
import { broadcastTemplate, importContactsFromFile } from '../../services/whatsAppTemplateSender';
import PageContainer from '../Base/PageContainer';
import WhastAppTemplatePreview from './WhastAppTemplatePreview';
import WhatsAppAllUserList from './WhatsAppAllUserList';
import WhatsAppContactListCreateForm from './WhatsAppContactListCreateForm';
import WhatsAppContactListPicker from './WhatsAppContactListPicker';
import WhatsAppTemplateFiller from './WhatsAppTemplateFiller';
import WhatsAppTemplateList from './WhatsAppTemplateList';

class WhatsAppPushNotifcationNewJobPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: 1,
            templateToSend: null,
            templateParameters: null,
            recipientType: ''
        };

        this.handleTemplateClick = this.handleTemplateClick.bind(this);
        this.handleRecipientTypeSelect = this.handleRecipientTypeSelect.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleUploadDocClick = this.handleUploadDocClick.bind(this);
        this.handleDocumentChange = this.handleDocumentChange.bind(this);
        this.handleComponentsSubmit = this.handleComponentsSubmit.bind(this);
        this.handleTemplateSend = this.handleTemplateSend.bind(this);
        this.contactListSelect = this.contactListSelect.bind(this);
        this.handleAllContactsComplete = this.handleAllContactsComplete.bind(this);
    }

    handleTemplateClick(template){ 
        this.setState({ templateToSend: template, step: 2 });
    }

    handleRecipientTypeSelect(type){
        this.setState({ recipientType: type });
    }

    handleStepChange(step){
        this.setState({ step })
    }
    
    handleUploadDocClick(inputElement){
        inputElement.click();
    }
    
    async handleDocumentChange(e){
        this.setState({ importingDoc: true, error: '' })
        try{ 
            const numbers = await importContactsFromFile(this.props.auth.selectedChatbot.token, e.target.files[0]);
            this.setState({ importingDoc: false, numbers })
        }
        catch(e){
            this.setState({ error: 'Failed to import numbers. Please ensure all numbers are in the first column of your doc', importingDoc: false })
        }
    }

    handleComponentsSubmit(components){
        this.setState({ componentsBody: components, step: 5 });
    }

    async handleTemplateSend(){
        this.setState({ awaitingSendStart: true })
        await broadcastTemplate(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id, this.state.templateToSend.name, this.state.templateToSend.language, this.state.componentsBody, this.state.contactList ? this.state.contactList._id : null, this.state.allContacts);
        this.setState({ awaitingSendStart: false })
        history.push('/whatsapp-push-notifications');
    }

    async handleAllContactsComplete(filters){
        this.setState({ allContacts: {
            filters
        } })

        this.handleStepChange(4);
    }

    contactListSelect(contactList){
        this.setState({ contactList })
        this.handleStepChange(4);
    }
        
    render(){
        return (
            <PageContainer>
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">Send Push Notifications</div>
                        <div className='whatsapp_template_builder'>
                            {this.state.step === 1 && (
                                <div className='whatsapp_template_step'>
                                    <div className="form-group">
                                        <div className='whatsapp_template_composer__label' htmlFor="body">Select Template</div>
                                        <div className='whatsapp_template_composer__label_description'>Please select the template message you would like to push out.</div>
                                        <WhatsAppTemplateList allowPending={false} onClick={this.handleTemplateClick}/>
                                    </div>
                                </div>
                            )}
                            {this.state.step === 2 && (
                                <div className='whatsapp_template_step'>
                                    <div className="form-group">
                                        <div className='whatsapp_template_composer__label' htmlFor="body">Recipients</div>
                                        <div className='whatsapp_template_composer__label_description'>Please select who you would like to send <b>{this.state.templateToSend.name}</b> to. Please bear in mind any number that has opted out will be ignored.</div>
                                        <label>Select an audience</label>
                                        <div className={`whatsapp_template_builder_option_list_option ${this.state.recipientType === 'all_users' ? 'checked' : ''}`} onClick={() => { this.handleRecipientTypeSelect('all_users') }}>
                                            <div className='form_checkbox'></div>
                                            <div className='whatsapp_template_builder_option_list_option_icon'><HiOutlineUserGroup/></div>
                                            <div className='whatsapp_template_builder_option_list_option_label'>
                                                <div className='whatsapp_template_builder_option_list_option_label__label'>Bot Users</div>
                                                <div className='whatsapp_template_builder_option_list_option_label__description'>Send to users that have used the bot before</div>
                                            </div>
                                        </div>
                                        <div className={`whatsapp_template_builder_option_list_option ${this.state.recipientType === 'recipient_list' ? 'checked' : ''}`} onClick={() => { this.handleRecipientTypeSelect('recipient_list') }}>
                                            <div className='form_checkbox'></div>
                                            <div className='whatsapp_template_builder_option_list_option_icon'><TiContacts/></div>
                                            <div className='whatsapp_template_builder_option_list_option_label'>
                                                <div className='whatsapp_template_builder_option_list_option_label__label'>Select Existing Recipient List</div>
                                                <div className='whatsapp_template_builder_option_list_option_label__description'>Select an already imporeted recipient list</div>
                                            </div>
                                        </div>
                                        <div className={`whatsapp_template_builder_option_list_option ${this.state.recipientType === 'file' ? 'checked' : ''}`} onClick={() => { this.handleRecipientTypeSelect('file') }}>
                                            <div className='form_checkbox'></div>
                                            <div className='whatsapp_template_builder_option_list_option_icon'><TbFileSpreadsheet/></div>
                                            <div className='whatsapp_template_builder_option_list_option_label'>
                                                <div className='whatsapp_template_builder_option_list_option_label__label'>Import Recipient List</div>
                                                <div className='whatsapp_template_builder_option_list_option_label__description'>Import your recipient list from an external Excel or CSV file. Please note you will need to select a numbers column in this document</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='whatsapp_template_step__actions'>
                                        <button className='btn' onClick={() => { this.handleStepChange(1) }}>Previous step</button>
                                        <button className='btn' disabled={!this.state.recipientType} onClick={() => { this.handleStepChange(3) }}>Next step</button>
                                    </div>
                                </div>
                            )}
                            {this.state.step === 3 && (
                                <div className='whatsapp_template_step'>
                                    {this.state.recipientType === 'all_users' && (
                                        <div className="form-group">
                                            <div className='whatsapp_template_composer__label' htmlFor="body">All Users</div>
                                            <div className='whatsapp_template_composer__label_description'>Send notification to all bot users. Filter results below.</div>
                                            <WhatsAppAllUserList onCancel={() => { this.handleStepChange(2); this.setState({ contactList: null, allContacts: null}) }} onComplete={this.handleAllContactsComplete}/>
                                        </div>
                                    )}
                                    {this.state.recipientType === 'recipient_list' && (
                                        <WhatsAppContactListPicker onCancel={() => { this.handleStepChange(2); this.setState({ contactList: null, allContacts: null}) }} onClick={this.contactListSelect}/>
                                    )}
                                    {this.state.recipientType === 'file' && (
                                        <div className="form-group">
                                            <div className='whatsapp_template_composer__label' htmlFor="body">Import File</div>
                                            <div className='whatsapp_template_composer__label_description'>Please note you will need to select a numbers column in this document</div>
                                            <button className='btn mt-medium' onClick={() => { this.handleUploadDocClick(this.inputElement) }} disabled={this.state.importingDoc}><HiOutlineArrowUpTray/>Import file</button>
                                            <input ref={input => this.inputElement = input} type="file" style={{display: 'none'}} onChange={this.handleDocumentChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"/>                                 
                                            <WhatsAppContactListCreateForm loading={this.state.importingDoc} numbers={this.state.numbers} onComplete={this.contactListSelect} onCancel={() => { this.handleStepChange(2); this.setState({ contactList: null, allContacts: null}) }}/>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(this.state.step === 4 || this.state.step === 5)  && (
                                <div className='whatsapp_template_step'>
                                    <div className='whatsapp_template_composer'>
                                        {this.state.step === 4 && <WhatsAppTemplateFiller onCancel={() => { this.handleStepChange(3) }} components={this.state.componentsBody} template={this.state.templateToSend} onSubmit={this.handleComponentsSubmit}/>}
                                        {this.state.step === 5 && (
                                            <div className='whatsapp_template_composer_items'>
                                                <div className="page_title">Confirm Details</div>
                                                <p>You are about to send your template message. Please confirm the below details are correct</p>
                                                <div className='form-group'>
                                                    <div>
                                                        <label>Recipient:</label>
                                                        <div className='recipient_badge status_badge'><HiUserGroup/>{this.state.contactList ? this.state.contactList.name : 'Bot Users'}</div>
                                                    </div>
                                                </div>
                                                    <div>
                                                        <label>Filters Applied:</label>
                                                        <div className='filter_group'>
                                                        {this.state.allContacts && this.state.allContacts.filters && this.state.allContacts.filters && Object.keys(this.state.allContacts.filters).length > 0 ? (
                                                            Object.keys(this.state.allContacts.filters).map((key) => (
                                                                <div className='recipient_badge status_badge'><HiFunnel/>{titleCase((key + '').replace('user_info.', ''))}: {this.state.allContacts.filters[key]}</div>
                                                            ))
                                                        ) : <p>No filters applied</p>}
                                                        </div>
                                                    </div>
                                                <div className='whatsapp_template_step__actions'>                                    
                                                    <button className='btn' onClick={() => { this.handleStepChange(4) }}>Previous step</button>
                                                    <button type='submit' className='btn primary' disabled={this.state.awaitingSendStart} onClick={this.handleTemplateSend}>Send Push Notifcation</button>
                                                </div>
                                            </div>
                                        )}
                                        <WhastAppTemplatePreview template={this.state.templateToSend}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppPushNotifcationNewJobPage);