import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { getQuickReplies } from '../../services/quickReplies';
import QuickReplyListItem from './QuickReplyListItem';
import EditQuickReplyModal from './EditQuickReplyModal';
import ConfirmModal from '../Base/ConfirmModal';
import { updateQuickReplies, deleteQuickReply, newQuickReplies } from '../../services/quickReplies';
import LoadingModal from '../Base/LoadingModal';
import { HiPlusCircle } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';
import { HiOutlineBolt } from 'react-icons/hi2';
import { TextField } from '@mui/material';

const QuickReplyList = (props) => {
    const [loadingQuickReplies, setLoadingQuickReplies] = useState(true);
    const [quickReplies, setQuickReplies] = useState([]);
    const [filteredQuickReplies, setFilteredQuickReplies] = useState([]);
    const [selectedQuickReply, setSelectedQuickReply] = useState(undefined);
    const [quickReplyToDelete, setQuickReplyToDelete] = useState(undefined);
    const [deletingQuickReply, setDeletingQuickReply] = useState(false);

    useEffect(() => {
        loadQuickReplies();
    }, []);

    useEffect(() => {
        if (props.messageValue && props.messageValue.length > 0) {
            let qr = quickReplies;
            qr = qr.filter((quickReply) => {
                let filter = false;

                if (
                    quickReply.message_data.text &&
                    quickReply.message_data.text.toLowerCase().includes(props.messageValue.toLowerCase())
                ) {
                    filter = true;
                }

                if (
                    quickReply.message_data.media &&
                    quickReply.message_data.media.length > 0 &&
                    quickReply.message_data.media[0].name
                        .toLowerCase()
                        .includes(props.messageValue.toLowerCase())
                ) {
                    filter = true;
                }
                return filter;
            });

            setFilteredQuickReplies(qr);
        }
    }, [props.messageValue]);

    const loadQuickReplies = async () => {
        const quickReplies = await getQuickReplies(
            props.auth.selectedChatbot.token,
            props.auth.agent.agent._id
        );

        setQuickReplies(quickReplies);
        setLoadingQuickReplies(false);
    };

    const saveQuickReply = async (quickReplyId, body) => {
        if (quickReplyId) {
            await updateQuickReplies(props.auth.selectedChatbot.token, quickReplyId, body);
        } else {
            await newQuickReplies(props.auth.selectedChatbot.token, {
                ...body,
                owner: props.auth.agent.agent._id,
            });
        }
        await loadQuickReplies();
    };

    const onEditClick = (quickReply) => {
        setSelectedQuickReply(quickReply);
    };

    const handleDeleteQuickReplyToggle = (quickReply) => {
        setQuickReplyToDelete(quickReply);
    };

    const handleDeleteQuickReply = async () => {
        const quickReply = quickReplyToDelete;
        setQuickReplyToDelete(null)
        setDeletingQuickReply(true);
        await deleteQuickReply(props.auth.selectedChatbot.token, quickReply._id);
        await loadQuickReplies();
        setDeletingQuickReply(false);
    };

    const quickRepliesToShow =
        props.messageValue.trim().length > 0 ? filteredQuickReplies : quickReplies;

    return (
        <div className="quick_replies">
            {!props.editable && (
                <div className="quick_reply_list__title">
                    Quick Replies{' '}
                    <NavLink to="/profile" className="btn">
                        <HiOutlineBolt /> Manage Quick Replies
                    </NavLink>
                </div>
            )}
            {props.editable && !loadingQuickReplies && (
                <div className="quick_replies_top_bar">
                    {props.showSearch && (
                        <TextField
                            type="text"
                            size="small"
                            autoComplete="off"
                            onChange={props.handleSearchChange}
                            value={props.messageValue}
                            placeholder="Type to search"
                        />
                    )}
                    {props.editable && (
                        <button onClick={() => onEditClick({ private: true })} className="btn">
                            <HiPlusCircle /> New Quick Reply
                        </button>
                    )}
                </div>
            )}
            {!loadingQuickReplies ? (
                quickRepliesToShow.length > 0 ? (
                    <div className="quick_reply_list">
                        {quickRepliesToShow.map((quickReply) => (
                            <QuickReplyListItem
                                editable={props.editable}
                                key={quickReply._id}
                                handleDeleteQuickReplyToggle={handleDeleteQuickReplyToggle}
                                currentAgent={props.auth.agent.agent}
                                currentConversation={props.currentConversation}
                                onSelect={props.onSelect}
                                onEdit={onEditClick}
                                quickReply={quickReply}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="quick_reply_list__error">No quick replies found</div>
                )
            ) : (
                <Preloader />
            )}
            {selectedQuickReply && (
                <EditQuickReplyModal
                    saveQuickReply={saveQuickReply}
                    isOpen={selectedQuickReply ? true : false}
                    toggleEdit={() => onEditClick()}
                    quickReply={selectedQuickReply}
                />
            )}
            {quickReplyToDelete && (
                <ConfirmModal
                    onConfirm={handleDeleteQuickReply}
                    onRequestClose={() => handleDeleteQuickReplyToggle()}
                    isOpen={quickReplyToDelete ? true : false}
                    confirmText="Delete"
                    title="Delete Quick Reply"
                    description={
                        <span>
                            You are about to delete this quick reply permanently. Please note this action cannot
                            be undone.
                            <br />
                            <br />
                            Are you sure?
                        </span>
                    }
                />
            )}
            {deletingQuickReply && <LoadingModal isOpen={deletingQuickReply} text="Deleting quick reply" />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(QuickReplyList);