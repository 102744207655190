import React, { useState, useEffect } from 'react';
import Preloader from '../Preloader';
import InternalChatRoomInputArea from './InternalChatRoomInputArea';
import InternalChatRoomMessages from './InternalChatRoomMessages';
import InternalChatRoomTopBar from './InternalChatRoomTopBar';
import { createdMessage, setCurrentMessages, createNotification, notificationRead } from '../../services/internalChats';
import { uploadMedia } from '../../services/upload';
import { setCurrentMessagesValue, updateInternalChats } from '../../reducers/internalChatsSlice';
import { useDispatch, useSelector } from 'react-redux';

const InternalChatRoom = (props) => {
    const [messageValue, setMessageValue] = useState('');
    const [emojiPickerShown, setEmojiPickerShown] = useState(false);
    const [hasMedia, setHasMedia] = useState(false);
    const [mediaFile, setMediaFile] = useState(undefined);
    const [mediaPreview, setMediaPreview] = useState(undefined);
    const [loadingMediaPreview, setLoadingMediaPreview] = useState(false);

    const auth = useSelector(state => state.auth);
    const internalChats = useSelector(state => state.internalChats);
    const dispatch = useDispatch();

    const handleMessageType = (e) => {
        setMessageValue(e.target.value);
    };

    const handleEmojiPick = (emojiData) => {
        setMessageValue(prevMessageValue => prevMessageValue + emojiData.emoji);
    };

    const handleLoadMoreMessages = async () => {
        await dispatch(updateInternalChats({
            messagesLimit: (internalChats.messagesLimit + internalChats.messagesOffset)
        }));
        await setCurrentMessages(internalChats, auth, dispatch);
    };

    const handleMessageSubmit = async (e) => {
        e.preventDefault();
        if (messageValue.trim().length > 0 || hasMedia) {
            let media = null;
            if (mediaFile && !mediaFile.url) {
                const uploadResponse = await uploadMedia(auth.selectedChatbot.token, mediaFile);
                if (uploadResponse.url) {
                    media = {
                        url: uploadResponse.url,
                        name: mediaFile.name,
                        type: mediaFile.type
                    };
                }
            }

            if (mediaFile && mediaFile.url) {
                media = mediaFile;
            }

            const message = {
                sender: auth.agent.agent._id,
                message_data: {
                    text: messageValue && messageValue.trim().length > 0 ? messageValue.trim() : undefined,
                    media: media ? [media] : null
                }
            };
            setMessageValue('');
            setHasMedia(false);
            setMediaFile(undefined);
            setMediaPreview(undefined);
            setLoadingMediaPreview(false);
            dispatch(setCurrentMessagesValue([...internalChats.selectedChat.messages, { ...message, sender: auth.agent.agent, sending: true }]));
            createdMessage(internalChats.selectedChat.chat._id, message, auth.selectedChatbot.token).then(message => {
                setCurrentMessages(internalChats, auth, dispatch);
                const currentAgent = auth.agent.agent;
                const chattingTo = internalChats.selectedChat.chat.participants.filter(participant => participant._id !== currentAgent._id);
                const notificationBody = chattingTo.map(participant => ({ agent: participant._id, message: message._id }));
                createNotification(internalChats.selectedChat.chat._id, notificationBody, auth.selectedChatbot.token);
            });
        }
    };

    const handleTextEnter = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            handleMessageSubmit(e);
        }
    };

    const handleMediaTrigger = (input) => {
        input.current.click();
    };

    const handleMediaChange = (e) => {
        setHasMedia(true);
        setLoadingMediaPreview(true);
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setMediaFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setMediaPreview(e.target.result);
                setLoadingMediaPreview(false);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveMedia = () => {
        setHasMedia(false);
        setMediaFile(undefined);
        setMediaPreview(undefined);
        setLoadingMediaPreview(false);
    };

    useEffect(() => {
        setCurrentMessages(internalChats, auth, dispatch);
        notificationRead(internalChats.selectedChat.chat._id, auth.agent.agent._id, auth.selectedChatbot.token);
    }, []);

    useEffect(() => {
        if (internalChats.selectedChat.messages !== internalChats.selectedChat.messages) {
            notificationRead(internalChats.selectedChat.chat._id, auth.agent.agent._id, auth.selectedChatbot.token);
        }
    }, [internalChats.selectedChat.messages]);

    const chat = internalChats.selectedChat.chat;
    const currentAgent = auth.agent.agent;
    const participants = chat.participants;
    const chattingTo = participants.filter(participant => participant._id !== currentAgent._id);
    const isGroupChat = chattingTo.length > 1;

    return (
        <div className="internal_chat_room">
            <InternalChatRoomTopBar handleToggleChat={props.handleToggleChat} handleDeselectChat={props.handleDeselectChat} isGroupChat={isGroupChat} chattingTo={chattingTo} />
            {!internalChats.selectedChat.loadingMessages ? (
                <div className="internal_chat_room_content">
                    <InternalChatRoomMessages currentAgent={auth.agent.agent} handleLoadMoreMessages={handleLoadMoreMessages} messages={internalChats.selectedChat.messages} hasMoreMessages={internalChats.hasMoreMessages} />
                    <InternalChatRoomInputArea handleTextEnter={handleTextEnter} handleEmojiPick={handleEmojiPick} handleMessageSubmit={handleMessageSubmit} handleMessageType={handleMessageType} messageValue={messageValue} isGroupChat={isGroupChat} chattingTo={chattingTo} handleMediaTrigger={handleMediaTrigger} handleMediaChange={handleMediaChange} mediaFile={mediaFile} mediaPreview={mediaPreview} handleRemoveMedia={handleRemoveMedia} loadingMediaPreview={loadingMediaPreview} hasMedia={hasMedia} />
                </div>
            ) : <Preloader />}
        </div>
    );
};

export default InternalChatRoom;
