import { HiPhoto } from 'react-icons/hi2';

const InternalChatLastActivity = (props) => {
    const lastActivity = props.lastActivity;
    const isAttachment = ((lastActivity.message_data.media && lastActivity.message_data.media.length > 0) || lastActivity.message_data.meta);
    return (
        <div className="text-truncate">
            {!props.hideSender && <span>{lastActivity.sender.first_name[0]}. {lastActivity.sender.last_name}:</span>} <span className="text-span">{isAttachment && <HiPhoto/>} {lastActivity.message_data.text && lastActivity.message_data.text}{(!lastActivity.message_data.text && isAttachment) && 'Attachment'}</span>
        </div>
    );
};

export default InternalChatLastActivity;