import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Preloader from '../Preloader';
import { createChatbot, getChatbots, updateChatbot, deleteChatbot } from '../../services/chatbots';
import { HiOutlinePencil, HiTrash } from 'react-icons/hi2';
import Tooltip from '@mui/material/Tooltip';
import { HiPlus } from 'react-icons/hi2';
import EditChatbotModal from './EditChatbotModal';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';

const AdminChatbots = () => {
    const [chatbots, setChatbots] = useState([]);
    const [loadingChatbots, setLoadingChatbots] = useState(true);
    const [selectedChatbot, setSelectedChatbot] = useState(undefined);
    const [chatbotToDelete, setChatbotToDelete] = useState(undefined);
    const [deletingChatbot, setDeletingChatbot] = useState(false);
    const [savingChatbot, setSavingChatbot] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            const chatbots = await getChatbots(auth.agent.token);
            setChatbots(chatbots);
            setLoadingChatbots(false);
        };
        fetchData();
    }, [auth.agent.token]);

    const handleEditChatbot = (chatbot) => {
        setSelectedChatbot(chatbot);
    };

    const handleChatbotSave = async (chatbotItem) => {
        setSavingChatbot(true);
        setSelectedChatbot(undefined);
        if (chatbotItem._id) {
            await updateChatbot(chatbotItem._id, auth.agent.token, { ...chatbotItem, owner: chatbotItem.owner._id });
        } else {
            await createChatbot(auth.agent.token, { ...chatbotItem, owner: chatbotItem.owner._id });
        }
        const chatbots = await getChatbots(auth.agent.token);
        setChatbots(chatbots);
        setLoadingChatbots(false);
        setSavingChatbot(false);
    };

    const handleDeleteChatbotConfirm = (chatbot) => {
        setChatbotToDelete(chatbot);
    };

    const handleDeleteChatbot = async () => {
        await deleteChatbot(auth.agent.token, chatbotToDelete._id);
        setDeletingChatbot(true);
        setChatbotToDelete(undefined);
        const chatbots = await getChatbots(auth.agent.token);
        setChatbots(chatbots);
        setDeletingChatbot(false);
    };

    return (
        <div className="admin_settings_widget">
            <h3>Chatbots</h3>
            <p>Edit chatbot and change owners.</p>
            <button className="btn mb-medium" onClick={() => { handleEditChatbot({ name: '', owner: undefined }) }}><HiPlus />Add Chatbot</button>
            {!loadingChatbots ? (
                chatbots.length > 0 ? (
                    chatbots.map(chatbot => (
                        <div className="company_list_item" key={chatbot._id}>
                            <div className="company_list_item__badge">{(chatbot.owner && chatbot.owner.logo_icon) && <img src={chatbot.owner.logo_icon} alt="Admin Company Logo" />}{chatbot.owner && chatbot.owner.name}</div>
                            <div className="company_list_item__name">{chatbot.name}</div>
                            <div className="company_list_item__actions">
                                <Tooltip title="Edit chatbot" arrow><div className="company_list_item__action" onClick={() => { handleEditChatbot(chatbot) }}><HiOutlinePencil /></div></Tooltip>
                                <Tooltip title="Delete chatbot" arrow><div className="company_list_item__action" onClick={() => { handleDeleteChatbotConfirm(chatbot) }}><HiTrash /></div></Tooltip>
                            </div>
                            {selectedChatbot && <EditChatbotModal onSave={handleChatbotSave} isOpen={selectedChatbot ? true : false} onRequestClose={() => { handleEditChatbot(undefined) }} chatbot={selectedChatbot} />}
                            {chatbotToDelete && <ConfirmModal isOpen={chatbotToDelete ? true : false} title="Delete Chatbot" onConfirm={handleDeleteChatbot} description={<span>You are about to delete <b>{chatbotToDelete.name}</b><br /><br />Please note that deleting this chatbot will be a permanent action. Are you sure you want to do this?</span>} confirmText="Delete" onRequestClose={() => { handleDeleteChatbotConfirm(undefined) }} />}
                            {deletingChatbot && <LoadingModal isOpen={deletingChatbot} text="Deleting chatbot" />}
                            {savingChatbot && <LoadingModal isOpen={savingChatbot} text="Saving chatbot" />}
                        </div>
                    ))
                ) : 'No Chatbots'
            ) : <Preloader />}
        </div>
    );
}

export default AdminChatbots
