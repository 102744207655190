import React, { useState, useEffect, useRef } from "react";
import Preloader from '../Preloader';
import { useSelector } from 'react-redux';
import { getQueues } from '../../services/queues';
import { transferBotChat, transfer } from '../../services/humanHandoff';
import { companyService } from '../../services/company';
import ChatTransferList from "./ChatTransferList";
import ChatTransferSearch from "./ChatTransferSearch";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const ChatTransfer = (props) => {
    const [queues, setQueues] = useState([]);
    const [agents, setAgents] = useState([]);
    const [loadingQueues, setLoadingQueues] = useState(true);
    const [showing, setShowing] = useState(props.agentPicker ? 'agent' : 'queues');
    const [searchTerm, setSearchTerm] = useState('');
    const [transfering, setTransfering] = useState(false);
    const [actionPerforming, setActionPerforming] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        getQueues(auth.selectedChatbot.token).then(queues => {
            companyService.getCompanyAgents(auth.selectedCompany, auth.agent.token).then(agents => {
                setQueues(queues);
                setAgents(agents);
                setLoadingQueues(false);
            });
        });
    }, [auth.selectedChatbot.token, auth.selectedCompany, auth.agent.token]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase().trim());
    }

    const handleSwitchViewing = () => {
        setShowing(prevState => (prevState === 'queues' ? 'agent' : 'queues'));
    }

    const handleClickAway = () => {
        props.handleTransferToggle();
    }

    const handleTransfer = async (id, type) => {
        setTransfering(true);
        await transfer(auth.selectedChatbot.token, props.conversation.conversation_id, type, id, auth.agent.agent._id);
        await transferBotChat(auth.selectedChatbot.token, props.conversation.conversation_id, props.conversation.channel);
        setTransfering(false);
        props.handleTransferToggle();
    }

    const handleAction = async (id) => {
        setActionPerforming(true);
        await props.onSelect(id);
        setActionPerforming(false);
        props.handleTransferToggle();
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="chat_transfer">
                <div className="widget_title">{props.agentPicker || props.queuePicker ? props.title : 'Transfer'}</div>
                <div className="chat_transfer__inner">
                    {!loadingQueues ? !transfering && !actionPerforming ? (
                        <div>
                            <ChatTransferSearch agentPicker={props.agentPicker} handleSearchChange={handleSearchChange} searchTerm={searchTerm} />
                            <ChatTransferList conversation={props.conversation} currentAgent={auth.agent.agent} allowOffline={props.allowOffline} queuePicker={props.queuePicker} agentPicker={props.agentPicker} handleTransfer={props.agentPicker || props.queuePicker ? handleAction : handleTransfer} searchTerm={searchTerm} handleSwitchViewing={handleSwitchViewing} showing={showing} agents={agents} queues={queues} />
                        </div>
                    ) : <Preloader padding="2rem 0px" text={actionPerforming ? props.actionText : 'Transferring'} /> : <Preloader padding="2rem 0px" />}
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default ChatTransfer;
