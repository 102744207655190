import React, { useState, useRef } from "react";
import Quill from 'quill';
import EmojiPicker from '../Base/EmojiPicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PlainClipboard from "./PlainClipboard";
import { HiOutlinePlus } from "react-icons/hi";

Quill.register('modules/clipboard', PlainClipboard, true);

const WhatsAppTemplateBodyEditor = (props) => {
    const [cursorIndex, setCursorIndex] = useState(0);
    const reactQuillRef = useRef(null);

    const handleCursorFocus = (e) => {
        if (e) {
            setCursorIndex(e.index);
        }
    };

    const handleEmojiPick = (emoji) => {
        const quill = reactQuillRef.current.getEditor();
        quill.insertText(cursorIndex, emoji.emoji);
    };

    const handleInsertVariale = () => {
        const quill = reactQuillRef.current.getEditor();
        const currentVariables = quill.getText().match(/[^{{]+(?=}\})/g);
        quill.insertText(cursorIndex, '{{' + ((currentVariables ? currentVariables.length : 0) + 1) + '}}');
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'strike']
        ],
        clipboard: {
            matchVisual: false
        }
    };

    return (
        <div className="path_item_editor_text_editor">
            <ReactQuill
                format="json"
                ref={reactQuillRef}
                theme="snow"
                value={props.text}
                onChangeSelection={(e) => { handleCursorFocus(e) }}
                onChange={props.handleTextChange}
                modules={modules}
            />

            <div className="path_item_editor_text_editor__actions">
                <div className="btn" onClick={handleInsertVariale}><HiOutlinePlus />Add Variable</div>
                <div className="path_item_editor_text_editor__actions">
                    {props.maxLengthText}
                    <EmojiPicker handleEmojiPick={(e, emoji) => { handleEmojiPick(emoji) }} />
                </div>
            </div>
        </div>
    );
};

export default WhatsAppTemplateBodyEditor;
