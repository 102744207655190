
import Tooltip from '@mui/material/Tooltip';

const ConnectorsBadge = ({ connectors }) => {
    return (
        <div className="connectors_badge">
            {connectors.map(connector => <Tooltip title={`Requires ${connector.name}`} arrow><div className='connectors_badge__icon'><img src={connector.logo} alt={`${connector.name} badge logo`}/></div></Tooltip>)}
        </div>
    )
}

export default ConnectorsBadge;