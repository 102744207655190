import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getJobLogs } from '../../services/job';
import { titleCase } from '../../services/misc';
import LoadMore from '../Base/LoadMore';
import Preloader from '../Preloader';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const JobLogViewer = (props) => {
    const [limit, setLimit] = useState(30);
    const [offset, setOffset] = useState(30);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [filters, setFilters] = useState({});
    const [error, setError] = useState('Failed to retrieve');
    const [logs, setLogs] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        loadJobLogs();
    }, []);
    
    const handleClickAway = () => {
        props.handleClose();
    }

    const loadJobLogs = async () => {
        const logs = await getJobLogs(auth.selectedChatbot.token, props.jobId, limit + 1, filters, 'desc');

        const hasMore = logs.length > limit;
        if (hasMore) {
            logs.pop();
        }

        setLogs(logs);
        setHasMore(hasMore);
        setLoadingLogs(false);
    };

    const loadMoreClick = async () => {
        setLoadingMore(true);
        setLimit(prevLimit => prevLimit + offset);
        await loadJobLogs();
        setLoadingMore(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className='job_log_container'>
                {!loadingLogs ? (
                    logs.length ? (
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td>Trace ID</td>
                                    <td>Message</td>
                                    <td>Type</td>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.map(log => {
                                    let status = log.type.toLowerCase().replace('_log', '').trim();
                                    return (
                                        <tr key={log._id}>
                                            <td>{log._id}</td>
                                            <td>{log.message}</td>
                                            <td><div className={`status_badge ${status}`}>{titleCase(status)}</div></td>
                                        </tr>
                                    )
                                })}
                                {hasMore && <td colSpan={3} className='loadMoreColumn'><LoadMore loadingMore={loadingMore} onClick={loadMoreClick} /></td>}
                            </tbody>
                        </table>
                    ) : <div className='job_log_no_logs'>No logs found</div>
                ) : <Preloader />}
            </div>
        </ClickAwayListener>
    );
};

export default JobLogViewer;
