import React, { useRef, useState } from 'react';
import { toTimestamp } from '../../services/dates';
import { HiCheck, HiXMark } from 'react-icons/hi2';
import { titleCase } from '../../services/misc';
import Preloader from '../Preloader';
import TextArea from 'react-textarea-autosize';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const PendingQuestionItem = (props) => {
    const [approving, setApproving] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editedQuestion, setEditedQuestion] = useState('');

    const handleApproveClick = async () => {
        setApproving(true);
        await props.handleApproveQuestion(props.question._id);
        setApproving(false);
    };

    const handleRejectClick = async () => {
        setRejecting(true);
        await props.handleRejectQuestion(props.question._id);
        setRejecting(false);
    };

    const handleEditToggle = () => {
        setEditing(prevEditing => !prevEditing);
        setEditedQuestion(props.question.message_data.text);
    };

    const handleEditChange = (e) => {
        setEditedQuestion(e.target.value);
    };

    const handleEditSave = async () => {
        setSaving(true);
        await props.handleEditQuestion(props.question, editedQuestion);
        setEditing(false);
        setSaving(false);
    };

    const handleClickAway = () => {
        if (editing) {
            handleEditToggle();
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`pending_question ${approving || rejecting ? 'disabled' : ''}`}>
                <div className="pending_question__details">
                    <div className="pending_question__question">
                        {editing ? (
                            !saving ? (
                                <div className="unanswered_question__form">
                                    <TextArea placeholder="Enter unanswered question." value={editedQuestion} onChange={handleEditChange} />
                                    <div className="unanswered_question__form__actions">
                                        <button className="btn" disabled={editedQuestion.trim().length === 0} onClick={handleEditSave}>Save</button>
                                        <button className="btn" onClick={handleEditToggle}>Cancel</button>
                                    </div>
                                </div>
                            ) : <div className="text_preloader fit">{editedQuestion}</div>
                        ) : <div onClick={handleEditToggle}>{props.question.message_data.text}</div>}
                    </div>
                    <div className="pending_question__source">Source: {titleCase(props.question.type)}</div>
                    <div className="timestamp">{toTimestamp(props.question.createdAt)}</div>
                </div>
                <div className="pending_question__actions">
                    {!approving ? <div className="pending_question__action icon_only" onClick={handleApproveClick}><HiCheck /></div> : <div className="pending_question__action"><Preloader padding="0" color="white" size="13" /></div>}
                    {!rejecting ? <div className="pending_question__action reject icon_only" onClick={handleRejectClick}><HiXMark /></div> : <div className="pending_question__action reject"><Preloader padding="0" color="white" size="13" /></div>}
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default PendingQuestionItem;
