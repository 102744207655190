import { handleResponse } from "../misc";

const getModules = (authToken, eduBotId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot/' + eduBotId + '/module', requestOptions).then(handleResponse).then((modules) => {
        return modules;
    });
};

const saveModules = (authToken, modifiedModules) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(modifiedModules)
    };

    return fetch('/api/modules/save', requestOptions).then(handleResponse);
}

const deleteModules = (authToken, modules) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(modules)
    };

    return fetch('/api/modules', requestOptions).then(handleResponse);
}

export {
    getModules,
    saveModules,
    deleteModules
}