import { RiWhatsappLine, RiMessengerFill, RiWechatFill } from 'react-icons/ri';
import { SiMicrosoftteams } from 'react-icons/si';
import { FaTelegramPlane } from 'react-icons/fa'
import { HiGlobeEuropeAfrica } from 'react-icons/hi2'
import { FiCodepen } from 'react-icons/fi';

const { default: ChatStatusIcon } = require("./ChatStatusIcon");
const ChatChannelIcon = (props) => {
    const channelIcon = {
        name: undefined,
        color: undefined,
        iconUrl: undefined
    };

    switch(props.channel){
        case 'whatsapp': {
            channelIcon.name = 'WhatsApp';
            channelIcon.color = 'radial-gradient(circle, rgba(34,199,96,1) 0%, rgba(37,211,102,1) 100%)';
            channelIcon.icon = <RiWhatsappLine/>;
            break;
        }
        case 'facebook': {
            channelIcon.name = 'Facebook Messenger';
            channelIcon.color = '#0078FF';
            channelIcon.icon = <RiMessengerFill/>;
            break;
        }
        case 'msteams': {
            channelIcon.name = 'Microsoft Teams';
            channelIcon.color = '#6264A7';
            channelIcon.icon = <SiMicrosoftteams/>;
            break;
        }
        case 'telegram': {
            channelIcon.name = 'Telegram';
            channelIcon.color = 'radial-gradient(circle, rgba(0,126,189,1) 0%, rgba(0,136,204,1) 100%)';
            channelIcon.icon = <FaTelegramPlane/>;
            break;
        }
        case 'webchat':
        case 'directline': {
            channelIcon.name = 'Web Chat';
            channelIcon.color = 'radial-gradient(circle, rgba(0,115,251,1) 0%, rgba(3,91,166,1) 100%)';
            channelIcon.icon = <HiGlobeEuropeAfrica/>;
            break;
        }
        case "wechat": {
            channelIcon.name = 'WeChat';
            channelIcon.color = 'radial-gradient(circle, rgba(47,136,25,1) -6%, rgba(55,163,29,1) 34%)';
            channelIcon.icon = <RiWechatFill/>;
            break;
        }
        default:
        case 'emulator': {
            channelIcon.name = 'Emulator';
            channelIcon.color = 'radial-gradient(circle, rgb(139 80 158) 0%, rgb(83 47 96) 100%)';
            channelIcon.icon = <FiCodepen/>;
            break;
        }
    }

    return (
        <div className={`chat_channel_icon ${channelIcon.name.replace(' ', '_').toLowerCase()}`} style={{background: channelIcon.color}}>{channelIcon.icon}<ChatStatusIcon status={props.status}/></div>
    );
}

export default ChatChannelIcon;