import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import LoadMore from '../Base/LoadMore';
import ChatFilters from './ChatFilters';
import ChatSort from './ChatSort';
import ChatListActions from './ChatListActions';
import ChatListItem from './ChatListItem';
import ChatSearch from './ChatSearch';
import ChatsPreloader from './ChatsPreloader';
import { HiOutlineChevronDown } from 'react-icons/hi2';

const ChatsList = (props) => {
    const [filtersShown, setFiltersShown] = useState(false);
    const [searchShown, setSearchShown] = useState(false);
    const [sortShown, setSortShown] = useState(false);
    const [currentCollapsed, setCurrentCollapsed] = useState(false);
    const [allCollapsed, setAllCollapsed] = useState(false);

    const handleFilterTrigger = () => {
        setFiltersShown((prevState) => !prevState);
        setSearchShown(false);
        setSortShown(false);
    };

    const handleSearchTrigger = () => {
        setSearchShown((prevState) => !prevState);
        setFiltersShown(false);
        setSortShown(false);
    };

    const handleSortTrigger = () => {
        setSortShown((prevState) => !prevState);
        setFiltersShown(false);
        setSearchShown(false);
    };

    const handleClearSearchClick = () => {
        props.clearSearch();
        setSearchShown(false);
    };

    const handleCollapseToggle = (fieldName) => {
        if (fieldName === 'currentCollapsed') {
            setCurrentCollapsed((prevState) => !prevState);
        } else if (fieldName === 'allCollapsed') {
            setAllCollapsed((prevState) => !prevState);
        }
    };

    const currentChats =
        props.conversations && props.conversations.length > 0
            ? props.conversations.filter((conversation) => conversation.current === true)
            : [];
    const allChats =
        props.conversations && props.conversations.length > 0
            ? props.conversations.filter((conversation) => !conversation.current)
            : [];

    return (
        <div className="chats_list">
            <ChatListActions
                isSearched={props.isSearched}
                termSearched={props.termSearched}
                searching={props.searching}
                filters={props.filters}
                sortBy={props.sortBy}
                currentAgent={props.currentAgent}
                handleSetFilter={props.handleSetFilter}
                handleFilterTrigger={handleFilterTrigger}
                handleSearchTrigger={handleSearchTrigger}
                handleSortTrigger={handleSortTrigger}
                conversations={props.conversations}
                filtersShown={filtersShown}
                searchShown={searchShown}
                sortShown={sortShown}
            />
            {sortShown && <ChatSort sortBy={props.sortBy} handleSetSort={props.handleSetSort} />}
            {filtersShown && <ChatFilters filters={props.filters} handleFilterTrigger={handleFilterTrigger} handleSetFilter={props.handleSetFilter} />}
            {searchShown && (
                <ChatSearch
                    handleClearSearchClick={handleClearSearchClick}
                    handleSearchSubmit={props.handleSearchSubmit}
                    handleSearchChange={props.handleSearchChange}
                    searchTerm={props.searchTerm}
                    isSearched={props.isSearched}
                />
            )}
            {!props.loadingConversations ? (
                <div className="chat_list__inner_list">
                    {currentChats && currentChats.length > 0 && (
                        <div>
                            <div className="chat_list__subtitle" onClick={() => handleCollapseToggle('currentCollapsed')}>
                                <HiOutlineChevronDown className={currentCollapsed ? 'rotated' : ''} />
                                <span>Connected Conversations</span>
                            </div>
                            <div className={`list ${currentCollapsed ? 'collapsed' : ''}`}>
                                {currentChats.map((conversation) => (
                                    <ChatListItem
                                        key={conversation._id}
                                        currentConversationId={props.currentConversationId}
                                        handleConversationClick={props.handleConversationClick}
                                        currentConversation={props.currentConversation}
                                        conversation={conversation}
                                        currentAgent={props.currentAgent}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {props.showAllHistory && allChats && allChats.length > 0 && (
                        <div>
                            <div className="chat_list__subtitle" onClick={() => handleCollapseToggle('allCollapsed')}>
                                <HiOutlineChevronDown className={allCollapsed ? 'rotated' : ''} />
                                <span>All Conversations</span>
                            </div>
                            <div className={`list ${allCollapsed ? 'collapsed' : ''}`}>
                                {allChats.map((conversation) => (
                                    <ChatListItem
                                        key={conversation._id}
                                        currentConversationId={props.currentConversationId}
                                        handleConversationClick={props.handleConversationClick}
                                        currentConversation={props.currentConversation}
                                        conversation={conversation}
                                        currentAgent={props.currentAgent}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {!allCollapsed && props.conversations && props.hasMoreConversations && (
                        <VisibilitySensor
                            onChange={(isVisible) => {
                                if (isVisible && !props.loadingMoreConversations) {
                                    props.handleLoadMoreConversations();
                                }
                            }}
                        >
                            <LoadMore loadingMore={props.loadingMoreConversations} onClick={props.handleLoadMoreConversations} />
                        </VisibilitySensor>
                    )}
                </div>
            ) : (
                <ChatsPreloader />
            )}
        </div>
    );
};

export default ChatsList;