import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const MenuItem = (props) => {
    const auth = useSelector(state => state.auth);
    return props.rolesAllowed.includes(auth.agent.agent.role) ? (
        <div className="dashboard_menu__item">
            <NavLink className="dashboard_menu__item__navlink" onClick={props.onClick} activeclassname="active" to={props.to}>
                {props.children}
                {props.notificationCount !== undefined && <div className="count_badge">{props.notificationCount}</div>}
                {props.label && <div className="dashboard_menu__item__label"><span>{props.label}</span></div>}
            </NavLink>
        </div>
    ) : null;
};

export default MenuItem;