import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import { toTimeOnly } from '../../services/dates';
import MessageMedia from '../Chats/MessageMedia';
import ConversationCard from './ConversationCard';


const InternalChatRoomMessageItem = (props) => {
    const message = props.message;
    const messageData = message.message_data;
    let senderClass = message.sender._id === props.currentAgent._id ? 'user' : 'agent';

    return (
        <div className={`message ${senderClass}`}>
            <div className={`message_inner ${message.sender && message.new_message && !message.sending && message.sender._id.toString() === props.currentAgent._id.toString() ? 'no_animation' : ''}`}>
                <AgentProfileAvatar agent={message.sender}/>
                <div className="message_inner__content">
                    <div className="message__buble">{(messageData.media && messageData.media.length > 0) && <div className="message__media">{messageData.media.map(media => <MessageMedia media={media}/>)}</div> }{(messageData.meta && messageData.meta.type === 'conversation_card') && <ConversationCard conversationId={messageData.meta.id}/>}{messageData.text && <div className="message__text">{messageData.text}</div>}</div>  
                    <div className="message__timestamp">{message.sending ? 'Sending...' : toTimeOnly(message.createdAt)}</div>
                </div>  
            </div>
        </div>
    );
};

export default InternalChatRoomMessageItem;