import { useState } from "react";
import FileUpload from "./FileUpload";
import Modal from 'react-modal';

const FileUploadModal = (props) => {
    const fileTypes = props.fileTypes || [];
    const [file, setFile] = useState(null);
    
    const handleChange = (file) => {
        setFile(file);
    };

    const handleConfirm = () => {
        props.handleSetFile(file);
    }

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className={`modal modal__preloader ${props.className}`} onRequestClose={props.onRequestClose}>
            <div className="modal__title">{props.title || 'Upload files'}</div>
            <div className="modal__content">
                {props.description || 'Select a file to upload or drag and drop a file here.'}
            </div>
            <FileUpload handleChange={handleChange} multiple={props.multiple} fileTypes={fileTypes} handleSetFile={handleChange}/>
            <div className="modal__actions">
                <button className="btn" onClick={props.onRequestClose}>Cancel</button>
                <button className="btn primary" onClick={handleConfirm}>Upload</button>
            </div>
        </Modal>
    );
}

export default FileUploadModal;