import { handleResponse } from './misc';
function getCompanyChatbots(companyId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/company/' + companyId + '/chatbots', requestOptions).then(handleResponse).then((chatbots) => {
        return chatbots;
    });
}

function getCompanies(authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/company', requestOptions).then(handleResponse).then((companies) => {
        return companies;
    });
}

function getCompany(companyId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/company/' + companyId, requestOptions).then(handleResponse).then((company) => {
        return company;
    });
}

function deleteCompany(companyId, authToken){
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/company/' + companyId, requestOptions).then(handleResponse).then((company) => {
        return company;
    });
}

function getCompanyAgents(companyId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/company/' + companyId + '/agents', requestOptions).then(handleResponse).then((agents) => {
        return agents;
    });
}

function createCompany(authToken, body){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/company', requestOptions).then(handleResponse).then((company) => {
        return company;
    });
}

function updateCompany(companyId, authToken, body){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/company/' + companyId, requestOptions).then(handleResponse).then((company) => {
        return company;
    });
}

function updateCompanyAgents(companyId, authToken, agents){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ agents })
    };

    return fetch('/api/company/' + companyId + '/agents', requestOptions).then(handleResponse).then((company) => {
        return company;
    });
}

export const companyService = {
    createCompany,
    getCompanies,
    getCompanyChatbots,
    getCompanyAgents,
    getCompany,
    updateCompany,
    updateCompanyAgents,
    deleteCompany
};