const QuestionLogModelBadge = ({ model }) => {
    let modelText = null;

    switch(model){
        case 'gpt-4':
            modelText = 'GPT-4';
            break;
        case 'gpt-3':
            modelText = 'GPT-3';
            break;
        case 'gpt-35-turbo':
            modelText = 'GPT-3.5 Turbo';
            break;
        case 'gpt-35-turbo-16k':
            modelText = 'GPT-3.5 Turbo 16k';
            break;
        case 'gpt-4o':
            modelText = 'GPT-4o';
            break;
        case 'gpt-4o-mini':
            modelText = 'GPT-4o Mini';
            break;
    }
    return (
        <div className="question_log_model_badge">
            {modelText}
        </div>
    );
}

export default QuestionLogModelBadge;