import React from 'react';
import AuditTrail from './AuditTrail';
import { HiOutlineXMark } from 'react-icons/hi2';

const KnowledgeSetSidebar = (props) => {
    return (
        <div className='knowledge_set_sidebar_container'>
            <div className='knowledge_set_sidebar__overlay' onClick={() => { props.handleInfoSidebarTrigger(false) }}></div>
            <div className='knowledge_set_sidebar'>
                <div className='knowledge_set_sidebar__title'>
                    <div>Info</div>
                    <button onClick={() => { props.handleInfoSidebarTrigger(false) }}><HiOutlineXMark/></button>
                </div>
                {props.index && (
                    <div className="knowledge_set_sidebar__component">
                        <div className='knowledge_set_sidebar__info_title'>Index Info:</div>
                        <div className='knowledge_set_sidebar__info'>
                            <div className='knowledge_set_sidebar__info_item'>
                                <div className='knowledge_set_sidebar__info_item_label'>Total chunks:</div>
                                <div className='knowledge_set_sidebar__info_item_value'>{props.chunks.length}</div>
                            </div>
                            <div className='knowledge_set_sidebar__info_item'>
                                <div className='knowledge_set_sidebar__info_item_label'>Average token count:</div>
                                <div className='knowledge_set_sidebar__info_item_value'>{Math.round(props.chunks.reduce((sum, chunk) => sum + (chunk.embedding ? chunk.embedding.usage.totalTokens : 0), 0) / props.chunks.length)}</div>
                            </div>
                            <div className='knowledge_set_sidebar__info_item'>
                                <div className='knowledge_set_sidebar__info_item_label'>Largest chunk token size:</div>
                                <div className='knowledge_set_sidebar__info_item_value'>{
                                    props.chunks.reduce((largestSize, chunk) => {
                                        if (chunk.embedding && chunk.embedding.usage.totalTokens > largestSize) {
                                            return chunk.embedding.usage.totalTokens;
                                        }
                                        return largestSize;
                                    }, 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="knowledge_set_sidebar__component">
                    <div className='knowledge_set_sidebar__info_title'>Audit Trail:</div>
                    <AuditTrail
                        knowledgeSetId={props.knowledgeSet ? props.knowledgeSet._id : (props.index ? props.index.knowledge_set_id : null)}
                        versionId={props.knowledgeSet ? props.knowledgeSet.version_id : (props.index ? props.index.version_id : null)}
                    />
                </div>
            </div>
        </div>
    );
}

export default KnowledgeSetSidebar;
