import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import { getUnansweredQuestions, updateQuestion, exportUnansweredQuestions } from '../../services/unansweredQuestions';
import UnansweredQuestionItem from './UnansweredQuestionItem';
import LoadMore from '../Base/LoadMore';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { getQueues } from '../../services/queues';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Autocomplete, Box, Divider, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const UnansweredQuestionsPage = (props) => {
    const auth = useSelector(state => state.auth);
    
    const [loadingUnansweredQuestions, setLoadingUnansweredQuestions] = useState(true);
    const [queues, setQueues] = useState(undefined);
    const [loadingQueues, setLoadingQueues] = useState(true);
    const [unansweredQuestions, setUnansweredQuestions] = useState([]);
    const [unansweredQuestionsLimit, setUnansweredQuestionsLimit] = useState(50);
    const [unansweredQuestionsOffset] = useState(50);
    const [hasMore, setHasMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [filters, setFilters] = useState({
        startDate: undefined,
        endDate: undefined,
        orderBy: 'desc',
        validQuestion: true
    });

    const orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];

    useEffect(() => {
        handleSetUnasweredQuestions();
        getQueues(auth.selectedChatbot.token, auth.agent.agent._id)
            .then((queues) => {
                setQueues(queues);
                setLoadingQueues(false);
            });
    }, []);

    const handleSetUnasweredQuestions = async () => {
        const unanswerdQuestions = await getUnansweredQuestions(auth.selectedChatbot.token, unansweredQuestionsLimit + 1, filters);

        setHasMore(unanswerdQuestions.length > unansweredQuestionsLimit);
        if (unanswerdQuestions.length > unansweredQuestionsLimit) {
            unanswerdQuestions.pop();
        }

        setUnansweredQuestions(unanswerdQuestions);
        setLoadingUnansweredQuestions(false);
    };

    const handleRejectQuestion = async (questionId) => {
        await updateQuestion(auth.selectedChatbot.token, questionId, { valid_unanswered_question: false });
        handleSetUnasweredQuestions();
    };

    const handleLoadMoreUnasweredQuestions = () => {
        setUnansweredQuestionsLimit(prevLimit => prevLimit + unansweredQuestionsOffset);
        setLoadingMore(true);
        handleSetUnasweredQuestions()
            .then(() => {
                setLoadingMore(false);
            });
    };

    const handleEditQuestion = async (question, newText) => {
        await updateQuestion(auth.selectedChatbot.token, question._id, { message_data: { ...question.message_data, text: newText } });
        handleSetUnasweredQuestions();
    };

    const handleExport = async () => {
        const response = await exportUnansweredQuestions(auth.selectedChatbot.token, filters);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Unanswered Questions.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
    };

    const handleFilterChange = (fieldName, value) => {
        setLoadingUnansweredQuestions(true);
        setFilters(prevFilters => ({ ...prevFilters, [fieldName]: value }));
        handleSetUnasweredQuestions();
    };

    return (
        <div className="container column">
            <PageContainer>
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">Unanswered Questions</div>
                        <div className="unanswered_questions_actions">
                            <NavLink to="/unanswered-questions" className="btn selected">Approved</NavLink>
                            <NavLink to="/unanswered-questions/pending" className="btn">Pending</NavLink>
                            <NavLink to="/unanswered-questions/rejected" className="btn">Rejected</NavLink>
                        </div>
                        <div className="unanswered_questions_top_bar filters">
                            <div className="filters">
                                <div className="form-group">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Box sx={{ width: "100%", mb: '15px', display: 'flex', maxWidth: '400px', alignItems: 'center' }}>
                                            <DatePicker
                                                label="Start Date"
                                                format='DD/MM/YYYY'
                                                value={filters.startDate ? moment(filters.startDate) : null}
                                                disableFuture={true}
                                                maxDate={filters.endDate ? moment(filters.endDate) : null}
                                                sx={{ width: '100%' }}
                                                onChange={(newValue) => {
                                                    handleFilterChange('startDate', newValue ? newValue.format('YYYY-MM-DD') : undefined);
                                                }}
                                                slotProps={{
                                                    field: { size: 'small', clearable: true, onClear: () => {
                                                        handleFilterChange('startDate', undefined);
                                                    } },
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <Divider sx={{ minWidth: '20px', margin: '0px 8px'}} />
                                            <DatePicker
                                                label="End Date"
                                                format='DD/MM/YYYY'
                                                value={filters.endDate ? moment(filters.endDate) : null}
                                                disableFuture={true}
                                                minDate={filters.startDate ? moment(filters.startDate) : null}
                                                sx={{ width: '100%' }}
                                                onChange={(newValue) => {
                                                    handleFilterChange('endDate', newValue ? newValue.endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined);
                                                }}
                                                slotProps={{
                                                    field: { size: 'small', clearable: true, onClear: () => {
                                                        handleFilterChange('endDate', undefined);
                                                    } },
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="filters">
                                <div className="top_bar_filter">
                                    <Autocomplete
                                        disablePortal
                                        size='small'
                                        options={orderByOptions}
                                        defaultValue={orderByOptions[0]}
                                        disableClearable
                                        getOptionLabel={(option) => option.label}
                                        value={orderByOptions.find(filterOption => filters.orderBy === filterOption.value)}
                                        onChange={(event, newValue) => { handleFilterChange('orderBy', newValue?.value) }}
                                        renderInput={(params) => <TextField {...params} label="Order by" autoFocus />}
                                    />
                                </div>
                                {auth.agent.agent.role === 'admin' && (
                                    <div className="top_bar_filter">
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            options={queues && queues.map((queueItem) => ({ value: queueItem._id, label: queueItem.name }))}
                                            classNamePrefix="custom_select"
                                            className="filter form_select"
                                            isClearable={true}
                                            isLoading={loadingQueues}
                                            onChange={(event, newValue) => {
                                                handleFilterChange('queue', newValue?.value);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Queue" autoFocus />}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <button className="btn mt-medium mb-medium" onClick={handleExport}>Export</button>
                        {!loadingUnansweredQuestions ? unansweredQuestions.length > 0 ? (
                            <div>
                                {unansweredQuestions.map(unansweredQuestion => (
                                    <UnansweredQuestionItem
                                        key={unansweredQuestion._id}
                                        question={unansweredQuestion}
                                        handleRejectQuestion={handleRejectQuestion}
                                        handleEditQuestion={handleEditQuestion}
                                    />
                                ))}
                                {hasMore && <LoadMore onClick={handleLoadMoreUnasweredQuestions} loadingMore={loadingMore} />}
                            </div>
                        ) : <div>No unanswered questions</div> : <Preloader />}
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(UnansweredQuestionsPage);
