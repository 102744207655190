import { useEffect, useState } from "react";
import Preloader from "../Preloader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEduBots } from "../../services/edubot/edubot";

const EduBotListPage = () => {
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [eduBots, setEdubots] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const bots = await getEduBots(auth.selectedChatbot.token);

                if(bots.length === 0){
                    navigate('/edubot/create', {
                        replace: true
                    });
                }

                if(bots.length === 1){
                    navigate('/edubot/' + bots[0]._id, {
                        replace: true
                    });
                }

                setEdubots(bots);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return !loading && eduBots ? (
        <div>
            {eduBots.map((bot) => (
                <div>{bot.name}</div>
            ))}
        </div>
    ) : <Preloader />;
};

export default EduBotListPage;