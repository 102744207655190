import { InputAdornment, TextField, ClickAwayListener, Popper } from "@mui/material";
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import { useEffect, useState } from "react";
import colorString from 'color-string';

const ColorPickerInput = ({ value, disabled, size, style, className, fullWidth, label, handleColorChange }) => {
    const [colorText, setColorText] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    
    useEffect(() => { 
        if(value !== colorText){
            const rgb = colorString.get.rgb(value);
            const hex = colorString.to.hex(rgb);
            setColorText(hex);
        }
    }, [value])

    const onColorChange = (color) => {
        const rgb = color.toRgb();
        handleColorChange(`${rgb.r}, ${rgb.g}, ${rgb.b}`);
    }

    const handleTextFieldChange = (e) => {
        setColorText(e.target.value);
        const rgb = colorString.get.rgb(e.target.value)
        
        if(rgb){
            handleColorChange(`${rgb[0]}, ${rgb[1]}, ${rgb[2]}`);
        }
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    
    return (
        
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <div className={`color_input ${className}`}>
                <TextField 
                    label={label} 
                    disabled={disabled}
                    size={size} 
                    style={style}
                    value={colorText}
                    fullWidth={fullWidth} 
                    autoComplete="off" 
                    onFocus={handlePopoverOpen}
                    onChange={handleTextFieldChange} 
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <div style={{ background: value || 'black' }} className="color_input__swatch"/>
                        </InputAdornment>
                    ),
                }}/>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                >
                    <ColorPicker value={value} onChange={(color) => { onColorChange(color) }} disabledAlpha={true}/>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

export default ColorPickerInput;