import React from 'react';
import { getInitials } from '../../services/misc';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';

const ChatMessageAvater = (props) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const message = props.message;
    const conversation = props.conversation;
    const senderInfo = {
        senderType: undefined,
        senderName: undefined,
        senderInitials: undefined,
        senderImage: undefined
    };

    switch (message.sender) {
        case 'bot':
            senderInfo.senderType = 'bot';
            senderInfo.senderImage = '/favicon.ico';
            break;
        case 'user':
            senderInfo.senderType = 'user';
            if (conversation.user && conversation.user.user_info && conversation.user.user_info.name) {
                senderInfo.senderName = conversation.user.user_info.name;
                senderInfo.senderInitials = getInitials(senderInfo.senderName);
            }
            senderInfo.senderImage = '/images/user_icon.svg';
            break;
        default:
            senderInfo.senderType = 'agent';
            senderInfo.senderImage = '/images/user_icon.svg';
            break;
    };

    if (senderInfo.senderType === 'agent' && message.agent_sender_object) {
        return (
            <AgentProfileAvatar popupEnabled={true} agent={message.agent_sender_object} />
        );
    } else {
        return (
            <div className={`chat_message_avatar ${senderInfo.senderType}`}>
                {senderInfo.senderInitials ? (
                    <div className="chat_message_avatar__initials">
                        {props.svg ? (
                            <svg
                                width="100%"
                                height="100%"
                                viewBox="0 -30 500 200"
                            >
                                <text
                                    y="50%"
                                    x="50%"
                                    fontSize="200"
                                    alignmentBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {senderInfo.senderInitials}
                                </text>
                            </svg>
                        ) : (
                            senderInfo.senderInitials
                        )}
                    </div>
                ) : (
                    <img alt="Messge Icon" src={senderInfo.senderImage} />
                )}
            </div>
        );
    }
}

export default ChatMessageAvater;