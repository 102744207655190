import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom'
import { setCurrentChatbot, setCurrentCompany } from '../reducers/authSlice'; 
import { companyService } from '../services/company';
import LoadingPage from '../components/LoadingPage';
import Dashboard from '../components/Dashboard';

const PrivateRoute = ({ allowedRoles, component }) => { 
    let componentToRoute = undefined;
    
    const location = useLocation();
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const agent = auth.agent ? auth.agent.agent : null;

    if(agent && !auth.selectedCompany && agent.companies.length === 1){
        dispatch(setCurrentCompany(agent.companies[0]));
    }

    if(!localStorage.getItem('user') || !agent){
        componentToRoute = <Navigate to="/login"/>
    }else{
        if(agent && !agent.agent_settings.password_set){
            if(location.pathname !== '/set-password'){
                componentToRoute = <Navigate to="/set-password"/>
            }
        }else if(!auth.selectedCompany){
            if(location.pathname !== '/company'){
                componentToRoute = <Navigate to="/company"/>
            }
        }
    }

    if(auth.selectedCompany){
        if(!auth.selectedChatbot){
            componentToRoute = <LoadingPage/>
            companyService.getCompanyChatbots(auth.selectedCompany, auth.agent.token).then((chatbots) => {
                dispatch(setCurrentChatbot(chatbots[0]));
            });
        }else{
            if(allowedRoles && !allowedRoles.includes(agent.role)){
                componentToRoute = <Navigate to="/"/>
            }

            if(location.pathname === '/'){
                componentToRoute = <Navigate to="/chats"/>
            }
        }
    }

    return (
        <div>
            {componentToRoute ? componentToRoute : <Dashboard component={component}/>}
        </div>
    )
}

export default PrivateRoute;