import React from 'react';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import InternalChatWidget from './InternalChatWidget';
import InternalNotifications from './InternalNotifications';
import { setChatWidgetExpanded, setSelectedChat } from '../../reducers/internalChatsSlice';
import { useDispatch, useSelector } from 'react-redux';

const InternalChat = () => {
    const auth = useSelector(state => state.auth);
    const internalChats = useSelector(state => state.internalChats);
    
    const dispatch = useDispatch();
    const handleToggleChat = () => {
        dispatch(setChatWidgetExpanded(!internalChats.chatWidgetExpanded));
    }

    const handleNotificationClick = (chat) => {
        dispatch(setSelectedChat(chat));
        handleToggleChat();
    }

    const chats = internalChats.chats || [];
    const currentAgent = auth.agent.agent;
    let unread = 0;
    chats.forEach(chat => {
        const notification = chat.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
        if(notification && notification.unread){ unread = unread + notification.messages.length; }
    })

    return (
        <div className="internal_chat">
            {!internalChats.chatWidgetExpanded && <InternalNotifications handleNotificationClick={handleNotificationClick}/>}
            {!internalChats.chatWidgetExpanded && <div className="internal_chat_trigger" onClick={handleToggleChat}><HiChatBubbleLeftRight/> Internal Chat {unread > 0 && <div className="notification_badge">{unread}</div>}</div>}
            {internalChats.chatWidgetExpanded && <InternalChatWidget handleToggleChat={handleToggleChat}/>}
        </div>
    );
}

export default InternalChat;
