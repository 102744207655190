import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Switch } from "@mui/material";
import React from "react";
const SectionListItemDraggable = ({ id, section }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    
    return (
        <div className="section_list__item draggable_overlay" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className="section_list__item_icon"><div dangerouslySetInnerHTML={{ __html: section.section_template.icon }}/></div>
            <div className="section_list__item_details">
                <div className="edubot_detail__label">{section.section_template.name}</div>
                <div className="edubot_detail__value">{section.section_template.description}</div>
            </div>
        </div>
    )
}   

export default SectionListItemDraggable;