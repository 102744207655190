import React from 'react';
import AgentManager from '../Agents/AgentManager';

const AdminAgents = () => {
    return (
        <div className="admin_settings_widget">
            <h3>Agents</h3>
            <p>Edit agent information.</p>
            <AgentManager noCompany={true}/>
        </div>
    );
}

export default AdminAgents;
