import { handleResponse } from './misc';

const getChatbot = (authToken, id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/chatbots/' + id, requestOptions).then(handleResponse).then((chatbot) => {
        return chatbot;
    });
};

const deleteChatbot = (authToken, id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/chatbots/' + id, requestOptions).then(handleResponse).then((chatbot) => {
        return chatbot;
    });
};

const getChatbots = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/chatbots', requestOptions).then(handleResponse).then((chatbots) => {
        return chatbots;
    });
};


function createChatbot(authToken, body){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/chatbots', requestOptions).then(handleResponse).then((chatbot) => {
        return chatbot;
    });
}

function updateChatbot(chatbotId, authToken, body){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/chatbots/' + chatbotId, requestOptions).then(handleResponse).then((chatbot) => {
        return chatbot;
    });
}

const updateSetting = (authToken, updates) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ updates })
    };

    return fetch('/api/chatbots/setting', requestOptions).then(handleResponse).then((chatbot) => {
        return chatbot;
    });
};

export { 
    getChatbot,
    getChatbots,
    updateSetting,
    updateChatbot,
    createChatbot,
    deleteChatbot
};