import Badge from "../Base/Badge";
import moment from "moment";

const AgentName = ({ agent, currentAgent }) => {
    let status = '';
    const disabled = agent.disabled || agent.deleted || (agent.expiry_date && moment() > moment(agent.expiry_date).add(23.9, 'hours'));

    if(disabled){ status = 'Disabled' }
    if(agent.deleted){ status = 'Removed' }

    return <div className="agent_name_element">{agent.first_name} {agent.last_name} {(currentAgent && agent._id === currentAgent._id) && <Badge text="Me"/>}{disabled && <Badge text={status}/>}</div>
}

export default AgentName;