import { isVideo, isImage, isAudio } from '../../services/upload';
import { HiOutlineXCircle, HiPlay } from 'react-icons/hi2'
import {  HiOutlineDocumentText } from 'react-icons/hi2';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import ChatMediaViewer from '../Chats/ChatMediaViewer';
import Preloader from '../Preloader';

const MessageMedia = (props) => {
    const [mediaItem, setMediaItem] = useState(undefined);
    const [media, setMedia] = useState(props.media);
    const [loadingMedia, setLoadingMedia] = useState(true);
    const [dataUrl, setDataUrl] = useState(undefined);
    const [failed, setFailed] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const toDataURL = (url, type, callback, error) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                if (this.status === 200) {
                    var blob = new Blob([xhr.response], {type});
                    const url = window.URL.createObjectURL(blob);
                    callback(url)
                }else{
                    error();
                }
                reader.readAsDataURL(xhr.response);
            };

            xhr.onerror = function(){
                callback(url)
            }
            
            xhr.open('GET', url);

            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            
            if(auth.selectedChatbot.settings.infobip_enabled && auth.selectedChatbot.settings.infobip_api_key && url.includes('.infobip')){
                xhr.setRequestHeader("Authorization", "App " + auth.selectedChatbot.settings.infobip_api_key);
            }
            
            xhr.responseType = 'blob';
            xhr.send();
        }

        toDataURL(media.url, media.type, function(dataUrl) {
            setMedia(prevMedia => ({
                ...prevMedia,
                url: dataUrl
            }));
            setLoadingMedia(false);
            setDataUrl(dataUrl);
        }, () => {
            setFailed(true);
            setLoadingMedia(false);
        });
    }, [media.type, auth.selectedChatbot.settings.infobip_enabled, auth.selectedChatbot.settings.infobip_api_key]);

    const handleMediaClick = (media) => {
        setMediaItem(media);
        if(media && (!isVideo(media.type) && !isImage(media.type) && !isAudio(media.type))){
            window.open(media.url, "_blank")
        }
    }

    return (
        <div>
            {loadingMedia ? (
                <div className="message_media_item">
                    <div className={`message_media_item__fit ${props.thumbnail ? 'thumbnail' : ''}`}><div className="message_media_item__preloader"><Preloader fitToContent={true} text="Media loading"/></div></div>
                </div>
            ) : (!failed ? (
                <div className="message_media_item">  
                {isImage(media.type) && <div className={`message_media_item__fit ${props.thumbnail ? 'thumbnail' : ''}`}><img src={media.url} alt="Message Media" onClick={() => handleMediaClick(media)} /></div>}  
                {isVideo(media.type) && <div className={`message_media_item__fit ${props.thumbnail ? 'thumbnail' : ''}`}><video src={media.url} alt="Message Media"></video><div className="message_media_item__play" onClick={() => handleMediaClick(media)}><HiPlay/></div></div>}  
                {isAudio(media.type) && <div className={`message_media_item__fit ${props.thumbnail ? 'thumbnail' : ''}`}><audio controls alt="Message Media"><source src={media.url} type={media.type}></source></audio></div>}  
                {(!isImage(media.type) && !isVideo(media.type) && !isAudio(media.type)) && <div className="message_media_item__other" title={media.name} onClick={() => handleMediaClick(media)}><HiOutlineDocumentText/><span className="text-truncate">{media.name}</span></div>}  
            </div>  
            ) : (
                <div className={`message_media_item__failed ${props.thumbnail ? 'thumbnail' : ''}`}>
                    <HiOutlineXCircle/>
                    <div className="message_media_item__failed__details">
                        <div className="message_media_item__failed__details__title">Media expired</div>
                        <div className="message_media_item__failed__details__body">Media is missing or has expired.</div>
                    </div>
                </div>
            ))}
            {mediaItem && (isVideo(mediaItem.type) || isImage(mediaItem.type)) && <ChatMediaViewer media={mediaItem} handleMediaClick={handleMediaClick}/>}
        </div>
    );
}

export default MessageMedia;
