import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../Preloader';
import { getConversation } from '../../services/conversation';
import ChatChannelIcon from '../Chats/ChatChannelIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { goToConversation } from '../../reducers/chatsSlice';
import { setChatWidgetExpanded } from '../../reducers/internalChatsSlice';

const ConversationCard = (props) => {
    const [conversation, setConversation] = useState(undefined);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchConversation = async () => {
            const conversationData = await getConversation(auth.selectedChatbot.token, props.conversationId);
            setConversation(conversationData);
        };

        fetchConversation();
    }, [auth.selectedChatbot.token, props.conversationId]);

    const handleViewChatClick = () => {
        const conversationId = conversation.conversation_id;
        setConversation(undefined);

        dispatch(goToConversation({ conversation: conversationId, navigate, location }))

        dispatch(setChatWidgetExpanded(false));
    };

    return (
        <div className="conversation_card">
            {conversation ? (
                <div className="conversation_card__inner">
                    <div className="conversation_title">Shared Conversation</div>
                    <div className="conversation_info">
                        <ChatChannelIcon channel={conversation.channel} />
                        <div className="conversation_details">
                            <div className="conversation_details_name text-truncate">
                                {conversation.user && conversation.user.user_info && conversation.user.user_info.name
                                    ? conversation.user.user_info.name
                                    : conversation.user_id}
                            </div>
                            <div className="btn" onClick={handleViewChatClick}>
                                View Chat
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Preloader padding="29px 0" />
            )}
        </div>
    );
};

export default ConversationCard;
