import { useSelector } from 'react-redux';
import React from 'react';
import { Navigate } from 'react-router-dom';
import SetPasswordForm from './SetPasswordForm';

const SetPasswordPage = () => {
    const auth = useSelector(state => state.auth)
    return (
        <div className="login_page">
            {!auth.agent && <Navigate to="/"/>}
            <div className="login_page__container">
                <div className="container">
                    <div className="col-6">
                        <img alt="Set Password" className="login_page__image" src="/images/password_set_page.svg"/>
                    </div>
                    <div className="col-6">
                        <img className="login_page__logo" src="/images/logo_full.webp" alt="Verge AI Logo"/>
                        <SetPasswordForm/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SetPasswordPage;
