import { handleResponse } from "../misc";

const getStepTemplates = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
    };

    return fetch('/api/step_template', requestOptions).then(handleResponse).then((stepTemplates) => {
        return stepTemplates;
    });
}

const getStepPreview = (authToken, eduBotId, stepTemplateId, sectionId) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ edubot_id: eduBotId, section_id: sectionId })
    };

    return fetch(`/api/step_template/${stepTemplateId}/step_preview`, requestOptions).then(handleResponse).then((stepPreview) => {
        return stepPreview;
    });
}   

export {
    getStepTemplates,
    getStepPreview
}