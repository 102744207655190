import { toTimestamp } from '../../services/dates';

const ChatEvent = (props) => {
    return (
        <div className="chat_event">
            <div className="chat_event__content">
                <div className="chat_event__title">{props.event.title}</div>
                <div className="chat_event_text">{props.event.text}</div>
                <div className="timestamp">{toTimestamp(props.event.createdAt)}</div>
            </div>
        </div>
    );
};

export default ChatEvent;