import React from 'react';
import { connect } from 'react-redux';
import NotificationsContainer from './NotificationsContainer';
import NotificationsTrigger from './NotificationsTrigger';
import newNotificationSound from '../../sounds/new_notification.mp3';
import { setNotificationsExpanded } from '../../reducers/notificationsSlice';

class Notifications extends React.Component{
    constructor(props){
        super(props);

        this.audio = new Audio(newNotificationSound)
        this.state = {
            notificationsExpanded: false,
            notifications: this.props.notifications.notifications
        };

        this.handleNotificationsTrigger = this.handleNotificationsTrigger.bind(this);
    }
    
    componentDidMount() {
        this.audio.addEventListener('ended', () => this.audio.pause() );
    }

    componentWillUnmount() {
        this.audio.addEventListener('ended', () => this.togglePlay(false) );
        this.audio.pause() 
    }

    componentDidUpdate(prevProps){
        if(prevProps.notifications.notifications !== this.props.notifications.notifications || prevProps.meetings.meetingsWaiting !== this.props.meetings.meetingsWaiting){
            this.setState({ notifications: this.props.notifications.notifications });
            if(prevProps.notifications.notifications.length < this.props.notifications.notifications.length){
                if(!(this.props.auth.agent.agent && this.props.auth.agent.agent.agent_settings && this.props.auth.agent.agent.agent_settings.status === 'busy')){
                    this.props.dispatch(setNotificationsExpanded(true))
                    if(this.props.auth.agent.agent.agent_settings.notifications_muted === false){
                        try{
                            this.audio.play().catch(e => {})
                            if(Notification){
                                if (Notification.permission !== 'granted'){
                                    Notification.requestPermission();
                                } else {
                                    var notification = new Notification('New Human Handoff Request', {
                                        icon: '/images/logo.svg',
                                        body: 'A user has requested help from an agent, click here to go to portal!',
                                    });
                                    notification.onclick = function() {
                                        window.open('/?task=chats');
                                    };
                                }
                            }
                        }catch(e){
                        }
                        
                    }
                }
            }

            if(prevProps.meetings.meetingsWaiting.length < this.props.meetings.meetingsWaiting.length){
                this.props.dispatch(setNotificationsExpanded(true))
            }

            if(this.props.notifications.notifications.length === 0 && this.props.meetings.meetingsWaiting.length === 0){
                this.props.dispatch(setNotificationsExpanded(false))
            }
        }
    }

    handleNotificationsTrigger(){
        this.props.dispatch(setNotificationsExpanded(!this.props.notifications.notificationsExpanded))
    }

    render(){
        return (
            <div className="notifications">
                <NotificationsTrigger onClick={this.handleNotificationsTrigger} notificationsExpanded={this.props.notifications.notificationsExpanded} meetingsWaiting={this.props.meetings.meetingsWaiting} notifications={this.state.notifications}/>
                {this.props.notifications.notificationsExpanded && <NotificationsContainer  notifications={this.state.notifications}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Notifications);