import { handleResponse } from "../misc";

const getSectionTemplates = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
    };

    return fetch('/api/section_template', requestOptions).then(handleResponse).then((sectionTemplates) => {
        return sectionTemplates;
    });
}

const getSectionPreview = (authToken, eduBotId, sectionTemplateId, moduleId) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },    
        body: JSON.stringify({ edubot_id: eduBotId, module_id: moduleId })
    };

    return fetch(`/api/section_template/${sectionTemplateId}/section_preview`, requestOptions).then(handleResponse).then((sectionPreview) => {
        return sectionPreview;
    });

}

export {
    getSectionTemplates,
    getSectionPreview
}