import React, { useState, useCallback } from 'react';
import { toTimeOnly } from '../../services/dates';
import ChatMessageAvater from './ChatMessageAvater';
import MessageMedia from './MessageMedia';
import { bold, italic } from '../../services/misc';
import linkifyHtml from "linkify-html";
import { MdFormatListBulletedAdd } from "react-icons/md";
import ChatFlowResponse from './ChatFlowResponse';
import { useSelector } from 'react-redux';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { Tooltip } from '@mui/material';
import QuestionLogModal from '../QuestionLogs/QuestionLogModal';

export default function Message(props) {
    const [currentFlowResponse, setCurrentFlowResponse] = useState(null);
    const [hovered, setHovered] = useState(false);
    const [currentQuestionLogId, setCurrentQuestionLogId] = useState(null);

    const auth = useSelector(state => state.auth);

    const handleTriggerFlowResponseView = useCallback((flowResponse) => {
        setCurrentFlowResponse(flowResponse);
    }, []);

    const message = props.message;
    const messageData = message.message_data;

    if (messageData.text) {
        messageData.text = linkifyHtml(bold(italic(messageData.text)));
    }

    let senderClass;
    switch (message.sender) {
        case 'user':
            senderClass = 'user';
            break;
        case 'bot':
            senderClass = 'bot';
            break;
        default:
            senderClass = 'agent';
            break;
    }

    let prompts = [];
    if (messageData.meta && messageData.meta.prompts) {
        messageData.meta.prompts.forEach(prompt => {
            prompts.push(prompt);
        });
    } else if (messageData.meta && messageData.meta.suggested_actions) {
        messageData.meta.suggested_actions.forEach(suggestedAction => {
            prompts.push(suggestedAction.title);
        });
    } else if (messageData.meta && messageData.meta.list_message && messageData.meta.list_message.sections) {
        messageData.meta.list_message.sections.forEach(section => {
            section.options.forEach(option => {
                prompts.push(option.title);
            });
        });
    }

    if (messageData && messageData.text && (messageData.meta && messageData.meta.is_start_message)) {
        return <div></div>;
    }


    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    const handleViewLogClick = (questionLogId) => {
        setCurrentQuestionLogId(questionLogId);
    }

    return (
        <div className={`message ${senderClass}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`message_inner ${message.agent_sender_object && message.new_message && !message.sending && message.agent_sender_object._id.toString() === props.currentAgent._id.toString() ? 'no_animation' : ''}`}>    
                {(hovered &&
                    auth.selectedChatbot.settings.question_message_log_enabled && 
                    ['admin'].includes(auth.agent.agent.role) &&
                    messageData.meta &&
                    messageData.meta.question_log_id) && (
                    <Tooltip title="View Log" arrow><div className="view_logs_button" onClick={() => { handleViewLogClick(messageData.meta.question_log_id) }}><HiOutlineInformationCircle /></div></Tooltip>
                )}
                <ChatMessageAvater conversation={props.conversation} message={message} />
                <div className="message_inner__content">
                    <div className={`message__buble ${message.internal ? 'internal' : ''}`}>{
                        (messageData.media && messageData.media.length > 0) && (
                            <div className="message__media">{messageData.media.map(media => <MessageMedia key={media._id} media={media} handleMediaClick={props.handleMediaClick} />)}</div>
                        )
                    }{messageData.text && (
                        <div className="message__text" dangerouslySetInnerHTML={{ __html: messageData.text }} />
                    )}
                        {(messageData.meta && messageData.meta.flow_response) && (
                            <div className='message__flow_button' onClick={() => { handleTriggerFlowResponseView(messageData.meta.flow_response) }}><MdFormatListBulletedAdd />View Flow Response</div>
                        )}
                    {currentFlowResponse && (messageData.meta && messageData.meta.flow_response) && <ChatFlowResponse isOpen={currentFlowResponse} onRequestClose={() => { handleTriggerFlowResponseView(null) }} title={"Flow Response"} flowResponse={messageData.meta.flow_response} />}
                </div>
                {messageData.meta && (
                    <div className="message__meta">
                        {(prompts.length > 0) && (
                            <div className={`message__prompts ${prompts.length > 3 ? 'hero-card' : ''}`}>{prompts.map((prompt, index) => {
                                return <div className="message_prompt" key={index}>{prompt}</div>
                            })}</div>
                        )}
                    </div>
                )}
                <div className='message_outer_bottom'>
                    <div className="message__timestamp">{message.internal && 'Internal | '}{message.sending ? 'Sending...' : toTimeOnly(message.createdAt)}{(messageData.meta && messageData.meta.flow) && ` | ${messageData.meta.flow.name || 'Flow'} was sent`}</div>       
                </div>
            </div>
            </div>   
            {currentQuestionLogId && <QuestionLogModal questionLogId={currentQuestionLogId} isOpen={true} onRequestClose={() => { handleViewLogClick(null) }}/>}       
        </div>
    );
}
