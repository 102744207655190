import { Handle, Position } from 'reactflow';
import StepConfigPreview from './StepConfigPreview';

const StepNode = ({ data }) => {
    const { step } = data;

    return (
        <div className="step_node">
            <Handle type="target" position={Position.Top} isConnectable={false} />
            <div className='step_node__header'>
                <div className='step_node__icon'><div dangerouslySetInnerHTML={{ __html: step.step_template.icon }}/></div>
                <div className='step_node__info'>
                    <div className='step_node__info_name'>{step.step_template.name}</div>
                </div>
            </div>
            <StepConfigPreview step={step}/>
            <Handle type="source" position={Position.Bottom} id="b" isConnectable={false} />
        </div>
    )
}

export default StepNode;