import {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
} from 'reactflow';
import { HiPlus } from 'react-icons/hi2'
import Tooltip from '@mui/material/Tooltip';

const StepEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data }) => {
    const { step, handleAddStepClick } = data;

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                <div className='step_edge' style={{
                    
                    pointerEvents: 'all',
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                }}>
                    <Tooltip title="Add step" arrow>
                        <button
                            style={{
                            }}
                            className="step_edge__add nodrag nopan"
                            onClick={() => { handleAddStepClick(step) }}
                        ><HiPlus/></button>
                    </Tooltip>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}

export default StepEdge;