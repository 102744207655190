import Modal from 'react-modal';
import Preloader from '../../Preloader';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getModuleTemplates } from '../../../services/edubot/module_templates';
import { getConnectors } from '../../../services/edubot/connector';
import { getSectionTemplates } from '../../../services/edubot/section_template';
import { getStepTemplates } from '../../../services/edubot/step_template';
import ModuleTemplateList from '../ModuleManager/ModuleTemplateList';
import SectionTemplateList from './SectionTemplateList';

const CreateSectionModal = ({ isOpen, moduleTemplate, onSectionAdd, addedSections, onRequestClose }) => {
    const auth = useSelector(state => state.auth);
    
    const [loadingSectionTemplates, setLoadingSectionTemplates] = useState(true);

    const [connectors, setConnectors] = useState(null);
    const [sectionTemplates, setSectionTemplates] = useState(null);

    // Fetch module templates on component mount
    useEffect(() => {
        const fetchData = async () => {
            const connectors = await getConnectors(auth.selectedChatbot.token);
            const sectionsTemps = await getSectionTemplates(auth.selectedChatbot.token);

            setConnectors(connectors);
            setSectionTemplates(sectionsTemps.filter(sectionTemplate => sectionTemplate.module_template_id === moduleTemplate.template_id));

            setLoadingSectionTemplates(false);
        }

        fetchData();
    }, []);

    return (
        <Modal ariaHideApp={false} isOpen={isOpen} className="modal modal__preloader create_module_modal" onRequestClose={onRequestClose}>
            <div className="modal__title">Sections</div>
            <p>Select which section you would like to select from the list of section templates below.</p>
            <div className="modal__content">
                {loadingSectionTemplates ? <Preloader  fitToContent={true}/> : (
                    <SectionTemplateList sectionTemplates={sectionTemplates} connectors={connectors} addedSections={addedSections} onSectionAdd={onSectionAdd}/>
                )}
            </div>
            <div className="modal__actions">
                <button className="btn" onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
}

export default CreateSectionModal;