import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import GroupChatIcon from './GroupChatIcon';
import { toTimestamp } from '../../services/dates';
import InternalChatLastActivity from './InternalChatLastActivity';

const InternalChatListItem = (props) => {
    const chat = props.chat;
    const currentAgent = props.currentAgent;
    const participants = chat.participants;
    const chattingTo = participants.filter(participant => participant._id !== currentAgent._id);
    const isGroupChat = chattingTo.length > 1;
    const notification = props.chat.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString())
    return (
        <div className="internal_chat_list_item" onClick={() => { props.handleSelectChat(chat) }}>
            {isGroupChat ? <GroupChatIcon size="50"/> : <AgentProfileAvatar size="50" showStatus={true} agent={chattingTo[0]}/>}
            <div className="internal_chat_list_item_details">
                <div className="internal_chat_list_item_details__chatting_to text-truncate">{isGroupChat ? chattingTo.map((participant, i) => { const comma = (i + 1) !== chattingTo.length ? ', ' : '';return participant.first_name[0] + '. ' + participant.last_name + comma; }) : chattingTo[0].first_name + ' ' + chattingTo[0].last_name}</div>
                {chat.last_activity && <div className="internal_chat_list_item_details__last_activity text-truncate"><InternalChatLastActivity lastActivity={chat.last_activity}/></div>}
                <div className="timestamp">{toTimestamp(chat.updatedAt)}</div>
            </div>
            {(notification && notification.unread) && <div className="notification_badge">{notification.messages.length}</div>}
        </div>
    );
};

export default InternalChatListItem;