import { useSelector } from 'react-redux';
import AgentManager from '../Agents/AgentManager';
import PageContainer from '../Base/PageContainer';
import QueueManager from '../QueueManager/QueueManager';
import BotSettings from './BotSettings';
import PortalSettings from './PortalSettings';

function SettingsPage() {
    const auth = useSelector(state => state.auth)
    return (
        <div className="container column mb-medium">
            <PageContainer>
                <h2>Agents</h2>
                <AgentManager/>
            </PageContainer>
            <PageContainer>
                <h2>Queues</h2>
                <QueueManager/>
            </PageContainer>
            {['admin'].includes(auth.agent.agent.role) && (
                <PageContainer>
                    <h2>Portal Settings</h2>
                    <PortalSettings/>
                </PageContainer>
            )}
            {['admin'].includes(auth.agent.agent.role) && (
                <PageContainer>
                    <h2>Bot Settings</h2>
                    <BotSettings/>
                </PageContainer>
            )}
        </div>
    );
}

export default SettingsPage;
