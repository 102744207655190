import React, { useEffect, useState } from 'react';
import AlertBox from '../../components/AlertBox';
import { validatePassword } from '../../services/password';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword } from '../../reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';

const SetPasswordForm = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const passwordSet = auth.agent?.agent?.agent_settings?.password_set;
    
    const [password, setPasswordField] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if(passwordSet){
            navigate('/')
        }

    }, [passwordSet])

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'password') {
            setPasswordField(value);
        } else if (name === 'passwordConfirm') {
            setPasswordConfirm(value);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);

        if (password) {
            dispatch(setPassword({ authToken: auth.agent.token, password }));
        }
    }

    let passwordValid = validatePassword(password, passwordConfirm);

    return (
        <div className="login_form">
            <h2>Set Password</h2>
            <p>You have logged in with a temporary password. Please set a permanent password to login with.</p>
            <form className="login_form__form" name="form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <TextField label="Password" fullWidth disabled={auth.settingPassword} type="password" name="password" value={password} onChange={handleChange} autoComplete="username" placeholder="Enter your password"/>
                    {submitted && !password && <div className="help-block">Password is required</div>}
                </div>
                <div className="form-group">
                    <TextField label="Confirm Password" fullWidth disabled={auth.settingPassword} type="password" name="passwordConfirm" value={passwordConfirm} onChange={handleChange} autoComplete="current-password" placeholder="Confirm your password"/>
                    {submitted && !passwordConfirm && <div className="help-block">Password confirmation is required</div>}
                </div>
                <div className="form-group">
                    <button disabled={!passwordValid.isValid || auth.settingPassword} className="button">{auth.settingPassword ? 'Saving Password...' : 'Set Password'}</button>
                </div>
                {(!passwordValid.isValid && passwordValid.message) && <AlertBox type="error">{passwordValid.message}</AlertBox>}
            </form>
        </div>
    );
};

export default SetPasswordForm;