import React from "react";
import Preloader from "../Preloader";
import Authenticate from './Authenticate';

import { CallClient, LocalVideoStream } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import MeetingJoinPage from "./MeetingJoinPage";
import CallCard from "./CallCard";
import DisplayNameSetForm from "./DisplayNameSetForm";
import { HiOutlineXMark } from 'react-icons/hi2';

export default class VideoChat extends React.Component{
    constructor(props){
        super(props);
        this.callClient = null;
        this.callAgent = null;
        this.deviceManager = null;
        this.callOptions = null;
        this.destinationGroup = null;
        this.callError = null;

        this.state = {
            displayName: this.props.displayName || '',
            displayNameSet: this.props.displayName ? true : false,
            meeting: this.props.meeting,
            id: undefined,
            loggedIn: false,
            call: undefined,
            selectedCameraDeviceId: null,
            selectedSpeakerDeviceId: null,
            selectedMicrophoneDeviceId: null,
            showCameraNotFoundWarning: false,
            showSpeakerNotFoundWarning: false,
            showMicrophoneNotFoundWarning: false,
            callError: null,
            minimizable: this.props.minimizable || false,
            togglingVideo: false
        };

        this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
        this.handleDisplayNameSave = this.handleDisplayNameSave.bind(this);
        this.handleVideoToggle = this.handleVideoToggle.bind(this);
        this.handleAudioToggle = this.handleAudioToggle.bind(this);
        this.handleAudioChange = this.handleAudioChange.bind(this);
        this.handleSpeakerChange = this.handleSpeakerChange.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
        this.togglingVideo = this.togglingVideo.bind(this);
    }

    // Handle Login and Call Client Setup
    handleLogin = async (userDetails) => {
        if(userDetails){
            try{
                const tokenCredential = new AzureCommunicationTokenCredential(userDetails.token);
                this.callClient = new CallClient({});
                this.callAgent = await this.callClient.createCallAgent(tokenCredential, { displayName: this.state.displayName });
                window.callAgent = this.callAgent;
                this.deviceManager = await this.callClient.getDeviceManager();

                await this.deviceManager.askDevicePermission({ audio: true, video: true });
                setTimeout(async () => {
                    this.callAgent.on('callsUpdated', (e) => {
                        e.added.forEach(call => {
                            this.setState({ call: call, callEndReason: undefined })
                        });
    
                        e.removed.forEach(call => {
                            if (this.state.call && this.state.call === call) {
                                if (this.state.call.callEndReason.code !== 0) {
                                    this.setState({ callError: `Call end reason: code: ${this.state.call.callEndReason.code}, subcode: ${this.state.call.callEndReason.subCode}` });
                                }
    
                                this.setState({ call: null, incomingCall: null, callEndReason: this.state.call.callEndReason });
                            }
                        });
                    });
    
                    this.callOptions = {
                        videoOptions: {
                            localVideoStreams: undefined
                        }
                    };
    
                    await this.handleVideoToggle(true);
    
                    const microphones = await this.deviceManager.getMicrophones();
                    const microphoneDevice = microphones[0];
                    if(!microphoneDevice || microphoneDevice.id === 'microphone:'){
                        this.setState({showMicrophoneNotFoundWarning: true });
                    }else{
                        this.setState({ selectedMicrophoneDeviceId: microphoneDevice.id });
                        await this.deviceManager.selectMicrophone(microphoneDevice);
                    }
    
                    if(!microphoneDevice || microphoneDevice.id === 'microphone:'){
                        this.setState({showMicrophoneNotFoundWarning: true });
                    }else{
                        this.setState({ selectedMicrophoneDeviceId: microphoneDevice.id });
                        await this.deviceManager.selectMicrophone(microphoneDevice);
                    }
                    
                    
                    try{
                        const speaker = this.deviceManager.selectedSpeaker.id;
                        this.setState({ selectedSpeakerDeviceId: speaker })
                    }catch(e){
                        console.log('Speaker selection not supported on mobile')
                    }
    
                    this.setState({ loggedIn: true });
                }, 100)
            } catch(e){
            }
        }
    }

    joinGroup = async () => {
        try{
            this.callAgent.join({ groupId: this.state.meeting._id }, this.callOptions);
        }
        catch (e) {
            console.error('Failed to join a call', e);
            this.setState({ callError: 'Failed to join a call: ' + e });
        }
    }

    togglingVideo(togglingVideo){
        this.setState({ togglingVideo })
    }

    handleDisplayNameChange(e){
        this.setState({ displayName: e.target.value });
    } 

    handleDisplayNameSave(e){
        e.preventDefault();
        this.setState({ displayNameSet: true });
    }

    componentWillUnmount(){
        if(this.callAgent){
            this.callAgent.off('callsUpdated', () => {});
        }
    }

    async handleVideoToggle(videoOn){
        this.togglingVideo(true);
        if(videoOn){
            const cameras = await this.deviceManager.getCameras();
            const cameraDevice = cameras[0];
            if(!cameraDevice || cameraDevice.id === 'camera:'){
                this.setState({showCameraNotFoundWarning: true });
            } else if (cameraDevice){
                this.setState({ selectedCameraDeviceId: cameraDevice.id });
                const localVideoStream = new LocalVideoStream(cameraDevice);
                this.callOptions.videoOptions = { localVideoStreams: [localVideoStream] };
            }
        }else{
            this.callOptions.videoOptions = {};
            this.setState({ selectedCameraDeviceId: null }, () => {
                this.togglingVideo(false);
            })
        }
    }

    async handleAudioToggle(audioOn){
        if(audioOn){
            const microphones = await this.deviceManager.getMicrophones();
            const microphoneDevice = microphones[0];
            if(!microphoneDevice || microphoneDevice.id === 'microphone:'){
                this.setState({showMicrophoneNotFoundWarning: true });
            }else{
                this.callOptions.audioOptions = { muted: false };
                this.setState({ selectedMicrophoneDeviceId: microphoneDevice.id });
                await this.deviceManager.selectMicrophone(microphoneDevice);
            }
        }else{
            this.callOptions.audioOptions = { muted: true };
            this.setState({ selectedMicrophoneDeviceId: null })
        }
    }

    async handleVideoChange(e){
        const cameraDevice = e.value;
        if(!cameraDevice || cameraDevice.id === 'camera:'){
            this.setState({showCameraNotFoundWarning: true });
        } else if (cameraDevice){
            await this.handleVideoToggle(false);
            this.setState({ selectedCameraDeviceId: cameraDevice.id });
            const localVideoStream = new LocalVideoStream(cameraDevice);
            this.callOptions.videoOptions = { localVideoStreams: [localVideoStream] };
        }
    }

    async handleAudioChange(e){
        await this.deviceManager.selectMicrophone(e.value);
        this.setState({ selectedMicrophoneDeviceId: e.value.id });
    }

    async handleSpeakerChange(e){
        await this.deviceManager.selectSpeaker(e.value);
        this.setState({ selectedSpeakerDeviceId: e.value.id });
    }
    
    render(){
        return (
            <div className="meeting_room_panel_container">
                {!this.state.call && 
                    <div className="meeting_room_panel">
                        {this.props.agent && <div className="meeting_room_close" onClick={this.props.onDisconnect}><HiOutlineXMark/></div>}
                        {!this.props.validatingId && this.props.meeting ? (
                            <div className="flex-grow flex-center">
                                {this.state.displayNameSet ? (
                                    <div className="flex-grow">
                                        {!this.state.loggedIn && <Authenticate company={this.props.meeting.company} chatbot={this.props.meeting.chatbot_id}  onLoggedIn={this.handleLogin} asc_connection_string={this.props.meeting.asc_connection_string}/>}
                                        {
                                            this.state.showSpeakerNotFoundWarning && <b>No speaker device found!</b>
                                        }
                                        {
                                            this.state.callError && <b>{this.state.callError}</b>
                                        } 
                                        {(this.state.loggedIn && !this.state.call) &&(
                                            <MeetingJoinPage meeting={this.props.meeting} setTogglingVideo={this.togglingVideo}  togglingVideo={this.state.togglingVideo} company={this.props.meeting.company} chatbot={this.props.meeting.chatbot_id} handleVideoChange={this.handleVideoChange} handleAudioChange={this.handleAudioChange} handleAudioToggle={this.handleAudioToggle} handleSpeakerChange={this.handleSpeakerChange} handleVideoToggle={this.handleVideoToggle} showCameraNotFoundWarning={this.state.showCameraNotFoundWarning} showMicrophoneNotFoundWarning={this.state.showMicrophoneNotFoundWarning} videoEnabled={this.state.selectedCameraDeviceId ? true : false} microphoneEnabled={this.state.selectedMicrophoneDeviceId && true} deviceManager={this.deviceManager} autoJoin={this.props.autoJoin} onJoinClick={this.joinGroup} selectedCameraDeviceId={this.state.selectedCameraDeviceId} selectedMicrophoneDeviceId={this.state.selectedMicrophoneDeviceId} selectedSpeakerDeviceId={this.state.selectedSpeakerDeviceId}/>
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex-grow meeting_room__content_box">
                                        <DisplayNameSetForm company={this.props.meeting.company} chatbot={this.props.meeting.chatbot_id} handleDisplayNameSave={this.handleDisplayNameSave} handleDisplayNameChange={this.handleDisplayNameChange} displayName={this.state.displayName} displayNameSet={this.state.displayNameSet}/>
                                    </div>
                                )}
                            </div>
                        ) : <Preloader text="Validating ID"/>}
                    </div>}
                {this.state.call && (
                    <CallCard call={this.state.call}
                        deviceManager={this.deviceManager}
                        selectedCameraDeviceId={this.state.selectedCameraDeviceId}
                        selectedSpeakerDeviceId={this.state.selectedSpeakerDeviceId}
                        selectedMicrophoneDeviceId={this.state.selectedMicrophoneDeviceId}
                        onShowCameraNotFoundWarning={(show) => {this.setState({showCameraNotFoundWarning: show}) }}
                        onShowSpeakerNotFoundWarning={(show) => {this.setState({showSpeakerNotFoundWarning: show}) }}
                        onShowMicrophoneNotFoundWarning={(show) => {this.setState({showMicrophoneNotFoundWarning: show}) }}
                        meeting={this.state.meeting}
                        minimizable={this.state.minimizable}
                        minimized={this.props.minimized}
                        handleMinimize={this.props.handleMinimize}
                        onDisconnect={this.props.onDisconnect}
                        agent={this.props.agent}
                        handleAudioChange={this.handleAudioChange}
                        handleSpeakerChange={this.handleSpeakerChange}
                        setTogglingVideo={this.togglingVideo}  
                    />
                )}
            </div>
        )
    }
}