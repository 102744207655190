import { handleResponse } from "../misc";

const getSections = (authToken, eduBotId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot/' + eduBotId + '/sections', requestOptions).then(handleResponse).then((sections) => {
        return sections;
    });
}

const saveSections = (authToken, modifiedSections) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(modifiedSections)
    };

    return fetch('/api/sections/save', requestOptions).then(handleResponse);
}

const deleteSections = (authToken, sections) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(sections)
    };

    return fetch('/api/sections', requestOptions).then(handleResponse);
}

export {
    getSections,
    saveSections,
    deleteSections
}