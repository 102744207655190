import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { addChunk, deleteChunk, getChunks, getDocumentPreview, getIndex, importChunks, reorderChunks, searchChunks, updateChunk } from '../../services/knowedgeSetManager';
import { history } from '../../helpers/history';
import Preloader from '../Preloader';
import ChunkList from './ChunkList';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiOutlinePlus } from "react-icons/hi2";
import ChunkEditor from './ChunkEditor';
import ConfirmModal from '../Base/ConfirmModal';
import DocumentView from './DocumentView';
import IndexTopBar from './IndexTopBar';
import ScrollableFeed from 'react-scrollable-feed';
import KnowledgeSetSidebar from './KnowledgeSetSidebar';
import Tooltip from '@mui/material/Tooltip';
import IndexImportModal from './IndexImportModal';
import ProgressModal from '../Base/ProgressModal';
import AlertBox from '../AlertBox';
import { useParams } from 'react-router-dom';
import { AlertTitle } from '@mui/material';

const IndexPage = () => {
    const [, forceRender] = useReducer(p => !p, false);
    const [index, setIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingChunks, setLoadingChunks] = useState(true);
    const [savingChunk, setSavingChunk] = useState(false);
    const [chunks, setChunks] = useState(null);
    const [infoSidebarExpanded, setInfoSidebarExpanded] = useState(false);
    const [documentExpanded, setDocumentExpanded] = useState(false);
    const [currentChunk, setCurrentChunk] = useState(null);
    const [chunkToDeleteId, setChunkToDeleteId] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [importDocumentModalOpen, setImportDocumentModalOpen] = useState(false);
    const [importingDocument, setImportingDocument] = useState(false);
    const [importResults, setImportResults] = useState(null);
    const [error, setError] = useState(null);
    const [deletingChunk, setDeletingChunk] = useState(false);
    const [doc, setDoc] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [filters, setFilters] = useState({
        orderBy: null
    });
    const [displayFilters, setDisplayFilters] = useState(false);

    // Bool to reoorder chunks
    const [needsReorder, setNeedsReorder] = useState(false);

    const scrollableRef = useRef(null);
    const auth = useSelector(state => state.auth);
    const params = useParams();

    useEffect(() => {
        async function fetchData() {
            if(params.indexId){
                try{
                    const currentIndex = await getIndex(auth.selectedChatbot.token, params.indexId, params.versionId)
                    const doc = (currentIndex.type === 'document' && currentIndex.document && currentIndex.document.name) ? await getDocumentPreview(auth.selectedChatbot.token, currentIndex._id) : null
    
                    setIndex(currentIndex);
                    setDoc(doc);

                    // Get chunks
                    handleLoadChunks(currentIndex.knowledge_set_id, currentIndex.version_id, currentIndex.index_id);
                }catch(e){
                    console.log(e)
                    history.push('/knowledge-set-manager/' + params.id);
                }
            }
        }
        fetchData();
    }, []);

    // Side effect to set loading when index is loaded
    useEffect(() => {
        if(index){
            setLoading(false);
        }
    }, [index]);

    // Main method to retrieve and set chunks
    const handleLoadChunks = async (knowledgeSetId, versionId, indexId, filters) => {
        let currentChunks = null;
        
        // If search term is present, search chunks
        if(searchTerm?.trim().length > 0){
            currentChunks = await searchChunks(
                auth.selectedChatbot.token, 
                knowledgeSetId, 
                versionId, 
                indexId, 
                null, 
                searchTerm,
                filters?.orderBy
            );
        }else{
            currentChunks = await getChunks(
                auth.selectedChatbot.token, 
                knowledgeSetId, 
                versionId, 
                indexId,
                filters?.orderBy
            )
        }

        setChunks(currentChunks);
    };

    // Side effect to disable loadingChunks when chunks are loaded
    useEffect(() => {
        if(chunks && loadingChunks){
            setLoadingChunks(false);
        }
    }, [chunks]);

    useEffect(() => {
        if(needsReorder){
            handleReorderChunks();
            setNeedsReorder(false);
        }
    }, [needsReorder])

    // Side effect for when search term is updated to search chunks
    useEffect(() => {
        if(searchTerm?.length > -1){
            handleLoadChunks(index.knowledge_set_id, index.version_id, index.index_id);
        }
    }, [searchTerm]);

    const handleReorderChunks = () => {
        setChunks(chunks.map((c, i) => ({...c, order: (i + 1)})));
        reorderChunks(auth.selectedChatbot.token, index.knowledge_set_id, index.version_id, index.index_id, chunks.map(c => c._id));
    }

    const handleChunkEditorToggle = (chunk, order) => {
        setCurrentChunk(prevChunk => {
            if (!chunk && prevChunk && (prevChunk._id || !order)) {
                return null;
            } else if (chunk) {
                return chunk;
            } else {
                if(!order || order === chunks.length + 1){
                    setTimeout(() => {
                        scrollableRef.current.props.animateScroll(scrollableRef.current.wrapperRef.current, (scrollableRef.current.wrapperRef.current.scrollHeight))
                    }, 10);
                }
                return { text: '', attachments: [], order: order || (chunks.length + 1) };
            }
        });
    }

    const handleTextChange = (content) => {
        setCurrentChunk(prevChunk => {
            return { ...prevChunk, text: content };
        });
    }

    const handleSourceChange = (e) => {
        setCurrentChunk(prevChunk => {
            return { ...prevChunk, source: e.target.value };
        });
    }

    const handleChunkAttachmentsAdd = (files) => {
        console.log(files)
        setCurrentChunk(prevChunk => {
            return { ...prevChunk, attachments: [...prevChunk.attachments, ...files.map(file => ({
                url: file.url,
                title: file.title,
                description: ""
            }))] };
        });
    }

    const handleChunkAttachmentUpdate = (index, attachment) => {
        setCurrentChunk(prevChunk => { 
            return {  
                ...prevChunk,  
                attachments: prevChunk.attachments.map((a, i) => i === index ? attachment : a)  
            };  
        });  
    }

    const handleChunkAttachmentRemove = (index) => {        
        setCurrentChunk(prevChunk => { 
            setTimeout(() => {
                
            forceRender(); 
            }, 2000)
            return {  
                ...prevChunk,  
                attachments: prevChunk.attachments.filter((attachment, i) => i !== index)  
            };  
        });  
    };  
    
    useEffect(() => {
        if(index){
            handleLoadChunks(index.knowledge_set_id, index.version_id, index.index_id, filters);
        }
    }, [filters])

    const handleFilterChange = (fieldName, value) => {
        setLoadingChunks(true);
        setFilters((prevState) => ({ ...prevState, [fieldName]: value }));
    };
    
    const handleKeepNewFormatClick = (formattedText) => {
		handleTextChange(formattedText);
	}

    const handleInfoSidebarTrigger = () => {
        setInfoSidebarExpanded(prevState => !prevState);
    }

    const handleAddChunk = async () => {
        setSavingChunk(true);
        const chunk = await addChunk(
            auth.selectedChatbot.token, 
            index.knowledge_set_id, 
            index.version_id, 
            index.index_id, 
            currentChunk, 
            auth.agent.agent._id
        );
    
        setChunks(prevChunks => {
            // Check if chunk has an order property
            if (chunk.hasOwnProperty('order')) {
                // Find the index where the chunk should be inserted
                let index = prevChunks.findIndex(el => el.order > chunk.order - 1);
                // If no such index exists, append at the end
                if (index === -1) {
                    return [...prevChunks, chunk];
                }
                // Insert chunk at the found index
                return [...prevChunks.slice(0, index), chunk, ...prevChunks.slice(index)];
            } else {
                // If chunk does not have an order, append at the end
                return [...prevChunks, chunk];
            }
        });

        setSavingChunk(false);
        setCurrentChunk(null);
        setNeedsReorder(true);
    }

    const handleChunkUpdate = async () => {
        setSavingChunk(true);
        const updatedChunk = await updateChunk(
            auth.selectedChatbot.token, 
            currentChunk._id, 
            currentChunk, 
            auth.agent.agent._id
        );

        
    
        setSavingChunk(false);
        setCurrentChunk(null);
    
        setChunks(prevChunks => {
            return prevChunks.map(chunk => {
                return chunk._id === currentChunk._id ? updatedChunk : chunk;
            });
        });
    }

    const handleDeleteChunkClick = (e, chunkId) => {
        e.stopPropagation();
        setChunkToDeleteId(chunkId);
    }

    const handleSearchChunks = async (searchText) => {  
        if(searchText != searchTerm){
            setLoadingChunks(true);    
            setCurrentChunk(null);
            setSearchTerm(searchText);
        }
    };
    
    const clearSearch = async () => {
        setSearchResults(null);
        setLoadingChunks(true);
        const chunks = await getChunks(
            auth.selectedChatbot.token, 
            index.knowledge_set_id, 
            index.version_id, 
            index.index_id
        );
        setChunks(chunks);
        setLoadingChunks(false);
    };

    const handleDeleteChunk = async () => {
        const chunkId = chunkToDeleteId;
        setChunkToDeleteId(null);
        await deleteChunk(auth.selectedChatbot.token, chunkId, auth.agent.agent._id);
        const filteredChunks = chunks.filter(c => c._id !== chunkId);
        setDeletingChunk(false);
        setChunks(filteredChunks);
        setNeedsReorder(true);
    }

    const handleDocumentExpand = () => {
        setDocumentExpanded(prevState => !prevState);
    }
    
    const handleImportDocumentClick = () => {
        setImportDocumentModalOpen(true);
    }

    const handleFilterToggle = () => {
        setDisplayFilters(prevState => !prevState);
    }    

    const handleImportDocument = async (file, delimeter) => {
        try {
            setImportingDocument(true);
            setImportDocumentModalOpen(false);
            const response = await importChunks(
                auth.selectedChatbot.token, 
                index.index_id, 
                index.version_id, 
                file,
                delimeter,
                auth.agent.agent._id
            );
            const chunks = await getChunks(
                auth.selectedChatbot.token, 
                index.knowledge_set_id, 
                index.version_id, 
                index.index_id
            );
    
            setImportingDocument(false);
            setChunks(chunks);
            setSearchResults(null);
            setLoadingChunks(false);
            setImportResults(response);
            setError(null);
        } catch (e) {
            console.log(e);
            setImportingDocument(false);
            setImportDocumentModalOpen(false);
            setError('Failed to import document');
        }
    }

    return loading ? <Preloader fitToContent={true}/> : (
        <div className="index_page">
            <ConfirmModal 
                isOpen={chunkToDeleteId}
                confirmText="Delete chunk"
                title="Delete Chunk"
                description="Are you sure you want to delete this chunk, this will permanently remove this chunk from the knowledge set."
                onRequestClose={(e) => { handleDeleteChunkClick(e, null) }}
                onConfirm={handleDeleteChunk}
            />
            <div className="index_chunks">
                <IndexTopBar 
                    index={index}
                    handleChunkAddClick={() => { handleChunkEditorToggle() }}
                    handleInfoSidebarTrigger={handleInfoSidebarTrigger}
                    handleImportDocumentClick={handleImportDocumentClick}
                    searchChunks={handleSearchChunks}
                    searchResults={searchResults}
                    clearSearch={clearSearch}
                    handleFilterToggle={handleFilterToggle}
                    displayFilters={displayFilters}
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
                {error && (
                    <AlertBox className="m-medium" type="error">{error}</AlertBox>
                )}
                {importResults && (
                    <AlertBox className="m-medium" type="success"><div><AlertTitle>{importResults.message}</AlertTitle>Number of chunks imported: <b>{importResults.chunksImported}</b><br/>Average token size: <b>{importResults.averageToken}</b><br/>Largest chunk: <b>{importResults.biggestToken}</b></div></AlertBox>
                )}
                {loadingChunks ? <Preloader/> : (
                    <ScrollableFeed debug={false} className="chunk_list__scroll" ref={scrollableRef}>
                        {searchResults && <div className='index_chunks__search_title'>Search results:</div>}
                        <ChunkList 
                            currentChunk={currentChunk} 
                            chunks={chunks}
                            savingChunk={savingChunk} 
                            handleAddChunk={handleAddChunk}
                            handleChunkSave={handleChunkUpdate} 
                            handleChunkEditorToggle={handleChunkEditorToggle} 
                            handleTextChange={handleTextChange}
                            handleDeleteChunkClick={handleDeleteChunkClick}
                            handleKeepNewFormatClick={handleKeepNewFormatClick}
                            handleChunkAttachmentsAdd={handleChunkAttachmentsAdd}
                            handleChunkAttachmentUpdate={handleChunkAttachmentUpdate}
                            handleChunkAttachmentRemove={handleChunkAttachmentRemove}
                            handleSourceChange={handleSourceChange}
                            deletingChunk={deletingChunk}
                        />
                        {((!chunks || chunks.length === 0) && currentChunk && !currentChunk._id) ? (
                            <ChunkEditor 
                                chunk={currentChunk} 
                                savingChunk={savingChunk} 
                                handleChunkSave={handleAddChunk} 
                                handleChunkEditorToggle={handleChunkEditorToggle} 
                                handleTextChange={handleTextChange}
                                handleKeepNewFormatClick={handleKeepNewFormatClick}
                                handleChunkAttachmentsAdd={handleChunkAttachmentsAdd}
                                handleChunkAttachmentUpdate={handleChunkAttachmentUpdate}
                                handleChunkAttachmentRemove={handleChunkAttachmentRemove}
                                handleSourceChange={handleSourceChange}
                            />
                        ) : (
                            !searchResults && (
                                <div className='chunk_add_button_container'>
                                    <button className='chunk_add_button' onClick={() => { handleChunkEditorToggle() }}><HiOutlinePlus/>Add chunk</button>
                                </div>
                            )
                        )}
                    </ScrollableFeed>
                )}
            </div>
            {importDocumentModalOpen && (
                <IndexImportModal
                    isOpen={importDocumentModalOpen}
                    onRequestClose={() => { setImportDocumentModalOpen(false) }}
                    index={index}
                    handleImportDocument={handleImportDocument}
                />
            )}
            {importingDocument && <ProgressModal isOpen={importingDocument} label="Importing document" description="Uploading document to server, this could take a few minutes... Please do not close this page." /> }
            {infoSidebarExpanded && (
                <KnowledgeSetSidebar
                    index={index}
                    chunks={chunks}
                    handleInfoSidebarTrigger={handleInfoSidebarTrigger}
                />
                )
            }                
            {doc && (
                <div className='index_page__docs'>
                    <div className='index_page__sidebar'><Tooltip title={documentExpanded ? <>Hide document</> : <>Expand document</>} arrow><button onClick={handleDocumentExpand}>{documentExpanded ? <HiChevronDoubleRight/> : <HiChevronDoubleLeft/>}</button></Tooltip></div>
                    {(documentExpanded) && <DocumentView document={doc}/>}
                </div>
            )}
        </div>
    )
}

export default IndexPage;