import { Divider, Switch, TextareaAutosize, Tooltip } from "@mui/material";
import { HiArrowLeft, HiCheck, HiOutlinePlus, HiOutlineTrash } from "react-icons/hi2";
import SectionManager from "../SectionManager/SectionManager";
import CreateModuleModal from "./CreateModuleModal";
import CreateSectionModal from "../SectionManager/CreateSectionModal";
import LoadingModal from "../../Base/LoadingModal";
import { BiSolidSave } from "react-icons/bi";

const ModulePage = ({ eduBot, module, onBackClick, sections, handleModuleUpdate, handleSectionUpdate, onSectionSelect, creatingSection, addingSection, handleAddSectionClick, handleAddSection, handleModuleDelete, changesAreUnsaved, handleSavesChangesClick, changesSaved, setSections }) => {
    const handleSectionAdd = (section) => {
        handleAddSection(section, module._id);
    }

    return (
        <div className="edubot_page edubot_edit_page module_page">
            <div className="edubot_topbar">
                <Tooltip title="Back to EduBot settings" arrow><button className="edubot_topbar__back_button" onClick={onBackClick}><HiArrowLeft/></button></Tooltip>
                <h1 className="topbar_title">{module.module_template.name}</h1>
                <div className="edubot_topbar_actions">
                    <button className="topbar_action_button delete" onClick={() => { handleModuleDelete(module) }}>Delete module<HiOutlineTrash/></button>
                    <button className="topbar_action_button primary" disabled={!changesAreUnsaved() || changesSaved} onClick={handleSavesChangesClick}>{changesSaved ? <>Saved<HiCheck/></> : <>Save<BiSolidSave  /></>}</button>
                </div>
            </div>
            <div className="edubot_page_container">
                <div className="edubot_details_pane">
                    <div className="edubot_details_pane__inner col-9">
                        <div className="edubot_details_section__label">
                            Sections
                            <div className="edubot_details_section__label__actions">
                                <button className="btn" onClick={handleAddSectionClick}><HiOutlinePlus/>Add Section</button>
                            </div>
                        </div>
                        <div className="edubot_details_section">
                            <SectionManager module={module} sections={sections} handleSectionUpdate={handleSectionUpdate} onSectionSelect={onSectionSelect} handleAddSectionClick={handleAddSectionClick} setSections={setSections}/>
                        </div>
                    </div>
                    <div className="edubot_details_pane__inner col-3">
                        <div className="edubot_details_section__label">Module Settings</div>
                        <div className="edubot_details_section">
                            <div className="edubot_detail">
                                <div className="edubot_detail__label">Enabled</div>
                                <div className="edubot_detail__value"><Switch onChange={() => { handleModuleUpdate(module._id, "enabled", !module.enabled) }} checked={module.enabled}/></div>
                            </div>
                        </div>
                        {eduBot.gpt_enabled && <>
                            <div className="edubot_details_section__label">Module ChatGPT Settings</div>
                            <div className="edubot_details_section">
                                <div className="edubot_detail">
                                    <div className="edubot_detail__label">Allow GPT</div>
                                    <div className="edubot_detail__value"><Switch onChange={() => { handleModuleUpdate(module._id, 'gpt_settings', { ...module.gpt_settings, allow_gpt: !module.gpt_settings.allow_gpt }); }} checked={module.gpt_settings.allow_gpt}/></div>
                                </div>
                                {module.gpt_settings?.allow_gpt && (
                                    <div className="edubot_detail detail_column">
                                        <div className="edubot_detail__label">Custom Instructions</div>
                                        <div className="edubot_detail__value"><TextareaAutosize minRows={5} placeholder={`Enter custom instructions for Open AI processor specifically for the ${module.module_template.name} module.`}  onChange={(e) => { handleModuleUpdate(module._id, 'gpt_settings', { ...module.gpt_settings, custom_instructions: e.target.value }); }} value={module.gpt_settings.custom_instructions}/></div>
                                    </div>
                                )}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            {creatingSection && (
                <CreateSectionModal 
                    isOpen={creatingSection} 
                    moduleTemplate={module.module_template}
                    onSectionAdd={handleSectionAdd} 
                    onRequestClose={handleAddSectionClick}
                    addedSections={sections?.filter(section => section.module_id === module._id)}
                />
            )}
            {addingSection && <LoadingModal isOpen={addingSection}/>}
        </div>
    )
}

export default ModulePage;