import React, { useState } from 'react';
import Preloader from '../Preloader';
import { agentService } from '../../services/agents';
import AlertBox from '../AlertBox';
import { validatePassword } from '../../services/password';
import { useSelector } from 'react-redux';
import { AlertTitle, TextField } from '@mui/material';

const AgentResetForm = () => {
    const [passwordSet, setPasswordSet] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [validatingPassword, setValidatingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const auth = useSelector(state => state.auth);

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'currentPassword':
                setCurrentPassword(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                break;
            case 'newPasswordConfirm':
                setNewPasswordConfirm(value);
                break;
            default:
                break;
        }
    };

    const handleCurrentPasswordSubmit = (e) => {
        e.preventDefault();
        setValidatingPassword(true);
        setErrorMessage(undefined);
        agentService.validatePassword(auth.agent.agent.email, currentPassword)
            .then(agent => {
                setCurrentPasswordValid(true);
                setValidatingPassword(false);
            })
            .catch(e => {
                setErrorMessage('Password is incorrect');
                setValidatingPassword(false);
            });
    };

    const handleSetNewPasswordSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(undefined);
        agentService.setPassword(auth.agent.token, newPassword)
            .then(() => {
                setPasswordSet(true);
            })
            .catch(e => {
                setPasswordSet(false);
                setErrorMessage('Failed to reset password');
            });
    };

    let passwordValid = validatePassword(newPassword, newPasswordConfirm);

    return (
        <div className="agent_password_reset">
            {!currentPasswordValid ? (
                <form autoComplete="new-password" onSubmit={handleCurrentPasswordSubmit}>
                    <div className="form-group">
                        <TextField
                            type="password"
                            fullWidth
                            name="currentPassword"
                            label="Current Password"
                            disabled={validatingPassword}
                            autoComplete="new-password"
                            value={currentPassword}
                            placeholder="Enter your current password"
                            onChange={handleChange}
                        />
                        {errorMessage && <AlertBox type="error" className="mt-medium">{errorMessage}</AlertBox>}
                    </div>
                    <button className="btn" disabled={currentPassword.trim().length === 0 || validatingPassword}>
                        {!validatingPassword ? 'Submit' : <Preloader color="white" size="14px" padding="0px 1rem" />}
                    </button>
                </form>
            ) : (
                <div>
                    {!passwordSet ? (
                        <form onSubmit={handleSetNewPasswordSubmit}>
                            <div className="form-group">
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="New Password"
                                        type="password"
                                        name="newPassword"
                                        autoComplete="new-password"
                                        value={newPassword}
                                        placeholder="Enter your new password"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <TextField
                                    fullWidth
                                    label="Confirm New Password"
                                    type="password"
                                    name="newPasswordConfirm"
                                    autoComplete="new-password"
                                    value={newPasswordConfirm}
                                    placeholder="Confirm your new password"
                                    onChange={handleChange}
                                />
                                {passwordValid.message && <AlertBox type="error" className="mt-medium">{passwordValid.message}</AlertBox>}
                                {errorMessage && <AlertBox type="error" className="mt-medium">{errorMessage}</AlertBox>}
                            </div>
                            <button className="btn" disabled={!passwordValid.isValid}>
                                {!validatingPassword ? 'Submit' : <Preloader color="white" size="14px" padding="0px 1rem" />}
                            </button>
                        </form>
                    ) : (
                        <div>
                            <AlertBox type="success">
                                <AlertTitle>Success</AlertTitle>Your password has been successfully updated.
                            </AlertBox>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AgentResetForm;