import Modal from "react-modal";
import CodeBlock from "../Base/CodeBlock";

const EduBotEmbedCodeModal = ({ eduBot, isOpen, onRequestClose }) => {
    return (
        <Modal ariaHideApp={false} isOpen={isOpen} className={`modal modal__preloader`} onRequestClose={onRequestClose}>
            <div className="modal__title">Embed your chatbot</div>
            <div className="modal__content">Copy and paste the below script tag in the footer of your site.</div>
            <CodeBlock 
                label="Script" 
                code={`<div id="chattabotz_webchat_widget"></div>\n<script src="${eduBot.bot_config.staging_widget?.endpoint}/main.js"></script>`}
            />
            <div className="modal__actions">
                <button className="btn" onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    )
}

export default EduBotEmbedCodeModal;