import React, { useEffect, useState } from 'react';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import { toTimestamp } from '../../services/dates';
import { HiOutlineXMark } from 'react-icons/hi2';
import InternalChatLastActivity from './InternalChatLastActivity';
import newNotificationSound from '../../sounds/internal_message.mp3';

const InternalNotification = (props) => {
    const [deleted, setDeleted] = useState(false);
    const audio = new Audio(newNotificationSound);

    const handleDismiss = (e) => {
        e.stopPropagation();
        props.handleMarkAsShown();
        setDeleted(true);
    };

    useEffect(() => {
        if (!props.currentAgent.agent_settings.notifications_muted) {
            let played = false; // flag to check if audio has been played

            const playAudioOnce = () => {
                if (!played) {
                    audio.play();
                    played = true;
                    // remove the event listener after playing the audio
                    document.removeEventListener('mousemove', playAudioOnce);
                }
            };

            document.addEventListener('mousemove', playAudioOnce);

            setTimeout(() => {
                audio.pause();
                audio.currentTime = 0;
            }, 2000);
        }

        setTimeout(() => {
            props.handleMarkAsShown();
        }, 5000);

        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);

    useEffect(() => {
        if (!props.currentAgent.agent_settings.notifications_muted) {
            audio.play();
            setTimeout(() => {
                audio.pause();
                audio.currentTime = 0;
            }, 2000);
        }
    }, [props.notification.messages]);

    const notification = props.notification;

    return !deleted && (
        <div className="internal_notifications__item" onClick={() => { props.handleNotificationClick(notification.chat) }}>
            <AgentProfileAvatar agent={notification.agent} />
            <div className="internal_notifications__item__details text-truncate">
                <div className="internal_notifications__item__name">{notification.agent.first_name} {notification.agent.last_name}</div>
                <div className="internal_notifications__item__message_preview">{notification.messages.length === 1 ? <InternalChatLastActivity hideSender={true} lastActivity={notification.messages[0]} /> : notification.messages.length + ' new messages'}</div>
                <div className="timestamp">{toTimestamp(notification.messages[notification.messages.length - 1].createdAt)}</div>
                <div className="internal_notifications__item__dismiss" title="Dismiss Notification" onClick={handleDismiss}><HiOutlineXMark /></div>
            </div>
        </div>
    );
};

export default InternalNotification;
