import { handleResponse } from './misc';

const getJobs = (authToken, type, limit, filters, sortBy = 'desc') => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    queryString += sortBy ? '&sortBy=' + sortBy : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            queryString += value && value !== '' ? key + '=' + value + '&' : '';
        }
    }
    
    return fetch('/api/jobs/' + type + queryString, requestOptions).then(handleResponse).then((jobs) => {
        return jobs;
    });
}

const getJobLogs = (authToken, jobId, limit, filters, sortBy = 'desc') => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    queryString += sortBy ? '&sortBy=' + sortBy : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            queryString += value && value !== '' ? key + '=' + value + '&' : '';
        }
    }
    
    return fetch('/api/job/'+jobId+'/logs' + queryString, requestOptions).then(handleResponse).then((logs) => {
        return logs;
    });
}

export { getJobs, getJobLogs }