import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { titleCase } from '../../services/misc';
import { HiCheck, HiDocumentText, HiOutlineEnvelope, HiXMark } from 'react-icons/hi2';
import { updateAgent } from '../../services/user';
import UserInfoAttachmentModal from './UserInfoAttachmentModal';
import MessageMedia from './MessageMedia';
import Tooltip from '@mui/material/Tooltip';
import validator from 'validator';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const UserInfoItem = (props) => {
    const [key] = useState(props.keyName);
    const [value, setValue] = useState(props.value);
    const [initValue] = useState(props.value);
    const [editingField, setEditingField] = useState(false);
    const [user] = useState(props.user);
    const [savingField, setSavingField] = useState(false);
    const [userInfoAttachment, setUserInfoAttachment] = useState(undefined);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if(props.value !== value) {
            setValue(props.value);
            if(props.value === userInfoAttachment){
                setUserInfoAttachment(props.value);
            }
        }
    }, [props.value]);

    const handleEditFieldClick = () => {
        setEditingField(!editingField);
    }

    const handleCancelEditFieldClick = () => {
        setValue(initValue);
        setEditingField(false);
    }

    const handleClickAway = () => {
        if(editingField){
            handleCancelEditFieldClick()
        }
    }


    const handleFieldChange = (e) => {
        setValue(e.target.value);
    }

    const handleFieldSaveClick = () => {
        const updates = `{"updates": {"${key}": "${value}"}${props.objectName ? ', "objectName": "' + props.objectName + '"': ''}}`;
        const updatesObject = JSON.parse(updates);
        setSavingField(true);
        setEditingField(false);
        updateAgent(auth.selectedChatbot.token, user.user_id, updatesObject).then(() => {
            setSavingField(false);
        }).catch(() => {
            setSavingField(false);
            setValue(initValue);
        });
    }

    const handleViewAttachmentClick = (attachment) => {
        setUserInfoAttachment(attachment);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                {(typeof value !== 'object' || value === null) ? (  
                    <div className="user_info_list_item">
                        <div className="user_info_list_item__details">
                            <div className="user_info_list_item__key">{titleCase(key)}</div>
                            {!editingField ? <div className={`user_info_list_item__value ${savingField ? 'text_preloader' : ''}`} onClick={handleEditFieldClick}>{value ? <span className="user_info_item_value__inner">{value}{validator.isEmail(value) && <a onClick={(e) => { e.stopPropagation() }} href={`mailto:${value}`} target="_blank" rel="noreferrer" className="user_info_item_value__action"><HiOutlineEnvelope/></a>}</span> : <span className="italics">None entered</span>}</div> : <div className="user_info_list_edit"><input className='input-field' type="text" placeholder="Enter a value" value={value} onChange={handleFieldChange} autoFocus={true}/><div className="user_info_list_item__actions"><Tooltip title="Discard Changes" arrow><div className="user_info_list_item__action cancel" onClick={handleCancelEditFieldClick}><HiXMark/></div></Tooltip><Tooltip title="Save changes" arrow><div className="user_info_list_item__action save" onClick={handleFieldSaveClick}><HiCheck/></div></Tooltip></div></div>}
                        </div>
                    </div>
                ) : (
                    <div>
                        {(typeof value === 'object' && value !== null && value.type && value.type === 'table') && <div className="user_info_list_item"><div className="user_info_list_item__details"><div className="user_info_list_item__key">{titleCase(key)}</div><div className={`user_info_list_item__value`}><table className="user_info_subtable">{Object.keys(value.data).map(key => <tr><td className="user_info_subtable_title">{titleCase(key)}</td><td>{value.data[key]}</td></tr>)}</table></div></div></div>}
                        {(typeof value === 'object' && value !== null && value.url) && <div className="user_info_list_item"><div className="user_info_list_item__details"><div className="user_info_list_item__key">{titleCase(key)}</div><div className={`user_info_list_item__value`}><MessageMedia thumbnail={true} media={value} handleMediaClick={props.handleMediaClick}/></div></div></div>}
                        {typeof value === 'object' && value !== null && (!value.type && <div className="user_info_list_item"><div className="attachment" onClick={() => { handleViewAttachmentClick(value) }}><HiDocumentText/><span>View {titleCase(key)}</span></div></div>)}
                    </div>
                )}
                {userInfoAttachment && <UserInfoAttachmentModal isOpen={userInfoAttachment ? true : false} attachmentName={key} userInfoAttachment={userInfoAttachment}  user={props.user} onRequestClose={() => { handleViewAttachmentClick(undefined) }}/>}
            </div>
        </ClickAwayListener>
    );
}

export default UserInfoItem;