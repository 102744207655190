import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { MdOutlineDragIndicator } from "react-icons/md";

export function SortableStep(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className='draggable_step' ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className='draggable_step__reorder_icon'><MdOutlineDragIndicator/></div>
            <div className='draggable_step__icon'><div dangerouslySetInnerHTML={{ __html: props.step.step_template.icon }}/></div>
            <div className='draggable_step__info'>
                <div className='draggable_step__info_name'>{props.step.step_template.name}</div>
                <div className='draggable_step__info_description'>{props.step.step_template.description}</div>
            </div>
        </div>
    );
}