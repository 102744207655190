import React, { useEffect, useRef } from 'react';
import { toTimestamp } from '../../services/dates';
import ChatChannelIcon from './ChatChannelIcon';
import LastActivity from './LastActivity';
import newNotificationSound from '../../sounds/message_sound.mp3';

const ChatListItem = (props) => {
    const audioRef = useRef(null);

    useEffect(() => {
        const currentAgent = props.currentAgent;
        const prevNotification = props.conversation.notifications && props.conversation.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
        const notification = props.conversation.notifications && props.conversation.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());

        if (notification && prevNotification && prevNotification.messages.length < notification.messages.length) {
            if (!currentAgent.agent_settings.notifications_muted) {
                audioRef.current.play();
                setTimeout(() => {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                }, 600);
            }
        }
    }, [props.conversation, props.currentAgent]);

    const conversation = props.conversation;
    const currentAgent = props.currentAgent;
    const userName = (props.conversation.user && props.conversation.user.user_info && props.conversation.user.user_info.name) ? props.conversation.user.user_info.name : conversation.conversation_id;
    const notification = props.conversation.notifications && props.conversation.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());

    return (
        <div className={`chat_list__item ${props.currentConversationId && props.currentConversationId === conversation.conversation_id ? 'active' : ''}`} onClick={() => { props.handleConversationClick(conversation.conversation_id) }}>
            <ChatChannelIcon channel={conversation.channel} status={conversation.status} />
            <div className="chat_list__item_details">
                <div className="chat_list__item__title text-truncate">{userName}</div>
                {conversation.last_activity && <LastActivity currentAgent={currentAgent} lastActivity={conversation.last_activity} />}
                <div className="chat_list__item__timestamp">{toTimestamp(conversation.updatedAt)}</div>
            </div>
            {(notification && notification.unread) && <div className="notification_badge">{notification.messages.length}</div>}
            <audio ref={audioRef} src={newNotificationSound} />
        </div>
    );
};

export default ChatListItem;
