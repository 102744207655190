import React from 'react';

import LoginForm from './LoginForm';

function LoginPage() {
    return (
        <div className="login_page" style={{ backgroundImage: 'linear-gradient( rgba(255,255,255,0.75), rgba(255,255,255,0.75) ), url(/images/login_bg.webp)' }}>
            <div className="login_page__container">
                <div className="container">
                    <div className="col-6">
                        <img className="login_page__image" src={'/images/login_page_img_new.webp'} alt=""/>
                        <div className='login_page_container_text_overlay'>
                            <div className='login_page_container_text_overlay_sub'>Connect</div>
                            <div className='login_page_container_text_overlay_title'>Welcome back</div>
                            <div className='login_page_container_text_overlay_text'>Its nice to see you again, let's get started by loging in</div>
                        </div>
                    </div>
                    <div className="col-6">
                        <img className="login_page__logo" src='/images/logo_full.webp' alt="Verge AI Logo"/>
                        <LoginForm/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
