import React, { useState, useEffect, useRef } from 'react';
import { HiVideoCamera, HiClipboardDocumentCheck, HiClipboardDocument } from 'react-icons/hi2';
import { createMeeting, validateMeeting } from '../../services/meetings';
import { sendMessage } from '../../services/humanHandoff';
import Preloader from '../Preloader';
import { createEvent } from '../../services/conversation';
import { setCurrentMeeting } from '../../reducers/meetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TextField } from '@mui/material';

const MeetingSetup = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [meeting, setMeeting] = useState(props.meeting || undefined);
    const [meetingTitle, setMeetingTitle] = useState(auth.agent.agent ? 'Meeting with ' + auth.agent.agent.first_name : '');
    const [sharingLink, setSharingLink] = useState(false);
    const [linkShared, setLinkShared] = useState(false);
    const [generatingMeeting, setGeneratingMeeting] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setMeeting(props.meeting);
    }, [props.meeting]);

    const handleMeetingTitleChange = (e) => {
        setMeetingTitle(e.target.value);
    }

    const handleClickAway = () => {
        props.toggleMeetingSetup();
    }

    const handleSendLink = async () => {
        setSharingLink(true);
        const messageObject = {
            conversation_id: props.conversation.conversation_id,
            sender: 'agent',
            agent_sender: auth.agent.agent._id,
            agent_sender_object: auth.agent.agent,
            message_data: {
                text: auth.agent.agent.first_name + ' ' + auth.agent.agent.last_name + ' has setup a meeting. Please connect by clicking on the link below\n\n' + window.location.origin.toString() + '/meeting/' + meeting._id
            }
        }

        await sendMessage(auth.selectedChatbot.token, messageObject, props.conversation.channel);
        setSharingLink(false);
        setLinkShared(true);
    }

    const handleMeetingSetup = async () => {
        setGeneratingMeeting(true);
        const createdMeeting = await createMeeting(auth.selectedChatbot.token, meetingTitle, auth.agent.agent._id, props.conversation._id);
        const validatedMeeting = await validateMeeting(createdMeeting._id);
        setMeeting(validatedMeeting);
        setGeneratingMeeting(false);

        await createEvent(auth.selectedChatbot.token, props.conversation.conversation_id, 'Meeting Created', `${auth.agent.agent.first_name} ${auth.agent.agent.last_name} setup a meeting.`);
        props.handleSetCurrentConversation(props.conversation.conversation_id);
    }

    const acceptCall = (withVideo) => {
        dispatch(setCurrentMeeting(meeting));
    }

    const handleCopyToClipboard = () => {
        const link = window.location.origin.toString() + '/meeting/' + meeting._id;
        navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 10000);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="chat_action_widget">
                <div className="widget_title"><HiVideoCamera/> Setup Call</div>
                {!generatingMeeting ? !meeting ? (
                    <div>
                        <TextField fullWidth label="Meeeting Name" sx={{ mb: '10px' }} type="text" onChange={handleMeetingTitleChange} value={meetingTitle} placeholder="Enter meeting name"/>
                        <div className="chat_action_widget__actions">     
                            <button className="btn" disabled={meetingTitle.length === 0} onClick={handleMeetingSetup}>Setup Call</button>
                        </div>
                    </div>    
                ) : (
                    <div>
                        <div className="widget_body">Your meeting has been setup. Please see meeting link below.<br/><br/>
                            <div onClick={!copied ? handleCopyToClipboard : () => {}} className='widget_body__copy'>{copied ? <HiClipboardDocumentCheck/> : <HiClipboardDocument/>}{copied ? 'Copied to clipboard' : 'Copy meeting Link'}</div>
                        </div>
                        <div className="chat_action_widget__actions">                            
                            <button className="btn" disabled={sharingLink || linkShared} onClick={handleSendLink}>{sharingLink ? <span>Sharing...</span> : (linkShared ? <span>Link Shared</span> : <span>Send to User</span>)}</button>
                            <button className="btn" onClick={acceptCall}>Join Meeting</button>
                        </div>
                    </div> 
                ) : <div><Preloader/></div>}
            </div>
        </ClickAwayListener>
    );
}

export default MeetingSetup;
