import LoadMore from "../Base/LoadMore";
import QuestionLogListItem from "./QuestionLogListItem";
import Preloader from "../Preloader";

const QuestionLogList = ({ questionLogs, hasMore, loading, loadingMore, handleLoadMoreClick, handleQuestionLogSelect, selectedQuestionLog }) => {
    return (
        <div className="question_log_list">
            {loading ? <Preloader fitToContent={true}/> : (
                <>
                    {questionLogs.length > 0 ? (
                        questionLogs.map(questionLog => (
                            <QuestionLogListItem
                                key={questionLog._id}
                                questionLog={questionLog}
                                selectedQuestionLog={selectedQuestionLog}
                                handleQuestionLogSelect={handleQuestionLogSelect}
                            />
                        ))
                    ) : (
                        <div className="alert_box alert-clear center">No question logs</div>
                    )}
                    {hasMore && <LoadMore loadingMore={loadingMore} onClick={handleLoadMoreClick}/>}
                </>
            )}
        </div>
    );
}   

export default QuestionLogList;