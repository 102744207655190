import Modal from 'react-modal';
import { ProgressLoader } from './ProgressLoader';

const ProgressModal = (props) => {
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal modal__preloader">
            <ProgressLoader label={props.label} description={props.description} progress={props.progress} />
        </Modal>
    );
};

export default ProgressModal;