import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { HiOutlineChatBubbleLeftEllipsis, HiOutlineEye, HiOutlineEyeSlash, HiOutlineTrash } from 'react-icons/hi2';
import { toDateOnly, toTimeOnly, toTimestamp } from '../../services/dates';
import { titleCase } from '../../services/misc';
import { goToConversation } from '../../reducers/chatsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

const ErrorItem = (props) => {
    const [traceShown, setTraceShown] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const dispatch = useDispatch();

    const handleStackTrace = () => {
        setTraceShown(prevState => !prevState);
    };

    const handleViewChatClick = async () => {
        const conversationId = props.error.conversation_id;
        
        dispatch(goToConversation({ conversation: conversationId }))
    };
    
    const handleDeleteClick = () => {
        setDeleting(true);
        props.handleDelete(props.error._id);
    };

    return (
        <div className="error_item">
            <div className="error_details">
                <label>Message</label>
                <div className="error_message mb-medium">{props.error.message}</div>
                <div className="error_item__actions">
                    {traceShown ? (
                        <button className="btn" onClick={handleStackTrace}>
                            <HiOutlineEyeSlash />Hide Trace
                        </button>
                    ) : (
                        <button className="btn" onClick={handleStackTrace}>
                            <HiOutlineEye />Show Trace
                        </button>
                    )}
                    <button className="btn" onClick={handleDeleteClick} disabled={deleting}>
                        <HiOutlineTrash />
                        <span>{deleting ? 'Deleting...' : 'Delete'}</span>
                    </button>
                </div>

                {traceShown && (
                    <div className="error_trace">
                        <label>Stack Trace</label>
                        {props.error.trace}
                    </div>
                )}
            </div>
            <div className="error_meta">
                <div className="error_meta_item">
                    <label>Created At</label>
                    {toTimeOnly(props.error.createdAt)} [{toDateOnly(props.error.createdAt)}]
                </div>
                <div className="error_meta_item">
                    <label>Type</label>
                    {props.error.type && <>{titleCase(props.error.type) + ' '}</>}Error
                </div>
                {props.error.conversation_id && (
                    <div className="error_meta_item">
                        <label>Conversation</label>
                        {props.error.conversation_id}
                        <button className="btn mt-medium" onClick={handleViewChatClick}>
                            <HiOutlineChatBubbleLeftEllipsis />
                            <span>View Chat</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(ErrorItem);