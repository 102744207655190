import React from 'react';
import PageContainer from '../Base/PageContainer';
import KnowledgeSetList from './KnowledgeSetList';

const KnowledgeSetManagerPage = () => {
    return (
        <PageContainer>
            <div className="container">
                <div className="col-12">
                    <div className="page_title">Knowledge Set Manager</div>
                    <KnowledgeSetList />
                </div>
            </div>
        </PageContainer>
    );
};

export default KnowledgeSetManagerPage;
