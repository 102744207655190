import moment from 'moment';
import { createEvent } from './conversation';
import { handleResponse } from './misc';

const sendMessage = (authToken, message, channel) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({...message, channel})
    };

    if(message.internal){
        return fetch('/api/messages', requestOptions).then(handleResponse).then((response) => {
            return response;
        });
    }

    return fetch('/api/bot/message', requestOptions).then(handleResponse).then((response) => {
        return response;
    });
};

const acceptChat = async (authToken, conversationId, agent, humanHandoff, channel) => {
    try{
        await addAgentToConversation(authToken, conversationId, agent._id);
    }
    catch(e){
        
    }
    await createEvent(authToken, conversationId, 'Chat Accepted', `${agent.first_name} ${agent.last_name} accepted the chat.`);
    await acceptHumanHandoff(authToken, agent._id, humanHandoff);
    if(humanHandoff.conversation && humanHandoff.conversation.agents_connected && humanHandoff.conversation.agents_connected.length === 0){
        await joinChat(authToken, conversationId, agent, channel);
    }
}


const acceptHumanHandoff = (authToken, agentId, humanHandoff) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ status: "accepted", "accepted_by":  agentId, connection_time: moment().unix() - moment(humanHandoff.updatedAt).unix() })
    };

    return fetch('/api/humanhandoff/' + humanHandoff._id, requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
};

const joinChat = (authToken, conversationId, agent, channel) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, agent, channel })
    };

    return fetch('/api/bot/join', requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const requestUserEngagement = (authToken, conversationId, agent, channel) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, agent, channel })
    };

    return fetch('/api/bot/request_engagement', requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const addAgentToConversation = (authToken, conversationId, agentId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId + '/agent/' + agentId, requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
};


const removeAgentFromConversation = (authToken, conversationId, agentId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId + '/agent/' + agentId, requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
};

const getPendingHumanHandoffs = (authToken, agent) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/humanhandoff/pending?agent=' + agent, requestOptions).then(handleResponse).then((humanHandoffs) => {
        return humanHandoffs;
    });
};

const getTransferedHumanHandoffs = (authToken, agentId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/humanhandoff/transferred?agent=' + agentId, requestOptions).then(handleResponse).then((humanHandoffs) => {
        return humanHandoffs;
    });
};

const getInvitesHumanHandoffs = (authToken, agentId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/humanhandoff/invite?agent=' + agentId, requestOptions).then(handleResponse).then((humanHandoffs) => {
        return humanHandoffs;
    });
};

const declineChat = async (authToken, conversationId, agent, humanHandoff, channel) => {
    await declineHumanHandoff(authToken, humanHandoff);
    if(agent.role !== 'admin'){
        await createEvent(authToken, conversationId, 'Chat Declined', `${agent.first_name} ${agent.last_name} declined the chat.`);
    }
    if(humanHandoff.status !== 'invite'){
        await declineBotChat(authToken, conversationId, agent, channel);
        await removeAllAgentsFromConversation(authToken, conversationId);
    }
}

const closeChat = async (authToken, conversationId, channel) => {
    await closeBotChat(authToken, conversationId, channel);
    await removeAllAgentsFromConversation(authToken, conversationId);
}

const declineHumanHandoff = (authToken, humanHandoff) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ status: "declined"})
    };

    return fetch('/api/humanhandoff/' + humanHandoff._id, requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
};

const declineBotChat = (authToken, conversationId, agent, channel) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, agent, channel })
    };

    return fetch('/api/bot/decline', requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const closeBotChat = (authToken, conversationId, channel) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, channel })
    };

    return fetch('/api/bot/close', requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const transfer = (authToken, conversationId, type, id, updatedBy) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, updatedBy })
    };

    return fetch('/api/humanhandoff/transfer/' + type + '/' + id, requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const transferBotChat = (authToken, conversationId, channel) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, channel })
    };

    return fetch('/api/bot/transfer', requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const invite = (authToken, conversationId, id, updatedBy) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, updatedBy })
    };

    return fetch('/api/humanhandoff/invite/' + id, requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

const removeAllAgentsFromConversation = (authToken, conversationId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId + '/agent/all', requestOptions).then(handleResponse).then((response) => {
        return response;
    }); 
}

export { 
    sendMessage, 
    getPendingHumanHandoffs, 
    joinChat, 
    acceptChat, 
    declineChat, 
    closeChat, 
    addAgentToConversation, 
    removeAgentFromConversation,
    transferBotChat,
    getTransferedHumanHandoffs,
    transfer,
    getInvitesHumanHandoffs,
    invite,
    requestUserEngagement
};