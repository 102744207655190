import React from 'react'
import { HiPlusCircle } from 'react-icons/hi2'
import { NavLink } from 'react-router-dom'

export default ({ chunk, handleChunkEditorToggle, deletingChunk, findIndex }) => {
    return (
		<div className="chunk_list_item" onClick={(e) => { handleChunkEditorToggle({ _id: chunk._id, ...chunk }) }}>
			{deletingChunk === chunk._id && <div>Deleting...</div>}
			{findIndex && <NavLink to={`/knowledge-set-manager/${chunk.knowledge_set_id}/index/${chunk.index_id}/version/${chunk.version_id}`}><div className='index_name'>{findIndex(chunk.index_id)?.name}</div></NavLink>}
			<div className='timestamp'>Token count: {chunk.embedding ? chunk.embedding.usage.totalTokens : 'N/A'}</div>
			{chunk.source && <div className='timestamp'>Source: {chunk.source}</div>}
			<div dangerouslySetInnerHTML={{ __html: chunk.highlightText || chunk.text }}/>
			{chunk.attachments && chunk.attachments.length > 0 && (
				<>
					<label className="mb-medium mt-medium">{chunk.attachments.length} attachments</label>
					<div className="chunk_attachments">
						{chunk.attachments.map((attachment, index) => (
							<div key={index} className="chunk_attachment">
								<img src={attachment.url} alt={attachment.title}/>
								<div className='chunk_attachment_details'>
									{attachment.title && <div className='chunk_attachment_details__title'>{attachment.title}</div>}
									{attachment.description && <div className='chunk_attachment_details__description'>{attachment.description}</div>}
								</div>
							</div>
						))}
					</div>
				</>
			)}
			<div className="chunk_list_item__add" onClick={(e) => { e.stopPropagation(); handleChunkEditorToggle(null, chunk.order ? chunk.order + 1 : null ) }}><HiPlusCircle/></div>
		</div>
    )
}

