import React from 'react';
import AdminMenu from './AdminMenu';

const AdminDashboard = (props) => {
    return (
        <div className="admin_dashboard">
            <AdminMenu/>
            <div className="admin_settings_container">{props.component}</div>
        </div>
    );
}

export default AdminDashboard;
