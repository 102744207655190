import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import { deleteError, deleteErrors, getErrors } from '../../services/errors';
import ErrorItem from './ErrorItem';
import LoadMore from '../Base/LoadMore';
import { HiOutlineTrash } from 'react-icons/hi2';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Autocomplete, Box, Divider, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const ErrorPage = (props) => {
    const [loadingErrors, setLoadingErrors] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [errors, setErrors] = useState([]);
    const [errorsLimit, setErrorsLimit] = useState(20);
    const [errorsOffset, setErrorsOffset] = useState(20);
    const [hasMore, setHasMore] = useState(false);
    const [clearingAll, setClearingAll] = useState(false);
    const [filters, setFilters] = useState({
        startDate: undefined,
        endDate: undefined,
        orderBy: 'desc'
    });

    const orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];

    useEffect(() => {
        handleSetErrors();
    }, []);

    useEffect(() => {
        if(!loadingMore){
            setLoadingMore(true);
            handleSetErrors();
        }
    }, [errorsLimit]);

    useEffect(() => {
        if(clearingAll){
            setClearingAll(false);
        }

        if(loadingMore){
            setLoadingMore(false);
        }
    }, [errors]);

    const handleSetErrors = async () => {
        const errors = await getErrors(props.auth.selectedChatbot.token, errorsLimit + 1, filters);

        setLoadingErrors(false);
        setHasMore(errors.length > errorsLimit ? true : false);

        if (errors.length > errorsLimit) {
            errors.pop();
        }

        setErrors(errors);
    };

    useEffect(() => {
        handleSetErrors();
    }, [filters]);

    const handleLoadMore = () => {
        setErrorsLimit((errorsLimit + errorsOffset));
    };

    const handleDelete = async (errorId) => {
        await deleteError(props.auth.selectedChatbot.token, errorId);
        handleSetErrors();
    };

    const handleDeleteAll = async () => {
        setClearingAll(true);
        await deleteErrors(props.auth.selectedChatbot.token);
        handleSetErrors();
    };

    const handleFilterChange = (fieldName, value) => {
        setLoadingErrors(true);
        setFilters(prevFilters => ({ ...prevFilters, [fieldName]: value }));
    };

    return (
        <div className="container column">
            <PageContainer size="full_screen">
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">Error Logs</div>
                        <button className="btn mb-medium" onClick={handleDeleteAll} disabled={clearingAll}><HiOutlineTrash />{clearingAll ? 'Clearing all...' : 'Clear All'}</button>
                        <div className="filters">
                            <div className="filters">
                                <div className="form-group">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Box sx={{ width: "100%", mb: '15px', display: 'flex', maxWidth: '400px', alignItems: 'center' }}>
                                            <DatePicker
                                                label="Start Date"
                                                value={filters.startDate ? moment(filters.startDate) : null}
                                                disableFuture={true}
                                                format='DD/MM/YYYY'
                                                maxDate={filters.endDate ? moment(filters.endDate) : null}
                                                sx={{ width: '100%' }}
                                                slotProps={{
                                                    field: { size: 'small', clearable: true, onClear: () => {
                                                        handleFilterChange('startDate', undefined);
                                                    } },
                                                }}
                                                onChange={(newValue) => {
                                                    handleFilterChange('startDate', newValue ? newValue.format('YYYY-MM-DD') : undefined);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <Divider sx={{ minWidth: '20px', margin: '0px 8px'}} />
                                            <DatePicker
                                                label="End Date"
                                                value={filters.endDate ? moment(filters.endDate) : null}
                                                disableFuture={true}
                                                format='DD/MM/YYYY'
                                                minDate={filters.startDate ? moment(filters.startDate) : null}
                                                sx={{ width: '100%' }}
                                                onChange={(newValue) => {
                                                    handleFilterChange('endDate', newValue ? newValue.endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined);
                                                }}
                                                slotProps={{
                                                    field: { size: 'small', clearable: true, onClear: () => {
                                                        handleFilterChange('endDate', undefined);
                                                    } },
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="top_bar_filter">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                                        <Select
                                            value={orderByOptions.find(orderOption => orderOption.value === filters.orderBy).value}
                                            label="Sort by"
                                            size='small'
                                            onChange={(e) => { handleFilterChange('orderBy', e.target.value) }}
                                        >
                                            {orderByOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                        {!loadingErrors ? (
                            <div>
                                {errors.length > 0 ? (
                                    <div>
                                        {errors.map(error => <ErrorItem key={error._id} error={error} handleDelete={handleDelete} />)}
                                        {hasMore && <div><LoadMore onClick={handleLoadMore} loadingMore={loadingMore} /></div>}
                                    </div>
                                ) : (<div>No errors found.</div>)}
                            </div>
                        ) : <Preloader />}
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ErrorPage);
