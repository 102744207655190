import ScrollableFeed from "react-scrollable-feed";
import { HiChatBubbleLeftRight, HiOutlineChevronDoubleUp } from 'react-icons/hi2';
import InternalChatRoomMessageItem from "./InternalChatRoomMessageItem";
import ChatDateBadge from "../Chats/ChatDateBadge";
import LoadMore from "../Base/LoadMore";
import React, { useState, useEffect, useRef } from 'react';
import { toChatDate } from "../../services/dates";

const InternalChatRoomMessages = (props) => {
    const [messages, setMessages] = useState(props.messages);
    const [loadingMore, setLoadingMore] = useState(false);
    const scrollableRef = useRef();

    useEffect(() => {
        if (props.messages !== messages) {
            const updatedMessages = props.messages.map(currentMessage => ({ ...currentMessage, new_message: true }));
            setMessages(updatedMessages);
        }
    }, [props.messages]);

    const handleLoadMoreClick = async () => {
        setLoadingMore(true);

        if (scrollableRef.current) {
            const chatsElement = scrollableRef.current.wrapperRef.current;
            const scrollHeight = chatsElement.scrollHeight;

            await props.handleLoadMoreMessages();
            setLoadingMore(false);
            scrollableRef.current.props.animateScroll(chatsElement, (chatsElement.scrollHeight - scrollHeight));
        }
    };

    const renderedDates = [];

    return (
        <div className="internal_chat_room__message_container" >
            {messages.length > 0 ?
                <ScrollableFeed debug={false} className="internal_chat_room__message_list" ref={scrollableRef}>
                    {props.hasMoreMessages && <LoadMore icon={<HiOutlineChevronDoubleUp />} loadingMore={loadingMore} onClick={handleLoadMoreClick} />}
                    {messages.map((message, index) => {
                        let dateToRender = toChatDate(message.createdAt);
                        if (!renderedDates.includes(dateToRender)) {
                            renderedDates.push(dateToRender);
                        } else {
                            dateToRender = undefined;
                        }
                        return (
                            <div key={index}>
                                {dateToRender && <ChatDateBadge date={dateToRender} />}
                                <InternalChatRoomMessageItem key={message._id} currentAgent={props.currentAgent} message={message} />
                            </div>
                        )
                    })}
                </ScrollableFeed> : (
                    <div className="internal_chats_list__message_badge">
                        <HiChatBubbleLeftRight className="internal_chats_list__message_badge__icon" />
                        No messages yet
                    </div>
                )}
        </div>
    );
};

export default InternalChatRoomMessages;
