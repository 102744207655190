import { handleResponse } from "../misc";

const getEduBots = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot', requestOptions).then(handleResponse).then((edubots) => {
        return edubots;
    });
};

const getEduBot = (authToken, id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot/' + id, requestOptions).then(handleResponse).then((edubot) => {
        return edubot;
    });
}

const publishEduBot = (authToken, id) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot/' + id + '/publish', requestOptions).then(handleResponse).then((edubot) => {
        return edubot;
    });
}   

const createEduBot = (authToken, edubot) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(edubot)
    };

    return fetch('/api/edubot', requestOptions).then(handleResponse).then((edubot) => {
        return edubot;
    });
}

const updateEduBot = (authToken, edubot) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(edubot)
    };

    return fetch('/api/edubot/' + edubot._id, requestOptions).then(handleResponse).then((edubot) => {
        return edubot;
    });
}

const deleteEduBot = (authToken, id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot/' + id, requestOptions).then(handleResponse).then((edubot) => {
        return edubot;
    });
}

export {
    getEduBots,
    getEduBot,
    createEduBot,
    updateEduBot,
    deleteEduBot,
    publishEduBot
};