import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentService } from '../../services/agents';
import { setCurrentAgent } from '../../reducers/authSlice';
import { Switch } from '@mui/material';

const NotificationSoundToggle = (props) => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const [muted, setMuted] = useState(auth.agent.agent.agent_settings.notifications_muted);

    const handleNotificationMute = () => {
        setMuted(prevMuted => !prevMuted);
        dispatch(setCurrentAgent({
            ...auth.agent.agent,
            agent_settings: {
                ...auth.agent.agent.agent_settings,
                notifications_muted: !muted
            }
        }));
        agentService.setMuted(auth.agent.token, auth.agent.agent._id, !muted);
    };

    return (
        <Switch className="toggle_custom" onChange={handleNotificationMute} checked={!muted}/>
    );
};

export default NotificationSoundToggle;
