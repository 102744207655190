import { handleResponse } from './misc';

const getConversations = (authToken, limit, filters, sortBy = 'desc') => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
        
            let queryString = '';
            queryString = limit ? '?limit=' + limit : '';
            queryString += sortBy ? '&sortBy=' + sortBy : '';
            if(filters){
                queryString += limit ? '&' : '?';
                for (const [key, value] of Object.entries(filters)) {
                    queryString += value && value !== '' ? key + '=' + value + '&' : '';
                }
            }
            
            fetch('/api/conversations' + queryString, requestOptions).then(handleResponse).then((conversations) => {
                resolve(conversations);
            });
        }catch(e){
            reject(e);
        }
    });
}

const getChannelFilters = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/filters/channels', requestOptions).then(handleResponse).then((channels) => {
        return channels;
    });
}

const searchConversations = (authToken, search, limit, sortBy = 'desc') => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            let queryString = '';
            queryString = limit ? '?limit=' + limit : '';
            queryString += sortBy ? '&sortBy=' + sortBy : '';
        
            fetch('/api/conversations/search/' + search + queryString, requestOptions).then(handleResponse).then((conversations) => {
                resolve(conversations);
            });
        }catch(e){
            reject(e);
        }
    });
}


const getConversation = (authToken, conversationId) => {
    return new Promise(function (resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
        
            fetch('/api/conversations/' + conversationId, requestOptions).then(handleResponse).then((conversation) => {
                resolve(conversation);
            }).catch(e => {
                reject(e)
            });
        }catch(e){
            reject(e);
        }
    })
}

const notificationRead = (conversationId, agentId, authToken) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/conversations/' + conversationId + '/notification/read/' + agentId, requestOptions).then(handleResponse).then((conversation) => {
        return conversation;
    });
}

const getConversationMessages = (authToken, conversationId, limit) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
        
            let queryString = '';
            queryString = limit ? queryString + '?limit=' + limit : queryString;
            
            fetch(`/api/conversations/${conversationId}/messages`  + queryString, requestOptions).then(handleResponse).then((messages) => {
                resolve(messages);
            }).catch(e => {
                reject(e)
            });;
        }catch(e){
            reject(e);
        }
    })
};

const addNote = (authToken, conversationId, note) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(note)
    };

    return fetch('/api/conversations/' + conversationId + '/notes/add', requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const removeNote = (authToken, conversationId, noteId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId + '/notes/remove/' +  noteId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const addTag = (authToken, conversationId, tagId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId + '/tags/add/' + tagId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const updateTags = (authToken, conversationId, tags) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ tags })
    };

    return fetch('/api/conversations/' + conversationId + '/tags/update', requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const removeTag = (authToken, conversationId, tagId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId + '/tags/remove/' +  tagId, requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const deleteConversaton = (authToken, conversationId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/conversations/' + conversationId, requestOptions).then(handleResponse).then((conversation) => {
        return conversation;
    });
}

const createEvent = (authToken, conversationId, title, text) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ title, text })
    };

    return fetch('/api/conversations/' + conversationId + '/events/add', requestOptions).then(handleResponse).then((chat) => {
        return chat;
    });
}

const createTypingEvent = (authToken, conversationId, type) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ conversation_id: conversationId, type })
    }

    return fetch('/api/messages/typing', requestOptions).then(handleResponse).then(() => {
        return null;
    });
}

export { getConversationMessages, getConversations, searchConversations, getConversation, addNote, removeNote, deleteConversaton, notificationRead, getChannelFilters, createEvent, addTag, removeTag, updateTags, createTypingEvent };