import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
	MiniMap,
	Controls,
	Background,
	useNodesState,
	useEdgesState,
	addEdge,
	MarkerType,
	Panel
} from 'reactflow';
import StepNode from './StepNode';

import dagre from 'dagre';
import StepEdge from './StepEdge';
import _ from 'lodash'
import HiddenNode from './HiddenNode';

const dagreGraph = new dagre.graphlib.Graph();

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges) => {
	dagreGraph.setGraph({ rankdir: 'LR' });

	nodes.forEach((node) => {
		dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
	});

	dagre.layout(dagreGraph);

	let y = 0;
	nodes.forEach((node) => {
		const nodeWithPosition = dagreGraph.node(node.id);

		node.position = {
			x: nodeWithPosition.x - nodeWidth / 2,
			y,
		};

		y += node.height + 60;

		return node;
	});

	return { nodes, edges };
};

const StepManager = ({ steps, selectedStep, handleStepSelect, handleAddStepClick, triggerStepDeselect, setTriggerStepDeselect }) => {
	const initialNodes = steps.map((step, index) => ({
		id: step._id,
		position: { x: 0, y: index * 200 },
		type: 'stepNode',
		data: { step },
		selected: selectedStep?._id === step._id
	}))

	initialNodes.push({
		id: 'add-step',
		position: { x: 0, y: initialNodes.length * 200 },
		type: 'hiddenNode',
		width: null,
		selectable: false,
		selected: false
	});

	const initialEdges = steps.map((step, index) => ({
		id: `e${index}-${index + 1}`,
		source: step._id,
		target: steps[index + 1]?._id,
		type: 'stepEdge',
		data: { step, handleAddStepClick },
		markerEnd: {
			type: MarkerType.ArrowClosed
		},
		focusable: false
	}));

	initialEdges.push({
		id: `e${steps.length}-add`,
		source: steps[steps.length - 1]?._id,
		target: 'add-step',
		type: 'stepEdge',
		data: { step: steps[steps.length - 1], handleAddStepClick },
		focusable: false
	});

	const nodeTypes = {
		stepNode: StepNode,
		hiddenNode: HiddenNode
	}

	const edgeTypes = {
		stepEdge: StepEdge
	}

	const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
	const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

	// Handle update of steps
	useEffect(() => {
		if (steps && nodes) {
			
			const updatesNodes = steps.map((step, index) => {
				const currentNode = nodes.find(node => node.data?.step?._id === step._id);

				return currentNode ? { ...currentNode, width: null, data: { step } } : {
					id: step._id,
					position: { x: 0, y: (index * 200) + 80 },
					type: 'stepNode',
					width: 200,
					height: 200,
					data: { step },
					selected: selectedStep?._id === step._id
				}			
			});

			updatesNodes.push({
				id: 'add-step',
				position: { x: 0, y: updatesNodes.length * 200 },
				width: null,
				type: 'hiddenNode',
				selectable: false,
				selected: false
			});

			setNodes(updatesNodes);
			setEdges(initialEdges);
		}
	}, [steps])

	// Handle the trigger to deselect all nodes from external component
	useEffect(() => { if(triggerStepDeselect){ deselectNodes(); setTriggerStepDeselect(false); } }, [triggerStepDeselect])

	const deselectNodes = () => {
		const newNodes = nodes.map((node) => ({ ...node, selected: false }));
		setNodes(newNodes);
	}

	const onConnect = useCallback(
		(params) => setEdges((eds) => addEdge(params, eds)),
		[setEdges],
	);

	const handleSelectionChange = (e) => {
		const { nodes, edges } = e;
		
		const selectedNode = nodes.length > 0 ? nodes[0].data.step : null;

		if (selectedNode) {
			if (selectedNode._id !== selectedStep?._id) {
				handleStepSelect(selectedNode);
			}
		} else {
			handleStepSelect(null);
		}
	}

	const onLayout = useCallback(
		(direction) => {
			const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
				nodes,
				edges,
				direction
			);

			setNodes([...layoutedNodes]);
			setEdges([...layoutedEdges]);
		},
		[nodes, edges]
	);

	const [prevNodes, setPrevNodes] = useState(null);
const [prevEdges, setPrevEdges] = useState(null);

useEffect(() => {
    if(nodes && edges){
        if(!_.isEqual(nodes, prevNodes) || !_.isEqual(edges, prevEdges)){
            onLayout('TB');
            setPrevNodes(nodes);
            setPrevEdges(edges);
        }
    }
}, [nodes, edges, prevNodes, prevEdges]);

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				fitView={true}
				nodesDraggable={false}
				proOptions={{ hideAttribution: true }}
				onSelectionChange={handleSelectionChange}
				multiSelectionKeyCode={null}
			>
				<Controls showInteractive={false} />
				<MiniMap />
				<Background style={{ background: '#ececec' }} variant="dots" gap={12} size={1} />
			</ReactFlow>
		</div>
	)
}

export default StepManager;