import Preloader from "../Preloader";
import { HiOutlineXMark, HiOutlineDocumentText } from 'react-icons/hi2';
import { isImage, isVideo } from '../../services/upload';

const ChatMediaPreview = (props) => {
    return (
        <div className="chat_media_preview">
            {!props.loadingMediaPreview ? (
                <div className="chat_media_preview__container">
                    {isImage(props.mediaFile.type) && <img src={props.mediaPreview} alt="Message Media Preview"/>}
                    {isVideo(props.mediaFile.type) && <video controls={true} src={props.mediaPreview}/>}
                    {(!isImage(props.mediaFile.type) && !isVideo(props.mediaFile.type) && (
                        <div className="chat_media_preview__container__default">
                            <HiOutlineDocumentText/>
                            <div className="chat_media_preview__container__default__type">{props.mediaFile.type}</div>
                        </div>
                    ))}
                    {props.mediaFile && (
                        <div className="chat_media_preview__file_info">
                            <div className="chat_media_preview__file_info__title text-truncate">{props.mediaFile.name}</div>
                            <div className="chat_media_preview__file_info__type">{props.mediaFile.type}</div>
                            <div className="chat_media_preview__file_remove" onClick={props.handleRemoveMedia}><HiOutlineXMark/></div>
                        </div>
                    )}
                </div>
            ) : <Preloader padding="3rem 0px"/>}
        </div>
    );
}

export default ChatMediaPreview;