import React, { useState } from 'react';
import ChatChannelIcon from "../Chats/ChatChannelIcon";
import { useDispatch, useSelector } from 'react-redux';
import { toTimestamp } from '../../services/dates';
import { declineChat } from '../../services/humanHandoff';
import Preloader from '../Preloader';
import QueueBadge from '../Base/QueueBadge';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import ChatTransfer from '../Chats/ChatTransfer';
import { HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2';
import { acceptHumanHandoff } from '../../actions/humanHandoff';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import { goToConversation } from '../../reducers/chatsSlice';

const HumanHandoffInvite = (props) => {
    const [performingAction, setPerformingAction] = useState(undefined);
    const [showingTransfer, setShowingTransfer] = useState(false);
    const [viewingChat, setViewingChat] = useState(false);

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const handleAcceptClick = async () => {
        setPerformingAction("Joining chat...");

        dispatch(goToConversation({ conversation: props.humanHandoff.conversation.conversation_id, navigate, location }))
        await acceptHumanHandoff(auth, props.humanHandoff);
    };

    const handleDeclineClick = () => {
        declineChat(auth.selectedChatbot.token, props.humanHandoff.conversation.conversation_id, auth.agent.agent, props.humanHandoff, props.humanHandoff.conversation.channel);
        setPerformingAction("Declining chat...");
    };

    const handleToggleChat = () => {
        setShowingTransfer(prevState => !prevState);
    };

    const handleViewChatClick = async () => {
        setViewingChat(true);

        dispatch(goToConversation({ conversation: props.humanHandoff.conversation.conversation_id, navigate, location }))
        setViewingChat(false);
    };

    const humanHandoff = props.humanHandoff;
    const conversation = humanHandoff.conversation;

    let title = '';

    if (humanHandoff.updatedBy) {
        title = humanHandoff.updatedBy.first_name[0] + '. ' + humanHandoff.updatedBy.last_name;
    } else {
        title = 'An agent';
    }
    title += ' has invited you to join this chat.';

    return (
        <div className="human_handoff_notification transfer">
            {performingAction && (
                <div className="human_handoff_notification__overlay">
                    <Preloader text={performingAction} />
                </div>
            )}
            <div className="human_handoff_notification__title">
                {humanHandoff.updatedBy && <AgentProfileAvatar size="20" agent={humanHandoff.updatedBy} />}
                {title}
            </div>
            <div className="human_handoff_notification__info">
                <ChatChannelIcon channel={conversation.channel} />
                <div className="human_handoff_notification__info__user">
                    <div className="human_handoff_notification__info__queue">
                        <QueueBadge queue={conversation.queue} />
                    </div>
                    <div className="human_handoff_notification__info__user__name text-truncate">
                        {conversation.user && conversation.user.user_info && conversation.user.user_info.name ? conversation.user.user_info.name : conversation.conversation_id}
                    </div>
                    <div className="timestamp">{toTimestamp(humanHandoff.createdAt)}</div>
                </div>
                {!viewingChat ? (
                    <Tooltip title="View Chat" arrow>
                        <div className="human_handoff_notification__view_chat" onClick={handleViewChatClick}>
                            <HiOutlineChatBubbleLeftEllipsis />
                        </div>
                    </Tooltip>
                ) : (
                    <Preloader size="23" />
                )}
            </div>
            <div className="human_handoff_actions">
                <div className="human_handoff_action accept" onClick={handleAcceptClick}>
                    Accept
                </div>
                <div className="human_handoff_action decline" onClick={handleDeclineClick}>
                    Decline
                </div>
            </div>
            {showingTransfer && <ChatTransfer handleTransferToggle={handleToggleChat} conversation={conversation} />}
        </div>
    );
};

export default HumanHandoffInvite;
