import React from 'react';
import { useSelector } from 'react-redux';
import AlertBox from '../AlertBox';

const AnalyticsPage = (props) => {
    const auth = useSelector(state => state.auth);
    return (
        <>
            <style>
                {`
                    .dashboard_content {
                        background: white;
                    }
                `}
            </style>
            {auth.selectedChatbot.settings.analytics_embed ? <div className="analytics_page" style={{background: 'white', height: '100%' }} dangerouslySetInnerHTML={{ __html: auth.selectedChatbot.settings.analytics_embed }}/> : <div className="container"><div className="col-6"><AlertBox type="error" className="m-medium">Analytics have not been configured</AlertBox></div></div>}
        </>
    );
}

export default AnalyticsPage;
