import Modal from "react-modal";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay, } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { useState } from "react";
import { SortableStep } from "./SortableStep";
import { SortableStepDraggable } from "./SortableStepDraggable";


const StepReOrderModal = ({ steps, setSteps, isOpen, onConfirm, onRequestClose }) => {
	const [activeId, setActiveId] = useState(null);
	const [items, setItems] = useState(steps);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragStart(event) {
		const {active} = event;
		
		setActiveId(active.id);
	}
	
	function handleDragEnd(event) {
		const {active, over} = event;
		
		if (active.id.toString() !== over.id.toString()) {
			setItems((items) => {
				const oldIndex = items.findIndex(i => i._id == active.id);
				const newIndex = items.findIndex(i => i._id == over.id);
				
				return arrayMove(items, oldIndex, newIndex).map((item, index) => ({...item, order: index}));
			});
		}
		
		setActiveId(null);
	}

	const handleConfirm = () => {
		setSteps(prevSteps => {
			const newSteps = prevSteps.filter(step => !items.find(i => i._id === step._id));
			return [...newSteps, ...items];
		});
		onConfirm();
	}

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal modal__preloader">
			<div className="modal__title">Re-order Steps</div>
			<div className="modal__content">
				<DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} >
					<SortableContext items={items} strategy={verticalListSortingStrategy} >
						{items.map(step => <SortableStep key={step._id} id={step._id} step={step} />)}
					</SortableContext>
					<DragOverlay>
						{activeId ? <SortableStepDraggable id={activeId} step={items.find(i => i._id === activeId)}/> : null}
					</DragOverlay>
				</DndContext>
			</div>
			<div className="modal__actions">
                <button className="btn" onClick={onRequestClose}>Cancel</button>
                <button className="btn primary" onClick={handleConfirm}>Update order</button>
            </div>
		</Modal>
	)
}

export default StepReOrderModal;