import { handleResponse } from './misc';

const getQuickReplies = (authToken, agentId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/quick-replies/agent/' + agentId, requestOptions).then(handleResponse).then((quickReplies) => {
        return quickReplies;
    });
};

const updateQuickReplies = (authToken, quickReplyId, body) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/quick-replies/' + quickReplyId, requestOptions).then(handleResponse).then((quickReplies) => {
        return quickReplies;
    });
};

const newQuickReplies = (authToken, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/quick-replies', requestOptions).then(handleResponse).then((quickReplies) => {
        return quickReplies;
    });
}

const deleteQuickReply = (authToken, quickReplyId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/quick-replies/' + quickReplyId, requestOptions).then(handleResponse).then((quickReplies) => {
        return quickReplies;
    })
}

export { getQuickReplies, updateQuickReplies, deleteQuickReply, newQuickReplies }