import React, { useState, useEffect, useRef } from 'react';
import { closeChat, joinChat, addAgentToConversation, removeAgentFromConversation, requestUserEngagement } from '../../services/humanHandoff';
import { acceptHumanHandoff } from '../../actions/humanHandoff';
import { HiOutlineXMark, HiVideoCamera, HiOutlineUserPlus, HiOutlineInformationCircle, HiCheck } from 'react-icons/hi2';
import { RiUserVoiceLine } from 'react-icons/ri';
import ChatActionButton from './ChatActionButton';
import MeetingSetup from './MeetingSetup';
import moment from 'moment';
import { createEvent } from '../../services/conversation';
import ConfirmModal from '../Base/ConfirmModal';
import { useSelector } from 'react-redux';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const ChatActions = (props) => {
    const [handoffPending, setHandoffPending] = useState(false);
    const [leavingChat, setLeavingChat] = useState(false);
    const [joiningChat, setJoiningChat] = useState(false);
    const [meetingSetupOpen, setMeetingSetupOpen] = useState(false);
    const [acceptingHandoff, setAcceptingHandoff] = useState(false);
    const [confirmRequestEngagement, setConfirmRequestEngagement] = useState(false);
    const [closingChat, setClosingChat] = useState(false);
    const [leaveChatOptionsShown, setLeaveChatOptionsShown] = useState(false);
    const [requestingEngagement, setRequestingEngagement] = useState(false);

    const notifications = useSelector(state => state.notifications);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const humanHandoff = notifications.notifications.find(notification => notification.props.humanHandoff && notification.props.humanHandoff.conversation_id === props.currentConversation.conversation_id);

        setHandoffPending(humanHandoff || false);
    }, [notifications.notifications, props.currentConversation.conversation_id]);

    const handleClickAway = () => {
        handleLeaveChatActionsTrigger(false);
    }

    // When conversation changes, reset the state of the chat actions
    useEffect(() => {
        if(closingChat){ 
            setClosingChat(false);
            setLeaveChatOptionsShown(false);
        }

        if(leavingChat){
            setLeavingChat(false);
            setLeaveChatOptionsShown(false);
        }

        if(joiningChat){
            setJoiningChat(false);
            setLeaveChatOptionsShown(false);
        }
    }, [props.currentConversation])

    const handleCloseChat = async () => {
        setClosingChat(true);
        await closeChat(auth.selectedChatbot.token, props.currentConversation.conversation_id, props.currentConversation.channel);
        props.handleSetCurrentConversation(props.currentConversation.conversation_id);
    };

    const handleJoinChat = async () => {
        setJoiningChat(true);
        const currentChat = props.currentConversation;
        await createEvent(auth.selectedChatbot.token, currentChat.conversation_id, 'Agent Join', `${auth.agent.agent.first_name} ${auth.agent.agent.last_name} joined the chat.`);
        if (!(currentChat.agents_connected && currentChat.agents_connected.length > 0)) {
            await joinChat(auth.selectedChatbot.token, currentChat.conversation_id, auth.agent.agent, currentChat.channel);
        }

        await addAgentToConversation(auth.selectedChatbot.token, currentChat.conversation_id, auth.agent.agent._id);
        props.handleSetCurrentConversation(currentChat.conversation_id);
    };

    const handleRequestEngagmentClick = () => {
        setConfirmRequestEngagement(prevState => !prevState);
    };

    const handleLeaveChat = async () => {
        setLeavingChat(true);
        const currentChat = props.currentConversation;
        await createEvent(auth.selectedChatbot.token, currentChat.conversation_id, 'Agent Leave', `${auth.agent.agent.first_name} ${auth.agent.agent.last_name} left the chat.`);
        await removeAgentFromConversation(auth.selectedChatbot.token, currentChat.conversation_id, auth.agent.agent._id);
        props.handleSetCurrentConversation(currentChat.conversation_id);
    };

    const handleRequestEngagement = async () => {
        setRequestingEngagement(true);
        const currentChat = props.currentConversation;
        await createEvent(auth.selectedChatbot.token, currentChat.conversation_id, 'User Engagement Requested', `${auth.agent.agent.first_name} ${auth.agent.agent.last_name} requested engagement from the user.`);

        await requestUserEngagement(auth.selectedChatbot.token, currentChat.conversation_id, auth.agent.agent, currentChat.channel);

        await props.handleSetCurrentConversation(currentChat.conversation_id);
        await props.setCurrentMessages(auth.selectedChatbot.token, currentChat.conversation_id);
        setRequestingEngagement(false);
    };

    const handleSetupMeetingToggle = () => {
        setMeetingSetupOpen(!meetingSetupOpen);
    };

    const handleLeaveChatActionsTrigger = (open) => {
        setLeaveChatOptionsShown(open);
    };

    const handleAcceptHandoff = async () => {
        setAcceptingHandoff(true);
        await acceptHumanHandoff(auth, handoffPending.props.humanHandoff);
    };

    const userConnectedToChat = props.currentConversation.agents_connected && props.currentConversation.agents_connected.find(agent => agent._id.toString() === auth.agent.agent._id.toString());
    const agentChats = props.currentMessages.filter(message => message.sender === 'user');
    const inSession = (agentChats.length > 0 && moment(agentChats[agentChats.length - 1].createdAt) > moment().subtract(auth.selectedChatbot.settings.chat_session_time, 'minutes')) ? true : false;

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="chat_actions">
                {userConnectedToChat && auth.selectedChatbot.settings.enable_video_meetings && <ChatActionButton icon={<HiVideoCamera />} onClick={handleSetupMeetingToggle} label={props.currentConversation.latestMeeting ? "View Meeting" : "Meet Now"} />}
                {(userConnectedToChat && props.currentConversation.agents_connected.length > 1) && <ChatActionButton icon={<HiOutlineXMark />} disabled={leavingChat} onClick={() => { handleLeaveChatActionsTrigger(!leaveChatOptionsShown) }} label={leaveChatOptionsShown ? 'Cancel' : 'Leave Chat'} />}
                {(userConnectedToChat && props.currentConversation.agents_connected.length === 1) && <ChatActionButton isHovered={true} icon={<HiOutlineXMark />} disabled={closingChat} onClick={handleCloseChat} label={!closingChat ? 'End Chat' : 'Ending chat...'} />}
                {userConnectedToChat && leaveChatOptionsShown && (
                    <div className="chat_actions_dropdown">
                        <button disabled={closingChat || leavingChat} onClick={handleLeaveChat} className="chat_actions_dropdown__item">{!leavingChat ? 'Leave Chat' : 'Leaving chat...'}</button>
                        <button onClick={handleCloseChat} disabled={closingChat || leavingChat} className="chat_actions_dropdown__item">{!closingChat ? 'End Chat' : 'Ending chat...'}</button>
                    </div>
                )}
                {(auth.selectedChatbot.settings.human_handoff_enabled && !inSession && !handoffPending && !userConnectedToChat && auth.selectedChatbot.settings.allow_chat_join && auth.selectedChatbot.settings.enable_request_engagement) && <ChatActionButton icon={<RiUserVoiceLine />} disabled={requestingEngagement} onClick={handleRequestEngagmentClick} label={!requestingEngagement ? 'Request User Engagement' : 'Requesting...'} />}
                {(auth.selectedChatbot.settings.human_handoff_enabled && inSession && !handoffPending && !userConnectedToChat && auth.selectedChatbot.settings.allow_chat_join) ? <ChatActionButton icon={<HiOutlineUserPlus />} disabled={joiningChat} onClick={handleJoinChat} label={!joiningChat ? 'Join' : 'Joining...'} /> : (handoffPending && !userConnectedToChat && <ChatActionButton icon={<HiCheck />} disabled={acceptingHandoff} onClick={handleAcceptHandoff} label={!acceptingHandoff ? 'Accept handoff' : 'Accepting...'} />)}
                {meetingSetupOpen && (
                    <MeetingSetup
                        meeting={props.currentConversation.latestMeeting}
                        conversation={props.currentConversation}
                        handleSetCurrentConversation={props.handleSetCurrentConversation}
                        toggleMeetingSetup={handleSetupMeetingToggle}
                    />
                )}
                <ChatActionButton className="mobile_info" isHovered={true} icon={<HiOutlineInformationCircle />} onClick={props.handleMobileSidebarTrigger} label="Info" />
                <ConfirmModal confirmText="Request User Engagement" title="Request User Engagement" description="This will trigger a message to the user to request they start engagement with the conversation." isOpen={confirmRequestEngagement} onConfirm={handleRequestEngagement} onRequestClose={handleRequestEngagmentClick} />
            </div>
        </ClickAwayListener>
    );
};

export default ChatActions;
