import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { agentService } from '../services/agents';
import { expiryTime } from '../services/misc';
import moment from 'moment';

let agent = JSON.parse(localStorage.getItem('user'));
let selectedCompany = localStorage.getItem('selectedCompany') || undefined;
let selectedChatbot = null; 

try{
    selectedChatbot = localStorage.getItem('selectedChatbot') ? JSON.parse(localStorage.getItem('selectedChatbot')) : undefined;
}catch{
    // No chatbots, log user out
    alert('No chatbots linked to company. Please contact admin.')
    agentService.logout();
}

if(agent && moment(agent.expiryTime) < moment()){
    agent = undefined;
    localStorage.removeItem('user');
    localStorage.removeItem('selectedChatbot');
    localStorage.removeItem('selectedCompany');
}else{
    if(agent){
        agent.expiryTime = expiryTime();
        localStorage.setItem('user', JSON.stringify(agent))
    }
}

const initialState = agent ? { loggedIn: true, agent, selectedCompany, selectedChatbot, notificationsExpanded: false } : {};

// Login async thunk
const login = createAsyncThunk('auth/login', async (loginArgs) => {
    const { email, password } = loginArgs;
    const agent = await agentService.login(email, password);
    
    return agent;
});

// Validate password reset async thunk
const validatePasswordReset = createAsyncThunk('auth/validatePasswordReset', async (token) => {
    const agent = await agentService.validatePasswordReset(token);
    return agent;
})

// Set password async thunk
const setPassword = createAsyncThunk('auth/setPassword', async (setPasswordArgs) => {
    const { authToken, password } = setPasswordArgs;
    const agent = await agentService.setPassword(authToken, password);
    return agent;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentAgent: (state, action) => {
            state.agent.agent.agent_settings = action.payload.agent_settings;   
            state.agent.agent.avatar = action.payload.avatar;
            state.agent.agent.email = action.payload.email;
            state.agent.agent.first_name = action.payload.first_name;
            state.agent.agent.last_name = action.payload.last_name;
            state.agent.agent.role = action.payload.role;
            state.agent.agent._id = action.payload._id;
            state.agent.agent.companies = action.payload.companies;
        },
        setCurrentCompany: (state, action) => {
            state.selectedCompany = action.payload;
            localStorage.setItem('selectedCompany', action.payload);
        },
        setCurrentChatbot: (state, action) => {
            localStorage.setItem('selectedChatbot', JSON.stringify(action.payload));
            state.selectedChatbot = action.payload;
        },
        setCurrentChatbotSettings: (state, action) => {
            const settings = action.payload;
            
            state.selectedChatbot.settings = {
                ...state.selectedChatbot.settings,
                ...settings
            };
            
            localStorage.setItem('selectedChatbot', JSON.stringify(state.selectedChatbot));
        },
        logout: (state) => {
            agentService.logout();
            state.loggedIn = false;
            state.agent = null;
            state.error = null;
            state.loggingIn = false;
            state.settingPassword = false;
            state.selectedCompany = null;
            state.selectedChatbot = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Login cases
            .addCase(login.pending, (state) => {
                state.loggingIn = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loggedIn = true;
                state.agent = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.loggedIn = false;
                state.loggingIn = false;
                state.agent = null;
                state.error = action.error.message;
            })
            // Validate password reset cases
            .addCase(validatePasswordReset.fulfilled, (state, action) => {
                state.loggedIn = true;
                state.agent = action.payload;

            })
            .addCase(validatePasswordReset.rejected, (state, action) => {
                state.loggedIn = false;
                state.loggingIn = false;
                state.agent = null;
                state.error = action.error.message;
            })
            .addCase(setPassword.pending, (state) => {
                state.settingPassword = true;
            })
            .addCase(setPassword.fulfilled, (state, action) => {
                state.settingPassword = false;
                state.agent = {
                    ...state.agent,
                    agent: action.payload
                };
            })
            .addCase(setPassword.rejected, (state, action) => {
                state.settingPassword = false;
                state.error = action.error.message;
            });
    }
});

export { login, validatePasswordReset, setPassword }

export const { setCurrentAgent, setCurrentCompany, setCurrentChatbot, setCurrentChatbotSettings, logout } = authSlice.actions

export default authSlice.reducer