import { handleResponse } from './misc';

const getTags = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/tags', requestOptions).then(handleResponse).then((tags) => {
        return tags;
    });
};

const createTag = (authToken, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/tags', requestOptions).then(handleResponse).then((tag) => {
        return tag;
    });
}

export { getTags, createTag }