import { useEffect, useState } from "react"
import AlertBox from "../AlertBox"
import Preloader from "../Preloader";

const EduBotPreviewWidget = ({ eduBot }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.src = `${eduBot.bot_config.staging_widget?.endpoint}/main.js`;
        scriptTag.addEventListener('load', () => {
            setLoading(false)
            
        });
        document.body.appendChild(scriptTag);
    }, [])

    return (
        <div className="edubot_preview_widget">
            {eduBot.bot_config.staging_widget?.endpoint ? (
                <>
                    <div id="chattabotz_webchat_widget"></div>
                </>
            ) : <AlertBox type="info">Your staging widget has not been configured yet. Please contact your EduBot admin to proceed.</AlertBox>}
        </div>
    )
}

export default EduBotPreviewWidget