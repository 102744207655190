import { HiOutlineTrash } from "react-icons/hi2";
import { titleCase } from "../../services/misc";

const WhatsAppTemplateListItem = (props) => {
    const isDisabled = (!props.allowPending && props.template.status.toLowerCase() !== 'approved');
    return !isDisabled && (
        <tr className={`whatsapp_template_list_item ${props.onClick ? 'selectable' : ''} ${isDisabled ? 'disabled' : ''}`} onClick={(!isDisabled && props.onClick) ? () => { props.onClick(props.template) } : null}>
            <td className="whatsapp_template_list_item__details">
                <div className="whatsapp_template_list_item__details__name text-truncate">{props.template.name}</div>
                <div className="whatsapp_template_list_item__details__category text-truncate">{titleCase(props.template.category.toLowerCase())}</div>
            </td>
            <td className="whatsapp_template_list_item__language">
                <div className="whatsapp_template_list_item__language__language_code">{props.template.language}</div>
                <div className="whatsapp_template_list_item__language__language_body_preview text-truncate">{props.template.components.find(c => c.type.toLowerCase() === 'body').text}</div>
            </td>
            <td className="whatsapp_template_list_item__status"><div className={`status_badge ${props.template.status.toLowerCase()}`}>{props.template.status}</div></td>
            {props.editable && (
                <td>
                    <button className='btn' onClick={() => { props.onDelete(props.template); }}><HiOutlineTrash/>Delete</button>
                </td>
            )}
        </tr>
    );
};

export default WhatsAppTemplateListItem