import React, { useState } from 'react';
import Modal from 'react-modal';
import FileUpload from '../Base/FileUpload';
import Preloader from '../Preloader';
import AlertBox from '../AlertBox';
import { HiOutlineCheckCircle } from 'react-icons/hi2';
import { TextField } from '@mui/material';

const IndexImportModal = (props) => {
    const [file, setFile] = useState(null);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [delimeter, setDelimeter] = useState('########');
    const handleSetFile = (file) => {
        setFile(file);
    }

    const handleImportDocumentClick = () => {
        props.handleImportDocument(file, delimeter);
    }

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal modal__preloader" onRequestClose={props.onRequestClose}>
            <div className="modal__title">Import Index</div>
            <div className="modal__content">Import chunks for predefined word document. Ensure you specify the correct delimeter. This will add imported chunks to your index and not replace any.</div>
            <form className='modal_content'>
                {file ? <AlertBox className="mt-medium mb-medium" type="success">Successfully selected <b>{file.name}</b> for import</AlertBox> : (
                    uploadingFile ? <Preloader text="Uploading file..." /> : (
                        (
                            <div className='modal_field'>
                                <label>Document</label>
                                <FileUpload handleSetFile={handleSetFile} fileTypes={['doc', 'docx']} />
                            </div>
                        )
                    )
                )}
                <TextField label="Delimeter" fullWidth required onChange={(e) => { setDelimeter(e.target.value) }} className="mt-medium" value={delimeter} />
                <div className="modal__actions">
                    <button className="btn" onClick={props.onRequestClose}>Cancel</button>
                    <button className="btn primary" onClick={handleImportDocumentClick} disabled={!file}>Import Index</button>
                </div>
            </form>
        </Modal>
    );
}

export default IndexImportModal;
