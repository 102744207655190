import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Switch, Tooltip } from "@mui/material";
import React from "react";
const ModuleListItemDraggable = ({ id, module, handleModuleUpdate, onModuleSelect }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className="module_list__item draggable_overlay" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className="module_list__item_icon"><div dangerouslySetInnerHTML={{ __html: module.module_template.icon }}/></div>
            <div className="module_list__item_details">
                <div className="edubot_detail__label">{module.module_template.name}</div>
                <div className="edubot_detail__value">{module.module_template.description}</div>
            </div>
        </div>
    )
}   

export default ModuleListItemDraggable;