const isMobile = () => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(width > 896){
        return false;
    }else{
        return true;
    }
}

const isTablet = () => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(width > 1100){
        return false;
    }else{
        return true;
    }
}

export {
    isMobile,
    isTablet
};