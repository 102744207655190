import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../reducers/authSlice';

const AdminMenu = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <div className="admin_menu">
            <img className="admin_menu__logo" src="/favicon.ico" alt="Verge AI Logo"/>
            <h2>Admin Dashboard</h2>
            <div className="admin_menu_inner">
                <button className="admin_menu__item logout" onClick={handleLogout}>Logout</button>
            </div>
        </div>
    );
}

export default AdminMenu;
