import React, { useState, useRef } from 'react';
import EmojiPicker from '../Base/EmojiPicker';
import ChatMessageBarActions from './ChatMessageBarActions';
import { HiOutlineBolt, HiOutlineDocumentPlus, HiPaperAirplane, HiOutlineCheckCircle, HiOutlinePlusCircle, HiOutlineXMark } from 'react-icons/hi2'
import ChatMediaPreview from './ChatMediaPreview';
import TextArea from 'react-textarea-autosize';
import QuickReplyList from '../QuickReplies/QuickReplyList';
import Tooltip from '@mui/material/Tooltip';
import Preloader from '../Preloader';

const ChatMessageBar = (props) => {
    const [message, setMessage] = useState('');
    const [publicBool, setPublicBool] = useState(true);
    const [hasMedia, setHasMedia] = useState(false);
    const [mediaFile, setMediaFile] = useState(undefined);
    const [mediaPreview, setMediaPreview] = useState(undefined);
    const [loadingMediaPreview, setLoadingMediaPreview] = useState(false);
    const [quickRepliesShown, setQuickRepliesShown] = useState(false);
    const [savingQuickReply, setSavingQuickReply] = useState(false);
    const [savedQuickReply, setSavedQuickReply] = useState(false);
    const chatMessageTextArea = useRef(null);

    const mediaUploadElement = useRef(null);

    const handleMessageType = (e) => {
        if (e.target.value.toLowerCase() === '/r') {
            setMessage('')
            return setQuickRepliesShown(true);
        }

        setMessage(e.target.value);

        props.handleTypingEvent();
    }

    const handleMessageSend = (e) => {
        e.preventDefault();
        if (message.trim().length > 0 || mediaFile) {
            props.handleSendMessage(message, publicBool, mediaFile, props.currentConversation.channel);

            setMessage('');
            setHasMedia(false);
            setMediaFile(undefined);
            setMediaPreview(undefined);
            setLoadingMediaPreview(false);
        }
    }

    const handleTogglePublic = (publicBool) => {
        setPublicBool(publicBool);
    }

    const handleEmojiPick = (emojiData) => {
        setMessage(prevMessage => prevMessage + emojiData.emoji);
    }

    const handleMediaTrigger = (input) => {
        input.current.click();
    }

    const handleMediaChange = (e) => {
        setHasMedia(true);
        setLoadingMediaPreview(true);
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setMediaFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setHasMedia(true);
                setMediaPreview(e.target.result);
                setLoadingMediaPreview(false);
            }

            reader.readAsDataURL(file);
        }
    }

    const handleRemoveMedia = () => {
        setHasMedia(false);
        setMediaFile(undefined);
        setMediaPreview(undefined);
        setLoadingMediaPreview(false);
    }

    const handleTextEnter = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            handleMessageSend(e);
        }
    }

    const handleQuickRepliesToggle = () => {
        setQuickRepliesShown(prevState => !prevState);
        if (quickRepliesShown) {
            chatMessageTextArea.current.focus();
        }
    }

    const handleQuickReplySelect = (quickReply) => {
        handleQuickRepliesToggle();
        setMessage(quickReply.message_data.text || message);
        setHasMedia(quickReply.message_data.media && quickReply.message_data.media.length > 0 ? quickReply.message_data.media[0] : false);
        setMediaFile((quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0]);
        setMediaPreview((quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0].url);
    }

    const handleAddToQuickReplyClick = async () => {
        setSavingQuickReply(true);
        await props.handleAddToQuickReplies(message, mediaFile);
        setSavingQuickReply(false);
        setSavedQuickReply(true);
        setTimeout(() => {
            setSavedQuickReply(false);
        }, 5000);
    }

    return (
        <div className="chat_message_bar">
            {quickRepliesShown && <QuickReplyList messageValue={message} currentConversation={props.currentConversation} onSelect={handleQuickReplySelect} />}
            {(hasMedia && mediaPreview) && <ChatMediaPreview handleRemoveMedia={handleRemoveMedia} loadingMediaPreview={loadingMediaPreview} mediaPreview={mediaPreview} mediaFile={mediaFile} />}
            <div className='chat_message_bar__message_bar'>
                <form onSubmit={handleMessageSend}>
                    <div className='chat_message_bar__message_bar__input'>
                        <div className={`chat_message_bar__message_bar__input_area ${!publicBool ? 'internal' : ''}`}>
                            <TextArea ref={chatMessageTextArea} onKeyPress={handleTextEnter} type="text" value={message} onChange={handleMessageType} placeholder={quickRepliesShown ? 'Type to search quick replies' : publicBool ? 'Enter your message to user' : 'Enter your internal message'} />
                            {(message.trim().length > 0 || hasMedia) && (
                                <div className="add_to_quick_reply__container">
                                    {(savedQuickReply ? <div className="add_to_quick_reply_loader"><HiOutlineCheckCircle /></div> : (savingQuickReply ? <div className="add_to_quick_reply_loader"><Preloader size="15" padding="0" /></div> : <Tooltip arrow title="Add to quick replies"><div className="add_to_quick_reply" onClick={handleAddToQuickReplyClick}><HiOutlinePlusCircle /></div></Tooltip>))}
                                </div>
                            )}
                        </div>
                        <button className="chat_send_button" title="Send message" disabled={(message.length === 0 || message.length >= 2800) && !hasMedia}><HiPaperAirplane /></button>
                    </div>
                </form>
                <div className='chat_message_bar__actions'>
                    <Tooltip title="Attach file" arrow placement='top'><button className="chat_message_bar__action" onClick={() => { handleMediaTrigger(mediaUploadElement) }}><HiOutlineDocumentPlus /></button></Tooltip>
                    <Tooltip title={quickRepliesShown ? 'Close quick replies' : 'Show quick replies'} arrow placement='top'><button className="chat_message_bar__action" onClick={handleQuickRepliesToggle}>{!quickRepliesShown ? <HiOutlineBolt /> : <HiOutlineXMark />}</button></Tooltip>
                    <EmojiPicker tooltip handleEmojiPick={handleEmojiPick} />
                    <ChatMessageBarActions
                        currentConversation={props.currentConversation}
                        public={publicBool}
                        handleTogglePublic={handleTogglePublic}
                    />
                </div>
            </div>
            <input ref={mediaUploadElement} type="file" style={{ display: 'none' }} onChange={handleMediaChange} />
            {savedQuickReply && <div className="message_bar_alert"><HiOutlineCheckCircle />Added to quick replies</div>}
        </div>
    );
}

export default ChatMessageBar;
