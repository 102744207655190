import { handleResponse } from './misc';

const getQuestionLog = (authToken, questionLogId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/question-logs/' + questionLogId, requestOptions).then(handleResponse).then((questionLog) => {
        return questionLog;
    });
}

const getQuestionLogs = (authToken, limit, filters, sortBy = 'desc') => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    queryString += sortBy ? '&sortBy=' + sortBy : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            queryString += value && value !== '' ? key + '=' + value + '&' : '';
        }
    }
    
    return fetch('/api/question-logs' + queryString, requestOptions).then(handleResponse).then((questionLogs) => {
        return questionLogs;
    });
}

const updateQuestionLogReview = (authToken, knowledgeSetId, questionLogId, update) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(update)
    };

    return fetch('/api/question-logs/' + questionLogId + '/review/' + knowledgeSetId, requestOptions).then(handleResponse).then((questionLog) => {
        return questionLog;
    });
}

const updateQuestionLogStatus = (authToken, questionLogId, status) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ status })
    };

    return fetch('/api/question-logs/' + questionLogId + '/status', requestOptions).then(handleResponse).then((questionLog) => {
        return questionLog;
    });
}

const addQuestionLogAgentComment = (authToken, questionLogId, comment) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(comment)
    };

    return fetch('/api/question-logs/' + questionLogId + '/agent-comments', requestOptions).then(handleResponse).then((questionLog) => {
        return questionLog;
    });
}

const deleteQuestionLogAgentComment = (authToken, questionLogId, commentId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/question-logs/' + questionLogId + '/agent-comments/' + commentId, requestOptions).then(handleResponse).then((questionLog) => {
        return questionLog;
    });
}

export { getQuestionLog, getQuestionLogs, updateQuestionLogReview, updateQuestionLogStatus, addQuestionLogAgentComment, deleteQuestionLogAgentComment };