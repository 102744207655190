import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentService } from '../services/agents';
import CompanyListItem from './CompanyListItem';
import Preloader from './Preloader';
import { setCurrentCompany } from '../reducers/authSlice';

const CompanyList = (props) => {
    const [companies, setCompanies] = useState(undefined);
    const [loadingCompanies, setLoadingCompanies] = useState(true);
    const [selectedCompanyItem, setSelectedCompanyItem] = useState(undefined);

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        agentService.getAgentCompanies(auth.agent.agent._id, auth.agent.token)
            .then((companies) => {
                setCompanies(companies);
                setLoadingCompanies(false);
            });
    }, [auth.agent.agent._id, auth.agent.token]);

    const handleOnClick = (companyId) => {
        setSelectedCompanyItem(companyId);
    };

    const handleOnSelect = () => {
        dispatch(setCurrentCompany(selectedCompanyItem));
    };

    return (
        <div>
            {loadingCompanies ? <Preloader /> : (
                <div>
                    {companies && companies.length > 0 ? (
                        <div className="company_list">
                            <div className="company_list__inner">
                                {companies.map((company) => (
                                    <CompanyListItem
                                        key={company._id}
                                        selectedCompanyItem={selectedCompanyItem}
                                        onClick={handleOnClick}
                                        company={company}
                                    />
                                ))}
                            </div>
                            <button onClick={handleOnSelect} className="button" disabled={!selectedCompanyItem}>
                                Select Portal
                            </button>
                        </div>
                    ) : <p>No companies found</p>}
                </div>
            )}
        </div>
    );
};

export default CompanyList;
