import React from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi2';

const KnowledgeSetTopBar = (props) => {
    return (
        <div className='index_top_bar'>
            <div className='index_top_bar__button_group'>
                <div className='index_top_bar_title'><strong>Manage Knowledge Set</strong></div>
            </div>
            <div className='index_top_bar__button_group'>
                <button className='index_top_bar__button' onClick={props.handleInfoSidebarTrigger}><HiOutlineInformationCircle/></button>
            </div>
        </div>
    );
}

export default KnowledgeSetTopBar;
