import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextArea from 'react-textarea-autosize';
import MessageMedia from "../Chats/MessageMedia";
import ChatMediaViewer from '../Chats/ChatMediaViewer';
import ChatMediaPreview from '../Chats/ChatMediaPreview';
import { isVideo, isImage } from '../../services/upload';
import { HiOutlinePlusCircle, HiOutlineCodeBracket, HiOutlineXCircle } from 'react-icons/hi2';
import { uploadMedia } from '../../services/upload';
import Preloader from '../Preloader';
import DynamicFieldModal from './DynamicFieldModal';
import EmojiPicker from '../Base/EmojiPicker';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const EditQuickReplyModal = ({ isOpen, toggleEdit, quickReply, auth, saveQuickReply }) => {
    const [state, setState] = useState({
        quickReply,
        message_data: {
            text: '',
            media: [],
            ...quickReply.message_data
        },
        private: quickReply.private ? true : false,
        savingQuickReply: false,
        mediaItem: undefined,
        mediaFile: undefined,
        mediaPreview: undefined,
        loadingMediaPreview: false,
        hasMedia: false,
        dynamicFieldModalOpen: false
    });

    const mediaUploadElement = useRef(null);

    const handleMediaClick = (media) => {
        setState((prevState) => ({ ...prevState, mediaItem: media }));
        if (state.mediaItem && (!isVideo(state.mediaItem.type) && !isImage(state.mediaItem.type))) {
            window.open(media.url, "_blank");
        }
    };

    const handleMediaTrigger = (input) => {
        input.click();
    };

    const handleMediaChange = (e) => {
        setState((prevState) => ({ ...prevState, hasMedia: true, loadingMediaPreview: true }));
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setState((prevState) => ({ ...prevState, mediaFile: file }));
            const reader = new FileReader();
            reader.onload = (e) => {
                setState((prevState) => ({ ...prevState, mediaPreview: e.target.result, loadingMediaPreview: false }));
            };

            reader.readAsDataURL(file);
        }
    };

    const handlePrivateChange = (e) => {
        setState((prevState) => ({ ...prevState, private: e.target.value === "private" ? true : false }));
    };

    const handleTextChange = (e) => {
        setState((prevState) => ({ ...prevState, message_data: { ...prevState.message_data, text: e.target.value } }));
    };

    const handleEmojiPick = ({ emoji }) => {
        setState((prevState) => ({ ...prevState, message_data: { ...prevState.message_data, text: prevState.message_data.text + emoji } }));
    };

    const handleSaveQuickReply = async () => {
        setState((prevState) => ({ ...prevState, savingQuickReply: true }));
        let media = null;
        if (state.mediaFile && !state.mediaFile.url) {
            const uploadResponse = await uploadMedia(auth.selectedChatbot.token, state.mediaFile);
            if (uploadResponse.url) {
                media = {
                    url: uploadResponse.url,
                    name: state.mediaFile.name,
                    type: state.mediaFile.type
                };
            }
        }

        let messageData = state.message_data;

        if (media) {
            messageData.media = [media];
        }

        await saveQuickReply(state.quickReply._id, { private: state.private, message_data: messageData });
        toggleEdit();
    };

    const handleDyanmicMenuToggle = () => {
        setState((prevState) => ({ ...prevState, dynamicFieldModalOpen: !prevState.dynamicFieldModalOpen }));
    };

    const insertDynamicField = (userType, fieldName) => {
        const dynamicFieldString = `{{${userType}[${fieldName.toLowerCase().trim()}]}}`;
        setState((prevState) => ({
            ...prevState,
            dynamicFieldModalOpen: false,
            message_data: {
                ...prevState.message_data,
                text: prevState.message_data.text + dynamicFieldString
            }
        }));
    };

    const handleRemoveMedia = () => {
        setState((prevState) => ({
            ...prevState,
            message_data: {
                ...prevState.message_data,
                media: []
            }
        }));
    };

    useEffect(() => {
        setState((prevState) => ({ ...prevState, quickReply }));
    }, [quickReply]);

    return (
        <Modal ariaHideApp={false} isOpen={isOpen} className="action_modal modal__preloader" onRequestClose={toggleEdit}>
            {!state.savingQuickReply ? (
                <div>
                    <div className="modal__title px-medium">{!state.quickReply._id ? 'New' : 'Edit'} Quick Reply</div>
                    <div className="container">
                        <div className="col-9 px-medium mb-medium">
                            {['admin', 'manager'].includes(auth.agent.agent.role) && (
                                <FormControl fullWidth sx={{ mb: '1rem'}}>
                                    <InputLabel id="demo-simple-select-label">Privacy</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.private ? 'private' : 'public'}
                                        label="Privacy"
                                        onChange={handlePrivateChange}
                                    >
                                        <MenuItem value="private">Private (Can only be used by you)</MenuItem>
                                        <MenuItem value="public">Public (All agents can use this)</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            <div className="modal__subtitle">Content</div>
                            <button className="btn mb-medium" onClick={handleDyanmicMenuToggle}><HiOutlineCodeBracket/>Insert Dynamic Field</button>
                            <div className="flex-middle">
                                <TextArea placeholder="Enter your quick reply" minRows="3" onChange={handleTextChange} value={state.message_data.text}/>
                                <EmojiPicker handleEmojiPick={handleEmojiPick}/>
                            </div>
                        </div>
                        <div className="col-3 px-medium mb-medium">
                            <div className="modal__subtitle">File Attachment</div>
                            {(!state.hasMedia && !state.mediaPreview && state.message_data.media.length > 0) && <button className="btn mb-medium" onClick={handleRemoveMedia}><HiOutlineXCircle/>Remove Media</button>}
                            {(!state.hasMedia && !state.mediaPreview) && state.message_data.media.length > 0 ? <MessageMedia thumbnail={true} media={state.message_data.media[0]} handleMediaClick={handleMediaClick}/> : <button className="btn" onClick={() => { handleMediaTrigger(mediaUploadElement.current) }}><HiOutlinePlusCircle/>{state.mediaFile ? 'Change' : 'Add'} Media</button>}
                            {(state.hasMedia && state.mediaPreview) && <ChatMediaPreview handleRemoveMedia={handleRemoveMedia} loadingMediaPreview={state.loadingMediaPreview} mediaPreview={state.mediaPreview} mediaFile={state.mediaFile}/>}
                            <input ref={mediaUploadElement} type="file" style={{display: 'none'}} onChange={handleMediaChange}/>
                        </div>
                    </div>
                    <div className="modal__actions">
                        <button className="btn" onClick={toggleEdit}>Cancel</button>
                        <button className="btn" onClick={handleSaveQuickReply}>Save</button>
                    </div>
                    {state.mediaItem && (isVideo(state.mediaItem.type) || isImage(state.mediaItem.type)) && <ChatMediaViewer media={state.mediaItem} handleMediaClick={handleMediaClick}/>}
                    <DynamicFieldModal isOpen={state.dynamicFieldModalOpen} insertDynamicField={insertDynamicField} handleDyanmicMenuToggle={handleDyanmicMenuToggle}/>
                </div>
            ) : <Preloader text="Saving quick reply" padding="100px 0"/>}
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditQuickReplyModal);
