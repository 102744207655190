import React from 'react';
import { connect } from 'react-redux';
import { setUsersWaiting, connectAgent } from '../../services/meetings';
import ChatMessageAvater from '../Chats/ChatMessageAvater';
import IncomingMeetingActions from './IncomingMeetingActions';
import { history } from '../../helpers/history';

class IncomingMeeting extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            meeting: this.props.meeting
        };

        this.acceptCall = this.acceptCall.bind(this);
        this.declineCall = this.declineCall.bind(this);
    }

    async acceptCall(withVideo){
        if(history.location.pathname !== '/chats'){
            history.push('/chats');
        }

        await connectAgent(this.state.meeting._id, this.props.auth.agent.agent._id)
        await setUsersWaiting(this.state.meeting._id, false);
        await this.props.dispatch({
            type: 'SET_MEETINGS',
            meetings: {
                currentMeeting: this.state.meeting
            }
        });
    }

    async declineCall(){
        await setUsersWaiting(this.state.meeting._id, false);
        await this.props.dispatch({
            type: 'SET_MEETINGS',
            meetings: {
                currentMeeting: undefined
            }
        });
    }

    render(){
        return (
            <div className="incoming_meeting">
                <div className="incoming_meeting__label">Incoming Call</div>
                <div className="incoming_meeting_details">
                    <div className="incoming_meeting_action_container">
                        <div className="incoming_meeting__username">{this.state.meeting.conversation.user.user_info && this.state.meeting.conversation.user.user_info.name ? this.state.meeting.conversation.user.user_info.name : this.state.meeting.conversation.user.user_id}</div>
                        <IncomingMeetingActions currentMeeting={this.props.meetings.currentMeeting} onAcceptCall={this.acceptCall} onDeclineCall={this.declineCall}/>
                    </div>
                    <ChatMessageAvater conversation={this.state.meeting.conversation} message={{ sender: 'user' }}/>
                </div>
                {this.props.meetings.currentMeeting && <div className="mt-medium timestamp">Disconnect current call to answer this one.</div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(IncomingMeeting);