import React from "react";
import { utils } from '../../services/communicationSevices';

export default class RemoteParticipantCard extends React.Component {
    constructor(props) {
        super(props);
        this.call = props.call;
        this.remoteParticipant = props.remoteParticipant;
        this.id = utils.getIdentifierText(this.remoteParticipant.identifier);

        this.state = {
            isSpeaking: this.remoteParticipant.isSpeaking,
            state: this.remoteParticipant.state,
            isMuted: this.remoteParticipant.isMuted
        };
    }

    async componentWillMount() {
        this.remoteParticipant.on('isMutedChanged', () => {
            this.setState({ isMuted: this.remoteParticipant.isMuted })
        });

        this.remoteParticipant.on('stateChanged', () => {
            this.setState({ state: this.remoteParticipant.state })
        });

        this.remoteParticipant.on('isSpeakingChanged', () => {
            this.setState({ isSpeaking: this.remoteParticipant.isSpeaking });
        })
    }

    componentWillUnmount(){
        this.remoteParticipant.off('isMutedChanged', () => {});

        this.remoteParticipant.off('stateChanged', () => {});

        this.remoteParticipant.off('isSpeakingChanged', () => {})
    }

    handleRemoveParticipant(e, identifier) {
        e.preventDefault();
        this.call.removeParticipant(identifier).catch((e) => console.error(e))
    }

    render() {
        return (
            <li className={this.state.isSpeaking ? `speaking-border-for-initials` : ``} key={utils.getIdentifierText(this.remoteParticipant.identifier)}>
                <div className="details">
                    <div className="text">{this.remoteParticipant.displayName}</div>
                    <div className="state">{this.state.state}</div>
                </div>
                <div className="statuses">
                    <div className="muted">{ this.state.isMuted && 'Muted' }</div>                    
                    <div onClick={e => this.handleRemoveParticipant(e, this.remoteParticipant.identifier)} className="participant-remove float-right ml-3">Remove participant</div>

                </div>
            </li>
        )
    }
}



