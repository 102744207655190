import { handleResponse } from './misc';

function updateAgent(authToken, userId, updates){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(updates)
    };
    return fetch('/api/users/' + userId + '/info', requestOptions).then(handleResponse).then((user) => {
        return user;
    });
}

const getUsers = (authToken, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            queryString += value && value !== '' ? key + '=' + value + '&' : '';
        }
    }
    return fetch('/api/users' + queryString, requestOptions).then(handleResponse).then((users) => {
        return users;
    });
}


const getLeads = (authToken, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = Object.keys(value).map(function(k){return value[k]}).join(",");
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/leads' + queryString, requestOptions).then(handleResponse).then((users) => {
        return users;
    });
}

const getObjectTitles = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/leads/objectFilters', requestOptions).then(handleResponse).then((users) => {
        return users;
    });
}

const getUserInfoFilters = (authToken, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/users/userInfoFilters', requestOptions).then(handleResponse).then((filters) => {
        return filters;
    });
}

const exportLeads = (authToken, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    if(filters){
        queryString += '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/leads/export' + queryString, requestOptions).then((leads) => {
        return leads;
    });
}

const exportLeadInfo = (authToken, id, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    if(filters){
        queryString += '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }
    
    return fetch('/api/leads/export/' + id + queryString, requestOptions).then((leads) => {
        return leads;
    });
}

const searchLeads = (authToken, limit, searchTerm, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';
    if(filters){
        queryString += limit ? '&' : '?';
        for (const [key, value] of Object.entries(filters)) {
            let valueFormatted = value;
            if(key === 'objectFilters'){
                valueFormatted = value.join();
            }
            queryString += valueFormatted && valueFormatted !== '' ? key + '=' + valueFormatted + '&' : '';
        }
    }

    return fetch('/api/leads/search/' + searchTerm + queryString, requestOptions).then(handleResponse).then((users) => {
        return users;
    });
}

export { updateAgent, getUsers, getLeads, searchLeads, exportLeads, getObjectTitles, exportLeadInfo, getUserInfoFilters };