import { Handle, Position } from 'reactflow';

const HiddenNode = () => {
    return (
        <div className="step_node hidden">
            <Handle type="target" position={Position.Top} isConnectable={false} />
        </div>
    )
}

export default HiddenNode;