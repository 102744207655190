import { handleResponse } from "../misc";

const getSteps = (authToken, eduBotId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/edubot/' + eduBotId + '/steps', requestOptions).then(handleResponse).then((steps) => {
        return steps;
    });
}

const saveSteps = (authToken, modifiedSteps) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(modifiedSteps)
    };

    return fetch('/api/steps/save', requestOptions).then(handleResponse);
}

const deleteSteps = (authToken, steps) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(steps)
    };

    return fetch('/api/steps', requestOptions).then(handleResponse);
}

export {
    getSteps,
    saveSteps,
    deleteSteps
}