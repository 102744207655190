import { HiVideoCamera } from 'react-icons/hi2';
import { ImPhoneHangUp, ImPhone } from 'react-icons/im';

const IncomingMeetingActions = (props) => {
    return (
        <div className="incoming_meeting__actions">
            <button className="incoming_meeting__action" disabled={props.currentMeeting} onClick={() => { props.onAcceptCall(true) }}><HiVideoCamera/></button>
            <button className="incoming_meeting__action" disabled={props.currentMeeting} onClick={() => { props.onAcceptCall(false) }}><ImPhone/></button>
            <button className="incoming_meeting__action decline" disabled={props.currentMeeting} onClick={props.onDeclineCall}><ImPhoneHangUp/></button>
        </div>
    )
};

export default IncomingMeetingActions;