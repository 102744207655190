import { handleResponse, serialize } from './misc';

const getUnansweredQuestions = (authToken, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    queryString += filters ? '&' + serialize(filters) : '';

    return fetch('/api/unansweredquestions' + queryString, requestOptions).then(handleResponse).then((unanswerdQuestions) => {
        return unanswerdQuestions;
    });
}

const deleteUnansweredQuestions = (authToken, filters) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = '?limit=20';

    queryString += filters ? '&' + serialize(filters) : '';

    return fetch('/api/unansweredquestions' + queryString, requestOptions).then(handleResponse).then((unanswerdQuestions) => {
        return unanswerdQuestions;
    });
}

const getUnansweredQuestionCount = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/unansweredquestions/count', requestOptions).then(handleResponse).then((unanswerdQuestions) => {
        return unanswerdQuestions;
    });
}

const exportUnansweredQuestions = (authToken, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '?';

    queryString += filters ? '&' + serialize(filters) : '';
    
    return fetch('/api/unansweredquestions/export' + queryString, requestOptions).then((unanswerdQuestions) => {
        return unanswerdQuestions;
    });
}

const updateQuestion = (authToken, questionId, body) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/unansweredquestions/' + questionId, requestOptions).then(handleResponse).then((unanswerdQuestion) => {
        return unanswerdQuestion;
    });
}


export {
    getUnansweredQuestions,
    exportUnansweredQuestions,
    updateQuestion,
    deleteUnansweredQuestions,
    getUnansweredQuestionCount
};