import { TextField, TextareaAutosize } from "@mui/material";
import { titleCase } from "../../../services/misc";

const StepConfigPreviewValue = ({ value }) => {
    switch(typeof(value)){
        case 'string':
             return <TextareaAutosize value={value} size="small" disabled/>;
        case 'boolean':
            return <div>{value ? 'Yes' : 'No'}</div>
        case 'number':
            return <TextField type="number" value={value} size="small" disabled fullWidth/>
        default:
            return <div></div>
    }
}

const StepConfigPreview = ({ step }) => {
    const { config } = step;
    return (
        <div className="step_config_preview">
            {config && Object.entries(config).map(([field, value]) => (
                <div key={field} className="step_config_preview__item">
                    <label>{titleCase(field)}</label>
                    <div><StepConfigPreviewValue value={value}/></div>
                </div>
            ))}
        </div>
    )
}

export default StepConfigPreview;