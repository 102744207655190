import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { HiOutlineXMark, HiOutlinePencilSquare } from 'react-icons/hi2';
import InternalChatSearch from './InternalChatSearch';
import Preloader from '../Preloader';
import InternalChatsList from './InternalChatsList';
import InternalChatRoom from './InternalChatRoom';
import { setInternalChats } from '../../services/internalChats';
import InternalChatNewChat from './InternalChatNewChat';
import InternalChatSearchResults from './InternalChatSearchResults';
import Tooltip from '@mui/material/Tooltip';
import { deselectChat, setLoadingChats, setSelectedChat, updateInternalChats } from '../../reducers/internalChatsSlice';

const InternalChatWidget = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [newChatOpen, setNewChatOpen] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const auth = useSelector((state) => state.auth);
    const internalChats = useSelector((state) => state.internalChats);
    const dispatch = useDispatch();

    const handleSelectChat = (chat) => {
        dispatch(setSelectedChat(chat));
        setNewChatOpen(false);
    };

    const handleDeselectChat = () => {
        dispatch(deselectChat());
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleNewChatClick = () => {
        setNewChatOpen((prevNewChatOpen) => !prevNewChatOpen);
    };

    const handleSearchSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        if (searchTerm.trim().length > 0) {
            setIsSearched(searchTerm);
        } else {
            clearSearch();
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        setIsSearched('');
        dispatch(setLoadingChats(true));
        setInternalChats(internalChats, auth, dispatch);
    };

    const handleLoadMore = async () => {
        await dispatch(
            updateInternalChats({
                loadingMore: true,
                internalChatsLimit:
                    internalChats.internalChatsLimit +
                    internalChats.internalChatsOffset,
            })
        );
        if (searchTerm.trim().length > 0) {
            handleSearchSubmit();
        } else {
            await setInternalChats(internalChats, auth, dispatch);
        }
        await dispatch(updateInternalChats({ loadingMore: false }));
    };

    return (
        <div className="internal_chat_widget">
            {!internalChats.selectedChat ||
            (internalChats.selectedChat &&
                !(
                    internalChats.selectedChat.chat &&
                    internalChats.selectedChat.chat.participants
                )) ? (
                <div className="internal_chat_widget__menu">
                    {!newChatOpen ? (
                        <div className="flex_grow_container">
                            <div className="internal_chat_widget__title">
                                Chats{' '}
                                <div
                                    className="internal_chat_widget__close_chat"
                                    onClick={props.handleToggleChat}
                                >
                                    <HiOutlineXMark />
                                </div>
                            </div>
                            <div className="internal_chat_top_bar">
                                <InternalChatSearch
                                    searchTerm={searchTerm}
                                    clearSearch={clearSearch}
                                    handleSearchSubmit={handleSearchSubmit}
                                    handleSearchChange={handleSearchChange}
                                />
                                <div className="internal_chat_top_bar__actions">
                                    <Tooltip title="New Chat" arrow>
                                        <div
                                            className="internal_chat_top_bar__action"
                                            onClick={handleNewChatClick}
                                        >
                                            <HiOutlinePencilSquare />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="internal_chat_widget__content">
                                {!isSearched ? (
                                    !internalChats.loadingChats ? (
                                        <InternalChatsList
                                            handleNewChatClick={handleNewChatClick}
                                            handleSelectChat={handleSelectChat}
                                            handleLoadMore={handleLoadMore}
                                        />
                                    ) : (
                                        <Preloader />
                                    )
                                ) : (
                                    <InternalChatSearchResults searchTerm={isSearched} />
                                )}
                            </div>
                        </div>
                    ) : (
                        <InternalChatNewChat
                            handleSelectChat={handleSelectChat}
                            handleNewChatClick={handleNewChatClick}
                            handleToggleChat={props.handleToggleChat}
                        />
                    )}
                </div>
            ) : (
                <InternalChatRoom
                    handleToggleChat={props.handleToggleChat}
                    handleDeselectChat={handleDeselectChat}
                />
            )}
        </div>
    );
};

export default InternalChatWidget;