import React from 'react';
import { toTimestamp } from '../../services/dates';
import Tooltip from '@mui/material/Tooltip';
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi2';
import { ProgressLoader } from '../Base/ProgressLoader';
import AlertBox from '../AlertBox';

const KnowledgeSetInfo = (props) => {
    let title = '';
    let knowledgeSetString = '';
    switch(props.knowledgeSet.latest_version.state.toLowerCase()){
        case 'uat':
            title = 'Testing Enviroment';
            knowledgeSetString = 'Your knowledge set is now in the testing enviroment. You can test it in the chatbot simulator. When you are ready to publish, click the publish button.';
            break;
        case 'unchanged':
            title = 'Published'
            knowledgeSetString = 'Your knowledge set is published.';
            break;
        case 'draft':
            title = 'Changes pending'
            knowledgeSetString = 'You have unsaved changes to your knowledge set. Save your changes in order to start testing and publish.';
            break;
        case 'published':
            title = 'Published'
            knowledgeSetString = 'Published';
            break;
        default:
            knowledgeSetString = 'Unknown';
    }

    return (
        <div className='knowledge_set_info'>
            <div className='knowledge_set_info__title'>{title}</div>
            <div className='knowledge_set_info__body'>{knowledgeSetString}</div>
            {props.knowledgeSet.previous_version && (
                <div className='knowledge_set_info__previous_version'>
                    <div className='knowledge_set_info__previous_version__title'><HiOutlineCheckCircle/>Your knowledge set is deployed</div>
                    <div className='knowledge_set_info_previous_version_stats'>
                        <div className='knowledge_set_info_previous_version_stat'>
                            <div className='knowledge_set_info_previous_version_stat__title'>Deployed version:</div>
                            <div className='knowledge_set_info_previous_version_stat__value'>{props.knowledgeSet.previous_version.version_number}</div>
                        </div>
                        <div className='knowledge_set_info_previous_version_stat'>
                            <div className='knowledge_set_info_previous_version_stat__title'>Published at:</div>
                            <div className='knowledge_set_info_previous_version_stat__value'>{toTimestamp(props.knowledgeSet.previous_version.published_at)}</div>
                        </div>
                    </div>
                </div>
            )}
            {props.knowledgeSet.latest_job && ['in_progress'].includes(props.knowledgeSet.latest_job.status) ? (
                <div className='knowledge_set_info__previous_version'>
                    <div className='knowledge_set_info_previous_version_stats'>
                        {props.knowledgeSet.latest_job.description && <label className='mb-medium'>{props.knowledgeSet.latest_job.description}</label>}
                        <ProgressLoader description={props.knowledgeSet.latest_job.status === 'in_progress' ? 'In progress' : (props.knowledgeSet.latest_job.status === 'completed' ? <>Completed</> : 'Failed')} progress={props.knowledgeSet.latest_job.progress} />
                    </div>
                </div>
            ) : (props.knowledgeSet.latest_job && ['failed'].includes(props.knowledgeSet.latest_job.status) ? (
                <div className='knowledge_set_info__previous_version'>
                        <AlertBox type="error">Failed</AlertBox>
                </div>
            ) : '')}
            {props.knowledgeSet.latest_version.state.toLowerCase() === 'uat' && (
                (
                    <div className='knowledge_set_info__previous_version'>
                        <AlertBox type="success">Saved</AlertBox>
                    </div>
                )
            )}
            {!(props.knowledgeSet.latest_job && ['in_progress'].includes(props.knowledgeSet.latest_job.status)) && (
                <div className='knowledge_set_info__actions'>
                    <>
                    {props.knowledgeSet.latest_version.state.toLowerCase()  === 'draft' && (<button className="btn" onClick={props.saveToUat}>Save changes</button>)}
                    <Tooltip title={props.knowledgeSet.latest_version.state.toLowerCase() === 'uat' ? 'Publish changes' : 'Changes must be saved in order to publish.'} arrow><button className="btn primary" disabled={props.knowledgeSet.latest_version.state.toLowerCase() !== 'uat'} onClick={() => { props.handlePublishKnowledgeSetClick(true) }}>{(['unchanged', 'published'].includes(props.knowledgeSet.latest_version.state.toLowerCase()) && props.knowledgeSet.previous_version) ? <>Published<HiOutlineCheckCircle/></> : <>Publish</>}</button></Tooltip>
                    </>
                    {props.knowledgeSet.latest_version.state.toLowerCase()  === 'published' && (<div>Published at {props.knowledgeSet.latest_version.published_at ? toTimestamp(props.knowledgeSet.latest_version.published_at) : ""}</div>)}
                </div>     
            )}       
        </div>
    );
}

export default KnowledgeSetInfo;
