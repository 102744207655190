import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import { getLeads, searchLeads, exportLeads } from '../../services/user';
import LeadInfoModal from './LeadInfoModal';
import moment from 'moment';
import { HiEyeSlash, HiOutlineFunnel } from 'react-icons/hi2';
import { toTimestamp } from '../../services/dates';
import LoadMore from '../Base/LoadMore';
import validator from 'validator';
import { getQueues } from '../../services/queues';
import socket from '../../services/socket';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Autocomplete, Box, Divider, TextField } from '@mui/material';

const LeadsPage = () => {
    const auth = useSelector((state) => state.auth);
    const [users, setUsers] = useState([]);
    const [queues, setQueues] = useState(undefined);
    const [loadingQueues, setLoadingQueues] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingObjectFilters, setLoadingObjectFilters] = useState(true);
    const [usersLimit, setUsersLimit] = useState(25);
    const [usersOffset, setUsersOffset] = useState(25);
    const [hasMore, setHasMore] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearched, setIsSearched] = useState(false);
    const [filters, setFilters] = useState({
        startDate: moment().subtract(1, 'months'),
        endDate: moment(),
        orderBy: 'desc'
    });
    const [objectFilterOptions, setObjectFilterOptions] = useState([]);
    const [exporting, setExporting] = useState(false);
    const [mobileFiltersShown, setMobileFiltersShown] = useState(false);

    const filtersRef = useRef(filters);
    const usersLimitRef = useRef(usersLimit);
    const searchTermRef = useRef(searchTerm);

    const orderByOptions = [
        { value: 'desc', label: 'Newest to oldest' },
        { value: 'asc', label: 'Oldest to newest' }
    ];

    const handleSetUsers = async () => {
        let users = [];
        if (searchTerm.trim().length > 0) {
            users = await searchLeads(auth.selectedChatbot.token, usersLimitRef.current + 1, searchTermRef.current, filtersRef.current);
        } else {
            users = await getLeads(auth.selectedChatbot.token, usersLimitRef.current + 1, filtersRef.current);
        }
        setLoadingUsers(false);
        setHasMore(users.length > usersLimit ? true : false);

        if (users.length > usersLimit) {
            users.pop();
        }

        setUsers(users);
    };

    const handleAllInfoTrigger = (user) => {
        setSelectedUser(user);
    };

    const handleLoadMore = () => {
        setUsersLimit((prevLimit) => prevLimit + usersOffset);
        setLoadingMore(true);
    };

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClearSearch = () => {
        setLoadingUsers(true);
        setSearchTerm('');
        setIsSearched(false);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setLoadingUsers(true);
        if (searchTerm.trim().length > 0) {
            setIsSearched(true);
        } else {
            setIsSearched(false);
        }
    };

    const handleFilterChange = (fieldName, value) => {
        setLoadingUsers(true);
        setFilters((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const handleExport = async () => {
        setExporting(true);
        const response = await exportLeads(auth.selectedChatbot.token, filters);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Leads.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        setExporting(false);
    };

    const handleFiltersTrigger = () => {
        setMobileFiltersShown((prevState) => !prevState);
    };

    useEffect(() => {
        filtersRef.current = filters;
        usersLimitRef.current = usersLimit;
        searchTermRef.current = searchTerm;

        const fetchData = async () => {
            await handleSetUsers();
            setLoadingMore(false);
        };

        fetchData();
    }, [usersLimit, searchTerm, filters]);

    useEffect(() => {
        const fetchQueues = async () => {
            const queues = await getQueues(auth.selectedChatbot.token, auth.agent.agent._id);
            setQueues(queues);
            setLoadingQueues(false);
        };

        fetchQueues();
    }, []);

    useEffect(() => {
        const handleUsersUpdate = ({ id, conversationId, userId }) => {
            if (auth.selectedChatbot._id === id) {
                handleSetUsers();
            }
        };

        socket.on('usersUpdate', handleUsersUpdate);

        return () => {
            socket.off('usersUpdate', handleUsersUpdate);
        };
    }, []);

    return (
        <div className="container column">
            <PageContainer>
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">Leads</div>
                        {mobileFiltersShown ? (
                            <button className="btn mobile_filter_trigger" onClick={handleFiltersTrigger}>
                                <HiEyeSlash />Hide Filters
                            </button>
                        ) : (
                            <button className="btn mobile_filter_trigger" onClick={handleFiltersTrigger}>
                                <HiOutlineFunnel />Filters
                            </button>
                        )}
                        <div className={`leads_top_bar ${mobileFiltersShown ? 'mobile_filters_shown' : ''}`}>
                            <div className="filters_column">
                                <div className="filters">
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Box sx={{ width: "100%", mb: '15px', display: 'flex', maxWidth: '420px', alignItems: 'center' }}>
                                                <DatePicker
                                                    label="Start Date"
                                                    value={filters.startDate ? moment(filters.startDate) : null}
                                                    disableFuture={true}
                                                    format='DD/MM/YYYY'
                                                    maxDate={filters.endDate ? moment(filters.endDate) : null}
                                                    sx={{ width: '100%' }}
                                                    
                                                    onChange={(newValue) => {
                                                        handleFilterChange('startDate', newValue ? newValue.format('YYYY-MM-DD') : undefined);
                                                    }}
                                                    slotProps={{
                                                        field: { size: 'small', clearable: true, onClear: () => {
                                                            handleFilterChange('startDate', undefined);
                                                        } },
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                                <Divider sx={{ minWidth: '20px', margin: '0px 8px'}} />
                                                <DatePicker
                                                    label="End Date"
                                                    value={filters.endDate ? moment(filters.endDate) : null}
                                                    disableFuture={true}
                                                    format='DD/MM/YYYY'
                                                    minDate={filters.startDate ? moment(filters.startDate) : null}
                                                    sx={{ width: '100%' }}
                                                    onChange={(newValue) => {
                                                        handleFilterChange('endDate', newValue ? newValue.endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined);
                                                    }}
                                                    slotProps={{
                                                        field: { size: 'small', clearable: true, onClear: () => {
                                                            handleFilterChange('endDate', undefined);
                                                        } },
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="top_bar_filter">
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            options={orderByOptions}
                                            defaultValue={orderByOptions[0]}
                                            disableClearable
                                            getOptionLabel={(option) => option.label}
                                            value={orderByOptions.find(filterOption => filters.orderBy === filterOption.value)}
                                            onChange={(event, newValue) => { handleFilterChange('orderBy', newValue?.value) }}
                                            renderInput={(params) => <TextField {...params} label="Order by" autoFocus />}
                                        />
                                    </div>
                                    {auth.agent.agent.role === 'admin' && (
                                        <div className="top_bar_filter">
                                            <Autocomplete
                                                size='small'
                                                disablePortal
                                                options={queues && queues.map((queueItem) => ({ value: queueItem._id, label: queueItem.name }))}
                                                classNamePrefix="custom_select"
                                                className="filter form_select"
                                                isClearable={true}
                                                isLoading={loadingQueues}
                                                onChange={(event, newValue) => {
                                                    handleFilterChange('queue', newValue?.value);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Queue" autoFocus />}
                                            />
                                        </div>
                                    )}
                                </div>
                                {1 == 2 && (
                                    <div className="filters">
                                        <div className="top_bar_filter" style={{ width: "100%"}}>
                                            <label>Filter</label>
                                            <Autocomplete
                                                disablePortal
                                                isLoading={loadingObjectFilters}
                                                onChange={(e) => {
                                                    handleFilterChange('objectFilters', e.map((item) => item.value));
                                                }}
                                                classNamePrefix="custom_select"
                                                className="flex-growform_select"
                                                isMulti
                                                options={objectFilterOptions}
                                                renderInput={(params) => <TextField {...params} label="Filter" autoFocus />}
                                            />
                                            <button className="btn mb-medium mt-medium" disabled={exporting} onClick={handleExport}>
                                                {!exporting ? 'Export' : <Preloader padding="0" size="15px" color="white" />}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div className="filters">
                                    <div className="top_bar_filter" style={{ width: '100%' }}>
                                        <button className="btn mb-medium mt-medium" disabled={exporting} onClick={handleExport}>
                                            {!exporting ? 'Export' : <Preloader padding="0" size="15px" color="white" />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="search-container">
                                <label>Search leads</label>
                                <form className="help_request_search" onSubmit={handleSearchSubmit}>
                                    <input type="text" placeholder="Enter your search term" value={searchTerm} onChange={handleSearchTerm} />
                                    {isSearched && (
                                        <div className="btn" onClick={handleClearSearch}>
                                            <HiOutlineXMark />
                                        </div>
                                    )}
                                    <button className="btn">
                                        <HiMagnifyingGlass />
                                    </button>
                                </form>
                            </div>*/}
                        </div>
                        <div className="leads_list">
                            {!loadingUsers ? (
                                users.length > 0 ? (
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Created At</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user, index) => {
                                                    return (
                                                        user.user_info &&
                                                        (user.user_info.email || user.user_info.phone_number) && (
                                                            <tr key={index}>
                                                                <td>{user.user_info.name ? user.user_info.name : <span className="leads_info_none">Not found</span>}</td>
                                                                <td>
                                                                    {user.user_info.email ? (
                                                                        validator.isEmail(user.user_info.email) ? (
                                                                            <a href={`mailto:${user.user_info.email}`} rel="noreferrer" target="_blank">
                                                                                {user.user_info.email}
                                                                            </a>
                                                                        ) : (
                                                                            user.user_info.email
                                                                        )
                                                                    ) : (
                                                                        <span className="leads_info_none">Not found</span>
                                                                    )}
                                                                </td>
                                                                <td>{user.user_info.phone_number ? user.user_info.phone_number : <span className="leads_info_none">Not found</span>}</td>
                                                                <td>{toTimestamp(user.createdAt)}</td>
                                                                <td>
                                                                    <button className="btn" onClick={() => handleAllInfoTrigger(user)}>
                                                                        View all info
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {hasMore && <div><LoadMore onClick={handleLoadMore} loadingMore={loadingMore} /></div>}
                                    </div>
                                ) : (
                                    <span>No leads found</span>
                                )
                            ) : (
                                <Preloader padding="30px 0" />
                            )}
                        </div>
                    </div>
                </div>
            </PageContainer>
            {selectedUser && <LeadInfoModal isOpen={selectedUser ? true : false} user={selectedUser} onRequestClose={() => setSelectedUser(undefined)} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(LeadsPage);