import { HiOutlineHandThumbUp, HiOutlineHandThumbDown } from "react-icons/hi2";

const QuestionLogStatus = ({ status, showNotReviewed }) => {
    let statusIcon = null;
    let statusText = null;
    switch (status) {
        case 'upvoted':
            statusIcon = <HiOutlineHandThumbUp/>
            statusText = 'Upvoted'
            break;
        case 'downvoted':
            statusIcon = <HiOutlineHandThumbDown/>
            statusText = 'Downvoted'
            break;
        default:
            if(showNotReviewed){
                statusText = 'Not Reviewed'
            }

            break;
    }
    
    return (
        <div className={`question_log_status ${status}`}>
            {statusIcon && <div className="question_log_status_icon">{statusIcon}</div>}
            {statusText && <div className="question_log_status_text">{statusText}</div>}
        </div>
    )
}

export default QuestionLogStatus;