import React from 'react';

const ChatActionButton = (props) => {
    return (
        <button className={'chat_action_button ' + (props.className || '')} onClick={props.onClick} disabled={props.disabled}>
            {props.icon}
            <div className="chat_action_button__label">{props.label}</div>
        </button>
    );
}

export default ChatActionButton;