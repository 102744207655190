import { toTimestamp } from '../../services/dates';

const EventsWidgetItem = (props) => {
    const event = props.event;
    return (
        <div className="events_widget_item">
            <div className="event_widget_title">{event.title}</div>
            <div className="event_widget_body">{event.text}</div>
            <div className="timestamp">{toTimestamp(event.createdAt)}</div>
        </div>
    )
};

export default EventsWidgetItem;