import React from 'react';
import { useSelector } from 'react-redux';
import { HiArrowLeft, HiOutlineInformationCircle, HiPlus, HiDocumentArrowUp, HiFunnel } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';
import IndexSearchBar from './IndexSearchBar';
import IndexFilters from './IndexFilters';

const IndexTopBar = (props) => {
    const auth = useSelector(state => state.auth)

    return (
        <div className='index_top_bar'>
            <div className='index_top_bar__button_group'>
                <NavLink to={`/knowledge-set-manager/${props.index.knowledge_set_id}`}><HiArrowLeft/></NavLink>
                <div className='index_top_bar_title text-truncate'><strong>{props.index.name}</strong></div>
            </div>
            <div className='index_top_bar__search'>
                <IndexSearchBar 
                    searchChunks={props.searchChunks} 
                    searchResults={props.searchResults}
                    clearSearch={props.clearSearch}
                />
            </div>
            <div className='index_top_bar__button_group'>
                {['admin'].includes(auth.agent.agent.role) && <button onClick={props.handleImportDocumentClick} className='index_top_bar__button'><HiDocumentArrowUp /></button>}
                <div style={{ position: 'relative', height: '100%'}}>
                    <button onClick={props.handleFilterToggle} className='index_top_bar__button'>
                        <HiFunnel/>
                    </button>
                    {props.displayFilters && <IndexFilters filters={props.filters} handleFilterChange={props.handleFilterChange} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}/>}
                </div>
                <button onClick={props.handleChunkAddClick} className='index_top_bar__button'><HiPlus/></button>
                <button onClick={props.handleInfoSidebarTrigger} className='index_top_bar__button'><HiOutlineInformationCircle/></button>
            </div>
        </div>
    );
}

export default IndexTopBar;
