import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import StatusText from '../Base/StatusText';

const CreateGroupChatModal = (props) => {
    const [agents, setAgents] = useState(props.agents);
    const [selectedAgents, setSelectedAgents] = useState([]);

    useEffect(() => {
        if (props.agents !== agents) {
            setAgents(props.agents);
        }
    }, [props.agents]);

    const handleAgentSelect = (agent) => {
        const agentSelectedIndex = selectedAgents.findIndex(selectedAgent => selectedAgent._id === agent._id);
        if (agentSelectedIndex > -1) {
            let updatedSelectedAgents = [...selectedAgents];
            updatedSelectedAgents.splice(agentSelectedIndex, 1);
            setSelectedAgents(updatedSelectedAgents);
        } else {
            setSelectedAgents([...selectedAgents, agent]);
        }
    };

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal modal__preloader" onRequestClose={props.onRequestClose}>
            <div>
                <div className="modal__title py-medium">Create Group Chat</div>
                <div className="modal__subtitle">{selectedAgents.length} agent{selectedAgents.length !== 1 ? 's' : ''} selected.</div>
                <div className="internal_create_group_chat_list">
                    {agents.map(agent => {
                        const agentSelected = selectedAgents.find(selectedAgent => selectedAgent._id === agent._id);
                        return (
                            <div key={agent._id} className="internal_agent_list_item" onClick={() => { handleAgentSelect(agent) }}>
                                <div className={`company_list__item__checkbox ${agentSelected ? 'selected' : ''}`}></div>
                                <AgentProfileAvatar size="50" agent={agent} />
                                <div className="internal_agent_list_item__details">
                                    <div className="internal_agent_list_item__details__name text_truncate">{agent.first_name} {agent.last_name}</div>
                                    <StatusText status={agent.agent_settings.status} />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="modal__actions">
                    <button className="btn" onClick={props.onRequestClose}>Cancel</button>
                    <button className="btn" disabled={selectedAgents.length < 2} onClick={() => { props.handleCreateGroupChat(selectedAgents) }}>Create Group Chat</button>
                </div>
            </div>
        </Modal>
    );
};


export default CreateGroupChatModal;
