import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { getChatbot, updateSetting } from '../../services/chatbots';
import { companyService } from '../../services/company';
import Switch from '@mui/material/Switch';
import { TextField } from '@mui/material';
import { setCurrentChatbotSettings } from '../../reducers/authSlice';

class PortalSettings extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingSettings: true,
            settings: this.props.auth.selectedChatbot.settings,
            company: undefined
        };
    }

    async componentDidMount(){
        const chatbot = await getChatbot(this.props.auth.selectedChatbot.token, this.props.auth.selectedChatbot._id);
        const company = await companyService.getCompany(this.props.auth.selectedCompany, this.props.auth.selectedChatbot.token)
        this.props.dispatch(setCurrentChatbotSettings(chatbot.settings));


        this.setState({ loadingSettings: false, company })
    }

    async handleSwitchSettingChage(name, value){
        this.setState({ [name + '_disabled']: true })
        this.props.dispatch(setCurrentChatbotSettings({ [name]: value }));
        this.setState(prevState => ({ settings: { ...prevState.settings, [name]: value }}));
        await updateSetting(this.props.auth.selectedChatbot.token, { [name]: value })
        this.setState({ [name + '_disabled']: false })
    }

    async handleInputSettingChage(name, value){
        this.setState({ [name + '_disabled']: true })
        this.props.dispatch(setCurrentChatbotSettings({ [name]: value }));
        this.setState(prevState => ({ settings: { ...prevState.settings, [name]: value }}));
        this.setState({ [name + '_disabled']: false })
    }

    async handleInputSettingSave(name, value){
        this.setState({ [name + '_disabled']: true })
        await updateSetting(this.props.auth.selectedChatbot.token, { [name]: value })
        this.setState({ [name + '_disabled']: false })
    }

    render(){
        return !this.state.loadingSettings ? (
            <div className="settings_container">
                {['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Human Handoff Enabled</div>
                            <div className="setting_name__body">Allow for users to request human handoff and be routed to agents on the portal. Allow for chat transfers and agent joins.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch disabled={this.state['human_handoff_enabled_disabled']} checked={this.props.auth.selectedChatbot.settings.human_handoff_enabled} onChange={() => { this.handleSwitchSettingChage('human_handoff_enabled', !this.props.auth.selectedChatbot.settings.human_handoff_enabled) }}/>
                        </div>
                    </div>)
                }
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.human_handoff_enabled) &&(
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Allow Chat Join</div>
                            <div className="setting_name__body">Give the ability for users to join conversations without human handoff request.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch disabled={this.state['allow_chat_join_disabled']} checked={this.props.auth.selectedChatbot.settings.allow_chat_join} onChange={() => { this.handleSwitchSettingChage('allow_chat_join', !this.props.auth.selectedChatbot.settings.allow_chat_join) }}/>
                        </div>
                    </div>)
                }
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.human_handoff_enabled && this.props.auth.selectedChatbot.settings.allow_chat_join) &&(
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Allow Request Engagement</div>
                            <div className="setting_name__body">Give the ability for users to request engagement from a user that has not messeged the bot within the session time.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch disabled={this.state['enable_request_engagement_disabled']} checked={this.props.auth.selectedChatbot.settings.enable_request_engagement} onChange={() => { this.handleSwitchSettingChage('enable_request_engagement', !this.props.auth.selectedChatbot.settings.enable_request_engagement) }}/>
                        </div>
                    </div>)
                }
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.human_handoff_enabled && this.props.auth.selectedChatbot.settings.allow_chat_join) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Chat Session Time (Minutes)</div>
                            <div className="setting_name__body">The amount of time allowed to join a users chat after the last message. Please note the max you can make this is 23 hours due to channel limitations.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth type="number" placeholder="Enter your time in minutes" disabled={this.state['chat_session_time_disabled']} onBlur={(e) => { this.handleInputSettingSave('chat_session_time', e.target.value) }} value={this.props.auth.selectedChatbot.settings.chat_session_time} onChange={(e) => { if(e.target.value <= 1380){ this.handleInputSettingChage('chat_session_time', e.target.value) }}}/>
                        </div>
                    </div>)
                }
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role)) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Show All History</div>
                            <div className="setting_name__body">Allow all chats with bot to be shown regardless of whether or not they have requested help from a human agent. Disabling this will make it so agents can only see chats they are connected to.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.show_all_history} disabled={this.state['show_all_history_disabled']} onChange={() => { this.handleSwitchSettingChage('show_all_history', !this.props.auth.selectedChatbot.settings.show_all_history) }}/>
                        </div>
                    </div>)
                }
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role)) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Anonymous Quick Resolve</div>
                            <div className="setting_name__body">Enabling this will hide agents names when quick resolving help requests.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.anonymous_quick_resolve} disabled={this.state['anonymous_quick_resolve_disabled']} onChange={() => { this.handleSwitchSettingChage('anonymous_quick_resolve', !this.props.auth.selectedChatbot.settings.anonymous_quick_resolve) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.anonymous_quick_resolve) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Anonymous Quick Resolve Alias</div>
                            <div className="setting_name__body">The alias that will substitute the agent's first name on quick resolve.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth type="text" placeholder="Enter anonymous quick resolve alias" disabled={this.state['anonymous_quick_resolve_alias_disabled']} onBlur={(e) => { this.handleInputSettingSave('anonymous_quick_resolve_alias', e.target.value) }} value={this.props.auth.selectedChatbot.settings.anonymous_quick_resolve_alias} onChange={(e) => { this.handleInputSettingChage('anonymous_quick_resolve_alias', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {((['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role))) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Enable Search</div>
                            <div className="setting_name__body">Allow for searching of chats.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.enable_search} disabled={this.state['enable_search_disabled']} onChange={() => { this.handleSwitchSettingChage('enable_search', !this.props.auth.selectedChatbot.settings.enable_search) }}/>
                        </div>
                    </div>)
                }
                {((['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role)) && this.props.auth.selectedChatbot.settings.human_handoff_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Enable Video Meetings</div>
                            <div className="setting_name__body">Allow for agents to setup video meetings will chat users and connect face to face with agents.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.enable_video_meetings} disabled={this.state['enable_video_meetings_disabled']} onChange={() => { this.handleSwitchSettingChage('enable_video_meetings', !this.props.auth.selectedChatbot.settings.enable_video_meetings) }}/>
                        </div>
                    </div>)
                }
                {["admin"].includes(this.props.auth.agent.agent.role) && (
					<div className="settings_row">
						<div className="settings_col setting_name">
							<div className="setting_name__name">Help Request Reference Number Prefix</div>
							<div className="setting_name__body">Choose the prefix that will be used for help request reference numbers.</div>
						</div>
						<div className="settings_col setting_value">
							<TextField
								size="small"
								fullWidth
								type="text"
								placeholder="Enter Prefix"
								disabled={this.state["Prefix Disabled"]}
								onBlur={(e) => {
									let value = e.target.value;
									if (!value) {
										value = "CON";
									}
									this.handleInputSettingSave("help_request_reference_prefix", value);
								}}
								value={this.props.auth.selectedChatbot.settings.help_request_reference_prefix}
								onChange={(e) => {
									this.handleInputSettingChage("help_request_reference_prefix", e.target.value);
								}}
							/>
						</div>
					</div>
				)}
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.enable_video_meetings) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">ASC Connection String</div>
                            <div className="setting_name__body">Connection string for Azure Communication Services.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth type="text" placeholder="Enter ASC connection string" disabled={this.state['asc_connection_string_disabled']} onBlur={(e) => { this.handleInputSettingSave('asc_connection_string', e.target.value) }} value={this.props.auth.selectedChatbot.settings.asc_connection_string} onChange={(e) => { this.handleInputSettingChage('asc_connection_string', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.enable_video_meetings) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Meeting Expiry Time (Minutes)</div>
                            <div className="setting_name__body">Time a meeting link will last before expiring</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth type="number" placeholder="Enter your time in minutes" disabled={this.state['meeting_expiry_time_disabled']} onBlur={(e) => { this.handleInputSettingSave('meeting_expiry_time', e.target.value) }} value={this.props.auth.selectedChatbot.settings.meeting_expiry_time} onChange={(e) => { this.handleInputSettingChage('meeting_expiry_time', e.target.value) }}/>
                        </div>
                    </div>)
                }                
                {(['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.enable_video_meetings) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Meeting Page Writeup</div>
                            <div className="setting_name__body">A paragraph of text that will be shown to users before joining a meeting.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your meeting writeup" disabled={this.state['meeting_room_writeup_disabled']} onBlur={(e) => { this.handleInputSettingSave('meeting_room_writeup', e.target.value) }} value={this.props.auth.selectedChatbot.settings.meeting_room_writeup} onChange={(e) => { this.handleInputSettingChage('meeting_room_writeup', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Analytics Embed Code</div>
                            <div className="setting_name__body">Embed code to display Power BI dashboard analytic.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth type="text" placeholder="Enter analytics embed" disabled={this.state['analytics_embed_disabled']} onBlur={(e) => { this.handleInputSettingSave('analytics_embed', e.target.value) }} value={this.props.auth.selectedChatbot.settings.analytics_embed} onChange={(e) => { this.handleInputSettingChage('analytics_embed', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role)) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Infobip Enabled</div>
                            <div className="setting_name__body">Allow for Infobip based methods to be allowed. Only enabled if bot is configured with Infobip.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.infobip_enabled} disabled={this.state['infobip_enabled_disabled']} onChange={() => { this.handleSwitchSettingChage('infobip_enabled', !this.props.auth.selectedChatbot.settings.infobip_enabled) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.infobip_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Infobip API Key</div>
                            <div className="setting_name__body">API key for Verge Connect to authenticate with Infobip.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your Infobip API key" disabled={this.state['infobip_api_key_disabled']} onBlur={(e) => { this.handleInputSettingSave('infobip_api_key', e.target.value) }} value={this.props.auth.selectedChatbot.settings.infobip_api_key} onChange={(e) => { this.handleInputSettingChage('infobip_api_key', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.infobip_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Infobip Endpoint</div>
                            <div className="setting_name__body">Endpoint for Verge AI Connect to authenticate with Infobip.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your Infobip endpoint" disabled={this.state['infobip_endpoint']} onBlur={(e) => { this.handleInputSettingSave('infobip_endpoint', e.target.value) }} value={this.props.auth.selectedChatbot.settings.infobip_endpoint} onChange={(e) => { this.handleInputSettingChage('infobip_endpoint', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.infobip_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Infobip Sender ID</div>
                            <div className="setting_name__body">Sender ID for Verge Connect to authenticate with Infobip.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your Infobip sender ID" disabled={this.state['infobip_sender_id']} onBlur={(e) => { this.handleInputSettingSave('infobip_sender_id', e.target.value) }} value={this.props.auth.selectedChatbot.settings.infobip_sender_id} onChange={(e) => { this.handleInputSettingChage('infobip_sender_id', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Template Sender Enabled</div>
                            <div className="setting_name__body">Enable WhatsApp template sender portal for all managers.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.whatsapp_template_sender_enabled} disabled={this.state['infobip_enabled_disabled']} onChange={() => { this.handleSwitchSettingChage('whatsapp_template_sender_enabled', !this.props.auth.selectedChatbot.settings.whatsapp_template_sender_enabled) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.whatsapp_template_sender_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Template Sender Provider</div>
                            <div className="setting_name__body">Enter WhatsApp API provider</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your provider" disabled={this.state['whatsapp_template_sender_provider']} onBlur={(e) => { this.handleInputSettingSave('whatsapp_template_sender_provider', e.target.value) }} value={this.props.auth.selectedChatbot.settings.whatsapp_template_sender_provider} onChange={(e) => { this.handleInputSettingChage('whatsapp_template_sender_provider', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.whatsapp_template_sender_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Template Allowed Filters</div>
                            <div className="setting_name__body">Enter allowed user info filters for number filtering.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your allowed object filters" disabled={this.state['allowed_object_filters']} onBlur={(e) => { this.handleInputSettingSave('allowed_object_filters', e.target.value) }} value={this.props.auth.selectedChatbot.settings.allowed_object_filters} onChange={(e) => { this.handleInputSettingChage('allowed_object_filters', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.whatsapp_template_sender_provider.toLowerCase() === 'whatsapp_cloud') && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Cloud Business ID</div>
                            <div className="setting_name__body">Enter WhatsApp Business ID</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your WhatsApp Business ID" disabled={this.state['whatsapp_cloud_business_id']} onBlur={(e) => { this.handleInputSettingSave('whatsapp_cloud_business_id', e.target.value) }} value={this.props.auth.selectedChatbot.settings.whatsapp_cloud_business_id} onChange={(e) => { this.handleInputSettingChage('whatsapp_cloud_business_id', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.whatsapp_template_sender_provider.toLowerCase() === 'whatsapp_cloud') && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Cloud Sender ID</div>
                            <div className="setting_name__body">Enter WhatsApp Sender ID</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your WhatsApp Sender ID" disabled={this.state['whatsapp_cloud_sender_id']} onBlur={(e) => { this.handleInputSettingSave('whatsapp_cloud_sender_id', e.target.value) }} value={this.props.auth.selectedChatbot.settings.whatsapp_cloud_sender_id} onChange={(e) => { this.handleInputSettingChage('whatsapp_cloud_sender_id', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.whatsapp_template_sender_provider.toLowerCase() === 'whatsapp_cloud') && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Cloud Endpoint</div>
                            <div className="setting_name__body">Enter WhatsApp Cloud Endpoint</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your WhatsApp Endpoint" disabled={this.state['whatsapp_cloud_endpoint']} onBlur={(e) => { this.handleInputSettingSave('whatsapp_cloud_endpoint', e.target.value) }} value={this.props.auth.selectedChatbot.settings.whatsapp_cloud_endpoint} onChange={(e) => { this.handleInputSettingChage('whatsapp_cloud_endpoint', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role) && this.props.auth.selectedChatbot.settings.whatsapp_template_sender_provider.toLowerCase() === 'whatsapp_cloud') && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">WhatsApp Cloud API Key</div>
                            <div className="setting_name__body">Enter WhatsApp Cloud API Key</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your WhatsApp Business ID" disabled={this.state['whatsapp_cloud_api_key']} onBlur={(e) => { this.handleInputSettingSave('whatsapp_cloud_api_key', e.target.value) }} value={this.props.auth.selectedChatbot.settings.whatsapp_cloud_api_key} onChange={(e) => { this.handleInputSettingChage('whatsapp_cloud_api_key', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Knowledge Set Manager Enabled</div>
                            <div className="setting_name__body">Enable Knowledge Set Manager portal for all managers.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.knowledge_set_manager_enabled} disabled={this.state['knowledge_set_manager_enabled']} onChange={() => { this.handleSwitchSettingChage('knowledge_set_manager_enabled', !this.props.auth.selectedChatbot.settings.knowledge_set_manager_enabled) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role)  && this.props.auth.selectedChatbot.settings.knowledge_set_manager_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Question Log Enabled</div>
                            <div className="setting_name__body">Enable logs for GPT question and answer data.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.question_message_log_enabled} disabled={this.state['question_message_log_enabled']} onChange={() => { this.handleSwitchSettingChage('question_message_log_enabled', !this.props.auth.selectedChatbot.settings.question_message_log_enabled) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role)  && this.props.auth.selectedChatbot.settings.knowledge_set_manager_enabled && this.props.auth.selectedChatbot.settings.question_message_log_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Question Message Log Viewable By Users</div>
                            <div className="setting_name__body">Allow users to view and manage question log feedback.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.question_message_log_viewable_by_users} disabled={this.state['question_message_log_viewable_by_users']} onChange={() => { this.handleSwitchSettingChage('question_message_log_viewable_by_users', !this.props.auth.selectedChatbot.settings.question_message_log_viewable_by_users) }}/>
                        </div>
                    </div>)
                }
                {(['admin'].includes(this.props.auth.agent.agent.role)  && this.props.auth.selectedChatbot.settings.knowledge_set_manager_enabled && this.props.auth.selectedChatbot.settings.question_message_log_enabled) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Question Log Feedback Knowledge Set</div>
                            <div className="setting_name__body">Set the knowledge set for feedback revisions to be inserted into.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <TextField size='small' fullWidth multiline placeholder="Enter your Question Log Feedback Knowledge Set ID" disabled={this.state['question_message_log_feedback_knowledge_set']} onBlur={(e) => { this.handleInputSettingSave('question_message_log_feedback_knowledge_set', e.target.value) }} value={this.props.auth.selectedChatbot.settings.question_message_log_feedback_knowledge_set} onChange={(e) => { this.handleInputSettingChage('question_message_log_feedback_knowledge_set', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">EduBot Backend Enabled</div>
                            <div className="setting_name__body">Enable EduBot backened for all managers.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <Switch checked={this.props.auth.selectedChatbot.settings.edubot_backend_enabled} disabled={this.state['edubot_backend_enabled']} onChange={() => { this.handleSwitchSettingChage('edubot_backend_enabled', !this.props.auth.selectedChatbot.settings.edubot_backend_enabled) }}/>
                        </div>
                    </div>)
                }
            </div>
        ) : <Preloader/>
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(PortalSettings);