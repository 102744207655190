import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Preloader from '../Preloader';
import { companyService } from '../../services/company';
import { HiOutlinePencil, HiTrash, HiPlus } from 'react-icons/hi2';
import Tooltip from '@mui/material/Tooltip';
import EditCompanyModal from './EditCompanyModal';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';

const AdminCompanies = () => {
    const [companies, setCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(undefined);
    const [companyToDelete, setCompanyToDelete] = useState(undefined);
    const [savingCompany, setSavingCompany] = useState(false);
    const [deletingCompany, setDeletingCompany] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            const companies = await companyService.getCompanies(auth.agent.token);
            setCompanies(companies);
            setLoadingCompanies(false);
        };

        fetchData();
    }, [auth.agent.token]);

    const handleEditCompany = (company) => {
        setSelectedCompany(company);
    };

    const handleCompanySave = async (companyItem, companyAgents) => {
        setSavingCompany(true);
        setSelectedCompany(undefined);

        if (companyItem._id) {
            await companyService.updateCompany(companyItem._id, auth.agent.token, companyItem);
            await companyService.updateCompanyAgents(companyItem._id, auth.agent.token, companyAgents.map(agent => agent._id));
        } else {
            const createdCompany = await companyService.createCompany(auth.agent.token, companyItem);
            await companyService.updateCompanyAgents(createdCompany._id, auth.agent.token, companyAgents.map(agent => agent._id));
        }

        const updatedCompanies = await companyService.getCompanies(auth.agent.token);
        setCompanies(updatedCompanies);
        setLoadingCompanies(false);
        setSavingCompany(false);
    };

    const handleDeleteCompanyConfirm = (company) => {
        setCompanyToDelete(company);
    };

    const handleDeleteCompany = async () => {
        await companyService.deleteCompany(companyToDelete._id, auth.agent.token);
        setDeletingCompany(true);
        setCompanyToDelete(undefined);

        const updatedCompanies = await companyService.getCompanies(auth.agent.token);
        setCompanies(updatedCompanies);
        setDeletingCompany(false);
    };

    return (
        <div className="admin_settings_widget">
            <h3>Companies</h3>
            <p>Edit company logos and details.</p>
            <button className="btn mb-medium" onClick={() => { handleEditCompany({ name: '' }) }}><HiPlus />Add Company</button>
            {!loadingCompanies ? (
                companies.length > 0 ? (
                    companies.map(company => (
                        <div className="company_list_item">
                            {company.logo ? <img src={company.logo_icon} alt="Admin Company Logo" /> : <img src="https://i.stack.imgur.com/y9DpT.jpg" alt="Admin Company Logo" />}
                            <div className="company_list_item__name">{company.name}</div>
                            <div className="company_list_item__actions">
                                <Tooltip title="Edit company" arrow><div className="company_list_item__action" onClick={() => { handleEditCompany(company) }}><HiOutlinePencil /></div></Tooltip>
                                <Tooltip title="Delete company" arrow><div className="company_list_item__action" onClick={() => { handleDeleteCompanyConfirm(company) }}><HiTrash /></div></Tooltip>
                            </div>
                        </div>
                    ))
                ) : 'No Companies'
            ) : <Preloader />}
            {selectedCompany && <EditCompanyModal onSave={handleCompanySave} isOpen={selectedCompany ? true : false} onRequestClose={() => { handleEditCompany(undefined) }} company={selectedCompany} />}
            {companyToDelete && <ConfirmModal isOpen={companyToDelete ? true : false} title="Delete Company" onConfirm={handleDeleteCompany} description={<span>You are about to delete <b>{companyToDelete.name}</b><br /><br />Please note that deleting this company will be a permanent action. Are you sure you want to do this?</span>} confirmText="Delete" onRequestClose={() => { handleDeleteCompanyConfirm(undefined) }} />}
            {deletingCompany && <LoadingModal isOpen={deletingCompany} text="Deleting company" />}
            {savingCompany && <LoadingModal isOpen={savingCompany} text="Saving company" />}
        </div>
    );
};

export default AdminCompanies;
