import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitials } from '../../services/misc';
import StatusText from '../Base/StatusText';
import Preloader from '../Preloader';
import AgentPopup from './AgentPopup';
import { newChatHandle } from '../../services/internalChats';
import moment from 'moment';
import { setChatWidgetExpanded, setSelectedChat } from '../../reducers/internalChatsSlice';

const AgentProfileAvatar = (props) => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(false);

    const handleHover = (showPopup) => {
        setShowPopup(showPopup);
    };

    const handleDirectMessage = async () => {
        const internalChat = await newChatHandle(auth.agent.agent._id, props.agent._id, auth.selectedChatbot.token);
        await dispatch(setSelectedChat(internalChat));
        await dispatch(setChatWidgetExpanded(true));
    };

    const agent = props.agent;
    const agentFullName = `${agent.first_name} ${agent.last_name}`;
    const agentInitials = getInitials(agentFullName);
    const width = props.size + 'px' || undefined;
    const height = props.size + 'px' || undefined;
    const fontSize = props.size / 3 || undefined;
    const avatar = agent.avatar || undefined;
    const popupEnabled = props.popupEnabled || false;
    const disabled = agent.disabled || agent.deleted || (agent.expiry_date && moment() > moment(agent.expiry_date).add(23.9, 'hours'));

    return (
        <div className={`avatar-container ${props.showStatus ? 'status_shown' : ''} ${disabled ? 'disabled' : ''}`}>
            <div className={`agent_profile_avatar ${avatar ? 'no_background' : ''}`} style={{ width, height }} onMouseEnter={() => { handleHover(true) }} onMouseLeave={() => { handleHover(false) }}>
                {props.changingAvatar && <Preloader fitToContent={true} color="white" />}
                {avatar ? <img alt="Agent Avatar" src={avatar} /> : <div className="agent_initials" style={{ fontSize }}>{agentInitials}</div>}
            </div>
            {props.showStatus && <StatusText status={agent.agent_settings.status} hideText={true} />}
            {popupEnabled && showPopup && <AgentPopup currentAgent={auth.agent.agent} handleDirectMessage={handleDirectMessage} agent={agent} onMouseEnter={() => { handleHover(true) }} onMouseLeave={() => { handleHover(false) }} />}
        </div>
    );
};

export default AgentProfileAvatar;
