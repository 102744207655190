import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChatMediaViewer from './ChatMediaViewer';
import ChatMessageBar from './ChatMessageBar';
import ChatMessages from './ChatMessages';
import ChatTopBar from './ChatTopBar';
import { isImage, isVideo } from '../../services/upload';
import { createTypingEvent, notificationRead } from '../../services/conversation';

const Chat = (props) => {
    const [mediaItem, setMediaItem] = useState(undefined);
    const [isTyping, setIsTyping] = useState(false);
    const [currentConversation, setCurrentConversation] = useState(props.currentConversation);

    useEffect(() => {
        setCurrentConversation(props.currentConversation);
    }, [props.currentConversation]);

    const auth = useSelector(state => state.auth);

    const handleMediaClick = (media) => {
        setMediaItem(media);
        if (media && (!isVideo(media.type) && !isImage(media.type))) {
            window.open(media.url, "_blank");
        }
    };

    const handleNotificationRead = (conversationId, agentId) => {
        return notificationRead(conversationId, agentId, auth.selectedChatbot.token);
    };

    const handleTypingEvent = () => {
        if (!isTyping) {
            setIsTyping(true);
            setTimeout(() => {
                setIsTyping(false);
            }, 1500);
        }
        createTypingEvent(auth.selectedChatbot.token, currentConversation.conversation_id, 'agent');
    };

    return (
        <div className="chat" style={{ backgroundImage: 'linear-gradient( rgba(250,250,250,.755), rgba(250,250,250,.825) ), url(/images/chats_background.webp)' }}>
            <ChatTopBar
                filters={props.filters}
                handleMobileSidebarTrigger={props.handleMobileSidebarTrigger}
                handleChatBackClick={props.handleChatBackClick}
                currentMessages={props.currentMessages}
                setCurrentMessages={props.setCurrentMessages}
                currentConversation={currentConversation}
                handleSetCurrentConversation={props.handleSetCurrentConversation}
            />
            <ChatMessages
                loadingConversation={props.loadingConversation}
                currentConversation={currentConversation}
                currentMessages={props.currentMessages}
                currentAgent={auth.agent.agent}
                hasMoreMessages={props.hasMoreMessages}
                loadingMoreMessages={props.loadingMoreMessages}
                handleLoadMoreMessages={props.handleLoadMoreMessages}
                handleMediaClick={handleMediaClick}
                notificationRead={handleNotificationRead}
            />
            {currentConversation.agents_connected &&
                currentConversation.agents_connected.some((agent) => agent._id === auth.agent.agent._id) &&
                <ChatMessageBar
                    handleTypingEvent={handleTypingEvent}
                    currentConversation={currentConversation}
                    handleSendMessage={props.handleSendMessage}
                    handleAddToQuickReplies={props.handleAddToQuickReplies} />
            }
            {mediaItem && (isVideo(mediaItem.type) || isImage(mediaItem.type)) && <ChatMediaViewer media={mediaItem} handleMediaClick={handleMediaClick} />}
        </div>
    );
};

export default Chat;
