import { handleResponse } from './misc';

const getQueues = (authToken, agentId = undefined) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = "";
    if(agentId){ queryString = "?agent=" + agentId }

    return fetch('/api/queues' + queryString, requestOptions).then(handleResponse).then((queues) => {
        return queues;
    });
}

const createQueue = (authToken, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/queues', requestOptions).then(handleResponse).then((queue) => {
        return queue;
    });
}

const updateQueue = (authToken, queueId, { name, agents }) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ name, agents })
    };

    return fetch('/api/queues/' + queueId, requestOptions).then(handleResponse).then((queue) => {
        return queue;
    });
}

const deleteQueue = (authToken, queueId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/queues/' + queueId, requestOptions).then(handleResponse).then((queue) => {
        return queue;
    });
}

export { getQueues, createQueue, updateQueue, deleteQueue };