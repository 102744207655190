import React from 'react';
import AgentHistory from '../AgentHistory';
import Preloader from '../Preloader';
import ChatNotes from './ChatNotes';
import HelpRequestWidget from './HelpRequestWidget';
import UserInfoList from './UserInfoList';
import Widget from './Widget';
import { HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineXMark, HiOutlineTrash } from 'react-icons/hi2';
import EventsWidget from './EventsWidget';
import TagWidget from '../Tags/TagWidget';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ChatSidebar = (props) => {
    const chats = useSelector(state => state.chats);
    const navigate = useNavigate();

    const widgets = [
        {
            title: chats?.back?.text || 'Back',
            component: <div className="btn" onClick={() => { navigate(chats?.back?.pathname) }}><HiOutlineChevronLeft /> Go back</div>,
            hidden: !(chats?.back?.conversation === props.currentConversation?.conversation_id)
        }, {
            title: 'User information',
            component: <UserInfoList showActions={true} handleDeleteTrigger={props.handleDeleteTrigger} conversation={props.currentConversation} />,
            actions: props.currentAgent && props.currentAgent.role === 'admin' ? [{
                title: 'Delete Conversation',
                icon: <HiOutlineTrash />,
                onClick: () => { props.handleDeleteTrigger(props.currentConversation) }
            }] : undefined
        },
        {
            title: 'Notes',
            component: <ChatNotes chat={props.currentConversation} handleSetCurrentConversation={props.handleSetCurrentConversation} />
        },
        {
            title: 'Tags',
            component: <TagWidget chat={props.currentConversation} />
        },
        {
            title: 'Agents',
            component: <AgentHistory conversation={props.currentConversation} />,
            hidden: !(props.currentConversation && props.currentConversation.agents_connected && props.currentConversation.agents_connected.length > 0) && !(props.currentConversation && props.currentConversation.agent_history && props.currentConversation.agent_history.length > 0)
        },
        {
            title: 'Last Help Request',
            component: <HelpRequestWidget helpRequests={(props.currentConversation && props.currentConversation.helpRequests) && props.currentConversation.helpRequests} />,
            hidden: !(props.currentConversation && props.currentConversation.helpRequests && props.currentConversation.helpRequests.length > 0)
        },
        {
            title: 'Events',
            component: <EventsWidget events={(props.currentConversation && props.currentConversation.events) && props.currentConversation.events} />,
            hidden: !(props.currentConversation && props.currentConversation.events && props.currentConversation.events.length > 0)
        },
    ];

    return (
        <div className={`chats_sidebar ${props.mobileSidebarExpanded ? 'mobile_expanded' : ''} ${props.sidebarExpanded ? '' : 'collapsed'}`}>
            {!props.loadingConversation ? (
                props.currentConversation && (
                    <div className="chats_sidebar_inner">
                        <div className="chats_sidebar_widgets">
                            <div className="chats_sidebar_collapse" onClick={props.handleSidebarTrigger}>{props.sidebarExpanded ? <HiOutlineChevronRight /> : <HiOutlineChevronLeft />}</div>
                            <div className="chats_sidebar_mobile_collapse" onClick={props.handleMobileSidebarTrigger}><HiOutlineXMark /></div>
                            {widgets.map((widget, index) => {
                                return !widget.hidden && <Widget key={index} title={widget.title} component={widget.component} actions={widget.actions} />
                            })}
                        </div>
                    </div>
                )
            ) : <Preloader />}
        </div>
    );
};

export default ChatSidebar;
