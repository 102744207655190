import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import { attachDocument, createIndex, getKnowledgeSet, publishKnowledgeSet, saveToDraft, saveToUat, updateIndex } from '../../services/knowedgeSetManager';
import { history } from '../../helpers/history';
import Preloader from '../Preloader';
import IndexList from './IndexList';
import KnowledgeSetTopBar from './KnowledgeSetTopBar';
import KnowledgeSetInfo from './KnowledgeSetInfo';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';
import KnowledgeSetSidebar from './KnowledgeSetSidebar';
import socket from '../../services/socket';
import { useParams } from 'react-router-dom';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import ChunkSearchModal from './ChunkSearchModal';

const KnowledgeSetPage = () => {
    const [loading, setLoading] = useState(true);
    const [confirmPublishKnowledgeSet, setConfirmPublishKnowledgeSet] = useState(false);
    const [publishKnowledgeSetLoading, setPublishKnowledgeSetLoading] = useState(false);
    const [confirmSaveToUat, setConfirmSaveToUat] = useState(false);
    const [saveToUatLoading, setSaveToUatLoading] = useState(false);
    const [confirmSaveToDraft, setConfirmSaveToDraft] = useState(false);
    const [saveToDraftLoading, setSaveToDraftLoading] = useState(false);
    const [indexToCreate, setIndexToCreate] = useState(null);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [creatingIndex, setCreatingIndex] = useState(false);
    const [infoSidebarExpanded, setInfoSidebarExpanded] = useState(false);
    const [knowledgeSet, setKnowledgeSet] = useState(null);
    const knowledgeSetRef = useRef(null);
    const [searching, setSearching] = useState(false);

    const auth = useSelector(state => state.auth);
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            if(params.id){
                try{
                    const kb = await getKnowledgeSet(auth.selectedChatbot.token, params.id)
                    setKnowledgeSet(kb);
                    setLoading(false);

                    socket.on('jobUpdated', ({ id, jobId }) => {
                        if(auth.selectedChatbot._id === id && knowledgeSetRef.current && knowledgeSetRef.current.latest_job && knowledgeSetRef.current.latest_job._id === jobId){
                            retrieveAndUpdateKb();
                        }else{
                        }
                    });
                }catch(e){
                    history.push('/knowledge-set-manager');
                }
            }
        };

        fetchData();

        return () => {
            socket.off('jobUpdated');
        };
    }, []);

    useEffect(() => {
        if(knowledgeSet){
            knowledgeSetRef.current = knowledgeSet;
        }
    }, [knowledgeSet])

    const retrieveAndUpdateKb = async () => {
        const knowledgeSet = await getKnowledgeSet(auth.selectedChatbot.token, params.id)
        setKnowledgeSet(knowledgeSet);
        setLoading(false);
    }

    const handlePublishKnowledgeSet = async () => {
        try{
            setConfirmPublishKnowledgeSet(false);
            setPublishKnowledgeSetLoading(true);

            publishKnowledgeSet(auth.selectedChatbot.token, params.id, auth.agent.agent._id);

            const knowledgeSet = await getKnowledgeSet(auth.selectedChatbot.token, params.id);
            setKnowledgeSet(knowledgeSet);
            setLoading(false);
            setPublishKnowledgeSetLoading(false);
        }catch(e){
            console.log(e);
        }
    }

    const handlePublishKnowledgeSetClick = (confirm) => {
        setConfirmPublishKnowledgeSet(confirm);
    } 

    const handleSaveToUatClick = (confirm) => {
        setConfirmSaveToUat(confirm);
    }

    const handleSaveToUat = async () => {
        try{
            setConfirmSaveToUat(false);
            setSaveToUatLoading(true);

            saveToUat(auth.selectedChatbot.token, params.id, auth.agent.agent._id);
            
            const knowledgeSet = await getKnowledgeSet(auth.selectedChatbot.token, params.id);
            setKnowledgeSet(knowledgeSet);
            setLoading(false);
            setSaveToUatLoading(false);
        }catch(e){
            console.log(e);
        }
    }

    const saveToDraft = async () => {
        try{
            setConfirmSaveToDraft(false);
            setSaveToDraftLoading(true);

            await saveToDraft(auth.selectedChatbot.token, params.id, auth.agent.agent._id);

            const knowledgeSet = await getKnowledgeSet(auth.selectedChatbot.token, params.id);
            setKnowledgeSet(knowledgeSet);
            setLoading(false);
            setSaveToDraftLoading(false);
        }catch(e){
            console.log(e);
        }
    }

    const handleAddIndexClick = () => {
        setIndexToCreate(prevIndexToCreate => prevIndexToCreate ? null : {
            knowledge_set_id: knowledgeSet._id,
            version_id: knowledgeSet.latest_version._id,
            name: '',
            type: 'document'
        });
    }

    const handleIndexCreateUpdate = (fieldName, value) => {
        setIndexToCreate(prevIndexToCreate => ({
            ...prevIndexToCreate,
            [fieldName]: value
        }));
    }

    const handleSetFile = async (file) => {
        setUploadingFile(true);
        const fileName = await attachDocument(auth.selectedChatbot.token, knowledgeSet._id, file);
        setIndexToCreate(prevIndexToCreate => ({
            ...prevIndexToCreate,
            document: {
                name: fileName.name
            },
            name: fileName.name
        }));
        setUploadingFile(false);
    }

    const handleCreateIndex = async () => {
        const index = indexToCreate;
        setCreatingIndex(true);
        setIndexToCreate(null);
        await createIndex(auth.selectedChatbot.token, index, auth.agent.agent._id);
        setCreatingIndex(false);
    }

    const handleInfoSidebarTrigger = () => {
        setInfoSidebarExpanded(prevInfoSidebarExpanded => !prevInfoSidebarExpanded);
    }

    const handleSearchAllClick = () => {
        setSearching(prevSearching => !prevSearching);
    }

    return (
        loading ? <Preloader/> : (
            <div className='knowledge_set_page'>
                <KnowledgeSetTopBar knowledgeSet={knowledgeSet} handleInfoSidebarTrigger={handleInfoSidebarTrigger}/>
                <div className='knowledge_set_page__content'>
                    <div className="knowledge_set_page_topbar">
                        <div>
                            <div className='knowledge_set_page_title'>{knowledgeSet.name}</div>
                            <div className='timestamp' style={{ marginRight: '15px' }}>Version: {knowledgeSet.latest_version.version_number}</div>
                        </div>
                        <button className='btn' onClick={handleSearchAllClick}><HiMagnifyingGlass/>Search all indexes</button>
                    </div>
                    <div className='container'>
                        <div className='col-9'>
                            <IndexList 
                                knowledgeSet={knowledgeSet}
                                retrieveAndUpdateKb={retrieveAndUpdateKb}
                                indexToCreate={indexToCreate}
                                handleAddIndexClick={handleAddIndexClick}
                                handleIndexCreateUpdate={handleIndexCreateUpdate}
                                handleSetFile={handleSetFile}
                                uploadingFile={uploadingFile}
                                handleCreateIndex={handleCreateIndex}
                            />
                        </div>
                        <div className='col-3 knowledge_set_info_container'>
                            <KnowledgeSetInfo 
                                knowledgeSet={knowledgeSet}
                                handlePublishKnowledgeSetClick={handlePublishKnowledgeSetClick}
                                handleSaveToUatClick={handleSaveToUatClick}
                                publishKnowledgeSet={handlePublishKnowledgeSet}
                                saveToDraft={saveToDraft}
                                saveToUat={handleSaveToUat}
                            />
                        </div>
                    </div>
                </div>
                {infoSidebarExpanded && (
                    <KnowledgeSetSidebar
                        knowledgeSet={knowledgeSet}
                        handleInfoSidebarTrigger={handleInfoSidebarTrigger}
                    />
                )}
                <ConfirmModal 
                    isOpen={confirmPublishKnowledgeSet}
                    title='Publish knowledge set'
                    description='Are you sure you want to publish this knowledge set?'
                    onConfirm={handlePublishKnowledgeSet}
                    onRequestClose={() => handlePublishKnowledgeSetClick(false) }
                />
                <ConfirmModal 
                    isOpen={confirmSaveToUat}
                    title='Save to UAT'
                    description='Are you sure you want to save this knowledge set to UAT?'
                    onConfirm={handleSaveToUat}
                    onRequestClose={() => handleSaveToUatClick(false) }
                />
                <LoadingModal 
                    isOpen={publishKnowledgeSetLoading}
                    text='Publishing knowledge set...'
                />
                <LoadingModal 
                    isOpen={saveToUatLoading}
                    text='Saving changes...'
                />
                <LoadingModal 
                    isOpen={creatingIndex}
                    text='Creating index...'
                />
                <ChunkSearchModal 
                    isOpen={searching}
                    onRequestClose={handleSearchAllClick}
                    knowledgeSet={knowledgeSet}
                />
            </div>
        )
    )
};

export default KnowledgeSetPage;
