import { Divider, Pagination, Skeleton, TextareaAutosize } from "@mui/material";
import { toTimestamp } from "../../services/dates";
import ChunkContent from "../KnowledgeSetManager/ChunkContent";
import { useEffect, useReducer, useState } from "react";
import { addQuestionLogAgentComment, deleteQuestionLogAgentComment, getQuestionLog, updateQuestionLogReview, updateQuestionLogStatus } from "../../services/questionLogs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { goToConversation } from "../../reducers/chatsSlice";
import { HiCheckCircle } from "react-icons/hi2";
import AlertBox from "../AlertBox";
import QuestionLogStatusButtons from "./QuestionLogStatusButtons";
import QuestionLogComments from "./QuestionLogComments";

const QuestionLogDetailsPane = ({ questionLogId, handleSetQuestionLogs }) => {
    const auth = useSelector(state => state.auth)

    const [, forceRender] = useReducer(p => !p, false);

    const [questionLog, setQuestionLog] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingQuestionLog, setLoadingQuestionLog] = useState(true);
    const [revisedAnswer, setRevisedAnswer] = useState(null);
    const [savingAnswer, setSavingAnswer] = useState(false);
    const [addingComment, setAddingComment] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Initial hook for render and retrieval of data
    useEffect(() => {
        setAddingComment(false);
        setLoadingQuestionLog(true);
        setQuestionLog(null);
        fetchQuestionLog();
    }, [questionLogId])

    const fetchQuestionLog = async () => {
        const ql = await getQuestionLog(auth.selectedChatbot.token, questionLogId);
        
        setRevisedAnswer(null);
        setQuestionLog(ql);
        setLoadingQuestionLog(false);
        setCurrentPage(1);
    }

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleViewChatClick = async (conversationId) => {
        dispatch(goToConversation({ conversation: conversationId, navigate, location, back: {
            pathname: location.pathname,
            text: 'Back to Question Log'
        } }))
    }

    const handleReviseAnswerToggle = () => {
        setRevisedAnswer(prevRevisedAnswer => prevRevisedAnswer ? null : (questionLog.review?.new_answer?.text ? questionLog.review.new_answer : {
            text: questionLog.answer
        }));
    }

    const handleReviseAnswerChange = (e) => {
        setRevisedAnswer(prevRevisedAnswer => ({
            ...prevRevisedAnswer,
            text: e.target.value
        }));
    }

    const handleReviseAnswerSave = async () => {
        setSavingAnswer(true);
        
        await updateQuestionLogReview(auth.selectedChatbot.token, auth.selectedChatbot.settings.question_message_log_feedback_knowledge_set, questionLogId, {
            ...questionLog.review,
            new_answer: {
                text: revisedAnswer.text,
                chunk: questionLog.review?.new_answer?.chunk?.chunk_id
            },
            changed_by: auth.agent.agent._id
        });

        await handleSetQuestionLogs();
        await fetchQuestionLog();
        setRevisedAnswer(null);
        await setSavingAnswer(false);
    }

    const handleStatusUpdate = async (status) => {
        await updateQuestionLogStatus(auth.selectedChatbot.token, questionLogId, status);
        
        await handleSetQuestionLogs();
        await fetchQuestionLog();
    }

    const handleAddCommentToggle = () => {
        setAddingComment(prevAddingComment => !prevAddingComment);
    }

    const handleCommentAdd = async (comment) => {
        await addQuestionLogAgentComment(auth.selectedChatbot.token, questionLogId, {
            text: comment,
            owner: auth.agent.agent._id
        });

        await handleSetQuestionLogs();
        await fetchQuestionLog();
        setAddingComment(false);
    }

    const handleCommentDelete = async (commentId) => {
        await deleteQuestionLogAgentComment(auth.selectedChatbot.token, questionLogId, commentId);
        await handleSetQuestionLogs();
        await fetchQuestionLog();
    }

    return (
        <div className="question_log_details_pane">
            <div className="question_log_details_pane__header">
                <div>
                    <div className="question_log_list__item__text__username">{loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /> : questionLog?.conversation?.user?.user_info?.name || <span className="unknown_user">Unknown User</span>}</div>
                    <div className="question_log_details_pane__timestamp">{loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '14px', width: '200px', margin: 'auto' }} /> : <> <div className="view_chat" onClick={() => { handleViewChatClick(questionLog.conversation_id) }}>View Chat</div> | Created at: {toTimestamp(questionLog.createdAt)}{questionLog.reviewedAt && <> | Review at: {toTimestamp(questionLog.reviewedAt)}</>}</>}</div>
                </div>
                {loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '14px', width: '200px'}} /> : <QuestionLogStatusButtons status={questionLog.review.status} handleStatusUpdate={handleStatusUpdate}/>}
            </div>
            <div className="question_log_details_pane__messages">
                <div className="question_log_details_pane__message question_log_details_pane__messages__question">{loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '300px' }} /> : questionLog.question}</div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px', alignItems: 'start' }}>
                    {!loadingQuestionLog && <QuestionLogComments questionLog={questionLog} addingComment={addingComment} handleCommentDelete={handleCommentDelete} handleCommentAdd={handleCommentAdd} handleAddCommentToggle={handleAddCommentToggle}/>}
                    <div onClick={() => { if(!revisedAnswer){ handleReviseAnswerToggle() } }} className={`question_log_details_pane__message question_log_details_pane__messages__answer ${revisedAnswer ? 'revising' : ''} ${questionLog?.review?.new_answer ? 'revised' : ''}`}>
                        {(!loadingQuestionLog && !revisedAnswer) && <label className="question_log_details_pane__message_edit_label">Click to {(questionLog?.review?.new_answer && !revisedAnswer) ? 'edit' : 'revise'}</label>}
                        {(!loadingQuestionLog && questionLog?.review?.new_answer) && <label className="question_log_details_pane__messages__answer__label"><HiCheckCircle />Revised Answer</label>}
                        {loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '500px' }} /> : (revisedAnswer ? (
                            <>
                                <TextareaAutosize disabled={savingAnswer} value={revisedAnswer.text} onChange={handleReviseAnswerChange}/>
                                <div style={{ display: 'flex', marginTop: '8px' }}>
                                    <button disabled={savingAnswer} className="btn primary" style={{ marginRight: '5px' }} onClick={handleReviseAnswerSave}>{savingAnswer ? 'Revising answer...' : 'Save answer'}</button>
                                    <button  disabled={savingAnswer} className="btn" onClick={handleReviseAnswerToggle}>Discard</button>
                                </div>
                            </>
                        ) : (questionLog?.review?.new_answer ? <div dangerouslySetInnerHTML={{ __html: questionLog.review.new_answer.text }}/> : questionLog.answer))}
                    </div>
                </div>
            </div>
            <div className="question_log_details_pane__revisions">
                {loadingQuestionLog ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '500px', height: '40px', margin: 'auto' }} /> : (
                    questionLog.review?.new_answer ? (
                        <div className="question_log_details_pane__revisions__old_answer">
                            <div className="question_log_details_pane__revisions__old_answer_label">Original Answer</div>
                            <div className="question_log_details_pane__revisions__old_answer__content">
                                <div className="question_log_details_pane__revisions__old_answer_text">{questionLog.answer}</div>
                            </div>
                        </div>
                    ) : (
                        !revisedAnswer ? (
                            <>
                                <div className="question_log_details_pane__revisions__label">Not happy with this answer?</div>
                                <button className="btn" onClick={handleReviseAnswerToggle}>Revise answer</button>
                            </>
                        ) : <AlertBox type="info">Please revise the original answer in the text area above.</AlertBox>
                    )
                )}
            </div>
            <Divider sx={{ my: '50px' }}/>
            {auth.agent.agent.role === 'admin' && (
                <>
                    <div className='chunks_carousel__title'>
                        <strong>Chunks Used</strong>
                        {(!loadingQuestionLog && questionLog && questionLog.chunks?.length > 0) && <Pagination page={currentPage} onChange={handleChange} count={questionLog.chunks.length}/>}
                    </div>
                    <div className='chunks_carousel__items mt-medium'>
                        {loadingQuestionLog ? <Skeleton variant="rounded" sx={{ width: '100%', height: '300px' }} /> : (
                            questionLog.chunks?.length > 0 ? <ChunkContent chunk={questionLog.chunks[currentPage - 1]}/> : 'No chunks used'
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default QuestionLogDetailsPane;