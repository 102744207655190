import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import MeetingIdForm from './MeetingIdForm';
import { validateMeeting } from '../../services/meetings';
import VideoChat from './VideoChat';
import { isMobile } from '../../services/responsive';
import Draggable from 'react-draggable';

export default function MeetingRoom(props) {
    const navigate = useNavigate();
    const params = useParams()
    
    const [meetingId, setMeetingId] = useState(props.meeting ? props.meeting._id : params.meetingId);
    const [alertMessage, setAlertMessage] = useState(undefined);
    const [validatingId, setValidatingId] = useState(false);
    const [meetingIdInput, setMeetingIdInput] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [meeting, setMeeting] = useState(props.meeting || undefined);
    const [minimizable, setMinimizable] = useState(props.minimizable || false);
    const [minimized, setMinimized] = useState(false);
    const [draggable, setDraggable] = useState(true);
    useEffect(() => {
        if (isMobile()) {
            setDraggable(false);
        }

        if (meetingId) {
            setValidatingId(true);
            validateMeeting(meetingId)
                .then((meeting) => {
                    setValidatingId(false);
                    setAlertMessage(undefined);
                    setMeetingId(meeting._id);
                    setMeeting(meeting);
                })
                .catch((e) => {
                    setAlertMessage(e);
                    setValidatingId(false);
                    setMeetingId(undefined);
                    navigate('/meeting');
                });
        }
    }, [meetingId]);

    const handleMeetingIdInput = (e) => {
        setMeetingIdInput(e.target.value);
    };

    const handleMeetingIdSubmit = (e) => {
        e.preventDefault();
        setValidatingId(true);
        validateMeeting(meetingIdInput)
            .then((meeting) => {
                setValidatingId(false);
                setAlertMessage(undefined);
                setMeetingId(meeting._id);
                setMeeting(meeting);
                navigate('/meeting/' + meeting._id);
            })
            .catch((e) => {
                setAlertMessage(e);
                setValidatingId(false);
            });
    };

    const handleMinimize = () => {
        setMinimized((prevState) => !prevState.minimized);
    };

    const mobile = isMobile();

    return (
        <Draggable disabled={!draggable || !minimized}>
            <div
                onClick={mobile && minimized ? handleMinimize : () => {}}
                className={`meeting_room ${props.agent ? 'agent_view' : ''} ${minimized ? 'minimized' : 'maximized'}`}
                style={{
                    backgroundImage: meeting && meeting.company && !props.agent ? `url(${meeting.company.background_image})` : '',
                }}
            >
                {meetingId && meeting ? (
                    <VideoChat
                        displayName={props.displayName}
                        agent={props.agent}
                        onDisconnect={props.onDisconnect}
                        handleMinimize={handleMinimize}
                        minimizable={minimizable}
                        minimized={minimized}
                        meeting={meeting}
                        autoJoin={props.meeting ? true : false}
                        validatingId={validatingId}
                    />
                ) : (
                    <MeetingIdForm
                        alertMessage={alertMessage}
                        meetingIdInput={meetingIdInput}
                        validatingId={validatingId}
                        handleMeetingIdSubmit={handleMeetingIdSubmit}
                        handleMeetingIdInput={handleMeetingIdInput}
                    />
                )}
            </div>
        </Draggable>
    );
}