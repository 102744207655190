import HelpRequestListItem from "./HelpRequestListItem";
import LoadMore from '../Base/LoadMore';

const HelpRequestList = (props) => {
    return (
        <div className="help_request_list">
            {props.helpRequests.length > 0 ? props.helpRequests.map(helpRequest => <HelpRequestListItem key={helpRequest._id} handleHelpRequestTransfer={props.handleHelpRequestTransfer} handleDeleteHelpRequest={props.handleDeleteHelpRequest} handleResolveHelpRequest={props.handleResolveHelpRequest} handleMediaClick={props.handleMediaClick} helpRequest={helpRequest} handleSendQuickReply={props.handleSendQuickReply} handleViewChatClick={props.handleViewChatClick}/>) : (
                <div className="alert_box alert-clear center">No help requests</div>
            )}
            {props.hasMore && <LoadMore loadingMore={props.loadingMore} onClick={props.loadMoreClick}/>}
        </div>
    );
};

export default HelpRequestList;