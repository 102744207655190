const ChatsPreloader = () => {
    return (
        <div className="chats-preloader">
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
            <div className="timeline-item"> <div className="loader-image animated-background"></div><div className="loader-details"> <div className="line name animated-background"></div><div className="line message animated-background"></div><div className="line date animated-background"></div></div></div>
        </div>
    );
};

export default ChatsPreloader;