import React from 'react';
import { HiHome } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="not_found_page">
            <div className="not_found_bold">404</div>
            <div className="not_found_heading">Page not found</div>
            <div className="not_found_text">The page you are looking for does not exist. Please check your address or click below to return to home.</div>
            <NavLink className="not_found_button" to="/"><HiHome/>Back to home</NavLink>
        </div>
    );
};

export default NotFoundPage;