import { HiCheckCircle, HiOutlinePlus } from "react-icons/hi2";
import ConnectorsBadge from "../Connectors/ConnectorsBadge";

const ModuleTemplateList = ({ moduleTemplates, connectors, addedModules, onModuleAdd }) => {
    return (
        <div className="module_template_list">
            {moduleTemplates.map((moduleTemplate) => {
                const added = addedModules && addedModules.find(module => moduleTemplate.template_id === module.module_template_id) ? true : false;

                return (
                    <div key={moduleTemplate.id} className="module_template_list__item">
                        <div className="module_template_list__item_icon" dangerouslySetInnerHTML={{__html: moduleTemplate.icon}}/>
                        <div className="module_template_list__item_content">
                            <div className="module_template_list__item_name"><b>{moduleTemplate.name}</b></div>
                            <div className="module_template_list__item_description">{moduleTemplate.description}</div>
                        </div>
                        <div className="module_template_list__item_bottom_bar">
                            <div className="module_template_list__item_actions">
                                {added ? <button className="btn" disabled={true}><HiCheckCircle/>Added</button> : <button className="btn" onClick={() => { onModuleAdd(moduleTemplate) }}><HiOutlinePlus/>Add</button>}
                            </div>
                            <ConnectorsBadge connectors={connectors.filter(connector => moduleTemplate.connector_dependencies?.includes(connector.connector_id))}/>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default ModuleTemplateList;