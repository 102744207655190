import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    helpRequests: [],
    helpRequestsCount: 0,
    loadingHelpRequest: true,
    limit: 10,
    offset: 10,
    hasMore: false,
    isSearched: false,
    searchTerm: '',
    filters: {
        orderBy: 'desc',
    },
};

const helpRequestsSlice = createSlice({
    name: 'helpRequests',
    initialState,
    reducers: {
        clearHelpRequests: () => initialState,
        setHelpRequestsValue: (state, action) => {
            state.helpRequests = action.payload.helpRequests;
            state.loadingHelpRequests = action.payload.loadingHelpRequests;
            state.isSearched = action.payload.isSearched;
            state.hasMore = action.payload.hasMore || false;
            state.helpRequestsCount = action.payload.count;
        },
        setHelpRequestsCountValue: (state, action) => {
            state.helpRequestsCount = action.payload.count;
        },
        updateSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setLoadingHelpRequests: (state, action) => {
            state.loadingHelpRequests = action.payload.loadingHelpRequests;
        },
        setHelpRequestsLimit: (state, action) => {
            state.limit = action.payload;
        },
        setHelpRequestFilters: (state, action) => {
            state.filters = {
                ...state.filters,
                ...action.payload.filter,
            };
        },
    },
});

export const {
    clearHelpRequests,
    setHelpRequestsValue,
    setHelpRequestsCountValue,
    updateSearchTerm,
    setLoadingHelpRequests,
    setHelpRequestsLimit,
    setHelpRequestFilters,
} = helpRequestsSlice.actions;

export default helpRequestsSlice.reducer;