import { HiOutlinePaperClip } from 'react-icons/hi2';
import { BiCheckDouble } from 'react-icons/bi';
import { bold, italic } from '../../services/misc';

const LastActivity = (props) => {
    const lastActivity = props.lastActivity;
    const messageData = lastActivity.message_data;
    let sender;
    const isAttachment = messageData.media && messageData.media.length > 0 ? true : false;
    const text = messageData.text ? bold(italic(messageData.text.replace(/<br\/>/g, ''))) : (isAttachment ? 'Sent an attachment' : '');
    
    switch(lastActivity.sender){
        case 'user':
            sender = 'User:';
            break;
        case 'bot':
            sender = 'Bot:';
            break;
        default:
            sender = lastActivity.agent_sender_object ? (lastActivity.agent_sender_object._id === props.currentAgent._id ? <BiCheckDouble className="delivered_check"/> : lastActivity.agent_sender_object.first_name[0] + '. ' + lastActivity.agent_sender_object.last_name + ':') : 'Agent:';
            break;
    }
    return (
        <div className="chat_list__item__last_activity text-truncate">
            {isAttachment && <div className="chat_list__item__last_activity__attachment_icon"><HiOutlinePaperClip/></div>}<div className="chat_list__item__last_activity__sender">{sender}</div><span className="text-truncate" dangerouslySetInnerHTML={{ __html: text }}/>
        </div>
    );
};

export default LastActivity;