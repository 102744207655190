import { HiOutlineBell, HiOutlineXMark } from 'react-icons/hi2';

const NotificationsTrigger = (props) => {
    return (
        <div className="notifications_trigger" onClick={props.onClick}>
            {props.notificationsExpanded ? <HiOutlineXMark/> : <HiOutlineBell/>}
            
            <div className="count_badge">{(props.notifications.length + props.meetingsWaiting.length)}</div>
        </div>
    );
};

export default NotificationsTrigger;