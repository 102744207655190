import { useState } from "react";
import { HiArrowUpTray, HiOutlineXMark } from "react-icons/hi2";
import FileUploadModal from "./FileUploadModal";
import { uploadMedia } from "../../services/upload";
import { useSelector } from "react-redux";
import LoadingModal from "./LoadingModal";
import AlertBox from "../AlertBox";
import { Tooltip } from "@mui/material";

const FileUploadInput = ({ value, fileTypes, modalTitle, modalDescription, onSuccessfulUpload }) => {
    const auth = useSelector(state => state.auth)

    const [uploadingFile, setUploadingFile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleUploadFileClick = () => {
        setUploadingFile(prevUploadingFile => !prevUploadingFile);
    }

    const handleSetFile = async (file) => {
        setLoading(true);
        setUploadingFile(false);
        setError(null);
        
        try{
            const uploadFile = await uploadMedia(auth.selectedChatbot.token, file);
            onSuccessfulUpload(uploadFile.url);
            setLoading(false);
        }catch(e){
            setError('Failed to upload file');
            setLoading(false);
        }
    }

    return (
        <div className="file_upload_input">
            {uploadingFile && <FileUploadModal 
                isOpen={uploadingFile} 
                onRequestClose={handleUploadFileClick} 
                handleSetFile={handleSetFile}
                title={modalTitle}
                description={modalDescription}
                fileTypes={fileTypes}
            />}
            {loading && <LoadingModal isOpen={loading}/>}
            {(value && value !== '') ? (
                <div className="file_upload_input_preview">
                    <div className="file_upload_input_preview_doc_preview">
                        <img src={value}/>
                        <Tooltip title="Remove file" arrow><button onClick={() => { onSuccessfulUpload(null) }}><HiOutlineXMark/></button></Tooltip>
                    </div>
                    <button className="btn" onClick={handleUploadFileClick}><HiArrowUpTray/>Change file</button>
                </div>
            ) : (
                <button className="btn" onClick={handleUploadFileClick}><HiArrowUpTray/>Select file</button>
            )}
            {error && <div className="mt-medium"><AlertBox type="error">{error}</AlertBox></div>} 
        </div>
    )
}

export default FileUploadInput;