import React, { useState } from "react";
import UserInfoList from "../Chats/UserInfoList";
import Widget from '../Chats/Widget';
import { HiOutlineCheck, HiCheckCircle, HiOutlineChatBubbleLeftEllipsis, HiOutlineTrash } from 'react-icons/hi2'
import QuickMessage from "../Chats/QuickMessage";
import MessageMedia from "../Chats/MessageMedia";
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import QueueBadge from '../Base/QueueBadge';
import { toTimestamp } from '../../services/dates';
import Preloader from "../Preloader";
import ConfirmModal from "../Base/ConfirmModal";
import { BsArrowLeftRight } from 'react-icons/bs'
import ChatTransfer from "../Chats/ChatTransfer";
import moment from "moment";
import { history } from '../../helpers/history';

const HelpRequestListItem = (props) => {
    const [resolving, setResolving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deletePromptShown, setDeletePromptShown] = useState(false);
    const [transferOpen, setTransferOpen] = useState(false);
    const [confirmResolve, setConfirmResolve] = useState(false);
    const [confirmQuickReply, setConfirmQuickReply] = useState(false);
    const [loadingConversation, setLoadingConversation] = useState(false);

    const handleConfirmResolve = () => {
        setConfirmResolve(prevState => !prevState);
    }

    const handleResolveClick = async (helpRequestId) => {
        setResolving(true);
        setConfirmResolve(false);
        await props.handleResolveHelpRequest(helpRequestId);
        setResolving(false);
    }

    const handleDeleteClick = async (helpRequestId) => {
        setDeleting(true);
        setDeletePromptShown(false);
        await props.handleDeleteHelpRequest(helpRequestId);
        history.push('/help-requests');
        setDeleting(false);
    }

    const handleHelpRequestDeleteModalTrigger = () => {
        setDeletePromptShown(prevState => !prevState);
    }

    const handleTransferTrigger = () => {
        setTransferOpen(prevState => !prevState);
    }

    const onSendQuickReply = async (message, conversation) => {
        await props.handleSendQuickReply(props.helpRequest._id, message, conversation);
    }

    const handleTransfer = async (queueId) => {
        await props.handleHelpRequestTransfer(props.helpRequest._id, queueId);
    }

    const handleViewChatClick = () => {
        setLoadingConversation(true);
        props.handleViewChatClick(props.helpRequest.conversation);
    }

    const helpRequest = props.helpRequest;
    const inSession = moment(helpRequest.createdAt) > moment().subtract(23.5, 'hours');

    return (
        <div className={`help_request_item ${props.helpRequest.resolved ? 'resolved' : ''}`}>
            <div className="help_request_item_container">
                {helpRequest.message_data && (
                    <div className="help_request_info">
                        <div className="help_request_info__content">
                            <div className="help_request_info__title"><strong>{helpRequest.conversation && helpRequest.conversation.user && helpRequest.conversation.user.user_info && helpRequest.conversation.user.user_info.name ? helpRequest.conversation.user.user_info.name : (helpRequest.conversation ? helpRequest.conversation.conversation_id : 'No ID Found') }</strong></div> <div className="timestamp">Date created: {toTimestamp(helpRequest.createdAt)}</div><QueueBadge queue={helpRequest.queue}/>
                            {props.helpRequest.reference_number && (<div className="help_request_info__text"><label>Reference number:</label>{props.helpRequest.reference_number}</div>
							)}
                            {props.helpRequest.message_data.text && <div className="help_request_info__text"><label>User asked:</label>{props.helpRequest.message_data.text}</div>}
                            {props.helpRequest.message_data.media && props.helpRequest.message_data.media.length > 0 && (
                                <MessageMedia thumbnail={true} media={props.helpRequest.message_data.media[0]} handleMediaClick={props.handleMediaClick}/>
                            )}
                            
                            {(!helpRequest.resolved && inSession && helpRequest.conversation) && <QuickMessage onSend={onSendQuickReply} label="Quick reply" helpRequest={props.helpRequest} placeholder="Enter your answer" conversation={helpRequest.conversation}/>}
                        </div>                        
                        <div className="help_request_actions">
                            <div className="help_request_actions_container">
                                {!helpRequest.resolved && <button disabled={resolving} className="btn" onClick={handleConfirmResolve}>{!resolving ? <div className="flex-middle"><HiOutlineCheck/><span>Mark as Resolved</span></div> : <div className="flex-middle">Resolving <Preloader padding="0 0.3rem" size="10"/></div>}</button>}
                                {!helpRequest.resolved && <button className="btn" onClick={handleTransferTrigger}><BsArrowLeftRight/><span>Transfer</span></button>}
                                {helpRequest.conversation && <button disabled={loadingConversation} className="btn" onClick={handleViewChatClick}><HiOutlineChatBubbleLeftEllipsis/><span>{loadingConversation ? 'Loading chat...' : 'View Chat'}</span></button>}
                                {transferOpen && <ChatTransfer queuePicker={true} title="Transfer help request" allowOffline={true} actionText="Transferring" onSelect={handleTransfer} handleTransferToggle={handleTransferTrigger} conversation={helpRequest.conversation}/>}
                            </div>
                            <button className="btn flex_end" disabled={deleting} onClick={handleHelpRequestDeleteModalTrigger}>{!deleting ? <div className="flex-middle"><HiOutlineTrash/></div> : <div className="flex-middle">Deleting <Preloader padding="0 0.3rem" size="10"/></div>}</button>
                        </div> 
                        {helpRequest.resolved && ( 
                            <div className="resolved_by">
                                <div className="resolved_by__title"><HiCheckCircle/> Resolved by</div>
                                <div className="resolved_by_inner p-medium">
                                    <div className="flex-middle pb-medium">
                                        {helpRequest.resolved.agent && <AgentProfileAvatar popupEnabled={true} agent={helpRequest.resolved.agent}/>} 
                                        <div className="resolved_by__details">
                                            {helpRequest.resolved.agent ? <div className="resolved_by__details__name">{helpRequest.resolved.agent.first_name[0]}. {helpRequest.resolved.agent.last_name}</div> : <div className="resolved_by__details__name">Agent not found</div>}
                                            <div className="timestamp">Date resolved: {toTimestamp(helpRequest.resolved.updatedAt)}</div>
                                        </div>                                              
                                    </div>
                                    {(props.helpRequest.resolved && props.helpRequest.resolved.comment && (props.helpRequest.resolved.comment.text || props.helpRequest.resolved.comment.media.length > 0)) && <div className="help_request_info__text"><label>Resolving agent said:</label>{props.helpRequest.resolved.comment.media.length > 0 && <MessageMedia thumbnail={true} media={props.helpRequest.resolved.comment.media[0]} handleMediaClick={props.handleMediaClick}/>}{props.helpRequest.resolved.comment.text}</div>}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {helpRequest.conversation && <Widget title="User Info" component={(
                <div>
                    <UserInfoList conversation={helpRequest.conversation} maxItems={4}/>
                </div>
            )}/>}
            <ConfirmModal confirmText="Mark as Resolved" title="Resolve Help Request" description="Please note you are about to mark this help request as resolved. If the text bar is still active, it will no longer be available." isOpen={confirmResolve} onConfirm={() => { handleResolveClick(helpRequest._id) }} onRequestClose={handleConfirmResolve}/>
            <ConfirmModal confirmText="Delete" title="Delete Help Request" description="Please note you are about to delete this help request. This action cannot be undone." onRequestClose={handleHelpRequestDeleteModalTrigger} isOpen={deletePromptShown} onConfirm={() => { handleDeleteClick(helpRequest._id) }}/>
        </div>
    );
};

export default HelpRequestListItem;
