import { toTimestamp } from "../../services/dates";
import QuestionLogModelBadge from "./QuestionLogModelBadge";
import QuestionLogStatus from "./QuestionLogStatus";

const QuestionLogListItem = ({ questionLog, selectedQuestionLog, handleQuestionLogSelect }) => {
    return (
        <div className={`question_log_list__item ${selectedQuestionLog?._id === questionLog._id ? 'active' : ''}`} onClick={() => { handleQuestionLogSelect(questionLog) }}>
            <div className="question_log_list__item__content">
                <div className="question_log_list__item__text">
                    <div className="question_log_list__item__text__username">{questionLog.conversation?.user?.user_info?.name || <span className="unknown_user">Unknown User</span>}</div>
                    {questionLog.question}
                </div>
                <div className="timestamp">{toTimestamp(questionLog.createdAt)}</div>
            </div>
            <div className="question_log_list__item__details">
                {questionLog.review && <QuestionLogStatus status={questionLog.review.status}/>}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {questionLog.model && <QuestionLogModelBadge model={questionLog.model}/>}
                    {questionLog.review?.new_answer && <div className="question_log_list__item__revised_status">Revised</div>}
                </div>
            </div>
        </div>
    );
}

export default QuestionLogListItem;
