import AgentProfileAvatar from "./AgentProfileAvatar"
import StatusText from '../Base/StatusText'
import { HiOutlineEnvelope, HiChatBubbleLeftRight } from 'react-icons/hi2';
import AgentName from "./AgentName";

const AgentPopup = (props) => {
    const agent = props.agent;
    return (
        <div className="popup_box" onMouseEnter={() => { props.onMouseEnter(true) }} onMouseLeave={() => { props.onMouseLeave(false) }}>
            <AgentProfileAvatar agent={agent} size="100"/>
            <div className="agent_popup_details">
                <b className="agent_popup_details__name"><AgentName agent={agent} currentAgent={props.currentAgent}/></b>
                <StatusText status={agent.agent_settings.status}/>
                <div className="agent_popup_details__detail mb-medium"><HiOutlineEnvelope/> <a href={'mailto:' + agent.email}>{agent.email}</a></div>
                {props.currentAgent._id !== agent._id && !(agent.disabled || agent.deleted) && <button className="btn" onClick={props.handleDirectMessage}><HiChatBubbleLeftRight/>Message</button>}
            </div>
        </div>
    );
}; 

export default AgentPopup;