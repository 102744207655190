import React, { useEffect, useRef, useState } from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import LoadMore from '../Base/LoadMore';
import ChatDateBadge from './ChatDateBadge';
import Message from './Message';
import { HiOutlineChevronDoubleUp } from 'react-icons/hi2';
import moment from 'moment';
import ChatEvent from './ChatEvent';
import VisibilitySensor from 'react-visibility-sensor';
import { toChatDate } from '../../services/dates';
import ChatNote from './ChatNote';

const ChatMessages = (props) => {
    const [currentMessages, setCurrentMessages] = useState(props.currentMessages);
    const scrollableRef = useRef();
    const loadMoreElement = useRef();
    const oldScrollHeightRef = useRef(0);
    const oldScrollTopRef = useRef(0);

    useEffect(() => {
        props.notificationRead(props.currentConversation.conversation_id, props.currentAgent._id);
    }, []);

    useEffect(() => {
        if (props.currentMessages && props.currentMessages !== currentMessages) {
            oldScrollHeightRef.current = scrollableRef.current.wrapperRef.current.scrollHeight;
            oldScrollTopRef.current = scrollableRef.current.wrapperRef.current.scrollTop;

            const updatedCurrentMessages = props.currentMessages.map((currentMessage) => ({
                ...currentMessage,
                new_message: true,
            }));
            setCurrentMessages(updatedCurrentMessages);
            props.notificationRead(props.currentConversation.conversation_id, props.currentAgent._id);
        }
    }, [props.currentMessages]);

    const renderedDates = [];
    let messagesAndEvents = [];
    if (currentMessages && currentMessages.length > 0) {
        messagesAndEvents = [
            ...currentMessages,
            ...props.currentConversation.events,
            ...props.currentConversation.notes,
        ];
        messagesAndEvents.sort((a, b) => moment(a.createdAt) - moment(b.createdAt));
    }

    // Handle change detection filter for scrollable feed for scrolling back on rerender
    const handleMessageChangeDetectionFilter = (previousProps, newProps) => {
        if(previousProps.currentMessages.length < newProps.currentMessages.length){
            const newScrollHeight = scrollableRef.current.wrapperRef.current.scrollHeight;

            scrollableRef.current.wrapperRef.current.scrollTop = oldScrollTopRef.current + (newScrollHeight - oldScrollHeightRef.current);
        }

        return true;
    }

    return (
        <ScrollableFeed debug={false} changeDetectionFilter={handleMessageChangeDetectionFilter} className="chat_messages_scroll" ref={scrollableRef} currentMessages={currentMessages}>
            <div className="chat_messages">
                {props.currentConversation ? (
                    <div>
                        {currentMessages && currentMessages.length > 0 ? (
                            <div>
                                {props.hasMoreMessages && (
                                    <VisibilitySensor
                                        onChange={(isVisible) => {
                                            if (isVisible) {
                                                props.handleLoadMoreMessages();
                                            }
                                        }}
                                    >
                                        <LoadMore
                                            ref={loadMoreElement}
                                            icon={<HiOutlineChevronDoubleUp />}
                                            onClick={() => {
                                                props.handleLoadMoreMessages();
                                            }}
                                            loadingMore={props.loadingMoreMessages}
                                        />
                                    </VisibilitySensor>
                                )}
                                {messagesAndEvents.map((messageOrEvent, index) => {
                                    let dateToRender = toChatDate(messageOrEvent.createdAt);
                                    if (!renderedDates.includes(dateToRender)) {
                                        renderedDates.push(dateToRender);
                                    } else {
                                        dateToRender = undefined;
                                    }
                                    return messageOrEvent.message_data ? (
                                        <div key={messageOrEvent._id}>
                                            {dateToRender && <ChatDateBadge date={dateToRender} />}
                                            {messageOrEvent.message_data && (
                                                <Message
                                                    currentAgent={props.currentAgent}
                                                    conversation={props.currentConversation}
                                                    key={messageOrEvent._id}
                                                    message={messageOrEvent}
                                                    handleMediaClick={props.handleMediaClick}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        moment(currentMessages[0].createdAt) < moment(messageOrEvent.createdAt) &&
                                        (messageOrEvent.owner ? (
                                            <ChatNote key={index} note={messageOrEvent} />
                                        ) : (
                                            <ChatEvent key={index} event={messageOrEvent} />
                                        ))
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="no_message">This chat has no messages yet...</div>
                        )}
                    </div>
                ) : (
                    <p>Loading messages...</p>
                )}
            </div>
        </ScrollableFeed>
    );
};

export default ChatMessages;