import { useState } from "react";
import { HiHandThumbDown, HiHandThumbUp, HiOutlineHandThumbDown, HiOutlineHandThumbUp } from "react-icons/hi2";

const QuestionLogStatusButtons = ({ status, handleStatusUpdate }) => {
    const [updatingStatus, setUpdatingStatus] = useState({
        updating: false,
        newStatus: null
    });

    const handleStatusUpdateClick = async (status) => {   
        setUpdatingStatus({
            updating: true,
            newStatus: status
        });

        await handleStatusUpdate(status);
        setUpdatingStatus({
            updating: false,
            newStatus: null
        });
    }
    return (
        <div className="question_log_status_buttons">
            <button disabled={updatingStatus.updating} className={`question_log_status_button upvote ${((status === 'upvoted' && !updatingStatus.updating) || updatingStatus.newStatus === 'upvoted') ? 'selected' : ''}`} onClick={() => handleStatusUpdateClick('upvoted')}>
                <HiHandThumbUp/>
                <span>{(updatingStatus.updating && updatingStatus.newStatus === 'upvoted') ? 'Upvoting...' : (status === 'upvoted' ? 'Upvoted' : 'Upvote')}</span>
            </button>
            <button disabled={updatingStatus.updating} className={`question_log_status_button downvote ${((status === 'downvoted' && !updatingStatus.updating) || updatingStatus.newStatus === 'downvoted') ? 'selected' : ''}`} onClick={() => handleStatusUpdateClick('downvoted')}>
                <HiHandThumbDown/>
                <span>{(updatingStatus.updating && updatingStatus.newStatus === 'downvoted') ? 'Downvoting...' : (status === 'downvoted' ? 'Downvoted' : 'Downvote')}</span>
            </button>
        </div>
    )
}

export default QuestionLogStatusButtons;