import React, { forwardRef } from 'react';
import { MdOutlineDragIndicator } from 'react-icons/md';

export const SortableStepDraggable = forwardRef(({ id, ...props }, ref) => {
	return (
		<div className='draggable_step draggable_overlay' ref={ref} {...props}>
            <div className='draggable_step__reorder_icon'><MdOutlineDragIndicator/></div>
            <div className='draggable_step__icon'><div dangerouslySetInnerHTML={{ __html: props.step.step_template.icon }}/></div>
            <div className='draggable_step__info'>
                <div className='draggable_step__info_name'>{props.step.step_template.name}</div>
                <div className='draggable_step__info_description'>{props.step.step_template.description}</div>
            </div>
        </div>
	)
});