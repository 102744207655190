import React from 'react';
import { connect } from 'react-redux';
import { toTimestamp } from '../../services/dates';
import { getJobs } from '../../services/job';
import { titleCase } from '../../services/misc';
import LoadMore from '../Base/LoadMore';
import Preloader from '../Preloader';
import socket from '../../services/socket';
import JobLogViewer from '../JobLogs/JobLogViewer';
import Tooltip from '@mui/material/Tooltip';

class WhatsAppPushNotificationJobList extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            loadingJobs: true,
            limit: 10,
            offset: 10
        }

        this.loadJobs = this.loadJobs.bind(this);
        this.loadMoreClick = this.loadMoreClick.bind(this);
        this.handleViewJobLogsTrigger = this.handleViewJobLogsTrigger.bind(this);
    }

    componentDidMount(){
        this.loadJobs();

        socket.on('jobUpdated', ({ id, jobId }) => {
            if(this.props.auth.selectedChatbot._id === id){
                this.loadJobs();
            }
        });
    }

    componentWillUnmount(){
        socket.off('jobUpdated');
    }

    async loadJobs(){
        const jobs = await getJobs(this.props.auth.selectedChatbot.token, 'whatsapp_broadcast', this.state.limit + 1);

        const hasMore = jobs.length > this.state.limit;
        if(hasMore){
            jobs.pop();
        }

        this.setState({ jobs, loadingJobs: false, hasMore })
    }

    async loadMoreClick(){
        this.setState(prevState => ({ loadingMore: true, limit: prevState.limit + prevState.offset }), async () => {
            await this.loadJobs();
            this.setState({ loadingMore: false });
        });
    }

    handleViewJobLogsTrigger(jobId){
        if(this.state.viewJobLogs === jobId){
            this.setState({ viewJobLogs: null })
        }else{
            this.setState({ viewJobLogs: jobId })
        }
    }

    render(){
        return (
            !this.state.loadingJobs ? (<table className='table mt-medium'>
                <thead>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Logs</th>
                    <th>Status</th>
                </thead>
                
                {this.state.jobs.length > 0 ? this.state.jobs.map(job => {
                    const numberOfFiltersApplied = job.metadata && job.metadata.filters ? Object.entries(job.metadata.filters).length : 0;
                    let filterString = "";
                    if(numberOfFiltersApplied > 0){
                        filterString = "<b>Filters Applied:</b><br/>";
                        for (const [key, value] of Object.entries(job.metadata.filters)) {
                            filterString += '<br/>' + key + ': ' + value
                        }
                        
                    }
                    return (
                        <tbody>
                            <tr>
                                <td><div className='job_name'>{job.metadata.whatsapp_template_name}</div><div className='timestamp'>{toTimestamp(job.createdAt)}</div></td>
                                <td><em className='job_decription'>{job.description} {job.metadata && job.metadata.filters ? <Tooltip title={<div dangerouslySetInnerHTML={{ __html: filterString}}/>}><div className='job_decription__filters_applied'>{`(${numberOfFiltersApplied} filter${numberOfFiltersApplied > 1 ? 's' : ''} applied)`}</div></Tooltip> : ''}</em></td>
                                <td ><div className='job_view_logs' onClick={(e) => { this.handleViewJobLogsTrigger(job._id) }}>View Logs</div></td>
                                <td><div className={`status_badge ${job.status}`}>{titleCase(job.status)}</div></td>
                            </tr>
                            {this.state.viewJobLogs === job._id && (
                                <tr><td className='job_log_column' colSpan={4}><JobLogViewer jobId={this.state.viewJobLogs} handleClose={() => { this.handleViewJobLogsTrigger(job._id) }}/></td></tr>
                            )}
                        </tbody>
                    )
                }) : (
                    <tr><td colSpan={4} className='none_found_message'>No broadcasts have been sent yet</td></tr>
                )}
                <tr>
                    <td colSpan={4} className='loadMoreColumn'>
                        {this.state.hasMore && <LoadMore loadingMore={this.state.loadingMore} onClick={this.loadMoreClick}/>}
                    </td>
                </tr>
                
            </table>) : <Preloader padding='30px 0px'/>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppPushNotificationJobList);