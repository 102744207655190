import { handleResponse } from "../misc";

const getConnectors = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
    };

    return fetch('/api/connector', requestOptions).then(handleResponse).then((connectors) => {
        return connectors;
    });
}

export {
    getConnectors
}