import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notificationsExpanded: false,
    notifications: []
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setNotificationsExpanded: (state, action) => {
            state.notificationsExpanded = action.payload;
        },
        clearNotifications: () => initialState
    }
});

export const {
    setNotifications,
    setNotificationsExpanded,
    clearNotifications
} = notificationsSlice.actions;

export default notificationsSlice.reducer;