import { TextField } from '@mui/material';
import { HiOutlineXMark, HiMagnifyingGlass } from 'react-icons/hi2';

const HelpRequestSearch = (props) => {
    return (
        <div className="search_container">
            <label>Search help requests</label>
            <form className="help_request_search" onSubmit={props.handleSearchSubmit}>
                <TextField size="small" placeholder="Search help requests" type="text" value={props.searchTerm} onChange={props.handleSearchChange} autoComplete="off"/>
                {props.isSearched && <div className="btn" onClick={props.clearSearch}><HiOutlineXMark/></div>}
                <button className="btn"><HiMagnifyingGlass/></button>
            </form>
        </div>
    );
};

export default HelpRequestSearch;