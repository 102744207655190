import React from 'react';
import HelpRequestWidgetItem from './HelpRequestWidgetItem';

const HelpRequestWidget = (props) => {
    return (
        <div className="help_request_widget">
            {props.helpRequests.map(helpRequestItem => (
                <HelpRequestWidgetItem key={helpRequestItem._id} helpRequest={helpRequestItem}/>
            ))}
        </div>
    );
};

export default HelpRequestWidget;
