import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { deleteIndex, getIndexes, updateIndex } from '../../services/knowedgeSetManager';
import { NavLink } from 'react-router-dom';
import { toTimestamp } from '../../services/dates';
import { titleCase } from '../../services/misc';
import { HiChatBubbleLeft, HiDocumentText, HiMiniTrash, HiPlusCircle, HiWrenchScrewdriver } from 'react-icons/hi2';
import Tooltip from '@mui/material/Tooltip';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';
import IndexCreateModal from './IndexCreateModal';
import { Switch } from '@mui/material';

const IndexList = (props) => {
    const [loading, setLoading] = useState(true);
    const [indexes, setIndexes] = useState([]);
    const [updatingIndex, setUpdatingIndex] = useState(null);
    const [confirmIndexDelete, setConfirmIndexDelete] = useState(null);
    const [deletingIndex, setDeletingIndex] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const indexes = await getIndexes(props.auth.selectedChatbot.token, props.knowledgeSet._id, props.knowledgeSet.latest_version.id);
            setIndexes(indexes);
            setLoading(false);
        };

        fetchData();
    }, [props.auth.selectedChatbot.token, props.knowledgeSet._id, props.knowledgeSet.latest_version.id]);

    const handleIndexToggle = async (indexId, disabled) => {
        setUpdatingIndex(indexId);
        await updateIndex(props.auth.selectedChatbot.token, indexId, { disabled });

        const indexes = await getIndexes(props.auth.selectedChatbot.token, props.knowledgeSet._id, props.knowledgeSet.latest_version.id);
        await props.retrieveAndUpdateKb();
        setIndexes(indexes);
        setLoading(false);
        setUpdatingIndex(null);
    };

    const handleDeleteIndexClick = (indexId) => {
        setConfirmIndexDelete(indexId);
    };

    const handleDeleteIndex = async (indexId) => {
        setDeletingIndex(true);
        setConfirmIndexDelete(null);
        await deleteIndex(props.auth.selectedChatbot.token, indexId, props.auth.agent.agent._id);

        const indexes = await getIndexes(props.auth.selectedChatbot.token, props.knowledgeSet._id, props.knowledgeSet.latest_version.id);
        await props.retrieveAndUpdateKb();
        setIndexes(indexes);
        setDeletingIndex(false);
    };

    const handleIndexesCreateClick = async () => {
        await props.handleCreateIndex();
        const indexes = await getIndexes(props.auth.selectedChatbot.token, props.knowledgeSet._id, props.knowledgeSet.latest_version.id);
        await props.retrieveAndUpdateKb();
        setIndexes(indexes);
        setLoading(false);
        setUpdatingIndex(null);
    };

    return loading ? (
        <Preloader />
    ) : (
        <>
            <div className='index_list__title'>
                <div className='index_list__title__text'>Preferred answers</div>
            </div>
            <div className='index_list__title mt-medium'>
                <div className='index_list__title__text'>Indexes</div>
                <button className='btn' onClick={props.handleAddIndexClick}>
                    <HiPlusCircle />New index
                </button>
            </div>
            <div className='index_list'>
                {props.indexToCreate && (
                    <IndexCreateModal
                        isOpen={props.indexToCreate}
                        onRequestClose={props.handleAddIndexClick}
                        indexToCreate={props.indexToCreate}
                        handleIndexCreateUpdate={props.handleIndexCreateUpdate}
                        handleSetFile={props.handleSetFile}
                        uploadingFile={props.uploadingFile}
                        onConfirm={handleIndexesCreateClick}
                    />
                )}
                <ConfirmModal
                    isOpen={confirmIndexDelete}
                    title='Delete index'
                    description='Are you sure you want to delete this index?'
                    confirmText='Delete'
                    onConfirm={() => {
                        handleDeleteIndex(confirmIndexDelete);
                    }}
                    onRequestClose={() => {
                        handleDeleteIndexClick(null);
                    }}
                />
                <LoadingModal isOpen={deletingIndex} text='Deleting index...' />
                {indexes.length > 0 ? (
                    <>
                        {indexes.find((index) => index.type === 'preferred_answers') && (
                        <NavLink to={`/knowledge-set-manager/${props.knowledgeSet._id}/index/${indexes.find((index) => index.type === 'preferred_answers').index_id}/version/${indexes.find((index) => index.type === 'preferred_answers').version_id}`} className={`index_list_item  ${indexes.find((index) => index.type === 'preferred_answers').disabled ? 'disabled' : null} preferred_answers`}>
                            <div className='index_list_item__header'>
                                <div className='index_list_item__type'>
                                    <HiWrenchScrewdriver/>
                                    {titleCase(indexes.find((index) => index.type === 'preferred_answers').type)}
                                </div>
                                <div className='index_list_item__actions'>
                                    <Switch disabled={updatingIndex === indexes.find((index) => index.type === 'preferred_answers')._id.toString()} checked={!indexes.find((index) => index.type === 'preferred_answers').disabled} onClick={(e) => {  e.stopPropagation(); }} onChange={(e) => { handleIndexToggle(indexes.find((index) => index.type === 'preferred_answers')._id, !indexes.find((index) => index.type === 'preferred_answers').disabled) }}/>
                                </div>
                            </div>
                            <div>
                                <div className='index_list_item__name'>{indexes.find((index) => index.type === 'preferred_answers').name}</div>
                                <div className='index_list_item__details'>
                                    <div className='index_list_item__details__item'>Set your preferred answers to fine tune your chatbots responses. Data put in here will be weighted heavier in the bot search.</div>
                                </div>
                                <div className='index_list_item__details__timestamp'>Last modified: {toTimestamp(indexes.find((index) => index.type === 'preferred_answers').updatedAt)}</div>
                            </div>
                        </NavLink>
                    )}
                    {indexes.map((index) => index.type !== 'preferred_answers' && (
                        <NavLink
                            to={!index.disabled ? `/knowledge-set-manager/${props.knowledgeSet._id}/index/${index.index_id}/version/${index.version_id}` : '/'}
                            className={`index_list_item ${index.disabled ? 'disabled' : null}`}
                            key={index.index_id}
                        >
                            <div className='index_list_item__header'>
                                <div className='index_list_item__type'>
                                    {index.type === 'document' ? <HiDocumentText /> : <HiChatBubbleLeft />}
                                    {titleCase(index.type)}
                                </div>
                                <div className='index_list_item__actions'>
                                    <Switch disabled={updatingIndex === index._id.toString()} checked={!index.disabled} onClick={(e) => {  e.stopPropagation(); }} onChange={(e) => { handleIndexToggle(index._id, !index.disabled) }}/>
                                    <Tooltip title="Delete index" arrow><button onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDeleteIndexClick(index._id) }}><HiMiniTrash/></button></Tooltip>
                                </div>
                            </div>
                            <div>
                                <div className='index_list_item__name'>{index.name}</div>
                                <div className='index_list_item__details__timestamp'>Last modified: {toTimestamp(index.updatedAt)}</div>
                            </div>
                        </NavLink>
                    ))}
                    </>
                ) : (
                    <span>No knowledge sets have been created. Please contact admin.</span>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(IndexList);
