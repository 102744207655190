import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getQueues } from '../../services/queues'
import { companyService } from '../../services/company';
import { getChannelFilters } from '../../services/conversation'
import { getTags } from '../../services/tags'
import { titleCase, channelLabel } from '../../services/misc'
import moment from 'moment';
import { isMobile } from '../../services/responsive'
import Tooltip from '@mui/material/Tooltip';
import { HiMinus } from 'react-icons/hi2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Divider } from '@mui/material';

const ChatFilters = (props) => {
    const auth = useSelector(state => state.auth);
    
    const [loadingQueues, setLoadingQueues] = useState(true);
    const [queueOptions, setQueueOptions] = useState(undefined);
    const [loadingChannels, setLoadingChannels] = useState(true);
    const [channelOptions, setChannelOptions] = useState(undefined);
    const [loadingTags, setLoadingTags] = useState(true);
    const [tagOptions, setTagOptions] = useState(undefined);
    const [loadingAgents, setLoadingAgents] = useState(true);
    const [agentOptions, setAgentOptions] = useState(undefined);

    const statusOptions = [
        { value: 'bot', label: 'Bot' },
        { value: 'open', label: 'Open' },
        { value: 'closed', label: 'Closed' }
    ];

    useEffect(() => {
        getQueues(auth.selectedChatbot.token, auth.agent.agent._id).then(queues => {
            setLoadingQueues(false);
            setQueueOptions(queues.map(queue => ({ value: queue._id, label: queue.name })));
        });

        getChannelFilters(auth.selectedChatbot.token).then(channels => {
            setLoadingChannels(false);
            setChannelOptions(channels.map(channel => ({ value: channel, label: titleCase(channelLabel(channel)) })));
        });

        getTags(auth.selectedChatbot.token).then(tags => {
            setLoadingTags(false);
            setTagOptions(tags.map(tag => ({ value: tag._id, label: tag.label })));
        });

        companyService.getCompanyAgents(auth.selectedCompany, auth.agent.token).then(agents => {
            setLoadingAgents(false);
            setAgentOptions(agents.map(agent => ({ value: agent._id, label: agent.first_name + " " + agent.last_name })));
        });
    }, [auth.selectedChatbot.token, auth.agent.agent._id, auth.selectedCompany, auth.agent.token]);

    return (
        <div className="chats_filter">
            <div className="label_title d-flex">Filter<Tooltip title="Minimize" arrow><div className='queue_list__action' onClick={props.handleFilterTrigger}><HiMinus/></div></Tooltip>
            </div>
            <div>
                <Autocomplete
                    id="queue-select"
                    className="custom_select" 
                    value={(queueOptions && props.filters['queue']) ? queueOptions.find(option => option.value === props.filters['queue']) : null}
                    options={queueOptions || []}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => { props.handleSetFilter('queue', newValue ? newValue.value : undefined ) }}
                    loading={loadingQueues}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Queue"
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingQueues ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <Autocomplete
                    id="channel-select"
                    className="custom_select" 
                    options={channelOptions || []}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => { props.handleSetFilter('channel', newValue ? newValue.value : undefined ) }}
                    loading={loadingChannels}
                    value={(channelOptions && props.filters['channel']) ? channelOptions.find(option => option.value === props.filters['channel']) : null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Channel"
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingChannels ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <Autocomplete
                    id="tags-select"
                    className="custom_select" 
                    options={tagOptions || []}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => { props.handleSetFilter('tags', newValue ? newValue.value : undefined ) }}
                    loading={loadingTags}
                    value={(tagOptions && props.filters['tags']) ? tagOptions.find(option => option.value === props.filters['tags']) : null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tags"
                            size="small"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingTags ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <Autocomplete
                    id="agents-select"
                    className="custom_select" 
                    options={agentOptions || []}
                    getOptionLabel={(option) => option.label}
                    value={(agentOptions && props.filters['agents']) ? agentOptions.find(option => option.value === props.filters['agents']) : null}
                    onChange={(event, newValue) => { props.handleSetFilter('agents', newValue ? newValue.value : undefined ) }}
                    loading={loadingAgents}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Agents"
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingAgents ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <Autocomplete
                    id="status-select"
                    className="custom_select" 
                    options={statusOptions}
                    value={(statusOptions && props.filters['status']) ? statusOptions.find(option => option.value === props.filters['status']) : null}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => { props.handleSetFilter('status', newValue ? newValue.value : undefined ) }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Status"
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Box sx={{ width: "100%", mb: '15px' }}>
                        <DatePicker
                            label="Start Date"
                            value={props.filters['startDate'] ? moment(props.filters['startDate']) : null}
                            disableFuture={true}
                            format='DD/MM/YYYY'
                            maxDate={props.filters['endDate'] ? moment(props.filters['endDate']) : null}
                            sx={{ width: '100%' }}
                            onChange={(newValue) => {
                                props.handleSetFilter('startDate', newValue ? newValue.format('YYYY-MM-DD') : undefined);
                            }}
                            slotProps={{
                                field: { size: 'small', clearable: true, onClear: () => {
                                    props.handleSetFilter('startDate', undefined);
                                } },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <DatePicker
                            label="End Date"
                            value={props.filters['endDate'] ? moment(props.filters['endDate']) : null}
                            disableFuture={true}
                            format='DD/MM/YYYY'
                            minDate={props.filters['startDate'] ? moment(props.filters['startDate']) : null}
                            sx={{ width: '100%' }}
                            onChange={(newValue) => {
                                props.handleSetFilter('endDate', newValue ? newValue.endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined);
                            }}
                            slotProps={{
                                field: { size: 'small', clearable: true, onClear: () => {
                                    props.handleSetFilter('endDate', undefined);
                                } },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                    </LocalizationProvider>
            </div>
        </div>
    );
};

export default ChatFilters;
