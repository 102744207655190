import { TextField } from '@mui/material';
import Preloader from '../Preloader';

const MeetingIdForm = (props) => {
    return (
        <div className="meeting_room__content_box">
            <div className="meeting_room__content_box__sub_title">Join a Meeting</div>
            <div className="meeting_room__content_box__body">Enter your unique meeting room ID in the box below to connect your meeting.</div>
            <form className="meeting_room_form" onSubmit={props.handleMeetingIdSubmit}>
                <TextField fullWidth sx={{ mb: '10px'}} disabled={props.validatingId} value={props.meetingIdInput} onChange={props.handleMeetingIdInput} type="text" placeholder="Enter meeting ID"/>
                <button disabled={props.meetingIdInput.length !== 36 || props.validatingId}>{!props.validatingId ? 'Connect to meeting' : <Preloader color="white" size="15" padding="0"/>}</button>
            </form>                
            {props.alertMessage && <div className="alert_box alert-danger">{props.alertMessage}</div>}
        </div>
    );
};

export default MeetingIdForm;