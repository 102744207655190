import { configureStore  } from '@reduxjs/toolkit';

import authSlice from '../reducers/authSlice';
import chatsSlice from '../reducers/chatsSlice';
import meetingSlice from '../reducers/meetingSlice';
import notificationsSlice from '../reducers/notificationsSlice';
import helpRequestsSlice from '../reducers/helpRequestsSlice';
import internalChatsSlice from '../reducers/internalChatsSlice';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        notifications: notificationsSlice,
        chats: chatsSlice,
        internalChats: internalChatsSlice,
        helpRequests: helpRequestsSlice,
        meetings: meetingSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
});

