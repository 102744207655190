import { setMeetingsWaiting } from '../reducers/meetingSlice';
import { handleResponse } from './misc';

const createMeeting = (authToken, title, owner, conversationId) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ title, owner, conversation: conversationId })
    };

    return fetch('/api/meetings', requestOptions).then(handleResponse).then((meeting) => {
        return meeting;
    });
}

const validateMeeting = (meetingId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/meetings/' + meetingId, requestOptions).then(handleResponse).then((meeting) => {
        return meeting;
    });
};

const getWaitingMeetings = (authToken, agentId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/meetings/agent/' + agentId + '?waiting=true', requestOptions).then(handleResponse).then((meetings) => {
        return meetings;
    });
};

const getLatestConversationMeeting = (authToken, conversationId) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
                
            };
        
            fetch('/api/meetings/conversation/' + conversationId, requestOptions).then(handleResponse).then((meeting) => {
                resolve(meeting);
            });
        }catch(e){
            reject(e);
        }
    })
};

const setUsersWaiting = (meetingId, waiting) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/meetings/' + meetingId + '/' + waiting.toString(), requestOptions).then(handleResponse).then((meeting) => {
        return meeting;
    });
};

const connectAgent = (meetingId, agentId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/meetings/' + meetingId + '/agent/' + agentId + '/connect', requestOptions).then(handleResponse).then((meeting) => {
        return meeting;
    });
};

const disconnectAgent = (meetingId, agentId) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('/api/meetings/' + meetingId + '/agent/' + agentId + '/disconnect', requestOptions).then(handleResponse).then((meeting) => {
        return meeting;
    });
};

const setMeetings = async (connectedProps) => {
    const meetingsWaiting = await getWaitingMeetings(connectedProps.auth.selectedChatbot.token, connectedProps.auth.agent.agent._id);
    await connectedProps.dispatch(setMeetingsWaiting(meetingsWaiting));
};

export { createMeeting, validateMeeting, getWaitingMeetings, setMeetings, setUsersWaiting, connectAgent, disconnectAgent, getLatestConversationMeeting }