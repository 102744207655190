import React from 'react';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi2';
import Preloader from '../Preloader';

const LoadMore = (props) => {
    const handleLoadMoreClick = () => {
        props.onClick();
    }

    const icon = props.icon || <HiOutlineChevronDoubleDown/>;

    return (
        <div className="load_more" onClick={handleLoadMoreClick}>
            <div className="load_more__button" title="Load More">
                {!props.loadingMore ? icon : <Preloader size="20"/>}
            </div>
        </div>
    );
}

export default LoadMore;
