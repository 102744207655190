import { TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { HiX } from "react-icons/hi";
import { HiMiniSparkles } from "react-icons/hi2";
import { generateAttachmentAiDescription } from "../../services/knowedgeSetManager";


const ChunkAttachmentEditor = ({ attachment, handleChunkAttachmentRemove, handleChunkAttachmentUpdate, handleAiDescriptionGenerate }) => {
    const [generatingDescription, setGeneratingDescription] = useState(false)

    const handleChunkTextAttachmentUpdate = (e) => {
        handleChunkAttachmentUpdate({
            ...attachment,
            [e.target.name]: e.target.value
        })
    }

    const handleAiDescriptionGenerateClick = async () => {
        setGeneratingDescription(true)

        // Call AI to generate description
        const description = await handleAiDescriptionGenerate(attachment.title, attachment.url);

        handleChunkAttachmentUpdate({
            ...attachment,
            description
        })
        setGeneratingDescription(false)
    }

    return (
        <div className="chunk_attachment_editor">
            <Tooltip title="Remove attachment"><button className="remove_attachment" alt="Remove attachment" onClick={handleChunkAttachmentRemove}><HiX/></button></Tooltip>
            <div className="message_media_item"><div className={`message_media_item__fit `} style={{ cursor: 'default' }}><img src={attachment.url} alt="Message Media"/></div></div>
            <TextField sx={{ mb: '8px' }} name="title" fullWidth={true} size="small" onChange={handleChunkTextAttachmentUpdate} value={attachment.title} placeholder="Enter file title"/>
            <TextField fullWidth={true} size="small" name="description" onChange={handleChunkTextAttachmentUpdate} value={attachment.description} placeholder="Enter file description" minRows={3} multiline/>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="btn mt-medium" disabled={generatingDescription} onClick={handleAiDescriptionGenerateClick}><HiMiniSparkles/>{generatingDescription ? 'Generating...' : 'Generate Description'}</button>
            </div>
        </div>
    )
}

export default ChunkAttachmentEditor;