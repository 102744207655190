import { Tooltip } from "@mui/material";
import { useState } from "react";
import { FiCopy } from "react-icons/fi";

const CodeBlock = ({ code, label }) => {
    const lines = code.split('\n');

    const handleCopyClick = () => {
        navigator.clipboard.writeText(code);
        setTooltipText("Copied");
        setTimeout(() => {
            setTooltipText("Copy");
        }, 5000);
    };

    const [tooltipText, setTooltipText] = useState("Copy");

    return (
        <div className="code_block">
            <div className="code_block_top_bar">
                <div className="code_block_label">{label}</div>
                <div className="code_block_actions">
                    <Tooltip title={tooltipText} arrow>
                        <button onClick={handleCopyClick}>
                            <FiCopy />
                        </button>
                    </Tooltip>
                </div>
            </div>
            <div className="code_block_content">
                <div className="line_numbers">
                    {lines.map((line, index) => (
                        <div className="line_number">{index + 1}</div>
                    ))}
                </div>
                <pre>{code}</pre>
           </div>
        </div>
    );
};

export default CodeBlock;