import AgentProfileAvatar from "../Agents/AgentProfileAvatar";
import { HiOutlineTrash} from 'react-icons/hi2';
import { toTimestamp } from '../../services/dates';
import Tooltip from '@mui/material/Tooltip';

const ChatNotesListItem = (props) => {
    const note = props.note;
    return (
        <div className="chats_notes_list_item">
            <div className="chats_notes_list_item__agent"><div className="chats_notes_list_item__agent__name"><AgentProfileAvatar size="25" agent={note.owner}/>{note.owner.first_name[0]}. {note.owner.last_name}</div><div className="timestamp">{toTimestamp(note.createdAt)}</div><Tooltip title="Delete" arrow><div className="delete_note" onClick={() => { props.handleNoteDeleteModalTrigger(note._id) }}><HiOutlineTrash/></div></Tooltip></div>
            <div className="chats_notes_list_item__text">{note.text}</div>
        </div>
    );
}  

export default ChatNotesListItem;