import { useSelector, useDispatch } from 'react-redux';
import StatusSelector from './StatusSelector';
import StatusText from './StatusText';
import { agentService } from '../../services/agents';
import { setCurrentAgent } from '../../reducers/authSlice';

const Status = ({ agent }) => {
    const auth = useSelector(state => state.auth);
    const currentAgent = auth.agent.agent;
    const dispatch = useDispatch();

    const handleStatusSelectChange = (e, status) => {
        agentService.setStatus(auth.agent.token, currentAgent._id, status).then((agent) => {
            dispatch(setCurrentAgent(agent));
        });
    }

    return (
        <div className="status">
            {agent._id === currentAgent._id ? (
                <StatusSelector onChange={handleStatusSelectChange} agent={currentAgent} />
            ) : (
                <StatusText status={currentAgent.agent_settings.status} />
            )}
        </div>
    );
}

export default Status;
