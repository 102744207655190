import { HiOutlineXMark } from 'react-icons/hi2';

const InternalAgentSearch = (props) => {
    return (
        <form className="internal_chat_search" onSubmit={(e) => e.preventDefault()}>
            <input type="text" placeholder="Type to search for agent" value={props.searchTerm} onChange={props.handleSearchChange}/>{props.isSearching && <div class="search_action_button" onClick={props.clearSearch}><HiOutlineXMark/></div>}
        </form>
    );
}

export default InternalAgentSearch;