import Tooltip from '@mui/material/Tooltip';

const Widget = (props) => {
    return (
        <div className="widget">
            <div className="widget_title">
                {props.title}
                {props.actions && props.actions.length > 0 && (
                    <div className="widget_actions">
                        {props.actions.map((action, index) => <Tooltip title={action.title} arrow key={index}><div className="widget_action" onClick={action.onClick}>{action.icon}</div></Tooltip>)}
                    </div>
                )}
            </div>
            <div className="widget_inner">{props.component}</div>
        </div>
    );
}

export default Widget;