import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQueues } from '../../services/queues'
import { agentService } from '../../services/agents'
import { getChannelFilters } from '../../services/conversation'
import { getTags } from '../../services/tags'
import { titleCase, channelLabel } from '../../services/misc'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ChatSort = (props) => {
    const [loadingQueues, setLoadingQueues] = useState(true);
    const [queueOptions, setQueueOptions] = useState(undefined);
    const [loadingChannels, setLoadingChannels] = useState(true);
    const [channelOptions, setChannelOptions] = useState(undefined);
    const [loadingTags, setLoadingTags] = useState(true);
    const [tagOptions, setTagOptions] = useState(undefined);
    const [loadingAgents, setLoadingAgents] = useState(true);
    const [agentOptions, setAgentOptions] = useState(undefined);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        getQueues(auth.selectedChatbot.token, auth.agent.agent._id).then(queues => {
            setLoadingQueues(false);
            setQueueOptions(queues.map(queue => ({ value: queue._id, label: queue.name })));
        });

        getChannelFilters(auth.selectedChatbot.token).then(channels => {
            setLoadingChannels(false);
            setChannelOptions(channels.map(channel => ({ value: channel, label: titleCase(channelLabel(channel)) })));
        });

        getTags(auth.selectedChatbot.token).then(tags => {
            setLoadingTags(false);
            setTagOptions(tags.map(tag => ({ value: tag._id, label: tag.label })));
        });

        agentService.getAgents(auth.agent.token).then(agents => {
            setLoadingAgents(false);
            setAgentOptions(agents.map(agent => ({ value: agent._id, label: agent.first_name + " " + agent.last_name })));
        });
    }, [auth.selectedChatbot.token, auth.agent.agent._id, auth.agent.token]);

    const handleChange = (event) => {
        props.handleSetSort(event.target.value);
    };

    return (
        <div className="chats_filter">
            <div>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.sortBy}
                            label="Sort Order"
                            onChange={handleChange}
                        >
                            <MenuItem value={'desc'}>Newest to oldest</MenuItem>
                            <MenuItem value={'asc'}>Oldest to newest</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
        </div>
    );
};

export default ChatSort;
