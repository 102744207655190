import { Skeleton } from "@mui/material";
import { HiXMark } from "react-icons/hi2";

const WebChatPreview = ({ webchat}) => {
    return (
        <>
            <div className="webchat_preview">
                <div className="webchat_header" style={{ background: `linear-gradient(135deg, rgb(${webchat.settings.primary_color}) 0%, rgb(${webchat.settings.secondary_color}) 100%)` }}>
                    {webchat.settings.header_image ? (
                        <img src={webchat.settings.header_image} alt="Header image" className="webchat_header_image"/>
                    ) : (
                        <>
                            {webchat.settings.avatar && <img src={webchat.settings.avatar} alt="Bot avatar" className="webchat_header_bot_avatar"/>}
                            <div className="webchat_header_bot_name">{webchat.settings.name}</div>
                        </>
                    )}
                </div>
                <div className="webchat_body">
                    <div className="webchat_preview_message bot">
                        <div className="webchat_preview_message__content " >
                            <div className="webchat_message__text">Bot message</div>
                        </div>
                    </div>
                    <div className="webchat_preview_message user">
                        <div className="webchat_preview_message__content user" style={{ background: `linear-gradient(135deg, rgb(${webchat.settings.primary_color}) 0%, rgb(${webchat.settings.secondary_color}) 100%)` }} >
                            <div className="webchat_message__text">User message</div>
                        </div>
                    </div>
                </div>
                <div className="webchat_input">
                    <div className="webchat_input__text"><Skeleton width={"50%"} animation={null}/></div>
                </div>
            </div>
            <div className="webchat_preview__expand" style={{ background: `linear-gradient(135deg, rgb(${webchat.settings.primary_color}) 0%, rgb(${webchat.settings.secondary_color}) 100%)` }}><HiXMark/></div>
        </>
    )
}

export default WebChatPreview;