import { handleResponse } from './misc';

const getKnowledgeSets = (authToken) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            fetch('/api/knowledge-sets', requestOptions).then(handleResponse).then((knowledgeSets) => {
                resolve(knowledgeSets);
            });
        }catch(e){
            reject(e);
        }
    });
}

const getKnowledgeSet = (authToken, id) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            fetch('/api/knowledge-sets/' + id, requestOptions).then(handleResponse).then((knowledgeSet) => {
                resolve(knowledgeSet);
            }).catch((e) => {
                reject(e)
            })
        }catch(e){
            reject(e);
        }
    });
}

const getIndexes = (authToken, knowledgeSetId, versionId) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            fetch('/api/kbIndexes/knowledgeSet/' + knowledgeSetId + '/version/' + versionId, requestOptions).then(handleResponse).then((indexes) => {
                resolve(indexes);
            });
        }catch(e){
            reject(e);
        }
    });
}

const getIndex = (authToken, indexId, versionId) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            fetch('/api/kbIndexes/' + indexId + '/version/' + versionId, requestOptions).then(handleResponse).then((index) => {
                resolve(index);
            }).catch((e) => {
                reject(e)
            });
        }catch(e){
            reject(e);
        }
    });
}

const createIndex = (authToken, indexData, changedBy) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
                body: JSON.stringify({
                    ...indexData,
                    changedBy: changedBy
                })
            };
            
            fetch('/api/kbIndexes', requestOptions).then(handleResponse).then((createdIndex) => {
                resolve(createdIndex);
            }).catch((e) => {
                reject(e)
            });
        }catch(e){
            reject(e);
        }
    });
}

const importChunks = (authToken, indexId, versionId, document, delimeter, changedBy) => {
    var data = new FormData()
    data.append('file', document)
    data.append('delimeter', delimeter)
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': 'Bearer ' + authToken },
                body: data
            };
            
            fetch('/api/kbIndexes/'+indexId+'/version/'+versionId+'/import', requestOptions)
                .then(handleResponse)
                .then((response) => {
                    resolve(response);
                })
                .catch((e) => {
                    reject(e)
                });
        }catch(e){
            reject(e);
        }
    });
}

const updateIndex = (authToken, indexId, updates) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
                body: JSON.stringify(updates)
            };
            
            fetch('/api/kbIndexes/' + indexId, requestOptions).then(handleResponse).then((index) => {
                resolve(index);
            }).catch((e) => {
                reject(e)
            });
        }catch(e){
            reject(e);
        }
    });
}

const attachDocument = (authToken, knowledgeSetId, document) => {
    var data = new FormData()
    data.append('file', document)

    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': 'Bearer ' + authToken },
                body: data
            };
            
            fetch('/api/knowledgeSets/'+knowledgeSetId+'/upload', requestOptions)
                .then(handleResponse)
                .then((kbIndex) => {
                    resolve(kbIndex);
                })
                .catch((e) => {
                    reject(e)
                });
        }catch(e){
            reject(e);
        }
    });
}

const deleteIndex = (authToken, indexId, changedBy) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
                body: JSON.stringify({
                    changedBy
                })
            };
            
            fetch('/api/kbIndexes/' + indexId, requestOptions).then(handleResponse).then((index) => {
                resolve(index);
            }).catch((e) => {
                reject(e)
            });
        }catch(e){
            reject(e);
        }
    });
}

const reorderChunks = (authToken, knowledgeSetId, versionId, indexId, chunkIds) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
                body: JSON.stringify({
                    new_order: chunkIds
                })
            };

            fetch('/api/chunks/knowledgeSet/' + knowledgeSetId + '/version/' + versionId + '/index/' + indexId + '/reorder', requestOptions).then(handleResponse).then((chunks) => {
                resolve(chunks);
            }
        )}catch(e){
            reject(e);
        }
    });
}

const getChunks = (authToken, knowledgeSetId, versionId, indexId, sortBy) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            let queryString = '';
            queryString += sortBy ? '?sortBy=' + sortBy : '';

            fetch('/api/chunks/knowledgeSet/' + knowledgeSetId + '/version/' + versionId + '/index/' + indexId + queryString, requestOptions).then(handleResponse).then((chunks) => {
                resolve(chunks);
            });
        }catch(e){
            reject(e);
        }
    });
}

const searchChunks = (authToken, knowledgeSetId, versionId, indexId, limit, searchText, sortBy) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            let queryString = '';
            queryString += sortBy ? '&sortBy=' + sortBy : '';

            fetch(`/api/chunks/knowledgeSet/${knowledgeSetId}/version/${versionId}/index/${indexId||'all'}/search?limit=${limit}&searchText=${encodeURIComponent(searchText)}${queryString}`, requestOptions)
                .then(handleResponse)
                .then((chunks) => {
                    resolve(chunks);
                });
        }catch(e){
            reject(e);
        }
    });
}

const aiFormatChunk = (authToken, chunkId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/chunks/' + chunkId + '/ai-format', requestOptions).then(handleResponse).then((chunk) => {
        return chunk;
    })
};

const generateAttachmentAiDescription = (authToken, knowledgeSetId, imageName, chunkText, imageUrl) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            image_name: imageName,
            text: chunkText,
            image_url: imageUrl
        })
    };

    return fetch('/api/knowledge-sets/'+knowledgeSetId+'/attachment/generate-description', requestOptions).then(handleResponse).then((image_description_response) => {
        return image_description_response.image_description;
    })
}

const addChunk = (authToken, knowledgeSetId, versionId, indexId, chunk, changed_by) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            knowledge_set_id: knowledgeSetId,
            version_id: versionId,
            index_id: indexId,
            changed_by,
            ...chunk
        })
    };

    return fetch('/api/chunks', requestOptions).then(handleResponse).then((chunk) => {
        return chunk;
    })
}

const updateChunk = (authToken, chunkId, updates, changedBy) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            updates,
            changedBy
        })
    };

    return fetch('/api/chunks/' + chunkId, requestOptions).then(handleResponse).then((chunk) => {
        return chunk;
    })
}

const deleteChunk = (authToken, chunkId, changedBy) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            changedBy
        })
    };

    return fetch('/api/chunks/' + chunkId, requestOptions).then(handleResponse).then((chunk) => {
        return chunk;
    })
}

const publishKnowledgeSet = (authToken, knowledgeSetId, changedBy) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            changedBy
        })
    };

    return fetch('/api/knowledge-sets/' + knowledgeSetId + '/publish', requestOptions)
        .then(handleResponse)
        .then((knowledgeSetVersion) => {
            return knowledgeSetVersion;
        });
}

const saveToUat = (authToken, knowledgeSetId, changedBy) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            changedBy
        })
    };

    return fetch('/api/knowledge-sets/' + knowledgeSetId + '/save-to-uat', requestOptions)
        .then(handleResponse)
        .then((knowledgeSetVersion) => {
            return knowledgeSetVersion;
        });
}

const saveToDraft = (authToken, knowledgeSetId, changedBy) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            changedBy
        })
    };

    return fetch('/api/knowledge-sets/' + knowledgeSetId + '/save-to-draft', requestOptions)
        .then(handleResponse)
        .then((knowledgeSetVersion) => {
            return knowledgeSetVersion;
        });
};

const getAuditTrailItems = (authToken, knowledgeSetId, versionId, limit, offset) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            fetch(`/api/auditTrailItems/knowledgeSet/${knowledgeSetId}/version/${versionId}?limit=${limit}&offset=${offset}`, requestOptions).then(handleResponse).then((auditTrailItems) => {
                resolve(auditTrailItems);
            }).catch((e) => {
                reject(e)
            })
        }catch(e){
            reject(e);
        }
    });
}

const getDocumentPreview = (authToken, indexId) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
            
            fetch(`/api/kbIndexes/${indexId}/document`, requestOptions)
                .then(handleResponse)
                .then(data => {
                    // Use data.blobSasUrl
                    resolve(data);
                });
        }catch(e){
            console.log(e);
            reject(e);
        }
    })
}

export { 
    getKnowledgeSets, 
    getKnowledgeSet, 
    getIndexes, 
    createIndex,
    importChunks,
    getIndex, 
    updateIndex,
    reorderChunks,
    getChunks, 
    searchChunks,
    attachDocument,
    aiFormatChunk,
    generateAttachmentAiDescription,
    addChunk, 
    updateChunk, 
    deleteChunk, 
    publishKnowledgeSet, 
    saveToUat, 
    saveToDraft, 
    getAuditTrailItems, 
    getDocumentPreview,
    deleteIndex
}