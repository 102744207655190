import React from 'react';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { connect } from 'react-redux';
import { getContactLists } from '../../services/contacts';
import Preloader from '../Preloader';

class WhatsAppContactListPicker extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loadingContactList: true
        }
    }

    async componentDidMount(){
        const contactLists = await getContactLists(this.props.auth.selectedChatbot.token);
        this.setState({ contactLists, loadingContactList: false })
    }
        
    render(){
        return !this.state.loadingContactList ? (
            <div>
                {(this.state.contactLists && this.state.contactLists.length > 0) ? (
                    <div>
                        {this.state.contactLists.map(contactList => (
                            
                            <div className={`whatsapp_template_builder_option_list_option ${this.state.recipientType === 'file' ? 'checked' : ''}`} onClick={() => { this.props.onClick(contactList) }}>
                                <div className='form_checkbox'></div>
                                <div className='whatsapp_template_builder_option_list_option_icon'><TbFileSpreadsheet/></div>
                                <div className='whatsapp_template_builder_option_list_option_label'>
                                    <div className='whatsapp_template_builder_option_list_option_label__label'>{contactList.name}</div>
                                    <div className='whatsapp_template_builder_option_list_option_label__description'>Import your recipient list from an external Excel or CSV file. Please note you will need to select a numbers column in this document</div>
                                </div>
                            </div>
                        ))}
                        <div className='whatsapp_template_step__actions'>                                    
                            <button className='btn' onClick={this.props.onCancel}>Previous step</button>
                            <button type='submit' className='btn' onClick={this.handleSubmit}>Next</button>
                        </div>
                    </div>
                ) : <div>No contact lists yet. <button className='btn'>Import Contacts</button></div>}
            </div>
        ) : <Preloader/>
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppContactListPicker);