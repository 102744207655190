import React from 'react';
import PasswordResetForm from './PasswordResetForm';

const PasswordResetPage = () => {
    return (
        <div className="login_page">
            <div className="login_page__container">
                <div className="container">
                    <div className="col-6">
                        <img alt="Set Password" className="login_page__image" src="/images/password_set_page.svg"/>
                    </div>
                    <div className="col-6">
                        <img className="login_page__logo" src="/images/logo_full.webp" alt="Verge AI Logo"/>
                        <PasswordResetForm/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordResetPage;
