import ChatNotesListItem from "./ChatNotesListItem";

const ChatNotesList = (props) => {
    return (
        <div className="chats_notes_list">
            {props.notes.length > 0 ? (
                <div className="chats_notes_list__inner">
                    {props.notes.map((note, index) => <ChatNotesListItem key={index} handleNoteDeleteModalTrigger={props.handleNoteDeleteModalTrigger} note={note}/>)}
                </div>
            ) : (
                <p>No notes yet</p>
            )}
        </div>
    );
}

export default ChatNotesList;