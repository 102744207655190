import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import ColorPickerInput from '../../Base/ColorPickerInput';
import { useState } from 'react';
import WebChatPreview from './WebChatPreview';

const WebChatThemeEditor = ({ webchat, handleWidgetUpdate }) => {

    const handleColorChange = (field, color) => {
        handleWidgetUpdate('settings', { ...webchat.settings, [field]: color })
    }

    return (
        <div className="webchat_theme_editor">
            <div className='webchat_theme_editor_col'>
                <ColorPickerInput size={"small"} style={{ width: '120px' }} className="mb-medium" label="Primary color" value={`rgb(${webchat.settings.primary_color})`} handleColorChange={(color) => { handleColorChange('primary_color', color) }}/>
                <ColorPickerInput size={"small"} style={{ width: '120px' }} label="Secondary color" value={`rgb(${webchat.settings.secondary_color})`} handleColorChange={(color) => { handleColorChange('secondary_color', color) }}/>
            </div>
            <div className='webchat_theme_editor_col webchat_preview_container'>
                <div className='webchat_theme_editor_preview_title'>Preview</div>
                <WebChatPreview webchat={webchat}/>
            </div>
        </div>
    )
}

export default WebChatThemeEditor;