import React, { useRef, useState } from 'react';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi2';
import StatusText from './StatusText';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

function StatusSelector(props) {
    const [menuShown, setMenuShown] = useState(false);
    const agent = props.agent;

    function handleMenuToggle() {
        setMenuShown(prevMenuShown => !prevMenuShown);
    };

    const handleClickAway = () => {
        if(menuShown){
            handleMenuToggle()
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="status_selector" onClick={handleMenuToggle}>
                <StatusText status={agent.agent_settings.status} />
                {menuShown ? <HiOutlineChevronUp className="chevron" /> : <HiOutlineChevronDown className="chevron" />}
                {menuShown && (
                    <div className="popup_box">
                        <div className="status_selector__item" onClick={(e) => { props.onChange(e, 'available') }}><StatusText status="available" /></div>
                        <div className="status_selector__item" onClick={(e) => { props.onChange(e, 'busy') }}><StatusText status="busy" /></div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
}

export default StatusSelector;
