import React, { useState } from 'react';
import Preloader from '../Preloader';
import validator from 'validator';
import { agentService } from '../../services/agents';
import AlertBox from '../AlertBox';
import { TextField } from '@mui/material';

const PasswordResetForm = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setSubmitting(true);
        
        try {
            await agentService.resetPassword(email);
            setSubmitting(false);
            setSubmitted(true);
        } catch (e) {
            setError(e);
            setSubmitting(false);
        }
    };

    return (
        <div className="login_form">
            {!submitted ? (
                <div>
                    <h2>Reset Password</h2>
                    <p>Enter the email address of your account and we will send you an email with a password reset link.</p>
                    <form className="login_form__form" name="form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <TextField label="Email" fullWidth disabled={submitting} type="email" name="email" value={email} onChange={handleChange} autoComplete="email" placeholder="Enter your email"/>
                        </div>
                        <div className="form-group">
                            <button disabled={submitting || !validator.isEmail(email)} className="button">{submitting ? <Preloader size="15" color="white" padding="0"/> : 'Reset Password'}</button>
                        </div>
                        {error && <AlertBox type="error">{error}</AlertBox>}
                    </form>
                </div>
            ) : (
                <div>
                    <h2>Check Your Email</h2>
                    <p>A password reset link has been sent to your email address.<br/><br/>If you cannot find this email in your inbox. Check your spam folder.</p>
                    <form className="login_form__form" name="form" onSubmit={handleSubmit}>
                        <button disabled={submitting || !validator.isEmail(email)} className="button">{submitting ? <Preloader size="15" color="white" padding="0"/> : 'Resend Link'}</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default PasswordResetForm;