import React from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom'
import AdminDashboard from '../components/AdminSettings/AdminDashboard';

const AdminRoute = ({ dispatch, isAuthenticated, component: Component, loggedIn, auth, agent, allowedRoles, ...rest }) => {
    const navigate = useNavigate();

    if(!localStorage.getItem('user')){
        navigate('/login');
        return null;
    }

    if(!(agent && agent.role === 'admin')){
        navigate('/login');
        return null;
    }

    return (
        <div>
            <AdminDashboard component={<Outlet/>}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid,
    auth: state.auth ? state.auth : undefined,
    agent: state.auth.agent ? state.auth.agent.agent : undefined
})

export default connect(mapStateToProps)(AdminRoute)