import React, { useRef } from 'react';
import { toTimestamp } from '../../services/dates';
import VisibilitySensor from 'react-visibility-sensor';
import LoadMore from '../Base/LoadMore';

const AuditTrailList = (props) => {
    const loadMoreElement = useRef(null);
    let lastTitle = '';

    return (
        <div>
            {props.auditTrail.map((item) => {
                let status = '';

                switch (item.type){
                    case 'DELETE': 
                        status = ' deleted a chunk.';
                        break;
                    case 'CREATE_INDEX': 
                        status = ` created index ${item.index ? item.index.name : ""}.`;
                        break;
                    case 'DELETE_INDEX': 
                        status = ` deleted index ${item.index ? item.index.name : ""}.`;
                        break;
                    case 'CREATE':
                        status = ' created a chunk.';
                        break;
                    case 'MODIFY':
                        status = ' modified a chunk.';
                        break;
                    case 'PUBLISH':
                        status = ' published the knowledge set to production.';
                        break;
                    case 'SAVE_TO_UAT':
                        status = ' saved the knowledge set to UAT.';
                        break;
                    case 'SAVE_TO_DRAFT':
                        status = ' set the knowledge set back to draft.';
                        break;
                }

                // Determine the title based on the date
                const today = new Date();
                const itemDate = new Date(item.updatedAt);
                let title = '';

                if (itemDate.toDateString() === today.toDateString()) {
                    title = 'Today';
                } else if (itemDate > new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)) {
                    title = 'Past Week';
                } else if (itemDate > new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())) {
                    title = 'Past Month';
                } else {
                    title = 'Older';
                }

                // Only show the title if it's different from the last one
                const titleElement = lastTitle !== title ? <div className="audit_trail_item__date_title chat_date_badge">{title}</div> : null;
                lastTitle = title;

                return (
                    <>
                        {titleElement}
                        <div className={`audit_trail_item ${item.type}`}>
                            <div className='audit_trail_item_meta timestamp'>
                                <div>{toTimestamp(item.updatedAt)}{item.index ? ` - ${item.index ? item.index.name : ''}` : ''}</div>
                                <div>Version: {item.version.version_number}</div>
                            </div>
                            <div></div>
                            <div className='audit_trail_item_info'>
                                <div className='audit_trail_item_info_content'>
                                    <div className={`audit_trail_item__status badge`}>{item.type.replace(/_/g, ' ')}</div>
                                    <div className='audit_trail_item__status__text'>{item.changed_by ? item.changed_by.first_name + ' ' + item.changed_by.last_name : "Unknown user"} {} {status}</div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
            {props.hasMore && <VisibilitySensor onChange={(isVisible) => { if(isVisible){ props.handleLoadMoreMessages(props.scrollableRef) } }}><LoadMore ref={loadMoreElement} onClick={() => { props.handleLoadMoreMessages(props.scrollableRef) }} loadingMore={props.loadingMore}/></VisibilitySensor>}
        </div>
    );
}

export default AuditTrailList;
