import { handleResponse } from "../misc";

const getModuleTemplates = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
    };

    return fetch('/api/module_template', requestOptions).then(handleResponse).then((moduleTemplates) => {
        return moduleTemplates;
    });
}

const getModulePreview = (authToken, eduBotId, moduleTemplateId) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ edubot_id: eduBotId })
    };

    return fetch(`/api/module_template/${moduleTemplateId}/module_preview`, requestOptions).then(handleResponse).then((modulePreview) => {
        return modulePreview;
    });
}

export {
    getModuleTemplates,
    getModulePreview
}