import { VideoStreamRenderer } from '@azure/communication-calling';
import React from 'react';
import { utils } from '../../services/communicationSevices';
import { FaMicrophoneSlash } from 'react-icons/fa';
import ChatMessageAvater from '../Chats/ChatMessageAvater'

export default class StreamMedia extends React.Component{
    constructor(props){
        super(props);
        this.stream = props.stream;
        this.remoteParticipant = props.remoteParticipant;
        this.componentId =  `${utils.getIdentifierText(this.remoteParticipant.identifier)}-${this.stream.mediaStreamType}-${this.stream.id}`;

        this.state = {
            isAvailable: props.stream.isAvailable,
            isSpeaking: false,
            isMuted: false
        };
    }

    /**
     * Start stream after DOM has rendered
     */
    async componentDidMount(){
        this.remoteParticipant.on('isSpeakingChanged', () => {
            this.setState({ isSpeaking: this.remoteParticipant.isSpeaking  });
        });

        let renderer = new VideoStreamRenderer(this.stream);
        let view;
        let videoContainer;

        const renderStream = async () => {
            if(!view){
                view = await renderer.createView();
            }
            videoContainer = document.getElementById(this.componentId);
            videoContainer.hidden = false;
            if(!videoContainer.hasChildNodes()) { videoContainer.appendChild(view.target); }
        }

        this.stream.on('isAvailableChanged', async () => {
            if(this.stream.isAvailable){
                this.setState({ isAvailable: true })
                await renderStream();
            } else {
                if(videoContainer){ videoContainer.hidden = true }
                this.setState({ isAvailable: false })
            }
        });

        this.remoteParticipant.on('isMutedChanged', () => {
            this.setState({ isMuted: this.remoteParticipant.isMuted })
        });

        if (this.stream.isAvailable) {
            this.setState({ isAvailable: true });
            await renderStream();
        }
    }

    componentWillUnmount(){
        this.remoteParticipant.off('isSpeakingChanged', () => {});
        this.remoteParticipant.off('isMutedChanged', () => {});
        this.stream.off('isAvailableChanged', async () => {});
    }

    render(){
        if(this.stream.isAvailable && this.remoteParticipant.state === 'Connected'){
            return (
                <div className={`meeting_room_video_grid__item ${this.stream.mediaStreamType === 'ScreenSharing' ? 'screen_share' : ''}`}>
                    <div className={`meeting_room_video_grid__item__display_name${this.state.isSpeaking && !this.state.isMuted ? ` speaking-border-for-display-name` : ``}`}><span className="text-truncate">{this.props.remoteParticipant.displayName}</span>{this.state.isMuted && <FaMicrophoneSlash/>}</div>
                    <div className={`meeting_room_video_grid__item__video${this.state.isSpeaking && !this.state.isMuted ? ` speaking-border-for-video` : ``}`} id={this.componentId}></div>
                </div>
            );
        } else {
           if(this.stream === null || (this.stream.mediaStreamType !== 'ScreenSharing' && this.remoteParticipant.state === 'Connected')){
                return (
                    <div className="meeting_room_video_grid__item no_video">
                        <div className={`meeting_room_video_grid__item__display_name${this.state.isSpeaking && !this.state.isMuted ? ` speaking-border-for-display-name` : ``}`}><span className="text-truncate">{this.props.remoteParticipant.displayName}</span>{this.state.isMuted && <FaMicrophoneSlash/>}</div>
                        <div className={`meeting_room_video_grid__item__avatar_holder ${this.state.isSpeaking && !this.state.isMuted  ? ` speaking-border-for-video` : ``}`} id={this.componentId}>
                            <div className="avatar_box">
                                <ChatMessageAvater svg={true} conversation={{ user: { user_info: { name: this.props.remoteParticipant.displayName } } }} message={{ sender: 'user' }}/>
                            </div>
                        </div>
                    </div>
                );
           }

            return null;
        }
    }
}