import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getQueues, createQueue, updateQueue, deleteQueue } from '../../services/queues';
import Preloader from '../Preloader';
import QueueListItem from './QueueListItem';
import { HiPlusCircle } from 'react-icons/hi2';
import EditQueueModal from './EditQueueModal';
import LoadingModal from '../Base/LoadingModal';
import ConfirmModal from '../Base/ConfirmModal';

const QueueManager = () => {
    const [queues, setQueues] = useState([]);
    const [loadingQueues, setLoadingQueues] = useState(true);
    const [editQueueFormOpen, setEditQueueFormOpen] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(undefined);
    const [savingQueue, setSavingQueue] = useState(false);
    const [queueToDelete, setQueueToDelete] = useState(undefined);
    const [deletingQueue, setDeletingQueue] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        loadQueues();
    }, []);

    const loadQueues = async () => {
        const queues = await getQueues(auth.selectedChatbot.token);
        setQueues(queues);
        setLoadingQueues(false);
        if (selectedQueue) {
            const queue = queues.find(queue => selectedQueue._id === queue._id);
            if (queue) {
                setSelectedQueue(queue);
            }
        }
    };

    const handleQueueClick = (queue) => {
        setEditQueueFormOpen(queue ? true : false);
        setSelectedQueue(queue);
    };

    const handleQueueSave = async (queue) => {
        setSavingQueue(true);
        setSelectedQueue(undefined);
        setEditQueueFormOpen(false);
        queue.agents = queue.agents.map(queueListItem => queueListItem._id);
        if (queue._id) {
            await updateQueue(auth.selectedChatbot.token, queue._id, queue);
            await loadQueues();
            setSavingQueue(false);
        } else {
            await createQueue(auth.selectedChatbot.token, queue);
            await loadQueues();
            setSavingQueue(false);
        }
    };

    const handleDeleteConfirm = (queueToDelete) => {
        setQueueToDelete(queueToDelete);
    };

    const handleDeleteQueue = async () => {
        const queue = queueToDelete;
        setDeletingQueue(true);
        setQueueToDelete(undefined);
        await deleteQueue(auth.selectedChatbot.token, queue._id);
        await loadQueues();
        setDeletingQueue(false);
    };

    return (
        <div className="queue_manager">
            <button className="btn" onClick={() => { handleQueueClick({ name: '', agents: [] }) }}><HiPlusCircle />Add Queue</button>
            {!loadingQueues ? queues && queues.length > 0 ? (
                queues.map(queue => <QueueListItem key={queue._id} handleDeleteConfirm={handleDeleteConfirm} handleQueueClick={handleQueueClick} queue={queue} />)
            ) : <div className="mt-medium">No queues found</div> : <Preloader />}
            {selectedQueue && <EditQueueModal loadQueues={loadQueues} onConfirm={handleQueueSave} onRequestClose={() => { handleQueueClick() }} queues={queues} isOpen={editQueueFormOpen} queue={selectedQueue} />}
            {savingQueue && <LoadingModal isOpen={savingQueue} text="Saving queue" />}
            {queueToDelete && <ConfirmModal isOpen={queueToDelete ? true : false} title="Delete Queue" onConfirm={handleDeleteQueue} onRequestClose={() => { handleDeleteConfirm() }} description={<span>You are about to delete this queue. This will transfer all conversations currently in this queue to "All Agents"<br /><br />Would you like to proceed?</span>} confirmText="Delete" />}
            {deletingQueue && <LoadingModal isOpen={deletingQueue} text="Deleting queue" />}
        </div>
    );
};

export default QueueManager;
