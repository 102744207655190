import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import Preloader from '../Preloader';
import moment from 'moment';
import { HiOutlineBolt, HiOutlinePaperClip, HiOutlineXMark, HiPaperAirplane } from 'react-icons/hi2';
import ChatMediaPreview from './ChatMediaPreview';
import { uploadMedia } from '../../services/upload';
import { timerRenderer } from '../../services/dates';
import EmojiPicker from '../Base/EmojiPicker';
import Countdown from 'react-countdown';
import ConfirmModal from '../Base/ConfirmModal';
import QuickReplyList from '../QuickReplies/QuickReplyList';
import Tooltip from '@mui/material/Tooltip';

const QuickMessage = (props) => {
    const [messageData, setMessageData] = useState({
        text: '',
        media: []
    });
    const [sending, setSending] = useState(false);
    const [hasMedia, setHasMedia] = useState(false);
    const [mediaFile, setMediaFile] = useState(undefined);
    const [mediaPreview, setMediaPreview] = useState(undefined);
    const [confirmQuickReply, setConfirmQuickReply] = useState(false);
    const [quickRepliesShown, setQuickRepliesShown] = useState(false);
    const chatMessageTextArea = useRef(null);

    const mediaUploadElement = useRef(null);

    const handleMessageChange = (e) => {
        if (e.target.value.toLowerCase() === '/r') {
            return setQuickRepliesShown(true);
        }

        setMessageData((prevState) => ({
            ...prevState,
            text: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        setConfirmQuickReply(false);

        let media = null;
        let currentMessageData = messageData;

        if (mediaFile && !mediaFile.url) {
            const uploadResponse = await uploadMedia(props.auth.selectedChatbot.token, mediaFile);
            if (uploadResponse.url) {
                media = {
                    url: uploadResponse.url,
                    name: mediaFile.name,
                    type: mediaFile.type
                };
            }
        }

        if (mediaFile && mediaFile.url) {
            media = mediaFile;
        }

        if (media) {
            currentMessageData.media = [media];
        }

        await props.onSend(currentMessageData, props.conversation);
        setMessageData({ text: '', media: [] });
        setSending(false);
    };

    const handleMediaTrigger = (input) => {
        input.current.click();
    };

    const handleConfirmQuickReply = (e) => {
        e.preventDefault();
        setConfirmQuickReply((prevState) => !prevState);
    };

    const handleMediaChange = (e) => {
        setHasMedia(true);
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setMediaFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setMediaPreview(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveMedia = () => {
        setHasMedia(false);
        setMediaFile(undefined);
        setMediaPreview(undefined);
    };

    const handleEmojiPick = (emojiData) => {
        setMessageData({
            ...messageData,
            text: messageData.text + emojiData.emoji
        });
    };

    const handleQuickRepliesToggle = () => {
        setQuickRepliesShown(!quickRepliesShown);
        if (quickRepliesShown) {
            chatMessageTextArea.current.focus();
        }
    };

    const handleQuickReplySelect = (quickReply) => {
        handleQuickRepliesToggle();
        setMessageData((prevState) => ({
            ...prevState,
            text: quickReply.message_data.text || prevState.message
        }));
        setHasMedia(quickReply.message_data.media && quickReply.message_data.media.length > 0 ? quickReply.message_data.media[0] : false);
        setMediaFile((quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0]);
        setMediaPreview((quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0].url);
    };

    const placeholder = quickRepliesShown ? 'Type to search quick replies' : (props.placeholder || 'Enter your message to user');

    return (
        <div className="quick_message__outer">
            {props.label && (
                <label className="flex-middle">
                    {props.label} - Time remaining:{' '}
                    <div className="queue_badge timer">
                        <Countdown date={moment(props.helpRequest.createdAt).add(23.5, 'hours').toDate()} renderer={timerRenderer} />
                    </div>{' '}
                </label>
            )}
            {hasMedia && mediaPreview && (
                <ChatMediaPreview handleRemoveMedia={handleRemoveMedia} loadingMediaPreview={false} mediaPreview={mediaPreview} mediaFile={mediaFile} />
            )}
            <form className="quick_message internal_chat_room__input_area" onSubmit={handleConfirmQuickReply}>
                <div className="quick_message_text_area">
                    {quickRepliesShown && <QuickReplyList messageValue={messageData.text} currentConversation={props.conversation} onSelect={handleQuickReplySelect} />}
                    <TextareaAutosize
                        ref={chatMessageTextArea}
                        disabled={sending}
                        value={messageData.text}
                        onChange={handleMessageChange}
                        placeholder={!sending ? placeholder : 'Sending message...'}
                    />
                </div>
                <button type="submit" className="send_button" disabled={sending || messageData.text.trim().length === 0}>
                    {!sending ? <HiPaperAirplane /> : <Preloader color="white" size="15" />}
                </button>
            </form>
            <div className="chat_message_bar__actions">
                <Tooltip title="Attach file" arrow placement="top">
                    <div className="chat_message_bar__action" onClick={() => handleMediaTrigger(mediaUploadElement)}>
                        <HiOutlinePaperClip />
                    </div>
                </Tooltip>
                <Tooltip title={quickRepliesShown ? 'Close quick replies' : 'Show quick replies'} arrow placement="top">
                    <div className="chat_message_bar__action" onClick={handleQuickRepliesToggle}>
                        {!quickRepliesShown ? <HiOutlineBolt /> : <HiOutlineXMark />}
                    </div>
                </Tooltip>
                <EmojiPicker handleEmojiPick={handleEmojiPick} />
            </div>
            <input ref={mediaUploadElement} type="file" style={{ display: 'none' }} onChange={handleMediaChange} />
            <ConfirmModal
                confirmText="Send and Resolve"
                title="Send and Resolve"
                description="Please note sending this quick reply will mark this help request as resolved and the text bar will be removed."
                isOpen={confirmQuickReply}
                onConfirm={handleSubmit}
                onRequestClose={handleConfirmQuickReply}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(QuickMessage);
