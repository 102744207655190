import { handleResponse } from './misc';

function uploadMedia(authToken, fileObject, profilePicture = false){
    var data = new FormData()
    data.append('file', fileObject)

    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken },
        body: data
    };

    const queryString = profilePicture ? '/avatar' : '';

    return fetch('/api/upload' + queryString, requestOptions).then(handleResponse).then((res) => {
        return res;
    }).catch(e => { throw e });
}

function isImage(type){
    const imageType = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/tiff'];
    return imageType.includes(type);
}

function isVideo(type){
    const videoTypes = ['video/mp4', 'video/x-msvideo', 'video/mpeg', 'video/ogg', 'video/mp2t'];
    return videoTypes.includes(type);
}

function isAudio(type){
    const audioTypes = ['audio/ogg', 'audio/mpeg', 'audio/mp3', 'audio/webm;codecs=opus', 'audio/mp4', 'audio/m4a'];
    return audioTypes.includes(type);
}

function isPDF(type){
    const videoTypes = ['application/pdf'];
    return videoTypes.includes(type);
}

export {
    uploadMedia,
    isImage,
    isVideo,
    isPDF,
    isAudio
};