import { useState } from 'react';
import ChatTransfer from './ChatTransfer';
import { HiOutlineCheck } from 'react-icons/hi2';
import { invite } from '../../services/humanHandoff';
import Tooltip from '@mui/material/Tooltip';
import { HiArrowsRightLeft, HiOutlineUserPlus } from 'react-icons/hi2';
import { useSelector } from 'react-redux';

const ChatMessageBarActions = (props) => {
    const auth = useSelector(state => state.auth);

    const [transferShown, setTransferShown] = useState(false);
    const [inviteListShown, setInviteListShown] = useState(false);
    const [invited, setInvited] = useState(false);

    const handleTransferToggle = () => {
        setTransferShown(prevState => !prevState);
    };

    const handleInviteToggle = () => {
        setInviteListShown(prevState => !prevState);
    };

    const handleInvite = async (id) => {
        await invite(auth.selectedChatbot.token, props.currentConversation.conversation_id, id, auth.agent.agent._id);
        setInvited(true);
        setTimeout(() => {
            setInvited(false);
        }, 3000);
    };

    return (
        <div className="chat_message_bar_actions">
            <Tooltip title="Transfer chat" arrow placement='top'><button className='chat_message_bar__action' onClick={handleTransferToggle}><HiArrowsRightLeft/></button></Tooltip>
            <Tooltip title={invited ? 'Agent invited' : 'Invite Agent to Chat'} arrow placement='top'><button className='chat_message_bar__action' disabled={invited} onClick={handleInviteToggle}>{invited ? <HiOutlineCheck/> : <HiOutlineUserPlus/>}</button></Tooltip>
            {transferShown && <ChatTransfer handleTransferToggle={handleTransferToggle} conversation={props.currentConversation}/>}
            {inviteListShown && <ChatTransfer agentPicker={true} title="Invite agent" actionText="Inviting" onSelect={handleInvite} handleTransferToggle={handleInviteToggle} conversation={props.currentConversation}/>}
            <div className="chat_message_bar_actions__right">
                <div className="internal_switch">
                    <div className={`internal_switch__button ${props.public ? 'active' : ''}`} onClick={() => { props.handleTogglePublic(true) }}>User</div>
                    <div className={`internal_switch__button ${!props.public ? 'active' : ''}`} onClick={() => { props.handleTogglePublic(false) }}>Internal</div>
                </div>
            </div>
        </div>
    );
};

export default ChatMessageBarActions;
