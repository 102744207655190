import Modal from 'react-modal';
import { isVideo, isImage } from '../../services/upload';
import { HiOutlineXMark } from 'react-icons/hi2';

const ChatMediaViewer = (props) => {
    return (
        <Modal isOpen={true} className="media_modal" onRequestClose={() => { props.handleMediaClick(undefined) }}>
            <div className="media_modal__close" onClick={() => { props.handleMediaClick(undefined) }}><HiOutlineXMark/></div>
            {isImage(props.media.type) && <img src={props.media.url} alt="Preview"/>}
            {isVideo(props.media.type) && <video controls={true}  src={props.media.url} alt="Preview"/>}
        </Modal>
    );
};

export default ChatMediaViewer;