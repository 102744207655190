import { Autocomplete, TextField } from "@mui/material";

const IndexFilters = ({ filters, handleFilterChange }) => {
    const orderByOptions = [
        { value: 'desc', label: 'Lowest to highest' },
        { value: 'asc', label: 'Highest to lowest' }
    ];

    return (
        <div className="index_filters">
            <label>Sort by</label>
            <Autocomplete
                disablePortal
                size='small'
                options={orderByOptions}
                getOptionLabel={(option) => option.label}
                value={orderByOptions.find(filterOption => filters.orderBy === filterOption.value)}
                onChange={(event, newValue) => { handleFilterChange('orderBy', newValue?.value) }}
                renderInput={(params) => <TextField {...params} label="Order by chunk size" autoFocus />}
                sx={{ minWidth: '200px' }}
            />
        </div>
    )
}

export default IndexFilters;