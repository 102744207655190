import React, { useState, useEffect } from 'react';
import AlertBox from '../AlertBox';
import Preloader from '../Preloader';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../../reducers/authSlice';
import { TextField } from '@mui/material';

const LoginForm = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    // reset login status
    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if(email && password){
            dispatch(login({ email, password }));
        }
    }

    useEffect(() => {
        if(auth.agent){
            navigate('/chats');
        }
    }, [auth.agent]);

    const loggingIn = auth.loggingIn;
    return(
        <div className="login_form">
            <h2>Login</h2>
            <form className="login_form__form" name="form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <TextField label="Email" fullWidth type="email" name="email" value={email} onChange={handleChange} autoComplete="username" placeholder="Enter your email address"/>
                    {submitted && !email && <div className="help-block">Email is required</div>}
                </div>
                <div className="form-group">
                    <TextField label="Password" fullWidth type="password" name="password" value={password} onChange={handleChange} autoComplete="current-password"  placeholder="Enter your password"/>
                    {submitted && !password && <div className="help-block">Password is required</div>}
                </div>
                <div className="form-group">
                    <button disabled={loggingIn} className="button">{loggingIn ? <Preloader color="white" size="16" padding="0px"/> : 'Log In'}</button>
                    <p>Forgot your password? Click <NavLink to="/reset-password">here</NavLink> to reset it.</p>
                </div>
                {auth.error && <AlertBox type="error">{auth.error}</AlertBox>}
            </form>
        </div>
    );
};

export default LoginForm;