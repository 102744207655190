import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAuditTrailItems } from '../../services/knowedgeSetManager';
import Preloader from '../Preloader';
import AuditTrailList from './AuditTrailList';

const AuditTrail = (props) => {
    const [auditTrail, setAuditTrail] = useState([]);
    const [loadingAuditTrail, setLoadingAuditTrail] = useState(true);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(20);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const scrollableRef = useRef(null);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        loadAuditTrailItems();
    }, []);

    const loadAuditTrailItems = async () => {
        const auditTrail = await getAuditTrailItems(
            auth.selectedChatbot.token,
            props.knowledgeSetId,
            props.versionId,
            limit + 1
        );

        const hasMore = auditTrail.length > limit;
        if (hasMore) {
            auditTrail.pop();
        }

        setAuditTrail(auditTrail);
        setLoadingAuditTrail(false);
        setLoadingMore(false);
        setHasMore(hasMore);
    };

    const loadMore = () => {
        setLoadingMore(true);
        setLimit(limit + offset);
    };

    useEffect(() => {
        if (loadingMore) {
            loadAuditTrailItems();
        }
    }, [loadingMore]);

    return loadingAuditTrail ? (
        <Preloader />
    ) : (
        <div className='audit_trail' ref={scrollableRef}>
            <AuditTrailList
                auditTrail={auditTrail}
                hasMore={hasMore}
                loadingMore={loadingMore}
                handleLoadMoreMessages={loadMore}
                scrollableRef={scrollableRef}
            />
        </div>
    );
};

export default AuditTrail;
