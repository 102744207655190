import { toTimestamp } from '../../services/dates';

const ChatNote = (props) => {
    return (
        <div className="chat_event">
            <div className="chat_event__content chat_note__content">
                <div className="chat_event__title">Note created by {props.note.owner.first_name} {props.note.owner.last_name}</div>
                <div className="chat_event_text">{props.note.text}</div>
                <div className="timestamp">{toTimestamp(props.note.createdAt)}</div>
            </div>
        </div>
    );
};

export default ChatNote;