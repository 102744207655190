import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { HiOutlineXMark, HiOutlineArrowLeft } from 'react-icons/hi2';
import Preloader from '../Preloader';
import { companyService } from '../../services/company';
import { newChatHandle, groupChatCreate } from '../../services/internalChats';
import InternalAgentListItem from './InternalAgentsListItem';
import InternalAgentSearch from './InternalAgentSearch';
import GroupChatIcon from './GroupChatIcon';
import CreateGroupChatModal from './CreateGroupChatModal';
import LoadingModal from '../Base/LoadingModal';

const InternalChatNewChat = (props) => {
    const [initAgents, setInitAgents] = useState([]);
    const [agents, setAgents] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [loadingAgents, setLoadingAgents] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [createGroupChatOpen, setCreateGroupChatOpen] = useState(false);
    const [creatingGroupChat, setCreatingGroupChat] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        companyService.getCompanyAgents(auth.selectedCompany, auth.agent.token).then(agents => {
            const agentsWithoutCurrent = agents.filter(agent => agent._id !== auth.agent.agent._id);
            setAgents(agentsWithoutCurrent);
            setInitAgents(agentsWithoutCurrent);
            setLoadingAgents(false);
        });
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        const searchTerm = e.target.value;
        if (searchTerm.trim().length > 0) {
            setIsSearching(true);
            setAgents(prevAgents => prevAgents.filter(agent => {
                let isMatch = false;
                if (agent.first_name.toLowerCase().includes(searchTerm) || agent.last_name.toLowerCase().includes(searchTerm) || agent.email.toLowerCase().includes(searchTerm)) {
                    isMatch = true;
                }
                return isMatch;
            }));
        } else {
            clearSearch();
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        setIsSearching(false);
        setAgents(initAgents);
    };

    const handleNewChatItemClick = (agent) => {
        setLoadingAgents(true);
        newChatHandle(auth.agent.agent._id, agent._id, auth.selectedChatbot.token).then((chat) => {
            props.handleSelectChat(chat);
        });
    };

    const handleCreateGroupChat = (participants) => {
        setCreatingGroupChat(true);
        setCreateGroupChatOpen(false);
        groupChatCreate([...participants, auth.agent.agent._id], auth.selectedChatbot.token, auth.agent.agent._id).then((chat) => {
            props.handleSelectChat(chat);
            setCreatingGroupChat(false);
        });
    };

    const handleGroupChatCreateToggle = (createGroupChatOpen) => {
        setCreateGroupChatOpen(createGroupChatOpen);
    };

    return (
        <div className="internal_chat_new_chat">
            <div className="internal_chat_widget__title">
                New Chat
                <div className="internal_chat_widget__title__actions">
                    <div className="internal_chat_widget__close_chat" onClick={props.handleNewChatClick} title="Back to chats">
                        <HiOutlineArrowLeft />
                    </div>
                    <div className="internal_chat_widget__close_chat" title="Close" onClick={props.handleToggleChat}>
                        <HiOutlineXMark />
                    </div>
                </div>
            </div>
            <div className="internal_chat_widget__content">
                {!loadingAgents ? (
                    <div className="internal_chat_widget__content_inner">
                        <div className="internal_chat_top_bar">
                            <InternalAgentSearch searchTerm={searchTerm} clearSearch={clearSearch} isSearching={isSearching} handleSearchChange={handleSearchChange} />
                        </div>
                        <div className="internal_agent_list">
                            <div className="internal_agent_list_item" onClick={() => { handleGroupChatCreateToggle(true) }}>
                                <GroupChatIcon size="50" />
                                <div className="internal_agent_list_item__details">
                                    <div className="internal_agent_list_item__details__name text_truncate">Create group chat</div>
                                    <div className="status_display"><span className="timestamp">Chat with multiple agents.</span></div>
                                </div>
                            </div>
                            {agents.map(agent => <InternalAgentListItem key={agent._id} handleNewChatItemClick={handleNewChatItemClick} agent={agent} />)}
                        </div>
                    </div>
                ) :
                    <Preloader />}
            </div>
            <CreateGroupChatModal isOpen={createGroupChatOpen} handleCreateGroupChat={handleCreateGroupChat} agents={agents} onRequestClose={() => { handleGroupChatCreateToggle(false) }} />
            <LoadingModal text="Creating group chat" isOpen={creatingGroupChat} />
        </div>
    );
};

export default InternalChatNewChat;
