import { toTimestamp } from '../../services/dates';
import { NavLink } from 'react-router-dom';

const HelpRequestWidgetItem = (props) => {
    const helpRequest = props.helpRequest;
    return (
        <div className="help_request_widget_item">
            <div className="help_request_widget_item__top_bar">
                <div className={`small_badge ${helpRequest.resolved ? 'success' : ''}`}>{helpRequest.resolved ? 'Resolved by ' + helpRequest.resolved.agent.first_name[0] + '. ' + helpRequest.resolved.agent.last_name : 'Unresolved'}</div>
                <div className="timestamp">{toTimestamp(helpRequest.createdAt)}</div>
            </div>
            {helpRequest.message_data.text && <div className="help_request_widget_item__body">{helpRequest.message_data.text}</div>}
            <div className="help_request_widget_item__actions">
                <NavLink className="btn" to={`/help-requests/${helpRequest._id}`}>View</NavLink>
            </div>
        </div>
    )
};

export default HelpRequestWidgetItem;