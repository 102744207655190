import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    chats: [],
    selectedChat: undefined,
    messagesLimit: 10,
    messagesOffset: 10,
    hasMoreMessages: false,
    loadingChats: true,
    internalChatsLimit: 10,
    internalChatsOffset: 10,
    hasMore: false,
    loadingMore: false,
};

const internalChatsSlice = createSlice({
    name: 'internalChats',
    initialState,
    reducers: {
        setInternalChatsValue: (state, action) => {
            state.chats = action.payload
            state.loadingChats = false;
        },
        updateInternalChats: (state, action) => {
            Object.assign(state, action.payload);
        },
        setSelectedChat: (state, action) => {
            state.messagesLimit = initialState.messagesLimit;
            state.selectedChat = {
                chat: action.payload,
                messages: [],
                loadingMessages: true,
            };
        },
        clearInternalChats: () => initialState,
        updateSelectedChat: (state, action) => {
            state.selectedChat.chat = {
                ...state.selectedChat.chat,
                ...action.payload,
            };
        },
        setChatWidgetExpanded: (state, action) => {
            state.chatWidgetExpanded = action.payload;
        },
        deselectChat: (state) => {
            state.selectedChat = undefined;
        },
        setCurrentMessagesValue: (state, action) => {
            state.selectedChat.messages = action.payload;
            state.selectedChat.loadingMessages = false;
        },
        setLoadingChats: (state, action) => {
            state.loadingChats = action.payload;
        },
    },
});

export const {
    setInternalChatsValue,
    updateInternalChats,
    setSelectedChat,
    clearInternalChats,
    updateSelectedChat,
    setChatWidgetExpanded,
    deselectChat,
    setCurrentMessagesValue,
    setLoadingChats,
} = internalChatsSlice.actions;

export default internalChatsSlice.reducer;