import { FaFile } from 'react-icons/fa'
import { HiLockClosed, HiUserGroup, HiOutlinePencil, HiTrash } from 'react-icons/hi2'
import Tooltip from '@mui/material/Tooltip'

const QuickReplyListItem = (props) => {
    const format = (sentence, currentConversation) => {
        let formattedSentence = sentence;
        const patternMap = [];

        if(currentConversation.user.user_info){
            Object.entries(currentConversation.user.user_info).forEach(
                ([key, value]) => patternMap.push({ pattern: new RegExp('{([^}]user[^]'+key+'])}}'), value })
            );
        }

        Object.entries(props.currentAgent).forEach(
            ([key, value]) => patternMap.push({ pattern: new RegExp('{([^}]agent[^]'+key+'])}}'), value })
        );

        patternMap.forEach(pattern => {
            formattedSentence = formattedSentence.replace(pattern.pattern, pattern.value)
        })

        formattedSentence = formattedSentence.replace(new RegExp('{([^}]user[^]*)}}'), '');
        
        return formattedSentence;
    };

    const editFormat = (sentence) => {
        var resultUser = sentence.match(/user\[.*?]/g)
        resultUser = resultUser ? resultUser.map(result => result.replace('user[', '').replace(']', '')) : null;
        var resultAgent = sentence.match(/agent\[.*?]/g)
        resultAgent = resultAgent ? resultAgent.map(result => result.replace('agent[', '').replace(']', '')) : null;
        let formattedSentence = sentence;
        if(resultUser){
            resultUser.forEach(word => {
                formattedSentence = formattedSentence.replace(new RegExp('{([^}]user[^]'+word+'])}}'), '<div class="quick_reply_field_badge">user <b>'+word+'</b></div>')
            })
        }

        if(resultAgent){
            resultAgent.forEach(word => {
                formattedSentence = formattedSentence.replace(new RegExp('{([^}]agent[^]'+word+'])}}'), '<div class="quick_reply_field_badge">agent <b>'+word+'</b></div>')
            })
        }

        return formattedSentence;
    } 

    const text = (props.quickReply.message_data && props.quickReply.message_data.text) ? (props.currentConversation ? format(props.quickReply.message_data.text, props.currentConversation) : editFormat(props.quickReply.message_data.text)) : undefined;
    
    return (
        <div className="quick_reply_list__item" onClick={props.onSelect ? () => { props.onSelect({...props.quickReply, message_data: {...props.quickReply.message_data, text }}) } : () => {}}>
            <div className="quick_reply_list__item__text">            
            {props.editable && <Tooltip arrow title={<div>{props.quickReply.private ? <span><b>Private</b><br/>This quick reply can only be seen and used by you.</span> : <span><b>Public</b><br/>All agents on the portal can see this.</span>}<br/><br/>Click to change</div>}><div className={`quick_reply_badge ${props.quickReply.private ? 'private' : ''}`}>{props.quickReply.private ? <HiLockClosed/> : <HiUserGroup/>}</div></Tooltip>}
            {text ? <span dangerouslySetInnerHTML={{ __html: text }}/> : (props.quickReply.message_data && props.quickReply.message_data.media && props.quickReply.message_data.media.length > 0 ? <span>{props.quickReply.message_data.media[0].name}</span> : '') }
            </div>
            <div className="quick_reply_list__item__meta">
                {(props.quickReply.message_data && props.quickReply.message_data.media && props.quickReply.message_data.media.length > 0) && <div className="quick_reply_list__item__attachment"><span className="text-truncate">{props.quickReply.message_data.media[0].name}</span><FaFile/></div>}
                {(props.quickReply.private || ['admin', 'manager'].includes(props.currentAgent.role)) && (
                    <div className="quick_reply_list__item_actions">
                        {props.editable && <Tooltip arrow title="Edit"><div className="quick_reply_list__item__action" onClick={() => { props.onEdit(props.quickReply) }}><HiOutlinePencil/></div></Tooltip>}
                        {props.editable && <Tooltip arrow title="Delete"><div className="quick_reply_list__item__action" onClick={() => { props.handleDeleteQuickReplyToggle(props.quickReply) }}><HiTrash/></div></Tooltip>}
                    </div>
                )}
            </div>
        </div>
    )
};

export default QuickReplyListItem;