import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import AgentProfileAvatar from './AgentProfileAvatar';
import ChangeAvatar from './ChangeAvatar';
import { agentService } from '../../services/agents';
import { uploadMedia } from '../../services/upload';
import Badge from '../Base/Badge';
import Status from '../Base/Status';
import EditAgentForm from './EditAgentForm';
import QuickReplyList from '../QuickReplies/QuickReplyList';
import AgentResetForm from '../PasswordReset/AgentResetForm';
import AlertBox from '../AlertBox';
import { setCurrentAgent } from '../../reducers/authSlice';

const AgentPage = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    
    const [agent, setAgent] = useState(auth.agent.agent);
    const [changingAvatar, setChangingAvatar] = useState(false);
    const [editSubmitting, setEditSubmitting] = useState(false);
    const [quickReplySearchValue, setQuickReplySearchValue] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (auth.agent.agent !== agent) {
            setAgent(auth.agent.agent);
        }
    }, [auth.agent.agent]);

    const handleImageSelect = (inputElement) => {
        inputElement.click();
    };

    const handleEditSubmit = (e, agent) => {
        e.preventDefault();
        setEditSubmitting(true);
        agentService.updateAgent(auth.agent.token, agent).then((agentReceived) => {
            dispatch(setCurrentAgent(agentReceived));
            setEditSubmitting(false);
        });
    };

    const handleAvatarRemove = async () => {
        setChangingAvatar(true);
        const updatedAgent = await agentService.removeAvatar(auth.agent.token, agent._id);
        dispatch(setCurrentAgent(updatedAgent));
        setChangingAvatar(false);
    };

    const handleImageChanged = async (e) => {
        setError(null);
        setChangingAvatar(true);
        try {
            const uploadResponse = await uploadMedia(auth.selectedChatbot.token, e.target.files[0], true);
            agentService.setAvatar(auth.agent.token, agent._id, uploadResponse.url).then((agent) => {
                dispatch(setCurrentAgent(agent));
                setChangingAvatar(false);
            });
        } catch (e) {
            setError('Failed to upload file');
            setChangingAvatar(false);
        }
    };

    const handleSearchChange = (e) => {
        setQuickReplySearchValue(e.target.value);
    };

    return (
        <div className="container column">
            <PageContainer>
                <div className="container">
                    <div className="col-6 agent_profile_card">
                        <AgentProfileAvatar size={150} changingAvatar={changingAvatar} agent={agent} />
                        <ChangeAvatar
                            handleImageSelect={handleImageSelect}
                            handleImageChanged={handleImageChanged}
                            handleAvatarRemove={handleAvatarRemove}
                        />
                        {error && <AlertBox type="error" className="mt-medium">{error}</AlertBox>}
                    </div>
                    <div className="col-6">
                        <div className="agent_info">
                            <div className="agent_name">{agent.first_name} {agent.last_name}</div>
                            <Status agent={agent} />
                            <Badge text={agent.role} />
                        </div>
                    </div>
                </div>
            </PageContainer>
            <PageContainer>
                <h2>Edit Information</h2>
                <EditAgentForm submiting={editSubmitting} onSubmit={handleEditSubmit} agent={agent} />
            </PageContainer>
            <PageContainer>
                <h2>Quick Replies</h2>
                <p>Edit your quick replies for in-chat template relpying.</p>
                <QuickReplyList
                    handleSearchChange={handleSearchChange}
                    messageValue={quickReplySearchValue}
                    showSearch={true}
                    editable={true}
                />
            </PageContainer>
            <PageContainer>
                <h2>Reset Password</h2>
                <AgentResetForm />
            </PageContainer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AgentPage);
