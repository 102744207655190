import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";


function FileUpload(props) {
    const fileTypes = props.fileTypes || [];
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
        props.handleSetFile(file);
    };
    return (
        <FileUploader classes="drag_file_upload" fileOrFiles={file} multiple={props.multiple} handleChange={handleChange} maxSize={200} name="file" types={fileTypes} required/>
    );
}

export default FileUpload;