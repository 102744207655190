import React from 'react';
import NotificationSoundToggle from './NotificationSoundToggle';

const NotificationsActions = () => {
    return (
        <div className="notifications_actions">
            <div className="notifications_title">Notifications</div>
            <div className="notifications_actions__container">
                <NotificationSoundToggle/>
            </div>
        </div>
    );
}

export default NotificationsActions;
