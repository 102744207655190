import AlertBox from '../AlertBox';
import NotificationsActions from './NotificationsActions';
import IncomingMeetingContainer from '../Meetings/IncomingMeetingContainer';
import { useSelector } from 'react-redux';

const NotificationsContainer = () => {
    const notifications = useSelector(state => state.notifications.notifications);
    const meetings = useSelector(state => state.meetings);
    return (
        <div className="notifications_top_container">
            {meetings.meetingsWaiting.length > 0 && <IncomingMeetingContainer />}
            <div className="notifications_container">
                <NotificationsActions />
                {notifications.length > 0 ? (
                    <div className="notifications_container__inner">
                        {notifications.map((notification) => {
                            return notification;
                        })}
                    </div>
                ) : (
                    <AlertBox type="info">No notifications</AlertBox>
                )}
            </div>
        </div>
    );
};

export default NotificationsContainer;
