import { RiOpenaiFill } from "react-icons/ri";
import EduBotModulesList from "./EduBotModulesList";
import EduBotConnectorsList from "./EduBotConnectorsList";
import SwitchStatusIndicator from "../Base/SwitchStatusIndicator";
import { Divider } from "@mui/material";
import { IoLibraryOutline } from "react-icons/io5";
import { titleCase } from "../../services/misc";
import ColorPickerInput from "../Base/ColorPickerInput";

const EduBotDetails = ({ eduBot, connectors }) => {
    return (
        <div className="edubot_details_pane">
            <div className="edubot_details_section__label">Bot configuration</div>
            <div className="edubot_details_section">
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Bot name</div>
                    <div className="edubot_detail__value">{eduBot.name}</div>
                </div>
                <Divider className="edubot_details_section__divider"/>
                {eduBot.bot_config?.welcome_messages?.length > 0 ? (
                    eduBot.bot_config.welcome_messages.map((message, index) => (
                        <div className="edubot_detail">
                            <div className="edubot_detail__label">Welcome message {index + 1}</div>
                            <div className="edubot_detail__value">{message}</div>
                        </div>
                    ))
                ) : (
                    <div className="edubot_detail">
                        <div className="edubot_detail__label">Welcome message</div>
                        <div className="edubot_detail__value">None</div>
                    </div>
                )}
                <Divider className="edubot_details_section__divider"/>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Initial Module</div>
                    <div className="edubot_detail__value">{eduBot.init_module_object ? <><div dangerouslySetInnerHTML={{ __html: eduBot.init_module_object.module_template.icon }}/>{eduBot.init_module_object.module_template.name}</> : 'None'}</div>
                </div>
            </div>
            <div className="edubot_details_section__label">Modules</div>
            <div className="edubot_details_section">
                <EduBotModulesList eduBot={eduBot}/>
            </div>
            <div className="edubot_details_section__label">ChatGPT</div>
            <div className="edubot_details_section">
                <div className="edubot_detail">
                    <div className="edubot_detail__label">ChatGPT Enabled</div>
                    <div className="edubot_detail__value"><SwitchStatusIndicator status={eduBot.gpt_enabled}/></div>
                </div>
                <Divider className="edubot_details_section__divider"/>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Model</div>
                    <div className="edubot_detail__value"><RiOpenaiFill/>{eduBot.gpt_config?.model || 'Not configured'}</div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Custom Instructions</div>
                    <div className="edubot_detail__value">{eduBot.gpt_config?.custom_instructions || 'None'}</div>
                </div>
                <Divider className="edubot_details_section__divider"/>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Knowledge Set</div>
                    <div className="edubot_detail__value"><IoLibraryOutline/>{eduBot.connected_knowledge_set_object?.name || 'N/A'}</div>
                </div>
            </div>
            <div className="edubot_details_section__label">Widget settings</div>
            <div className="edubot_details_section">
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Enabled</div>
                    <div className="edubot_detail__value"><SwitchStatusIndicator status={eduBot.staging_widget.settings.is_enabled}/></div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Name</div>
                    <div className="edubot_detail__value">{eduBot.staging_widget.settings.name}</div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Auto start enabled</div>
                    <div className="edubot_detail__value"><SwitchStatusIndicator status={eduBot.staging_widget.settings.auto_start}/></div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Widget size</div>
                    <div className="edubot_detail__value">{(eduBot.staging_widget.settings.widget_size && eduBot.staging_widget.settings.widget_size !== '') ? titleCase(eduBot.staging_widget.settings.widget_size) : 'Normal'}</div>
                </div>
                <Divider className="edubot_details_section__divider"/>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Avatar</div>
                    <div className="edubot_detail__value">{(eduBot.staging_widget.settings.avatar && eduBot.staging_widget.settings.avatar !== '') ? <img className="edubot_detail__value_img_value" src={eduBot.staging_widget.settings.avatar}/> : 'None'} </div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Avatar as icon</div>
                    <div className="edubot_detail__value"><SwitchStatusIndicator status={eduBot.staging_widget.settings.avatar_as_icon}/></div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Header image</div>
                    <div className="edubot_detail__value">{(eduBot.staging_widget.settings.header_image && eduBot.staging_widget.settings.header_image !== '') ? <img className="edubot_detail__value_img_value" src={eduBot.staging_widget.settings.header_image}/> : 'None'} </div>
                </div>
                <Divider className="edubot_details_section__divider"/>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Primary color</div>
                    <div className="edubot_detail__value"><ColorPickerInput size={"small"} style={{ width: '120px' }} value={`rgb(${eduBot.staging_widget.settings.primary_color})`} disabled={true}/></div>
                </div>
                <div className="edubot_detail">
                    <div className="edubot_detail__label">Secondary color</div>
                    <div className="edubot_detail__value"><ColorPickerInput size={"small"} style={{ width: '120px' }} value={`rgb(${eduBot.staging_widget.settings.secondary_color})`} disabled={true}/></div>
                </div>
            </div>
            <div className="edubot_details_section__label">Connectors</div>
            <div className="edubot_details_section">
                <EduBotConnectorsList eduBot={eduBot} connectorTemplates={connectors}/>
            </div>
        </div>
    );
}

export default EduBotDetails;