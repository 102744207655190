import React, { useState, useEffect } from 'react';
import moment from 'moment';
import EventsWidgetItem from './EventsWidgetItem';

const EventsWidget = (props) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const sortedEvents = props.events.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
        setEvents(sortedEvents);
    }, [props.events]);

    return (
        <div className="events_widget">
            {events.map(eventItem => (
                <EventsWidgetItem key={eventItem._id} event={eventItem}/>
            ))}
        </div>
    );
};

export default EventsWidget;