import React, { useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Picker from 'emoji-picker-react';
import { MdOutlineEmojiEmotions } from 'react-icons/md';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const EmojiPicker = (props) => {
    const [emojiPickerShown, setEmojiPickerShown] = useState(false);

    const handleEmojiPickerTrigger = () => {
        setEmojiPickerShown(!emojiPickerShown);
    };

    const handleClickAway = () => {
        setEmojiPickerShown(false);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="emoji_picker">
                <Tooltip title='Insert emoji' placement='top' arrow>
                    <div className="emoji_picker__triger" onClick={handleEmojiPickerTrigger}>
                        <MdOutlineEmojiEmotions/>
                    </div>
                </Tooltip>
                {emojiPickerShown && <Picker previewConfig={{ showPreview: false }}  preload={true} className="emoji_picker__picker" onEmojiClick={props.handleEmojiPick}/>}
            </div>
        </ClickAwayListener>
    );
};

export default EmojiPicker;
