import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { MdOutlineImage, MdPlayCircle } from 'react-icons/md';
import { HiDocumentText } from 'react-icons/hi2';

class WhastAppTemplatePreview extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            buttonType: ''
        }
    }

    componentDidMount(){
        const template = this.props.template;
        let updateObject = this.props.updateObject || {};
        if(template && template.components && template.components.length > 0){
            template.components.forEach(component => {
                if(component.type.toLowerCase() === 'header'){
                    if(component.format.toLowerCase() === 'text'){
                        updateObject.headerType = 'Text';
                        updateObject.headerText = component.text;
                    }else{
                        updateObject.headerType = 'Media';
                        updateObject.headerMediaType = component.format.toLowerCase();
                    }
                }else if(component.type.toLowerCase() === 'body'){
                    updateObject.body = component.text
                }else if(component.type.toLowerCase() === 'footer'){
                    updateObject.footer = component.text
                }else if(component.type.toLowerCase() === 'buttons'){
                    const callToActionsButtons = [];
                    const quickReplyButtons = [];

                    component.buttons.forEach(button => {
                        if(button.type.toLowerCase() === 'phone_number' || button.type.toLowerCase() === 'url'){
                            let callToActionButton = {
                                actionType: button.type.toLowerCase() === 'phone_number' ? 'Call Phone number' : 'Visit Website',
                                text: button.text,
                                websiteUrl: '',
                                phoneNumber: ''
                            };

                            callToActionsButtons.push(callToActionButton)
                        }

                        if(button.type.toLowerCase() === 'quick_reply'){
                            let quickReplyButton = {
                                text: button.text
                            };

                            quickReplyButtons.push(quickReplyButton)
                        }
                    })

                    if(callToActionsButtons.length > 0){
                        updateObject.callToActionsButtons = callToActionsButtons;
                        updateObject.buttonType = 'call to action';
                    }

                    if(quickReplyButtons.length > 0){
                        updateObject.quickReplyButtons = quickReplyButtons;
                        updateObject.buttonType = 'quick reply';
                    }
                }
            });
        }

        this.setState({ template, ...updateObject });
    }

    componentDidUpdate(prevProps){
        if(prevProps.updateObject !== this.props.updateObject){
            this.setState({ ...this.props.updateObject })
        }
    }

    render(){
        return (
            <div className='whatsapp_template_composer_preview'>
                <div style={{ background: 'url(/images/whatsapp_background.png)'}} className='whatsapp_template_composer_preview_background'></div>
                <div className='whatsapp_template_composer_preview__title'>WhatsApp Preview</div>
                <div className='whatsapp_tempate_preview_bubble_holder'>
                    <div className='whatsapp_tempate_preview_bubble'>
                        <span data-testid="tail-in" data-icon="tail-in" class="whatsapp_chevron_bot" style={{ display: 'flex' }}><svg viewBox="0 0 8 13" width="8" height="13" class=""><path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path><path fill="currentColor" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path></svg></span>
                        <div className='whatsapp_tempate_preview_content'>
                            {(this.state.headerType === 'Media' && this.state.headerMediaType) && (
                                <div className='whatsapp_tempate_preview_content_media'>
                                    {this.state.headerMediaType && this.state.headerMediaType.toLowerCase() === 'image' && (
                                        <div><MdOutlineImage/></div>
                                    )}
                                    {this.state.headerMediaType && this.state.headerMediaType.toLowerCase() === 'video' && (
                                        <div><MdPlayCircle/></div>
                                    )}
                                    {this.state.headerMediaType && this.state.headerMediaType.toLowerCase() === 'document' && (
                                        <div><HiDocumentText/></div>
                                    )}
                                </div>
                            )}
                            {(this.state.headerType === 'Text' && this.state.headerText) && <div className='whatsapp_header'>{this.state.headerText}</div>}
                            <span dangerouslySetInnerHTML={{ __html: this.state.body }}></span>
                            {(this.state.footer) && <div className='whatsapp_footer'>{this.state.footer}</div>}
                            <div className='whatsapp_timestamp'>{moment().format('HH:mm')}</div>
                            {(this.state.buttonType.toLowerCase() === 'call to action' && this.state.callToActionsButtons) && (
                                <div className='whatsapp_preview_call_to_action_buttons'>
                                    {this.state.callToActionsButtons.map(button => (
                                        (button.text && button.text.length > 0) && <div className='whatsapp_preview_call_to_action_button'><img alt='Call to action button icon' src={button.actionType.toLowerCase() === 'call phone number' ? '/images/8o77vvYFLgb.png' : '/images/OzOCQC-ukqH.png'}/>{button.text}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    {(this.state.buttonType.toLowerCase() === 'quick reply' && this.state.quickReplyButtons) && (
                        <div className='whatsapp_preview_quick_reply_buttons'>
                            {this.state.quickReplyButtons.map(button => (
                                (button.text && button.text.length > 0) && <div className='whatsapp_preview_quick_reply_button'>{button.text}</div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhastAppTemplatePreview);