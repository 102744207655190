import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getTags, createTag } from '../../services/tags';
import { addTag, updateTags } from '../../services/conversation';

// TBF import Creatable from 'react-select/creatable';

const TagWidget = (props) => {
    const [tagOptions, setTagOptions] = useState([]);
    const [loadingTagOptions, setLoadingTagOptions] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [chat, setChat] = useState(props.chat);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            const tags = await getTags(auth.selectedChatbot.token);
            setTagOptions(tags);
            setLoadingTagOptions(false);
        };

        fetchData();
    }, [auth.selectedChatbot.token]);

    useEffect(() => {
        if (chat && chat.tags) {
            setChat(chat);
        }
    }, [props.chat]);

    const createNewTag = async (label) => {
        setLoadingTagOptions(true);
        const newTag = { label };
        const addedTag = await createTag(auth.selectedChatbot.token, newTag);
        const tags = await getTags(auth.selectedChatbot.token);
        setTagOptions(tags);
        setLoadingTagOptions(false);
        await addTag(auth.selectedChatbot.token, chat._id, addedTag._id);
        setLoadingTagOptions(false);
    };

    const addTagToChat = async (e) => {
        const tags = e.map((tagItem) => tagItem.value);

        setDisabled(true);
        setChat((prevChat) => ({
            ...prevChat,
            tags: e.map((tagItem) => ({ _id: tagItem.value, label: tagItem.label })),
        }));

        await updateTags(auth.selectedChatbot.token, chat._id, tags);
        setDisabled(false);
    };

    return (
        <div className="tag_widget">
            {/* <Creatable classNamePrefix='custom_select' className="form_select" isDisabled={disabled} onCreateOption={createNewTag} isLoading={loadingTagOptions} onChange={addTagToChat} value={chat.tags.map(tag => ({ value: tag._id, label: tag.label }))} options={tagOptions.map(tag => ({ value: tag._id, label: tag.label }))} isMulti/> */}
        </div>
    );
};

export default TagWidget
