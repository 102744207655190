import React, { useState } from 'react';
import UserInfoItem from './UserInfoItem';
import { HiEyeSlash, HiEye, HiOutlineArrowUpTray, HiOutlinePaperAirplane } from 'react-icons/hi2';
import ChatTransfer from './ChatTransfer';
import { newChatHandle, createdMessage, createNotification } from '../../services/internalChats';
import { exportLeadInfo } from '../../services/user';
import { setChatWidgetExpanded, setSelectedChat } from '../../reducers/internalChatsSlice';
import { useDispatch, useSelector } from 'react-redux';

const UserInfoList = (props) => {
    const [shareAgentListOpen, setShareAgentListOpen] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [maxItems] = useState(props.maxItems);
    const [showAllItems, setShowAllItems] = useState(false);

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const handleShareToggle = () => {
        setShareAgentListOpen(prevState => !prevState);
    }

    const handleShare = async (id) => {
        const internalChat = await newChatHandle(auth.agent.agent._id, id, auth.selectedChatbot.token);
        const message = await createdMessage(internalChat._id, {
            sender: auth.agent.agent._id,
            message_data: {
                meta: {
                    type: 'conversation_card',
                    id: props.conversation.conversation_id
                }
            }
        }, auth.selectedChatbot.token);

        const currentAgent = auth.agent.agent;
        const chattingTo = internalChat.participants.filter(participant => participant._id !== currentAgent._id);
        const notificationBody = chattingTo.map(participant => ({ agent: participant._id, message: message._id }));
        createNotification(internalChat._id, notificationBody, auth.selectedChatbot.token);

        dispatch(setSelectedChat(internalChat));
        dispatch(setChatWidgetExpanded(true));
    }

    const handleExport = async () => {
        setExporting(true);
        const response = await exportLeadInfo(auth.selectedChatbot.token, props.conversation.user._id, props.conversation.user.user_info);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Leads.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        setExporting(false);
    }

    const toggleShowAll = () => {
        setShowAllItems(prevState => !prevState);
    }

    const user = props.conversation.user;
    let userInfo = user && user.user_info ? Object.keys(user.user_info).map((key) => ({ key, value: user.user_info[key] })) : null;
    if (userInfo) {
        userInfo = userInfo.sort(function (x, y) { return x.key === "phone_number" ? -1 : y.key === "phone_number" ? 1 : 0; });
        userInfo = userInfo.sort(function (x, y) { return x.key === "email" ? -1 : y.key === "email" ? 1 : 0; });
        userInfo = userInfo.sort(function (x, y) { return x.key === "name" ? -1 : y.key === "name" ? 1 : 0; });
        userInfo = userInfo.sort(function (x, y) { return typeof x.value === "object" ? 1 : typeof y.value === "object" ? -1 : 0; });
        userInfo = userInfo.sort(function (x, y) { return (x.value && typeof x.value === "object" && x.value.type) ? -1 : ((y.value && typeof y.value === "object") && y.value.type) ? 1 : 0; });
    }

    let itemsToShow = userInfo;
    if (maxItems && userInfo) {
        if (!showAllItems) {
            itemsToShow = userInfo.slice(0, maxItems);
        }
    }

    return (
        <div className="user_info_list">
            {user && user.user_info ? itemsToShow.map((item) => {
                return <UserInfoItem user={props.conversation.user} key={item.key} keyName={item.key} value={user.user_info[item.key]} />
            }) : <div className="alert_box">No information on user</div>}
            {(maxItems && userInfo && maxItems < userInfo.length) && (showAllItems ? <button className="btn mt-medium" onClick={toggleShowAll}><HiEyeSlash />Hide all info</button> : <button className="btn mt-medium" onClick={toggleShowAll}><HiEye />Show all info</button>)}
            {props.showActions && (
                <div className="user_info_list__actions">
                    <button className="btn" onClick={handleShareToggle}><HiOutlinePaperAirplane />Share Chat</button>
                    {(userInfo && userInfo.length > 0) && <button className="btn" disabled={exporting} onClick={handleExport}><HiOutlineArrowUpTray />{exporting ? 'Exporting...' : 'Export User'}</button>}
                </div>
            )}
            {shareAgentListOpen && <ChatTransfer allowOffline={true} agentPicker={true} title="Share chat with agent" actionText="Sharing" onSelect={handleShare} handleTransferToggle={handleShareToggle} conversation={props.conversation} />}
        </div>
    );
}

export default UserInfoList;
