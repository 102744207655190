import React from 'react';
import Modal from 'react-modal';
import UserInfoItem from './UserInfoItem';
import { titleCase } from '../../services/misc';

const UserInfoAttachmentModal = (props) => {
    let userInfo = props.userInfoAttachment ? Object.keys(props.userInfoAttachment).map((key) => ({ key, value: props.userInfoAttachment[key] })) : null;
    if(userInfo){
        userInfo = userInfo.sort(function(x,y){ return x.key === "phone_number" ? -1 : y.key === "phone_number" ? 1 : 0; });
        userInfo = userInfo.sort(function(x,y){ return x.key === "email" ? -1 : y.key === "email" ? 1 : 0; });
        userInfo = userInfo.sort(function(x,y){ return x.key === "name" ? -1 : y.key === "name" ? 1 : 0; });
        userInfo = userInfo.sort(function(x,y){ return (typeof x.value === "object" && x.value.url) ? 1 : (typeof y.value === "object" && y.value.url) ? -1 : 0; });
        userInfo = userInfo.sort(function(x,y){ return typeof x.value === "object" && !x.value.url ? 1 : typeof y.value === "object" && !y.value.url ? -1 : 0; });
    }
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} onRequestClose={() => { props.onRequestClose() }} className="modal modal__preloader">
            <div className="modal__title">{titleCase(props.attachmentName)}</div>
            <div className="user_info_modal__content">{userInfo.map((value, index) => <UserInfoItem key={index} keyName={value.key} objectName={props.attachmentName} value={props.userInfoAttachment[value.key]} user={props.user}/>)} </div>
            <div className="modal__actions mt-medium">
                <div className="btn button_fit" onClick={(e) => { props.onRequestClose() }}>Close</div>
            </div>
        </Modal>
    );
};

export default UserInfoAttachmentModal;
