import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect, useSelector } from 'react-redux';
import { companyService } from '../../services/company';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const EditChatbotModal = ({ chatbot: propChatbot, isOpen, onRequestClose, onSave }) => {
    const [chatbot, setChatbot] = useState(propChatbot);
    const [companies, setCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(true);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const fetchCompanies = async () => {
            const companies = await companyService.getCompanies(auth.agent.token);
            setCompanies(companies);
            setLoadingCompanies(false);
        };
        fetchCompanies();
    }, [auth.agent.token]);

    useEffect(() => {
        setChatbot(propChatbot);
    }, [propChatbot]);

    const handleInputChange = (e) => {
        setChatbot(prevChatbot => ({ ...prevChatbot, [e.target.name]: e.target.value }));
    };

    const handleSelectChange = (e) => {
        const company = companies.find(company => company._id === e.target.value);
        setChatbot(prevChatbot => ({ ...prevChatbot, owner: company }));
    };

    return (
        <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={onRequestClose} className="action_modal modal__preloader">
            <div className="modal__title">{!chatbot._id ? 'New' : 'Edit'} Chatbot</div>
            <div className="form-group">
                <TextField label="Name" fullWidth value={chatbot.name} onChange={handleInputChange} type="text" name="name" placeholder="Enter chatbot name"/>
            </div>
            <div className="form-group">
                <FormControl fullWidth>
                    <InputLabel id="company-select-label">Company</InputLabel>
                    <Select
                        labelId="company-select-label"
                        id="company-select"
                        value={chatbot.owner ? chatbot.owner._id : null}
                        label="Company"
                        onChange={handleSelectChange}
                        disabled={loadingCompanies}
                    >
                        {companies.map(company => (
                            <MenuItem key={company._id} value={company._id}>{company.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {chatbot._id && <div className="form-group">
                <TextField label="Token" fullWidth value={chatbot.token} onChange={handleInputChange} type="text" name="name" disabled/> 
            </div>}
            <div className="modal__actions mt-medium">
                <div className="btn" onClick={onRequestClose}>Cancel</div>
                <button className="btn" disabled={chatbot.name.trim().length === 0 || !chatbot.owner} onClick={() => onSave(chatbot)}>Save</button>
            </div>                
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditChatbotModal);