import ChatTransferListItem from "./ChatTransferListItem";
import { RiUserSharedLine } from 'react-icons/ri';
import { HiOutlineUserGroup } from 'react-icons/hi2';

function ChatTransferList(props) {
    let sortedQueues = [
        ...props.queues.filter(queue => queue.agents.filter(agent => agent.agent_settings.status === 'available').length > 0 ),
        ...props.queues.filter(queue => queue.agents.filter(agent => agent.agent_settings.status === 'available').length === 0)
    ];

    let sortedAgents = [
        ...props.agents.filter(agent => agent.agent_settings.status === 'available' && agent._id !== props.currentAgent._id),
        ...props.agents.filter(agent => agent.agent_settings.status === 'busy' && agent._id !== props.currentAgent._id),
        ...props.agents.filter(agent => agent.agent_settings.status !== 'busy' && agent.agent_settings.status !== 'available' && agent._id !== props.currentAgent._id)
    ];
    if(props.searchTerm && props.searchTerm.length > 0){
        sortedQueues = sortedQueues.filter(queue => queue.name.toLowerCase().includes(props.searchTerm));
        sortedAgents = sortedAgents.filter(agent => agent.first_name.toLowerCase().includes(props.searchTerm.toLowerCase()) || agent.last_name.toLowerCase().includes(props.searchTerm));
        return (
            <div className="chat_transfer_list">
                {!props.agentPicker && sortedQueues.length > 0 && <div className="chat_transfer_list__subtitle">Queues</div>}
                {!props.agentPicker && sortedQueues.length > 0 && sortedQueues.map((queue) => <ChatTransferListItem currentAgent={props.currentAgent} allowConnected={props.allowConnected} key={queue._id} allowOffline={props.allowOffline}  handleTransfer={props.handleTransfer} queue={queue} conversation={props.conversation}/>)}
                {!props.queuePicker && sortedAgents.length > 0 && <div className="chat_transfer_list__subtitle">Agents</div>}
                {!props.queuePicker && sortedAgents.length > 0 && sortedAgents.map((agent) => <ChatTransferListItem allowConnected={props.allowConnected} key={agent._id} allowOffline={props.allowOffline} agentPicker={props.agentPicker} handleTransfer={props.handleTransfer} agent={agent}  conversation={props.conversation}/>)}
            </div>
        );
    }else if(props.showing === 'queues'){
        return (
            <div className="chat_transfer_list">
                {!props.queuePicker && <div className="chat_transfer_list__item" onClick={props.handleSwitchViewing}><div className="transfer_to_user" title="Transfer to agent"><RiUserSharedLine/></div><div className="chat_transfer_list__item__details"><div className="queues_title">Transfer to agent</div></div></div>}
                <ChatTransferListItem allowOffline={props.allowOffline} handleTransfer={props.handleTransfer} queue={{ name: 'All', agents: sortedAgents }}/>
                {sortedQueues.map((queue) => <ChatTransferListItem allowOffline={props.allowOffline} currentAgent={props.currentAgent} allowConnected={props.allowConnected} key={queue._id} handleTransfer={props.handleTransfer} queue={queue} conversation={props.conversation}/>)}
            </div>
        );
    }else if(props.showing === 'agent'){
        return (
            <div className="chat_transfer_list">
                {!props.agentPicker && <div className="chat_transfer_list__item" onClick={props.handleSwitchViewing}><div className="transfer_to_user" title="Transfer to queue"><HiOutlineUserGroup/></div><div className="chat_transfer_list__item__details"><div className="queues_title">Transfer to queue</div></div></div>}
                {sortedAgents.map((agent) => <ChatTransferListItem allowConnected={props.allowConnected} key={agent._id} allowOffline={props.allowOffline} agentPicker={props.agentPicker} handleTransfer={props.handleTransfer} agent={agent}  conversation={props.conversation}/>)}
            </div>
        );
    }
}

export default ChatTransferList;
