import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import AgentList from '../Agents/AgentList';
import { companyService } from '../../services/company';
import { agentService } from '../../services/agents';
import { firstBy } from "thenby";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Avatar, Box, Chip, TextField } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditCompanyModal = (props) => {
    const [company, setCompany] = useState(props.company);
    const [allAgents, setAllAgents] = useState([]);
    const [companyAgents, setCompanyAgents] = useState([]);
    const [loadingAgents, setLoadingAgents] = useState(true);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        loadAgents();
    }, []);

    useEffect(() => {
        setCompany(props.company);
    }, [props.company]);

    const handleInputChange = (e) => {
        setCompany(prevCompany => ({ ...prevCompany, [e.target.name]: e.target.value }));
    }

    const loadAgents = async () => {
        let agents = await agentService.getAgents(auth.agent.token);
        let companyAgents = [];
        if(company._id){
            companyAgents = await companyService.getCompanyAgents(company._id, auth.agent.token);
        }

        agents = agents.sort(firstBy("disabled").thenBy((a, b) => {
            if(a.agent_settings.status === "offline"){ return  1 }
            if(b.agent_settings.status === "offline"){ return  -1 }
        }).thenBy("first_name"));
        companyAgents = companyAgents.sort(firstBy("disabled").thenBy((a, b) => {
            if(a.agent_settings.status === "offline"){ return  1 }
            if(b.agent_settings.status === "offline"){ return  -1 }
        }).thenBy("first_name"));
        setAllAgents(agents);
        setCompanyAgents(companyAgents);
        setLoadingAgents(false);
    }

    const handleAgentChange = (event) => {
        const {
            target: { value },
        } = event;
        setCompanyAgents(value.map(v => allAgents.find(a => a._id === v)));
    }

    const handleAgentListSelect = (agent, isSelected) => {
        if(isSelected){
            setCompanyAgents(prevCompanyAgents => prevCompanyAgents.filter(companyAgent => companyAgent._id !== agent._id ));
        }else{            
            setCompanyAgents(prevCompanyAgents => [ ...prevCompanyAgents, agent ]);
        }
    }

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} onRequestClose={() => { props.onRequestClose() }} className="action_modal modal__preloader">
            <div className="modal__title">{!company._id ? 'New' : 'Edit'} Company</div>
            <div className="form-group">
                <TextField label="Name" fullWidth value={company.name} onChange={handleInputChange} type="text" name="name" placeholder="Enter company name"/>
            </div>
            <div className="form-group">
                <TextField label="Icon Logo (URL)" fullWidth value={company.logo_icon} onChange={handleInputChange} type="text" name="logo_icon" placeholder="Enter company logo icon url"/>
            </div>
            <div className="form-group">
                <TextField label="Logo (URL)" fullWidth value={company.logo} onChange={handleInputChange} type="text" name="logo" placeholder="Enter company logo url"/>
            </div>
            <div className="form-group">
                <TextField label="Background Image (URL)" fullWidth value={company.background_image} onChange={handleInputChange} type="text" name="background_image" placeholder="Enter company background image url"/>
            </div>
            <div className="form-group">
                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Agents in company</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={companyAgents.map(a => a._id)}
                        onChange={handleAgentChange}
                        input={<OutlinedInput label="Agents in company" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                    const agent = companyAgents.find(a => a._id === value);
                                    return <Chip 
                                        key={value} 
                                        avatar={<Avatar alt={agent.first_name + ' ' + agent.last_name} src={agent.avatar} />}
                                        label={agent.first_name + ' ' + agent.last_name} />
                                })}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {allAgents.map((agent) => (
                            <MenuItem key={agent._id} value={agent._id}>
                                <Checkbox checked={companyAgents.findIndex(a => a._id === agent._id) > -1} />
                                <ListItemText primary={agent.first_name + ' ' + agent.last_name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <AgentList loadingAgents={loadingAgents} isSelectList={true} selectedAgents={companyAgents} onSelect={handleAgentListSelect} loadAgents={props.loadQueues} agents={allAgents} queues={props.queues} currentAgent={auth.agent.agent}/>
            <div className="modal__actions mt-medium">
                <div className="btn button_fit" onClick={(e) => { props.onRequestClose() }}>Cancel</div>
                <button className="btn button_fit" disabled={company.name.trim().length === 0} onClick={() => { props.onSave(company, companyAgents) }}>Save</button>
            </div>
        </Modal>
    )
}

export default EditCompanyModal;