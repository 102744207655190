import Modal from 'react-modal';
import Preloader from '../../Preloader';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getStepTemplates } from '../../../services/edubot/step_template';
import { getConnectors } from '../../../services/edubot/connector';
import ModuleTemplateList from '../ModuleManager/ModuleTemplateList';

const CreateStepModal = ({ isOpen, sectionTemplate, onStepAdd, onRequestClose }) => {
    const auth = useSelector(state => state.auth);
    
    const [loadingStepTemplates, setLoadingStepTemplates] = useState(true);

    const [connectors, setConnectors] = useState(null);
    const [stepTemplates, setStepTemplates] = useState(null);

    // Fetch step templates on component mount
    useEffect(() => {
        const fetchData = async () => {
            const connectors = await getConnectors(auth.selectedChatbot.token);
            const stepTemps = await getStepTemplates(auth.selectedChatbot.token);

            setConnectors(connectors);
            setStepTemplates(stepTemps.filter(stepTemplate => stepTemplate.section_template_id === sectionTemplate?.template_id));

            setLoadingStepTemplates(false);
        }

        fetchData();
    }, []);

    return (
        <Modal ariaHideApp={false} isOpen={isOpen} className="modal modal__preloader create_step_modal" onRequestClose={onRequestClose}>
            <div className="modal__title">Create New Step</div>
            <p>Select which step you would like to select from the list of step templates below.</p>
            <div className="modal__content">
                {loadingStepTemplates ? <Preloader  fitToContent={true}/> : (
                    <ModuleTemplateList moduleTemplates={stepTemplates} connectors={connectors} onModuleAdd={onStepAdd}/>
                )}
            </div>
            <div className="modal__actions">
                <button className="btn" onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
}

export default CreateStepModal;