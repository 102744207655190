import { HiOutlinePlus } from "react-icons/hi2";
import SectionListItem from "./SectionListItem";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay, } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { useState } from "react";
import SectionListItemDraggable from "./SectionListItemDraggable";

const SectionManager = ({ module, sections, handleSectionUpdate, onSectionSelect, handleAddSectionClick, setSections }) => {
    const filteredSections = sections?.filter(section => section.module_id === module._id).sort((a, b) => a.order - b.order);

    const [activeId, setActiveId] = useState(null);
    
    const sensors = useSensors(
		useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            }
        }),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

    function handleDragStart(event) {
		const {active} = event;
        
		setActiveId(active.id);
	}
	
	function handleDragEnd(event) {
		const {active, over} = event;
		
		if (active.id.toString() !== over.id.toString()) {
            setSections((items) => {
                const oldIndex = filteredSections.findIndex(i => i._id == active.id);
				const newIndex = filteredSections.findIndex(i => i._id == over.id);
				const reArrangedSections = arrayMove(filteredSections, oldIndex, newIndex).map((item, index) => ({...item, order: index})); 
				return items.map(item => reArrangedSections.find(i => i._id === item._id) || item);
            })
        }
		
		setActiveId(null);
	}
    
    return (
        <div className="sections_list">
            {filteredSections?.length > 0 ? (
                <DndContext  sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} >
                    <SortableContext items={filteredSections} strategy={verticalListSortingStrategy} >
                        {filteredSections.map(section => <SectionListItem id={section._id} key={section._id} section={section} handleSectionUpdate={handleSectionUpdate} onSectionSelect={onSectionSelect}/>)}
                    </SortableContext> 
                    <DragOverlay>
                        {activeId ? <SectionListItemDraggable id={activeId} section={filteredSections.find(i => i._id === activeId)}/> : null}
                    </DragOverlay>
                </DndContext>
            ) : (
                <div className="edubot_detail_message">
                    <div className="message_title">No sections configured</div>
                    You have not setup any sections for the {module.module_template.name} module. Click the button below to start adding sections.
                    <button className="btn mt-medium" onClick={handleAddSectionClick}><HiOutlinePlus/>Add section</button>
                </div>
            )}
        </div>
    );
}

export default SectionManager;