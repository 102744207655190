import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Preloader from "../Preloader";
import AlertBox from "../AlertBox";
import { getModules } from "../../services/edubot/module";
import { HiOutlinePlus } from "react-icons/hi2";
import SwitchStatusIndicator from "../Base/SwitchStatusIndicator";

const EduBotModulesList = ({ eduBot }) => {
    const auth = useSelector(state => state.auth);

    const [modules, setModules] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Initial effect to load modules.
    useEffect(() => {
        const fetchData = async () => {
            try {
                const eduBotModules = await getModules(auth.selectedChatbot.token, eduBot._id)
                
                setModules(eduBotModules);
                setLoading(false);
            } catch (error) {
                setError('Failed to load modules')
            }
        };

        fetchData();
    }, [])

    return (
        <div className="modules_list">
            {!error ? (!loading ? (
                (modules?.length > 0 ? modules.map(module => (
                    <div className="edubot_detail">
                        <div className="edubot_detail__label">{module.module_template?.name}</div>
                        <div className="edubot_detail__value"><SwitchStatusIndicator status={module.enabled}/></div>
                    </div>
                )) : (
                    <div className="edubot_detail_message">
                        <div className="message_title">No modules configured</div>
                        Your EduBot currently has no enabled modules. Click the button below to start adding modules.
                        <button className="btn mt-medium"><HiOutlinePlus/>Add module</button>
                    </div>
                ))
            ) : <Preloader/>) : (
                <AlertBox type="error">{error}</AlertBox>
            )}
        </div>
    )
}

export default EduBotModulesList;