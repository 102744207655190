import SmallText from '../components/SmallText';
const CompanyListItem = (props) => {
    const company = props.company;
    const handleOnClick = props.onClick;
    return (
        <div onClick={() => handleOnClick(company._id)} className={`company_list__item ${props.selectedCompanyItem === company._id && 'selected'}`}>
            <div className="company_list__item__checkbox"></div>
            <div className="company_list__item__details">
                <div className="company_list__item__title">{company.name}</div>
                <SmallText>Click to select</SmallText>
            </div>
        </div>
    );
}

export default CompanyListItem;