import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    meetingsWaiting: [],
    currentMeeting: undefined,
};

const meetingSlice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        setCurrentMeeting: (state, action) => {
            state.currentMeeting = action.payload;
        },
        setMeetingsWaiting: (state, action) => {
            state.meetingsWaiting = action.payload;
        },
        clearMeetings: () => initialState,
    },
});

export const { setCurrentMeeting, setMeetingsWaiting, clearMeetings } = meetingSlice.actions;

export default meetingSlice.reducer;