import React from 'react';
import { LocalVideoStream, VideoStreamRenderer} from '@azure/communication-calling';

export default class LocalVideoPreviewCard extends React.Component{
    constructor(props){
        super(props); 
        this.deviceManager = props.deviceManager;
        this.selectedCameraDeviceId = props.selectedCameraDeviceId;
        this.state = {
            renderer: undefined,
            localVideoStream: undefined
        }
    }

    async componentDidMount(){
        const cameras = await this.deviceManager.getCameras();
        this.cameraDeviceInfo = cameras.find((cameraDevice) => {
            return cameraDevice.id === this.props.selectedCameraDeviceId;
        });

        if(this.cameraDeviceInfo){
            try{
                const localVideoStream = new LocalVideoStream(this.cameraDeviceInfo);
                const renderer = new VideoStreamRenderer(localVideoStream);
                this.view = await renderer.createView({ scalingMode: 'Crop', isMirrored: true });
                const targetContainer = document.getElementById('localVideoRenderer');
                targetContainer.appendChild(this.view.target);
                this.setState({ renderer, localVideoStream }, () => {
                    this.props.setTogglingVideo(false)
                })
            }catch(e){
            }
        }
    }

    componentWillUnmount(){
        this.state.renderer.dispose();
    }

    render(){
        return (
            <div className="meeting_room_call__local_video_preview" id="localVideoRenderer"></div>
        );
    }
}