import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { getEduBot, publishEduBot } from "../../services/edubot/edubot";
import { useSelector } from "react-redux";
import Preloader from "../Preloader";
import EduBotDetails from "./EduBotDetailsList";
import EduBotPreviewWidget from "./EduBotPreviewWidget";
import { HiArrowUp, HiCodeBracket, HiEye, HiMiniPencilSquare } from "react-icons/hi2";
import { Divider } from "@mui/material";
import { getConnectors } from "../../services/edubot/connector";
import EduBotEmbedCodeModal from "./EduBotEmbedCodeModal";
import { updateWebchat } from "../../services/webchat";

const EduBotPage = () => {
    const auth = useSelector(state => state.auth)
    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [eduBot, setEdubot] = useState(null);
    const [connectors, setConnectors] = useState([]);
    const [showingEmbed, setShowingEmbed] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const eduBotId = params.id;
            const bot = await getEduBot(auth.selectedChatbot.token, eduBotId);
    
            if(!bot){
                // If EduBot not found, return to list page.
                navigate('/edubot', { replace: true })
            }

            const connectors = await getConnectors(auth.selectedChatbot.token);

            setEdubot(bot);
            setConnectors(connectors);
            setLoading(false);
        }

        fetchData();
    }, [])

    const handleEditClick = () => {
        navigate(`/edubot/${eduBot._id}/edit`);
    }

    const handleEmbedClick = () => {
        setShowingEmbed(!showingEmbed);
    }

    const publishEdubot = async () => {
        try {
            await publishEduBot(auth.selectedChatbot.token, eduBot._id);

            await updateWebchat(auth.selectedChatbot.token, eduBot.bot_config.production_widget.webchat_id, { settings: eduBot.staging_widget.settings })
            alert('EduBot published successfully');
        } catch (err) {
            alert('Failed to publish EduBot');
        }
    }

    return (!loading && eduBot) ? (
        <div className="edubot_page">
            <div className="edubot_topbar">
                <h1 className="topbar_title">{eduBot.name}</h1>
                <div className="edubot_topbar_actions">
                    <button className="topbar_action_button primary">Preview<HiEye /></button>
                    <button className="topbar_action_button" onClick={handleEmbedClick}>Embed code<HiCodeBracket /></button>
                    <Divider orientation="vertical" flexItem/>
                    <button className="topbar_action_button" onClick={handleEditClick}>Edit<HiMiniPencilSquare /></button>
                    <button className="topbar_action_button" onClick={publishEdubot}>Publish<HiArrowUp /></button>
                </div>
            </div>
            <div className="edubot_page_container">
                <EduBotDetails eduBot={eduBot} connectors={connectors}/>
                <EduBotPreviewWidget eduBot={eduBot}/>
            </div>
            {showingEmbed && <EduBotEmbedCodeModal eduBot={eduBot} isOpen={showingEmbed} onRequestClose={handleEmbedClick}/>}
        </div>
    ) : <Preloader/>
}   

export default EduBotPage;