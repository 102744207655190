import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { agentService } from '../../services/agents';
import { updateQueue } from '../../services/queues';
import Preloader from '../Preloader';
import AgentListItem from './AgentListItem';
import { HiUserPlus } from 'react-icons/hi2';
import EditAgentModal from './EditAgentModal';
import LoadingModal from '../Base/LoadingModal';
import ConfirmModal from '../Base/ConfirmModal';
import socket from '../../services/socket';
import moment from 'moment';
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2'
import { InputAdornment, OutlinedInput } from '@mui/material';

const AgentList = (props) => {
    const [editAgentFormOpen, setEditAgentFormOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(undefined);
    const [savingAgent, setSavingAgent] = useState(false);
    const [confirmDeleteAgent, setConfirmDeleteAgent] = useState(undefined);
    const [deletingAgent, setDeletingAgent] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [displayMode, setDisplayMode] = useState('online');

    const auth = useSelector(state => state.auth);

    const handleToggleEditAgent = (agent) => {
        setSelectedAgent(agent);
        setEditAgentFormOpen(agent ? true : false);
        setErrorMessage('');
    };

    const handleAgentSave = async (agent, agentQueues) => {
        setSavingAgent(true);
        setEditAgentFormOpen(false);
        setSelectedAgent(undefined);
        setErrorMessage('');

        try {
            if (agent._id) {
                // Edit Agent
                await agentService.updateAgent(auth.agent.token, agent);
                await agentService.setTimedAgent(auth.agent.token, agent._id, agent.agent_settings.timed_agent);
                if (!props.noCompany) {
                    await updateQueueAgents(agent, agentQueues);
                }
                await props.loadAgents();

                setSavingAgent(false);
            } else {
                // New Agent
                const createdAgent = await agentService.createAgent(auth.agent.token, { ...agent, companies: [(auth && auth.selectedCompany) ? auth.selectedCompany : null] });
                if (!props.noCompany) {
                    await updateQueueAgents(createdAgent.agent, agentQueues);
                }
                await props.loadAgents();
                setSavingAgent(false);
            }
        } catch (e) {
            setSavingAgent(false);
            setEditAgentFormOpen(true);
            setSelectedAgent(agent);
            setErrorMessage(e.message);
        }
    };

    const updateQueueAgents = async (agent, agentQueues) => {
        props.queues.forEach(async (queue) => {
            let queueAgents = queue.agents.filter(queueAgent => queueAgent._id !== agent._id);
            if (agentQueues.find(agentQueue => agentQueue._id === queue._id)) {
                queueAgents.push(agent);
            }

            const queueAgentIds = queueAgents.map(queueAgent => queueAgent._id);

            await updateQueue(auth.selectedChatbot.token, queue._id, { agents: queueAgentIds });
        });
    };

    const handleDeleteAgentConfirm = (agent) => {
        setConfirmDeleteAgent(agent);
    };

    const handleDeleteAgent = async () => {
        const agentToDelete = confirmDeleteAgent;
        setDeletingAgent(true);
        setConfirmDeleteAgent(undefined);
        await agentService.deleteAgent(auth.agent.token, agentToDelete._id);
        await props.loadAgents();
        setDeletingAgent(false);
    };

    const getAgentQueues = (agent) => {
        let agentQueues = [];
        if (props.queues && props.queues.length > 0) {
            agentQueues = props.queues.filter(queue => queue.agents.find(queueAgent => queueAgent._id === agent._id));
        }

        return agentQueues;
    };

    const handleSearchAgent = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const agentsUpdateHandler = async ({ id }) => {
            if (props.loadAgents) {
                await props.loadAgents();
            }
        };

        socket.on('agentsUpdate', agentsUpdateHandler);

        return () => {
            socket.off('agentsUpdate', agentsUpdateHandler);
        };
    }, []);

    let agents = props.agents;
    if (searchTerm.trim().length > 0) {
        const term = searchTerm.trim().toLowerCase();
        agents = agents.filter(agent => {
            let isMatch = false;
            if ((agent.first_name + " " + agent.last_name).trim().toLowerCase().includes(term)) { isMatch = true; }
            if (agent.email.trim().toLowerCase().includes(term)) { isMatch = true; }
            return isMatch;
        });
    }

    const onlineAgents = [];
    let offlineAgents = [];
    let disabledAgents = [];
	agents.forEach((agent) => {
		if (!agent.disabled) {
			if (agent.agent_settings.status !== "offline") {
				onlineAgents.push(agent);
			} else {
				offlineAgents.push(agent);
			}
		} else {
			disabledAgents.push(agent);
		}
	});

    offlineAgents = offlineAgents.sort((a, b) => {
        return moment(a.last_seen).unix() - moment(b.last_seen).unix();
    });

    offlineAgents = offlineAgents.sort((a, b) => {
        return !a.last_seen && b.last_seen ? 1 : -1;
    });

    offlineAgents = offlineAgents.sort((a, b) => {
        return !a.disabled && b.disabled ? -1 : 1;
    });

    return (
        <div className="agent_list">
            {!props.isSelectList && <button className="btn mb-medium" onClick={() => { handleToggleEditAgent({ first_name: '', last_name: '', email: '', role: undefined, agent_settings: { timed_agent: false } }) }}><HiUserPlus />Add Agent</button>}
            <div className="agent_list_topbar">
                <OutlinedInput 
                    sx={{ mb: 1 }} 
                    size='small'
                    type="text" 
                    placeholder="Type to search agents" 
                    onChange={handleSearchAgent} 
                    endAdornment={<InputAdornment position="end"><HiOutlineMagnifyingGlass/></InputAdornment>}
                />
                <div className="agent_filter_button_container">
					<div className={`agent_filter_button ${displayMode === "online" ? "active" : ""}`} onClick={() => setDisplayMode("online")}>
						Show Online Agents ({onlineAgents.length})
					</div>
					<div className={`agent_filter_button ${displayMode === "all" ? "active" : ""}`} onClick={() => setDisplayMode("all")}>
						Show All Agents ({onlineAgents.length + offlineAgents.length})
					</div>
					<div className={`agent_filter_button ${displayMode === "disabled" ? "active" : ""}`} onClick={() => setDisplayMode("disabled")}>
						Show Disabled Agents ({disabledAgents.length})
					</div>
				</div>
            </div>
            {!props.loadingAgents ? (
                (displayMode === "online" && onlineAgents.length === 0) || (displayMode === "all" && onlineAgents.length + offlineAgents.length === 0) || (displayMode === "disabled" && disabledAgents.length === 0) ? (
					<span>No agents found</span>
				) : (
                    <div>
                        {displayMode === 'online' || displayMode === 'all' ? (
                            <div>
                                {onlineAgents.length > 0 && <label>Online Agents ({onlineAgents.length})</label>}
                                <div className="agent_list__list mb-medium">{onlineAgents.map(agent => <AgentListItem isSelectList={props.isSelectList} onSelect={props.onSelect} isSelected={(props.isSelectList && props.selectedAgents && props.selectedAgents.find(selectedAgent => selectedAgent._id === agent._id)) ? true : false} key={agent._id} handleDeleteAgentConfirm={handleDeleteAgentConfirm} getAgentQueues={getAgentQueues} agent={agent} handleToggleEditAgent={handleToggleEditAgent} currentAgent={props.currentAgent} />)}</div>
                            </div>
                        ) : null}
                        {displayMode === 'all' ? (
                            <div>
                            {offlineAgents.length > 0 && <label>Offline Agents ({offlineAgents.length})</label>}
                            <div className="agent_list__list">{offlineAgents.map(agent => <AgentListItem isSelectList={props.isSelectList} onSelect={props.onSelect} isSelected={(props.isSelectList && props.selectedAgents && props.selectedAgents.find(selectedAgent => selectedAgent._id === agent._id)) ? true : false} key={agent._id} handleDeleteAgentConfirm={handleDeleteAgentConfirm} getAgentQueues={getAgentQueues} agent={agent} handleToggleEditAgent={handleToggleEditAgent} currentAgent={props.currentAgent} />)}</div>
                            </div>
                        ) : null}
                        {displayMode === 'disabled' ? (
                            <div>
                            {disabledAgents.length > 0 && <label>Disabled Agents ({disabledAgents.length})</label>}
                            <div className="agent_list__list">{disabledAgents.map(agent => <AgentListItem isSelectList={props.isSelectList} onSelect={props.onSelect} isSelected={(props.isSelectList && props.selectedAgents && props.selectedAgents.find(selectedAgent => selectedAgent._id === agent._id)) ? true : false} key={agent._id} handleDeleteAgentConfirm={handleDeleteAgentConfirm} getAgentQueues={getAgentQueues} agent={agent} handleToggleEditAgent={handleToggleEditAgent} currentAgent={props.currentAgent} />)}</div>
                            </div>
                        ) : null}
                    </div>
                )
            ) : (
                <Preloader />
            )}
            {selectedAgent && (
				<EditAgentModal errorMessage={errorMessage} noCompany={props.noCompany} isOpen={editAgentFormOpen} handleAgentSave={handleAgentSave} getAgentQueues={getAgentQueues} queues={props.queues} onRequestClose={handleToggleEditAgent} agent={selectedAgent} />
			)}
            {savingAgent && <LoadingModal isOpen={savingAgent} text="Saving agent" />}
            {confirmDeleteAgent && <ConfirmModal isOpen={confirmDeleteAgent ? true : false} title="Delete Agent" onConfirm={handleDeleteAgent} onRequestClose={() => { handleDeleteAgentConfirm(); }} description="You are about to delete this users account. Are you sure you want to do this?" confirmText="Delete" />}
            {deletingAgent && <LoadingModal isOpen={deletingAgent} text="Deleting agent" />}
        </div>
        );
};

export default AgentList;
