import AgentProfileAvatar from "./Agents/AgentProfileAvatar";
import { toTimestamp } from '../services/dates';
import React, { useState } from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi2';
import AgentName from "./Agents/AgentName";
import moment from "moment";

const AgentHistoryItem = (props) => {
    const [dropdownExpanded, setDropdownExpanded] = useState(false);

    const handleDropdownClick = () => {
        setDropdownExpanded(!dropdownExpanded);
    };

    let combinedAgentHistory = props.combinedAgentHistory;
    const agent = combinedAgentHistory ? combinedAgentHistory[0].agent : props.agent;
    if (props.combinedAgentHistory) {
        combinedAgentHistory = props.combinedAgentHistory.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
    }

    return (
        <div className="agent_history_item">
            <div className="agent_history_item_inner">
                <AgentProfileAvatar size="50" agent={agent} />
                <div className="agent_history_item__details">
                    <div className="agent_history_item__details__name"><AgentName agent={agent} /></div>
                    <div className="agent_history_item__details__status timestamp">{props.connected ? <div className="agent_history_item__connected">Connected</div> : (combinedAgentHistory && toTimestamp(combinedAgentHistory[0].createdAt))}</div>
                </div>
                {combinedAgentHistory && combinedAgentHistory.length > 1 && <div className="agent_history_item__expand" onClick={handleDropdownClick}>{dropdownExpanded ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}</div>}
            </div>
            {combinedAgentHistory && combinedAgentHistory.length > 1 &&
                dropdownExpanded && (
                    <div className="agent_history_item_dropdown">
                        {combinedAgentHistory.map((agentHistory) => {
                            return <div key={agentHistory._id}>
                                <div className="agent_history_item_inner">
                                    <div style={{ width: '66px' }} />
                                    <div className="agent_history_item__details">
                                        <div className="agent_history_item__details__status timestamp">{agentHistory.agent.connected ? <div className="agent_history_item__connected">Connected</div> : toTimestamp(agentHistory.createdAt)}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                )
            }
        </div>
    );
};

export default AgentHistoryItem;
