import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'

export const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => (
    localStorage.getItem('user') ? (
        <Navigate to="/" />
    ) : (
        <div>
            <Outlet/>
        </div>
    )
)

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid
})

export default connect(mapStateToProps)(PublicRoute)