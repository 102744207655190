import { useState, useEffect } from 'react';
import AgentList from './AgentList';
import { companyService } from '../../services/company';
import { agentService } from '../../services/agents';
import { getQueues } from '../../services/queues';
import { firstBy } from "thenby";
import { useSelector } from 'react-redux';

function AgentManager(props) {
    const [agents, setAgents] = useState([]);
    const [queues, setQueues] = useState([]);
    const [loadingAgents, setLoadingAgents] = useState(true);
    const auth = useSelector(state => state.auth);

    const loadAgents = async () => {
        let agents = [];
        let queues = [];
        if (!props.noCompany) {
            agents = await companyService.getCompanyAgents(auth.selectedCompany, auth.agent.token);
            queues = await getQueues(auth.selectedChatbot.token);
        } else {
            agents = await agentService.getAgents(auth.agent.token);
        }

        agents = agents.sort(firstBy("disabled").thenBy((a, b) => {
            if (a.agent_settings.status === "offline") { return 1 }
            if (b.agent_settings.status === "offline") { return -1 }
        }).thenBy("first_name"));

        setAgents(agents);
        setQueues(queues);
        setLoadingAgents(false);
    }

    useEffect(() => {
        loadAgents();
    }, []);

    return (
        <div className="agent_manager">
            <AgentList noCompany={props.noCompany} loadAgents={loadAgents} currentAgent={auth.agent.agent} agents={agents} queues={queues} loadingAgents={loadingAgents} />
        </div>
    );
}

export default AgentManager;
