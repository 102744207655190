import React from 'react';
import Modal from 'react-modal';
import UserInfoList from '../Chats/UserInfoList';

const LeadInfoModal = (props) => {
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} onRequestClose={() => { props.onRequestClose() }} className="modal modal__preloader">
            <div className="modal__title">All user info</div>
            <UserInfoList conversation={{ user: props.user }}/>
            <div className="modal__actions mt-medium">
                <div className="btn" onClick={(e) => { props.onRequestClose() }}>Close</div>
            </div>
        </Modal>
    )
}

export default LeadInfoModal;
