import AgentProfileAvatar from "../Agents/AgentProfileAvatar"
import StatusText from "../Base/StatusText"

const InternalAgentListItem = (props) => {
    return (
        <div className="internal_agent_list_item" onClick={() => props.handleNewChatItemClick(props.agent)}>
            <AgentProfileAvatar size="50" agent={props.agent}/>
            <div className="internal_agent_list_item__details">
                <div className="internal_agent_list_item__details__name text_truncate">{props.agent.first_name} {props.agent.last_name}</div>
                <StatusText status={props.agent.agent_settings.status}/>
            </div>
        </div>
    );
}

export default InternalAgentListItem;