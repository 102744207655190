import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Preloader from "../Preloader";
import AlertBox from "../AlertBox";
import { HiOutlinePlus } from "react-icons/hi2";
import EduBotConnectorsListItem from "./EduBotConnectorsListItem";

const EduBotConnectorsList = ({ eduBot, connectorTemplates }) => {
    const connectors = eduBot.enabled_connectors;
    
    return (
        <div className="connectors_list">
            {(connectors?.length > 0 ? connectors.map(connector => (
                <EduBotConnectorsListItem connector={connector} connectorDetails={connectorTemplates.find(connectorTemplate => connectorTemplate.connector_id === connector.connector_id)}/>
            )) : (
                <div className="edubot_detail_message">
                    <div className="message_title">No connectors configured</div>
                    Your EduBot currently has no enabled connectors. Click the button below to start adding connectors.
                    <button className="btn mt-medium"><HiOutlinePlus/>Add connector</button>
                </div>
            ))}
        </div>
    )
}

export default EduBotConnectorsList;