import { handleResponse } from './misc';

const getWhatsAppTemplates = (authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/whatsapp-templates', requestOptions).then(handleResponse).then((whatsAppTemplates) => {
        return whatsAppTemplates;
    });
}

const createWhatsAppTemplate = (authToken, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/whatsapp-templates', requestOptions).then(handleResponse).then((whatsAppTemplates) => {
        return whatsAppTemplates;
    });
}

const deleteWhatsAppTemplate = (authToken, templateName, language) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            language
        })
    };

    return fetch('/api/whatsapp-templates/' + templateName, requestOptions).then(handleResponse).then((response) => {
        return response;
    });
}

function importContactsFromFile(authToken, fileObject){
    var data = new FormData()
    data.append('file', fileObject)

    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken },
        body: data
    };

    return fetch('/api/whatsapp-templates/numbers/import', requestOptions).then(handleResponse).then((res) => {
        return res;
    }).catch(e => { throw e });
}

function broadcastTemplate(authToken, creatorId, templateName, templateLanguage, components, contactList, allContacts = null){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({
            creator: creatorId,
            templateName,
            templateLanguage,
            components,
            contactList,
            allContacts
        })
    };

    return fetch('/api/whatsapp-templates/broadcast', requestOptions).then(handleResponse).then((response) => {
        return response;
    });
}

function getTemplateParameters(template){
    const components = template.components;
    let parameters = {};
    if(components && components.length > 0){
        components.forEach(component => {
            if(component.type.toLowerCase() === 'header'){
                if(component.format.toLowerCase() === 'text'){
                    parameters.header = {
                        type: component.format,
                        numberOfParameters: getVariablesFromString(component.text)
                    }
                }else{
                    parameters.header = {
                        type: component.format,
                        numberOfParameters: ['1']
                    }
                }
            }else if(component.type.toLowerCase() === 'body'){
                parameters.body = {
                    type: component.type,
                    numberOfParameters: getVariablesFromString(component.text)
                }
            }else if(component.type.toLowerCase() === 'footer'){
                parameters.footer = {
                    type: component.type,
                    numberOfParameters: getVariablesFromString(component.text)
                }
            }else if(component.type.toLowerCase() === 'buttons'){
                let paramButtons = [];
                component.buttons.forEach(button => {
                    if(button.type.toLowerCase() === 'url'){
                        paramButtons.push({
                            buttonText: button.text,
                            buttonUrl: button.url,
                            numberOfParameters: button.url ? getVariablesFromString(button.url) : []
                        })
                    }
                })
                
                let totalButtonParams = 0;
                paramButtons.forEach(pButton => { totalButtonParams += pButton.numberOfParameters.length });
                parameters.buttons = {
                    type: component.type,
                    buttons: paramButtons,
                    numberOfParameters: totalButtonParams
                }
            }
        })
    }

    return parameters;
}

function getComponentUpdateBody (parameters){
    const components = [];
    if(parameters.header && parameters.header.numberOfParameters.length > 0){
        if(parameters.header.type.toLowerCase() === 'text'){
            components.push({
                type: 'header',
                parameters: parameters.header.numberOfParameters.map(() => ({
                    type: 'TEXT',
                    text: ''
                }))
            });
        }else{
            components.push({
                type: 'header',
                parameters: parameters.header.numberOfParameters.map(() => ({
                    type: parameters.header.type.toUpperCase(),
                    [parameters.header.type.toLowerCase()]: ''
                }))
            });
        }
    }

    if(parameters.body && parameters.body.numberOfParameters.length > 0){
        components.push({
            type: 'body',
            parameters: parameters.body.numberOfParameters.map(() => ({
                type: 'TEXT',
                text: ''
            }))
        });
    }

    if(parameters.buttons && parameters.buttons.buttons.length > 0){
        components.push({
            type: 'button',
            sub_type: 'URL',
            index: 0,
            parameters: parameters.buttons.buttons.filter(b => b.numberOfParameters.length > 0).map(() => ({
                type: 'TEXT',
                text: ''
            }))
        });
    }

    return components;
};

function getVariablesFromString(text){
    if(text){
        const variables = text.match(/[^{{]+(?=}\})/g)

        if(variables && variables.length > 0){
            return variables;
        }
    }    

    return [];
}

function toWhatsAppBody(text){
    let formattedText = text;
    formattedText = formattedText.replace(/<(\/?)strong\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '*')
    formattedText = formattedText.replace(/<(\/?)em\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '_')
    formattedText = formattedText.replace(/<(\/?)s\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '~')
    formattedText = formattedText.replace(/<(\/?)br\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '\n\n')
    formattedText = formattedText.replace(/<(\/?)p\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '')
    formattedText = formattedText.replace(/<(\/?)span\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g, '')
    return formattedText;
}

export { getWhatsAppTemplates, createWhatsAppTemplate, deleteWhatsAppTemplate, importContactsFromFile, getTemplateParameters, getComponentUpdateBody, broadcastTemplate, toWhatsAppBody }
