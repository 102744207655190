import React from 'react';
import PageContainer from '../Base/PageContainer';
import AdminAgents from './AdminAgents';
import AdminChatbots from './AdminChatbots';
import AdminCompanies from './AdminCompanies';

const AdminSettingsPage = () => {
    return (
        <div>
            <PageContainer>
                <h2>Admin Settings</h2>
                <AdminCompanies/>
                <AdminChatbots/>
                <AdminAgents/>
            </PageContainer>
        </div>
    );
}

export default AdminSettingsPage;
