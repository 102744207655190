import React from 'react';
import { connect } from 'react-redux';
import incomingSound from '../../sounds/incoming_call.wav';
import IncomingMeeting from './IncomingMeeting';
import { GiSpeakerOff, GiSpeaker } from 'react-icons/gi';
// TBF import Toggle from 'react-toggle';
import { agentService } from '../../services/agents'
import { Switch } from '@mui/material';

class IncomingMeetingContainer extends React.Component{
    constructor(props){
        super(props);
        
        this.audio = new Audio(incomingSound)
        this.state = {
            play: this.props.auth.agent.agent.agent_settings.call_notifications_muted ? false : true
        };

        this.togglePlay = this.togglePlay.bind(this);
    }

    componentDidMount() {
        this.audio.addEventListener('ended', () => this.togglePlay(true) );
        if(this.state.play){
            this.togglePlay(true)
        }else{
            this.togglePlay(false)
        }
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.meetings.currentMeeting !== this.props.meetings.currentMeeting){
            if(this.props.meetings.currentMeeting){
                this.audio.pause();
            }else{
                if(this.state.play){
                    this.audio.play();
                }
            }
        }
    }

    componentWillUnmount() {
        this.audio.addEventListener('ended', () => this.togglePlay(false) );
        this.audio.pause(); 
    }
    
    togglePlay = (play) => {
        this.setState({ play }, () => {
            if(this.state.play){
                this.audio.play() 
                agentService.setCallMuted(this.props.auth.agent.token, this.props.auth.agent.agent._id, !this.state.play);
                this.props.dispatch({
                    type: 'SET_CURRENT_AGENT_SET',
                    agent: {
                        ...this.props.auth.agent.agent,
                        agent_settings: {
                            ...this.props.auth.agent.agent.agent_settings,
                            call_notifications_muted: !this.state.play
                        }
                    }
                });                
            }else{
                this.audio.pause() 
                agentService.setCallMuted(this.props.auth.agent.token, this.props.auth.agent.agent._id, !this.state.play);
                this.props.dispatch({
                    type: 'SET_CURRENT_AGENT_SET',
                    agent: {
                        ...this.props.auth.agent.agent,
                        agent_settings: {
                            ...this.props.auth.agent.agent.agent_settings,
                            call_notifications_muted: !this.state.play
                        }
                    }
                });      
            }
        });
    }
    
    render(){
        return (
            <div className="incoming_meeting_container">    
                <div className="incoming_meeting_actions">
                    <div className="notifications_title">Calls</div>
                    <div className="notifications_actions__container">
                        <Switch className="toggle_custom" onChange={() => { this.togglePlay(!this.state.play) }} ref={input => this.inputElement = input} checked={this.state.play}/>
                    </div>
                </div>            
                {(this.props.meetings.meetingsWaiting.length > 0) && this.props.meetings.meetingsWaiting.map(meeting => <IncomingMeeting meeting={meeting}/>)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(IncomingMeetingContainer);