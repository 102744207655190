import { handleResponse, serialize } from './misc';

const getErrors = (authToken, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    queryString += filters ? '&' + serialize(filters) : '';

    return fetch('/api/errors' + queryString, requestOptions).then(handleResponse).then((errors) => {
        return errors;
    });
}

const deleteError = (authToken, id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/errors/' + id, requestOptions).then(handleResponse).then((errors) => {
        return errors;
    });
}

const deleteErrors = (authToken) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/errors/all', requestOptions).then(handleResponse).then((errors) => {
        return errors;
    });
}

export {
    getErrors,
    deleteError,
    deleteErrors
};