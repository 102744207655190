import React from 'react';

const DashboardSelectedComponent = (props) => {
    return (
        <div className="dashboard_component">
            {props.children}
        </div>
    );
}

export default DashboardSelectedComponent;
