import React, { useState } from 'react';
import { HiOutlineArrowLeft, HiOutlinePlusCircle, HiOutlineTrash, HiOutlineXMark } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';
import PageContainer from '../Base/PageContainer';
import WhatsAppTemplateList from './WhatsAppTemplateList';

const WhatsAppTemplatePage = () => {
    const [editable, setEditable] = useState(false);

    const handleTriggerEditable = () => {
        setEditable(prevEditable => !prevEditable);
    }

    return (
        <PageContainer>
            <div className="container">
                <div className="col-12">
                    <div className="page_title">Template Messages</div>
                    <div className='page_description'>To send notifications to your customer via WhatsApp you need to make use of message templates. Those templates will be approved by the WhatsApp team.</div>
                    <div className='whatsapp_template_page_actions_holder'>
                        <div className='whatsapp_template_page_actions'>
                            <NavLink to="/whatsapp-push-notifications" className='btn'><HiOutlineArrowLeft/>Back</NavLink>
                        </div>
                        <div className='whatsapp_template_page_actions'>
                            <NavLink to="/whatsapp-push-notifications/templates/create" className='btn primary'><HiOutlinePlusCircle/>Create New Template</NavLink>
                            {!editable && <button className='btn' onClick={handleTriggerEditable}><HiOutlineTrash/>Delete Templates</button>}
                            {editable && <button className='btn' onClick={handleTriggerEditable}><HiOutlineXMark/>Cancel Delete</button>}
                        </div>
                    </div>
                    <WhatsAppTemplateList allowPending={true} editable={editable}/>
                </div>
            </div>
        </PageContainer>
    );
};

export default WhatsAppTemplatePage;
