import React from 'react';
import { connect, useSelector } from 'react-redux';
import { HiChatBubbleLeftRight, HiOutlinePlus } from 'react-icons/hi2';
import InternalChatListItem from './InternalChatListItem';
import LoadMore from '../Base/LoadMore';

const InternalChatsList = (props) => {
    const auth = useSelector(state => state.auth);
    const internalChats = useSelector(state => state.internalChats);

    return (
        <div className="internal_chats_list">
            {internalChats.chats && internalChats.chats.length > 0 ? (
                <div>
                    {internalChats.chats.map(internalChat => <InternalChatListItem key={internalChat._id} handleSelectChat={props.handleSelectChat} chat={internalChat} currentAgent={auth.agent.agent}/>)}
                    {internalChats.hasMore && <LoadMore onClick={props.handleLoadMore} loadingMore={internalChats.loadingMore}/>}
                </div>
            ) : (
                <div className="internal_chats_list__message_badge">
                    <HiChatBubbleLeftRight className="internal_chats_list__message_badge__icon"/> 
                    No chats yet
                    <button className="btn" onClick={props.handleNewChatClick}><HiOutlinePlus/>New Conversation</button>
                </div>
            )}
        </div>
    );
}

export default InternalChatsList;
