import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PageContainer from '../Base/PageContainer';
import WhatsAppPushNotificationJobList from './WhatsAppPushNotificationJobList';
import { TbSend } from 'react-icons/tb';
import { BsListNested } from 'react-icons/bs'

class WhatsAppTemplateSenderPage extends React.Component {
    render(){
        return (
            <PageContainer>
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">WhatsApp Push Notifications</div>
                        <div className='whatsapp_template_page_actions_holder'>
                            <div className='whatsapp_template_page_actions'>
                                <NavLink to="/whatsapp-push-notifications/send" className='btn primary'><TbSend/>Send Push Notifications</NavLink>
                            </div>
                            <div className='whatsapp_template_page_actions'>
                                <NavLink to="/whatsapp-push-notifications/templates" className='btn'><BsListNested/>Manage Templates</NavLink>
                            </div>
                        </div>
                        <WhatsAppPushNotificationJobList/>
                    </div>
                </div>
            </PageContainer>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppTemplateSenderPage);