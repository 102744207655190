import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import AgentProfileAvatar from './Agents/AgentProfileAvatar'
import Status from './Base/Status';
import { HiOutlineCog, HiOutlineArrowRightOnRectangle } from 'react-icons/hi2';
import { FaUserEdit } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { logout } from '../reducers/authSlice';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const MiniAgentProfile = ({ agent }) => {
    const [profileShown, setProfileShown] = useState(false);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        setProfileShown(false);
    }, [agent]);

    const handleProfileTrigger = () => {
        setProfileShown(prevState => !prevState);
    }

    const handleLogout = () => {
        dispatch(logout());
    }

    const handleClickAway = () => {
        if (profileShown) {
            handleProfileTrigger();
        }
    }
    
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="mini_agent_profie">
                <div className="mini_agent_profie_trigger" onClick={handleProfileTrigger}>
                    <AgentProfileAvatar agent={agent} showStatus={true} />
                </div>
                {profileShown && (
                    <div className="mini_agent_profile__profile">
                        <div className="mini_agent_profie__title">Profile</div>
                        <div className="mini_agent_profile_details">
                            <AgentProfileAvatar size="60" agent={agent} />
                            <div className="mini_agent_profile_details__container">
                                <div className="mini_agent_profile_details__name text-truncate">{agent.first_name} {agent.last_name}</div>
                                <Status agent={agent} />
                            </div>
                        </div>
                        <div className="mini_agent_profile__actions">
                            <NavLink to="/profile" className="mini_agent_profile__action"><FaUserEdit /> Edit Profile</NavLink>
                            {['admin', 'manager'].includes(auth.agent.agent.role) && <NavLink to="/settings" className="mini_agent_profile__action"><HiOutlineCog /> Settings</NavLink>}
                            <div onClick={handleLogout} className="mini_agent_profile__action"><HiOutlineArrowRightOnRectangle /> Logout</div>
                        </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    )
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(MiniAgentProfile);
