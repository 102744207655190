import QueueText from './QueueText';

const QueueBadge = (props) => {
    const queue = props.queue;
    return (
        <div className="queue_badge">
            <QueueText queue={queue}/>
        </div>
    );
} 

export default QueueBadge;