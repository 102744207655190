import React from 'react';
import '../styles/styles.scss';
import 'reactflow/dist/style.css';
import AppRouter from '../routers/AppRouter';

const App = () => {
	return (
		<div className="App">
			<AppRouter/>
		</div>
	);
};

export default App;