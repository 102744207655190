import { LocalVideoStream } from '@azure/communication-calling';
import React from 'react';
import { utils } from '../../services/communicationSevices';
import LocalVideoPreviewCard from './LocalVideoPreviewCard';
import StreamMedia from './StreamMedia';
import RemoteParticipantCard from './RemoteParticipantCard';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash } from 'react-icons/fa';
import { CgMiniPlayer, CgMaximizeAlt } from 'react-icons/cg';
import { HiCog6Tooth, HiOutlineXMark } from 'react-icons/hi2';
import { ImPhoneHangUp } from 'react-icons/im'
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md'
import { setUsersWaiting, connectAgent, disconnectAgent } from '../../services/meetings'
import AgentProfileAvatar from '../Agents/AgentProfileAvatar'
import LocalSettings from './LocalSettings';
import { RiCameraSwitchLine } from 'react-icons/ri';

export default class CallCard extends React.Component {
    constructor(props){
        super(props);
        this.callFinishConnectingResolve = undefined;
        this.call = props.call;
        this.deviceManager = props.deviceManager;
        this.state = {
            callState: this.call.state,
            callId: this.call.id,
            remoteParticipants: this.call.remoteParticipants,
            allRemoteParticipantStreams: [],
            videoOn: props.selectedCameraDeviceId || false,
            micMuted: props.selectedMicrophoneDeviceId ? false : true,
            onHold: this.call.state === 'LocalHold' || this.call.state === 'RemoteHold',
            screenShareOn: this.call.isScreenShareOn,
            cameraDeviceOptions:[],
            selectedCameraDeviceId: props.selectedCameraDeviceId,
            selectedMicrophoneDeviceId: props.selectedMicrophoneDeviceId,
            selectedSpeakerDeviceId: props.selectedSpeakerDeviceId,
            showSettings: false,
            showLocalVideo: props.selectedCameraDeviceId ? true : false,
            meeting: this.props.meeting,
            minimizable: this.props.minimizable || false,
            someoneSharing: false
        };

        this.handleMicOnOff = this.handleMicOnOff.bind(this);
        this.handleEndCall = this.handleEndCall.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
        this.handleVideoSwitch = this.handleVideoSwitch.bind(this);
    }

    async componentDidMount(){
        if(this.call){
            if(this.state.remoteParticipants && this.state.remoteParticipants.length === 0 && !this.props.agent){
                setUsersWaiting(this.state.meeting._id, true);
            }

            if(this.props.agent){
                connectAgent(this.state.meeting._id, this.props.agent._id)
            }

            const cameraDevices = await this.deviceManager.getCameras();

            cameraDevices.forEach((cameraDevice) => { this.state.cameraDeviceOptions.push({cameraDevice,  key: cameraDevice.id, text: cameraDevice.name}) });

            // Handle Video Device Change
            this.deviceManager.on('videoDevicesUpdated', (e) => {
                e.added.forEach(cameraDevice => { this.state.cameraDeviceOptions.push({key: cameraDevice.id, text: cameraDevice.name}); });

                e.removed.forEach(removedCameraDevice => {
                    this.state.cameraDeviceOptions.forEach(async (value, index) => {
                        if(value.key === removedCameraDevice.id) {
                            this.state.cameraDeviceOptions.splice(index, 1);
                            if(removedCameraDevice.id === this.state.selectedCameraDeviceId) {
                                const cameras = await this.deviceManager.getCameras();
                                if(cameras.length > 0){
                                    const cameraDevice = await cameras[0];
                                    this.setState({selectedCameraDeviceId: cameraDevice.id});
                                }else{
                                    if(this.state.videoOn){
                                        this.handleVideoOnOff();
                                    }
                                }
                            }
                        }
                    });
                });
            });

            const callStateChanged = () => {
                this.setState({callState: this.call.state});

                if(this.state.callState !== 'None' && this.state.callState !== 'Connecting' && this.state.callState !== 'Incoming'){
                    if(this.callFinishConnectingResolve){
                        this.callFinishConnectingResolve();
                    }
                }
            };

            callStateChanged();
            this.call.on('stateChanged', callStateChanged);


            this.call.on('idChanged', () => {
                this.setState({ callId: this.call.id});
            });
            this.call.on('isMutedChanged', () => {
                this.setState({ micMuted: this.call.isMicrophoneMuted });
            });

            this.call.on('isScreenSharingOnChanged', () => {
                this.setState({ screenShareOn: this.call.isScreenShareOn});
            });

            // Handles remote participants
            this.call.remoteParticipants.forEach((remoteParticipant) => {
                this.subscribeToRemoteParticipant(remoteParticipant);
            });
            
            this.call.on('remoteParticipantsUpdated', (e) => {
                e.added.forEach((remoteParticipant) => {
                    this.subscribeToRemoteParticipant(remoteParticipant);
                    this.setState((prevState) => {
                        return {
                            remoteParticipants: [...prevState.remoteParticipants, remoteParticipant]
                        };
                    });
                });

                e.removed.forEach((remoteParticipant) => {                    
                    this.setState({remoteParticipants: this.state.remoteParticipants.filter(rp => { return rp !== remoteParticipant })});
                });
            });

            setInterval(() => { 
                if(this.call.tsCall.isSomeoneSharing !== this.state.someoneSharing){
                    this.setState({ someoneSharing: this.call.tsCall.isSomeoneSharing })
                }
             }, 1)
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedMicrophoneDeviceId !== this.props.selectedMicrophoneDeviceId){
            this.setState({ selectedMicrophoneDeviceId: this.props.selectedMicrophoneDeviceId })
        }

        if(prevProps.selectedSpeakerDeviceId !== this.props.selectedSpeakerDeviceId){
            this.setState({ selectedSpeakerDeviceId: this.props.selectedSpeakerDeviceId })
        }
    }

    componentWillUnmount(){
        if(this.call){
            this.call.hangUp()
            setUsersWaiting(this.state.meeting._id, false);
        }

        if(this.props.agent){
            disconnectAgent(this.state.meeting._id, this.props.agent._id)
        }

        this.deviceManager.off('videoDevicesUpdated', () => {});
        this.call.off('stateChanged', () => {})
        this.call.off('idChanged', () => {})
        this.call.off('isMutedChanged', () => {})
        this.call.off('isScreenSharingOnChanged', () => {})
        this.call.off('remoteParticipantsUpdated', () => {})
        this.call.remoteParticipants.forEach((remoteParticipant) => {
            remoteParticipant.off('displayNameChanged', () => {});
    
            remoteParticipant.off('stateChanged', () => {});
        });
    }

    subscribeToRemoteParticipant(remoteParticipant){
        remoteParticipant.on('displayNameChanged', () => {

        });

        remoteParticipant.on('stateChanged', () => {
            this.setState({ remoteParticipants: this.call.remoteParticipants });
        });

        const addToListofAllRemoteParticipantStreams = (participantStreams) => {
            if(participantStreams){
                let participantStreamTuples = participantStreams.map((stream) => { return { stream, remoteParticipant } });
                participantStreamTuples.forEach((participantStreamTuple) => {
                    if(!this.state.allRemoteParticipantStreams.find((v) => { return v === participantStreamTuple })){
                        this.setState((prevState) => ({
                            allRemoteParticipantStreams: [...prevState.allRemoteParticipantStreams, participantStreamTuple]
                        }));
                    }
                });
            }
        }
        
        const removeFromListOfAllRemoteParticipantStreams = (participantStreams) => {
            participantStreams.forEach(streamToRemove => {
                const tupleToRemove = this.state.allRemoteParticipantStreams.find((v) => { return v.stream === streamToRemove})
                if(tupleToRemove) {
                    this.setState({
                        allRemoteParticipantStreams: this.state.allRemoteParticipantStreams.filter(streamTuple => { return streamTuple !== tupleToRemove })
                    });
                }
            });
        }

        const handleVideoStreamsUpdated = (e) => {
            addToListofAllRemoteParticipantStreams(e.added);
            removeFromListOfAllRemoteParticipantStreams(e.removed);
        };

        addToListofAllRemoteParticipantStreams(remoteParticipant.videoStreams)
        remoteParticipant.on('videoStreamsUpdated', handleVideoStreamsUpdated);
    }

    handleVideoOnOff = async () => {
        try{
            const cameras = await this.deviceManager.getCameras();
            let cameraDeviceInfo = cameras.find((cameraDeviceInfo) => {
                return cameraDeviceInfo.id === this.state.selectedCameraDeviceId
            });

            if(!cameraDeviceInfo){
                this.setState({ selectedCameraDeviceId: cameras[0].id })
                cameraDeviceInfo = cameras[0];
            }

            const localVideoStream = new LocalVideoStream(cameraDeviceInfo);

            if(this.call.state === 'None' || this.call.state === 'Connecting' || this.call.state === 'Incoming'){
                if(this.state.videoOn){
                    this.setState({ videoOn: false })
                } else {
                    this.setState({ videoOn: true })
                }
                await this.watchForCallFinishConnecting();
                if(this.state.videoOn) {
                    this.call.startVideo(localVideoStream).catch(error => {});
                } else {
                    this.call.stopVideo(this.call.localVideoStreams[0]).catch(error => {});
                }
            } else{
                if(this.call.localVideoStreams[0]) {
                    await this.call.stopVideo(this.call.localVideoStreams[0]);
                    this.setState({showLocalVideo: false})
                } else {
                    await this.call.startVideo(localVideoStream);
                    this.setState({showLocalVideo: true})
                }
            }

            this.setState({ videoOn: this.call.localVideoStreams[0] ? true : false });
        } catch(e){
            console.error(e);
        }
    }

    async handleVideoChange(e){
        const cameraDevice = e.value;
        if(!cameraDevice || cameraDevice.id === 'camera:'){
            this.setState({showCameraNotFoundWarning: true });
        } else if (cameraDevice){
            await this.call.stopVideo(this.call.localVideoStreams[0]);
            this.setState({ selectedCameraDeviceId: cameraDevice.id });
            const localVideoStream = new LocalVideoStream(cameraDevice);
            await this.call.startVideo(localVideoStream);
        }
    }

    async handleVideoSwitch(){
        if(!this.state.togglingVideo){
            const cameraDevices = await this.deviceManager.getCameras();
            if(cameraDevices.length === 2){
                this.setState({ togglingVideo: true})
                await this.call.stopVideo(this.call.localVideoStreams[0]);
                const cameraDevice = this.state.selectedCameraDeviceId === cameraDevices[0].id ? cameraDevices[1] : cameraDevices[0];
                this.setState({ selectedCameraDeviceId: cameraDevice.id });
                const localVideoStream = new LocalVideoStream(cameraDevice);
                await this.call.startVideo(localVideoStream);
                this.setState({ togglingVideo: false})
            }
        }
    }

    async watchForCallFinishConnecting() {
        return new Promise((resolve) => {
            if (this.state.callState !== 'None' && this.state.callState !== 'Connecting' && this.state.callState !== 'Incoming') {
                resolve();
            } else {
                this.callFinishConnectingResolve = resolve;
            }
        }).then(() => {
            this.callFinishConnectingResolve = undefined;
        });
    }

    async handleMicOnOff(){
        try {
            if (!this.state.micMuted) {
                await this.call.mute();
                this.setState({micMuted: true});
            } else {
                await this.call.unmute();
                this.setState({micMuted: false});
            }
        } catch(e) {
            console.error(e);
        }
    }

    handleHoldUnhold = async () => {
        try {
            if(this.call.state === 'LocalHold' || this.call.state === 'RemoteHold') {
                this.call.resume();
            } else {
                this.call.hold();
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleScreenSharingOnOff = async () => {
        try {
            if (this.call.isScreenSharingOn) {
                await this.call.stopScreenSharing()
            } else {
                await this.call.startScreenSharing();
                if(this.state.videoOn){
                    this.handleVideoOnOff();
                }
            }
            this.setState({screenShareOn: this.call.isScreenSharingOn});
        } catch(e) {
            console.error(e);
        }
    }

    cameraDeviceSelectionChanged = async (event, item) => {
        const cameras = await this.deviceManager.getCameras();
        const cameraDeviceInfo = cameras.find(cameraDeviceInfo => { return cameraDeviceInfo.id === item.key });
        const localVideoStream = this.call.localVideoStreams[0];
        localVideoStream.switchSource(cameraDeviceInfo);
        this.setState({selectedCameraDeviceId: cameraDeviceInfo.id});
    };

    handleEndCall(){
        this.call.hangUp()
        
        if(this.props.onDisconnect){
            this.props.onDisconnect();
        }
    }

    render(){
        return (
            <div className={`meeting_room_call ${this.state.someoneSharing ? 'screen_share' : ''}`}>
                {this.state.callState === 'Connected' && 1 == 2 && (
                    <div className="meeting_room_call__participants">
                        {this.state.remoteParticipants.length === 0 && <p>You are currently the only one in the group</p>}
                        <ul>
                            {
                                this.call.remoteParticipants.map(remoteParticipant =>
                                    <RemoteParticipantCard key={`${this.call.id}-${utils.getIdentifierText(remoteParticipant.identifier)}`} remoteParticipant={remoteParticipant} call={this.call}/>
                                )
                            }
                        </ul>
                    </div>
                )}
                <div className="screen_share__container">
                    {
                        this.state.allRemoteParticipantStreams.length > 0 && this.state.allRemoteParticipantStreams.map((v) => {
                            if(v.stream.mediaStreamType === 'ScreenSharing'){
                                return <StreamMedia key={`${utils.getIdentifierText(v.remoteParticipant.identifier)}-${v.stream.mediaStreamType}-${v.stream.id}`} stream={v.stream} remoteParticipant={v.remoteParticipant}/>
                            }else{
                                return '';
                            }
                        })
                    }
                </div>
                <div className={`meeting_room_video_grid ${this.state.allRemoteParticipantStreams.length > 0 && 'grid_' + (this.state.remoteParticipants.length)}`}>
                    {
                        this.state.allRemoteParticipantStreams.length > 0 ? this.state.allRemoteParticipantStreams.map((v) => {
                            if(v.stream.mediaStreamType !== 'ScreenSharing'){
                                return <StreamMedia key={`${utils.getIdentifierText(v.remoteParticipant.identifier)}-${v.stream.mediaStreamType}-${v.stream.id}`} stream={v.stream} remoteParticipant={v.remoteParticipant}/>
                            }else{
                                return '';
                            }
                        }) : (
                            <div className="meeting_room_video_grid__waiting">
                                <AgentProfileAvatar size="120" agent={this.state.meeting.owner}/>
                                <label>You are waiting to talk to</label>
                                <div className="meeting_room_video_grid__waiting__meeting_owner">{this.state.meeting.owner.first_name} {this.state.meeting.owner.last_name}</div>
                            </div>
                        )
                    }
                    {
                        // Show Local Preview
                        this.state.showLocalVideo && <LocalVideoPreviewCard setTogglingVideo={this.props.setTogglingVideo} selectedCameraDeviceId={this.state.selectedCameraDeviceId} deviceManager={this.deviceManager}/>
                    }
            </div>
                <div className="meeting__title">{this.state.meeting.title}</div>
                <div className="meeting_room_call__call_actions">
                    <button onClick={this.handleVideoOnOff} disabled={this.state.screenShareOn}>{!this.state.videoOn ? <FaVideoSlash/> : <FaVideo/>}</button>
                    <button onClick={this.handleMicOnOff}>{this.state.micMuted ? <FaMicrophoneSlash/> : <FaMicrophone/>}</button>
                    <button className="mobile_hide" onClick={() => this.handleScreenSharingOnOff()}>{!this.state.screenShareOn ? <MdScreenShare/> : <MdStopScreenShare/>}</button>
                    <button className="mobile_hide" onClick={() => this.setState((prevState) => {return prevState.showSettings ?  {showSettings: false} : {showSettings: true}})}>{this.state.showSettings ? <HiOutlineXMark/> : <HiCog6Tooth/>}</button>
                    {this.state.videoOn && this.state.cameraDeviceOptions && this.state.cameraDeviceOptions.length === 2 && <button className="switch_camera" onClick={this.handleVideoSwitch} disabled={this.state.togglingVideo}><RiCameraSwitchLine/></button>}
                    {this.state.showSettings && <LocalSettings videoOn={this.state.videoOn} deviceManager={this.deviceManager} selectedCameraDeviceId={this.state.selectedCameraDeviceId} selectedMicrophoneDeviceId={this.state.selectedMicrophoneDeviceId} selectedSpeakerDeviceId={this.state.selectedSpeakerDeviceId} handleVideoChange={this.handleVideoChange} handleAudioChange={this.props.handleAudioChange} handleSpeakerChange={this.props.handleSpeakerChange}/>}
                    <button className="leave" onClick={this.handleEndCall} handleAudioChange={this.props.handleAudioChange}><ImPhoneHangUp/></button>               
                    {this.state.minimizable && <button onClick={this.props.handleMinimize}>{this.props.minimized ? <CgMaximizeAlt/> : <CgMiniPlayer/>}</button>}
                </div>                
                {this.state.screenShareOn && <div className="meeting_alert_message">
                    You are now sharing you screen. Please note camera is disabled while sharing screen.
                    <button className="meeting_alert_message__button button" onClick={() => this.handleScreenSharingOnOff()}><MdStopScreenShare/>Stop sharing screen</button>
                </div>}
            </div>
        );
    }
}