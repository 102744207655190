import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import AgentList from '../Agents/AgentList';
import { companyService } from '../../services/company';
import { firstBy } from "thenby";
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Avatar, Box, Chip } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditQueueModal = (props) => {
    const [queue, setQueue] = useState(props.queue);
    const [allAgents, setAllAgents] = useState([]);
    const [loadingAgents, setLoadingAgents] = useState(true);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        loadAgents();
    }, []);

    useEffect(() => {
        if (props.queue !== queue) {
            setQueue(props.queue);
        }
    }, [props.queue]);

    const handleInputChange = (e) => {
        setQueue(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleAgentChange = (event) => {
        const {
            target: { value },
        } = event;
        setQueue(prevState => ({ ...prevState, agents: value.map(v => allAgents.find(a => a._id === v)) }));
    }

    const handleAgentListSelect = (agent, isSelected) => {
        if (isSelected) {
            setQueue(prevState => ({ ...prevState, agents: prevState.agents.filter(queueAgent => queueAgent._id !== agent._id) }));
        } else {
            setQueue(prevState => ({ ...prevState, agents: [...prevState.agents, agent] }));
        }
    }

    const loadAgents = async () => {
        let agents = await companyService.getCompanyAgents(auth.selectedCompany, auth.agent.token);

        agents = agents.sort(firstBy("disabled").thenBy((a, b) => {
            if (a.agent_settings.status === "offline") { return 1 }
            if (b.agent_settings.status === "offline") { return -1 }
        }).thenBy("first_name"));

        setAllAgents(agents);
        setLoadingAgents(false);
    }

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} onRequestClose={() => { props.onRequestClose() }} className="action_modal modal__preloader">
            <div className="modal__title">{!queue._id ? 'New' : 'Edit'} Queue</div>
            <div className="form-group">
                <TextField fullWidth label="Name" value={queue.name} onChange={handleInputChange} type="text" name="name" placeholder="Enter queue name" />
            </div>
            <div className="form-group">
                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Agents in queue</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={queue.agents.map(a => a._id)}
                        onChange={handleAgentChange}
                        input={<OutlinedInput label="Agents in queue" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                    const agent = queue.agents.find(a => a._id === value);
                                    return <Chip
                                        key={value}
                                        avatar={<Avatar alt={agent.first_name + ' ' + agent.last_name} src={agent.avatar} />}
                                        label={agent.first_name + ' ' + agent.last_name} />
                                })}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {allAgents.map((agent) => (
                            <MenuItem key={agent._id} value={agent._id}>
                                <Checkbox checked={queue.agents.findIndex(a => a._id === agent._id) > -1} />
                                <ListItemText primary={agent.first_name + ' ' + agent.last_name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <AgentList loadingAgents={loadingAgents} isSelectList={true} selectedAgents={queue.agents} onSelect={handleAgentListSelect} loadAgents={props.loadQueues} agents={allAgents} queues={props.queues} currentAgent={auth.agent.agent} />
            <div className="modal__actions mt-medium">
                <div className="btn" onClick={(e) => { props.onRequestClose() }}>Cancel</div>
                <button className="btn" disabled={queue.name.trim().length === 0} onClick={() => { props.onConfirm(queue) }}>Save</button>
            </div>
        </Modal>
    );
}

export default EditQueueModal;
