import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

const ChangeAvatar = (props) => {
    const inputElement = useRef(null);

    const auth = useSelector(state => state.auth)
    const agent = auth.agent.agent;

    const handleImageSelect = () => {
        props.handleImageSelect(inputElement.current);
    };

    const handleAvatarRemove = () => {
        props.handleAvatarRemove();
    };

    const handleImageChanged = (event) => {
        props.handleImageChanged(event);
    };

    return (
        <div className="avatar_actions">
            {agent.avatar ? 
                <div className="avatar_actions__inner">
                    <div className="avatar_action" onClick={handleImageSelect}>Change</div>
                    <div className="avatar_action remove" onClick={handleAvatarRemove}>Remove</div>
                </div> : 
                <div className="avatar_actions__inner">
                    <div className="avatar_action" onClick={handleImageSelect}>Upload Profile Picture</div>
                </div>}
            <input ref={inputElement} type="file" style={{display: 'none'}} onChange={handleImageChanged} accept="image/png, image/jpeg"/>
        </div>
    );
};

export default ChangeAvatar;