import React from 'react';
import { connect } from 'react-redux';
import { getLeads, getUserInfoFilters } from '../../services/user';
import { titleCase } from '../../services/misc';
import { Autocomplete, TextField } from '@mui/material';

class WhatsAppAllUserList extends React.Component {
    constructor(props){
        super(props);

        this.orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];
        this.state = {
            loadingUsers: true,
            usersLimit: 2,
            filters: {
            }
        }

        this.setUsers = this.setUsers.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleObjectFilterChange = this.handleObjectFilterChange.bind(this);
        this.handleAllContactsComplete = this.handleAllContactsComplete.bind(this);
    }

    async componentDidMount(){
        const allowedObjectFiltersString = this.props.auth.selectedChatbot.settings.allowed_object_filters;
        if(allowedObjectFiltersString && allowedObjectFiltersString.length){
            const allowedObjectFilters = allowedObjectFiltersString.split(',');
            const allowedFilters = await getUserInfoFilters(this.props.auth.selectedChatbot.token, { user_info_filters: allowedObjectFilters });
            this.setState({ allowedFilters });
        }
        
        this.setUsers();
    }

    async setUsers(){
        const users = await getLeads(this.props.auth.selectedChatbot.token, this.state.usersLimit + 1, { ...this.state.filters, orderBy: 'desc' });

        this.setState({ loadingUsers: false, hasMore: users.length > this.state.usersLimit ? true : false });

        if(users.length > this.state.usersLimit){
            users.pop()
        }

        this.setState({ users })
    }


    handleFilterChange(fieldName, value){
        let filters = this.state.filters;
        if(value){
            filters = {
                ...this.state.filters, [fieldName]: value
            }
        }else{
            delete filters[fieldName]
        }
        this.setState(prevState => ({ loadingUsers: true, filters }), () => {
            this.setUsers();
        })
    }

    handleObjectFilterChange(fieldName, value){
        this.setState(prevState => ({ loadingUsers: true, filters: { ...prevState.filters, objectFilters: { ...prevState.filters.objectFilters, [fieldName]: value } } }), () => {
            this.setUsers();
        })
    }

    handleAllContactsComplete(){
        this.props.onComplete(this.state.filters);
    }

    render(){
        return (
            <div>
                <div className="filters_column">
                    <div className='leads_top_bar'>
                        <div className="filters">
                            {(this.state.allowedFilters && this.state.allowedFilters.length > 0) && (
                                this.state.allowedFilters.map(filter => (
                                    <div className='top_bar_filter'>
                                        <label>{titleCase(filter.key)}</label>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            options={filter.values.map(filterValue => ({ value: filterValue, label: filterValue }))}
                                            classNamePrefix="custom_select"
                                            className="filter form_select"
                                            isClearable={true}
                                            isLoading={this.state.loadingUsers}
                                            onChange={(event, newValue) => { this.handleFilterChange('user_info.' + filter.key, newValue?.value) }}
                                            renderInput={(params) => <TextField {...params} label={titleCase(filter.key)} autoFocus />}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <div className='whatsapp_template_step__actions'>
                    <button className='btn' onClick={this.props.onCancel}>Previous step</button>
                    <button className='btn' disabled={!this.state.users} onClick={this.handleAllContactsComplete}>Next step</button>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppAllUserList);