import { Divider, Switch, TextField, TextareaAutosize, Tooltip } from "@mui/material";

const SectionSettings = ({ eduBot, section, handleSectionUpdate }) => {
    return (
        <div className="edubot_edit_page__side_pane">
            <div className="edubot_edit_page__side_pane__topbar">
                <div className="edubot_edit_page__side_pane__title"><strong>Section Settings</strong></div>    
            </div>
            <div className="edubot_edit_page__side_pane__content">
                <Tooltip title="This cannot be modified" arrow><TextField size="small" fullWidth label="Section Name" disabled value={section.section_template.name}/></Tooltip>
                <Divider sx={{ my: '30px' }}/>
                {eduBot.gpt_enabled && (
                    <>
                        <div className="edubot_detail">
                            <div className="edubot_detail__label">Allow GPT</div>
                            <div className="edubot_detail__value"><Switch checked={section.gpt_settings.allow_gpt} onChange={() => { handleSectionUpdate(section._id, 'gpt_settings', { ...section.gpt_settings, allow_gpt: !section.gpt_settings.allow_gpt }) }}/></div>
                        </div>
                        {section.gpt_settings.allow_gpt && (
                            <div className="edubot_detail detail_column">
                                <div className="edubot_detail__label">Custom Instructions</div>
                                <div className="edubot_detail__value"><TextareaAutosize minRows={5} placeholder={`Enter custom instructions for Open AI processor specifically for the ${section.section_template.name} section.`} value={section.gpt_settings.custom_instructions} onChange={(e) => { handleSectionUpdate(section._id, 'gpt_settings', { ...section.gpt_settings, custom_instructions: e.target.value }) }}/></div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default SectionSettings;