import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InternalNotification from './InternalNotification';
import { notificationShown } from '../../services/internalChats';

const InternalNotifications = (props) => {
    const [agentNotifications, setAgentNotifications] = useState([]);

    const handleMarkAsShown = (chatId, agentId) => {
        notificationShown(chatId, agentId, props.auth.selectedChatbot.token);
    }

    useEffect(() => {
        let notifications = [];
        const chats = props.internalChats.chats;
        const currentAgent = props.auth.agent.agent;

        chats.forEach(chat => {
            const notification = chat.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
            if(notification && !notification.shown && notification.unread && notification.messages && notification.messages.length > 0){
                notification.messages.forEach(message => {
                    const agentNotificationIndex = notifications.findIndex(agentNotification => agentNotification.agent._id.toString() === message.sender._id.toString());
                    if(agentNotificationIndex > -1){
                        let agentNotification =  notifications[agentNotificationIndex];
                        agentNotification.messages = [...agentNotification.messages, message];
                    }else{
                        notifications.push({
                            agent: message.sender,
                            messages: [message],
                            chat
                        });
                    }
                })
            }
        });

        setAgentNotifications(notifications)
    }, [props.internalChats.chats, props.auth.agent.agent]);

    return (
        <div className="internal_notifications">
            {agentNotifications.map((agentNotification, index) => (
                <InternalNotification
                    key={index}
                    handleNotificationClick={props.handleNotificationClick}
                    notification={agentNotification}
                    currentAgent={props.auth.agent.agent}
                    handleMarkAsShown={() => { handleMarkAsShown(agentNotification.messages[0].conversation, props.auth.agent.agent._id) }}
                />
            ))}
        </div>
    );
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalNotifications);
