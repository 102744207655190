import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';

import Quill from 'quill';

const Editor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
	const containerRef = useRef(null);
	const defaultValueRef = useRef(defaultValue);
	const onTextChangeRef = useRef(onTextChange);
	const onSelectionChangeRef = useRef(onSelectionChange);

	useLayoutEffect(() => {
		onTextChangeRef.current = onTextChange;
		onSelectionChangeRef.current = onSelectionChange;
	});

	useEffect(() => {
		ref.current?.enable(!readOnly);
	}, [ref, readOnly]);
	
	useEffect(() => {
		const container = containerRef.current;
		const editorContainer = container.appendChild(
			container.ownerDocument.createElement('div'),
		);
		const quill = new Quill(editorContainer, {
			theme: null,
			modules: {
				toolbar: '#toolbars'
			}
		});

		ref.current = quill;

		if (defaultValueRef.current) {
			quill.root.innerHTML = defaultValueRef.current;
		}

		const handleTextChange = () => {
			onTextChangeRef.current?.(quill.root.innerHTML);
		};

		quill.on(Quill.events.TEXT_CHANGE, handleTextChange);

		quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
			onSelectionChangeRef.current?.(...args);
		});

		// Handle paste events to trigger an update
		const handlePaste = () => {
			// Use a timeout to ensure the paste content is processed by Quill before triggering the update
			setTimeout(handleTextChange, 0);
		};

		quill.root.addEventListener('paste', handlePaste);

		// Wait for the next event loop tick to ensure the DOM has been updated
		setTimeout(() => {
			// Focus the editor
			quill.focus();
			// Calculate the length of the editor's content
			const length = quill.getLength();
			// Set the selection to the end
			quill.setSelection(length, 0);
		}, 0);

		return () => {
			ref.current = null;
			container.innerHTML = '';
		};
	}, [ref]);

	return <div ref={containerRef}></div>;
	},
);

Editor.displayName = 'Editor';

export default Editor;