import React from 'react';
import { useSelector } from 'react-redux';

import { HiMagnifyingGlass, HiOutlineXMark } from 'react-icons/hi2';
import { IconButton, TextField } from '@mui/material';

const ChatSearch = (props) => {
    const auth = useSelector(state => state.auth)

    return auth.selectedChatbot.settings.enable_search ? (
        <div className="internal_chat_top_bar">
            <form className="chat_search internal_chat_search" onSubmit={props.handleSearchSubmit}>
                <input className='input-field' value={props.searchTerm} type="text" autoFocus={true} placeholder="Enter your search query" onChange={props.handleSearchChange}/>
                {props.isSearched && <div className="search_action_button" onClick={props.handleClearSearchClick}><HiOutlineXMark/></div>}
                <IconButton sx={{ ml: '5px' }} className="search_action_button" type='submit'><HiMagnifyingGlass/></IconButton>
            </form>
        </div>
    ) : (
        <div className="internal_chat_top_bar">
            <center><label>Search is currently disabled</label></center>
        </div>
    );
}

export default ChatSearch;
