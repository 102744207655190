import React from "react";
import Modal from 'react-modal';

const ChatFlowResponse = (props) => {
    const flowData = props.flowResponse.Data;
    delete flowData["flow_reply_to"];

    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal_flow_response modal modal__preloader" onRequestClose={props.onRequestClose}>
            <div className="modal__title">{props.title}</div>
            <div className="modal__content">
                <div className="modal_flow_response__response">
                    <div className="modal_flow_response__response_label">JSON Response</div>
                    <pre>{JSON.stringify(props.flowResponse.Data, null, 2)}</pre>
                </div>
            </div>
            <div className="modal__actions">
                <button className="btn" onClick={props.onRequestClose}>Close</button>
            </div>
        </Modal>
    );
}

export default ChatFlowResponse;
