import React from 'react';
import MiniAgentProfile from './MiniAgentProfile';
import Notifications from './Notifications/Notifcations';
import { HiBars3BottomLeft } from 'react-icons/hi2';
import { useSelector } from 'react-redux';

const TopBar = (props) => {
    const auth = useSelector(state => state.auth)

    return (
        <div className="dashboard_topbar">
            <div className="dashboard_topbar__menu_trigger" onClick={props.mobileMenuTrigger}><HiBars3BottomLeft/></div>
            <img src="/images/logo_full.webp" className="top_bar_logo" alt="Verge AI Logo"/>
            {/* {auth.selectedChatbot && <div className="chatbot_selector_toggle" onClick={(e) => { e.preventDefault(); handleToggleChatbotSelector(!chatbotSelectorOpen) }}>{auth.selectedChatbot.name}{chatbotSelectorOpen ? <HiOutlineChevronUp/> : <HiOutlineChevronDown/>}                {chatbotSelectorOpen && <ChatbotSelector selectedChatbot={auth.selectedChatbot._id} handleToggleChatbotSelector={handleToggleChatbotSelector}/>}</div> } */}
            <div className="dashboard_topbar__actions">
                <Notifications/>
                <MiniAgentProfile agent={auth.agent.agent}/>
            </div>
        </div>
    );
}

export default TopBar;
