import LocalSetttings from "./LocalSettings";
import LocalVideoPreviewCard from "./LocalVideoPreviewCard";
import VideoAudioSettings from "./VideoAudioSettings";
import { FaVideoSlash } from 'react-icons/fa';

const MeetingJoinPage = (props) => {
    return (
        <div className="container">
                <div className="col-6">
                    <div className="meeting_room__content_box__title__mobile">
                        {props.meeting.title}
                        {props.company.logo && <img className="meeting_room__settings_logo" src={props.company.logo} alt="Company logo"/>}
                    </div>
                    <div className="video_preview_card">
                        <div className="video_preview_card_icon">
                            {(props.selectedCameraDeviceId && props.deviceManager) && (                            
                                <LocalVideoPreviewCard setTogglingVideo={props.setTogglingVideo} selectedCameraDeviceId={props.selectedCameraDeviceId} deviceManager={props.deviceManager}/>
                            )}{!props.videoEnabled && <FaVideoSlash/>}
                            </div>
                            <VideoAudioSettings togglingVideo={props.togglingVideo} handleVideoToggle={props.handleVideoToggle} handleAudioToggle={props.handleAudioToggle} videoEnabled={props.videoEnabled} microphoneEnabled={props.microphoneEnabled}/>
                        </div>
                </div>
                <div className="meeting_join_page_settings col-6">
                    <div className="meeting_room__content_box__title">
                        {props.meeting.title}
                        {props.company.logo && <img className="meeting_room__settings_logo" src={props.company.logo} alt="Company logo"/>}
                    </div>
                    <LocalSetttings handleVideoChange={props.handleVideoChange} handleAudioChange={props.handleAudioChange} handleSpeakerChange={props.handleSpeakerChange} showCameraNotFoundWarning={props.showCameraNotFoundWarning} showMicrophoneNotFoundWarning={props.showMicrophoneNotFoundWarning} deviceManager={props.deviceManager} selectedCameraDeviceId={props.selectedCameraDeviceId} selectedMicrophoneDeviceId={props.selectedMicrophoneDeviceId} selectedSpeakerDeviceId={props.selectedSpeakerDeviceId}/>

                    <button className="btn primary join_meeting_button" onClick={props.onJoinClick}>Join Meeting</button>
                </div>
        </div>
    )
}

export default MeetingJoinPage;