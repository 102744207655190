import OnlineIndicator from "../Base/OnlineIndicator";
import StatusText from '../Base/StatusText';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';

const ChatTransferListItem = (props) => {
    if(props.queue){
        const onlineAgents = props.queue.agents.filter(agent => agent.agent_settings.status === 'available' && (!props.currentAgent || props.currentAgent._id !== agent._id));
        return (
            <div className="chat_transfer_list__item" onClick={props.allowOffline || onlineAgents.length > 0 ? () => { props.handleTransfer(props.queue._id, 'queues') } : () => {}} disabled={!props.allowOffline && onlineAgents.length === 0}>
                {!props.allowOffline && <OnlineIndicator online={onlineAgents.length > 0}/>}
                <div className="chat_transfer_list__item__details">
                    <div className="queues_title">{props.queue.name}</div>
                    {!props.allowOffline && <div className="queues_description">{onlineAgents.length > 0 ? onlineAgents.length : 'No'} other agent{onlineAgents.length !== 1 ? 's' : ''} online</div>}
                </div>
            </div>
        );
    }else{        
        const agentInChat = props.agent && props.conversation && props.conversation.agents_connected && props.conversation.agents_connected.find(agentConnected => agentConnected._id === props.agent._id) ? true : false;
        return (
            <div className="chat_transfer_list__item" onClick={props.allowOffline || props.agent.agent_settings.status !== 'offline' ? () => { props.handleTransfer(props.agent._id, 'agent') } : () => {}} disabled={agentInChat || (!props.allowOffline && props.agent.agent_settings.status !== 'available')}>
                <AgentProfileAvatar size="30" agent={props.agent}/>
                <div className="chat_transfer_list__item__details">
                    <div className="queues_title">{props.agent.first_name} {props.agent.last_name}</div>
                    {agentInChat && !props.allowConnected ? <div className="status_display">Agent already in chat</div> : <StatusText status={props.agent.agent_settings.status}/>}
                </div>
            </div>
        );
    }
}

export default ChatTransferListItem;