import { TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi2";

const QuestionLogCommentEditor = ({ handleAddCommentToggle, handleCommentAdd }) => {
    const [comment, setComment] = useState('');
    const [addingComment, setAddingComment] = useState(false);
    const maxLength = 500;

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }        

    const handleCommentSave = async () => {
        setAddingComment(true);
        await handleCommentAdd(comment);
        setAddingComment(false);
    }

    return (
        <div className="question_log_comment_editor">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="question_log_comment_editor__title">Add Comment</div>
                <div className="question_log_comment_editor__charcount">{comment.length} / {maxLength}</div>
            </div>
            <TextareaAutosize 
                maxRows={5} 
                placeholder="Enter your comment here"
                value={comment}
                onChange={handleCommentChange}
                maxLength={maxLength}
                disabled={addingComment}
                autoFocus
            />
            <div style={{ display: 'flex' }}>
                <button className="primary btn" disabled={addingComment || comment.length === 0} onClick={handleCommentSave}><HiPlusCircle/>{addingComment ? 'Adding comment...' : 'Add comment'}</button>
                <button className="btn" onClick={handleAddCommentToggle} disabled={addingComment}>Cancel</button>
            </div>
        </div>
    );
}

export default QuestionLogCommentEditor;
