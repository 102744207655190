import React from 'react';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';
import { HiOutlineXMark, HiUserCircle, HiChartBar, HiChatBubbleLeftEllipsis, HiEnvelope, HiEnvelopeOpen, HiUsers, HiExclamationTriangle, HiCog6Tooth, HiMiniSparkles  } from 'react-icons/hi2';
import { RiQuestionnaireFill, RiWhatsappFill } from 'react-icons/ri';
import { IoLibrary } from "react-icons/io5";
import { FaGraduationCap } from "react-icons/fa";

const Menu = ({ mobileMenuOpen, mobileMenuTrigger }) => {
    const auth = useSelector(state => state.auth);
    const helpRequests = useSelector(state => state.helpRequests);
    
    const handleMenuItemClick = () => {
        if (mobileMenuOpen) {
            mobileMenuTrigger();
        }
    }

    return (
        <div className={`dashboard_menu ${mobileMenuOpen ? 'mobile_expanded' : ''}`}>
            <div className="dashboard_menu__top_bar">
                <div className="dashboard_topbar__menu_trigger" onClick={mobileMenuTrigger}><HiOutlineXMark/></div>
                <img className="dashboard_topbar__full_logo" src="/images/logo_full.webp" alt="Mobile Logo"></img>
            </div>
            <div className="dashboard_menu__inner_top">
                <img className="dashboard_menu__logo" src="/images/logo.svg" alt="Verge AI Logo"/>
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager']} to="/analytics" label="Analytics"><HiChartBar/></MenuItem>
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager', 'agent']} to="/chats" label="Chats"><HiChatBubbleLeftEllipsis/></MenuItem>
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager']} to="/leads" label="Leads"><HiUsers/></MenuItem>
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager', 'agent']} to="/help-requests" label="Help Requests" notificationCount={helpRequests.helpRequestsCount}>{helpRequests.helpRequests.filter(helpRequest => !helpRequest.resolved).length === 0 ? <HiEnvelope/> : <HiEnvelopeOpen/>}</MenuItem>
                {auth.selectedChatbot.settings.whatsapp_template_sender_enabled && <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager']} to="/whatsapp-push-notifications" label="WhatsApp Push Notifications"><RiWhatsappFill/></MenuItem>}
                {auth.selectedChatbot.settings.knowledge_set_manager_enabled && <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin']} to="/knowledge-set-manager" label="Knowledge Set Manager"><IoLibrary/></MenuItem>}
                {auth.selectedChatbot.settings.edubot_backend_enabled && <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin']} to="/edubot" label="EduBot"><FaGraduationCap/></MenuItem>}
            </div>
            <div className="dashboard_menu__inner_bottom">
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin']} to="/errors" label="Errors"><HiExclamationTriangle/></MenuItem>
                {auth.selectedChatbot.settings.question_message_log_enabled ? <MenuItem onClick={handleMenuItemClick} rolesAllowed={auth.selectedChatbot.settings.question_message_log_viewable_by_users ? ['admin', 'manager'] : ['admin']} to="/question-logs" label="Question Logs"><HiMiniSparkles/></MenuItem>
                : <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager']} to="/unanswered-questions/pending" label="Unanswered Questions"><RiQuestionnaireFill/></MenuItem>}
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager', 'agent']} to="/profile" label="Edit Profile"><HiUserCircle/></MenuItem>
                <MenuItem onClick={handleMenuItemClick} rolesAllowed={['admin', 'manager']} to="/settings" label="Settings"><HiCog6Tooth/></MenuItem>
            </div>
        </div>
    );
};

export default Menu;