import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const HelpRequestFilters = (props) => {
    const orderByOptions = [ { value: 'desc', label: 'Newest to Oldest'}, { value: 'asc', label: 'Oldest to Newest'}];
    const showOptions = [ { value: true, label: 'Resolved'}, { value: false, label: 'Unresolved'}];

    return (
        <div className="filters">
            <div className="top_bar_filter">
                <Autocomplete
                    size='small'
                    disablePortal
                    options={orderByOptions}
                    defaultValue={orderByOptions[0]}
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    value={orderByOptions.find(filterOption => props.filters.orderBy === filterOption.value)}
                    onChange={(event, newValue) => { props.filterChange('orderBy', newValue?.value) }}
                    renderInput={(params) => <TextField {...params} label="Order by" />}
                />
            </div>
            <div className="top_bar_filter">
                <Autocomplete
                    size='small'
                    disablePortal
                    options={showOptions}
                    getOptionLabel={(option) => option.label}
                    value={showOptions.find(filterOption => props.filters.resolved === filterOption.value)}
                    onChange={(event, newValue) => { props.filterChange('resolved', newValue?.value) }}
                    renderInput={(params) => <TextField {...params} label="Show" />}
                />
            </div>
            <div className="top_bar_filter">
                <Autocomplete
                    size='small'
                    disablePortal
                    options={props.queues && props.queues.map(queueItem => ({ value: queueItem._id, label: queueItem.name }))}
                    getOptionLabel={(option) => option.label}
                    value={props.filters.queue}
                    onChange={(event, newValue) => { props.filterChange('queue', newValue?.value) }}
                    renderInput={(params) => <TextField {...params} label="Queue" />}
                />
            </div>
        </div>
    );
};

export default HelpRequestFilters;