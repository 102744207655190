import { titleCase } from '../../services/misc';
import { HiCheckBadge, HiMinusCircle, HiOutlineXCircle } from 'react-icons/hi2';
import { toTimestamp } from '../../services/dates';

const StatusText = (props) => {
    let status = titleCase(props.status);
    status = props.status === 'offline' && props.lastSeen ? 'Last Seen: ' + toTimestamp(props.lastSeen) : status;
    let icon;
    switch(status){
        case 'Available':
            icon = <HiCheckBadge className="status_icon"/>
            break;
        case 'Busy':
            icon = <HiMinusCircle className="status_icon" />
            break;
        case 'Offline':
        default:
            icon = <HiOutlineXCircle className="status_icon" />
            break;
    }

    return <div className={'status_display ' + status}>{!props.hideIcon && icon}{!props.hideText && <span>{status}</span>}</div>;
};

export default StatusText;