import Modal from 'react-modal';
import Preloader from '../Preloader';
const LoadingModal = (props) => {
    return (
        <Modal ariaHideApp={false} isOpen={props.isOpen} className="modal modal__preloader">
            <Preloader padding="30px 0px" text={props.text}/>
        </Modal>
    );
};

export default LoadingModal;