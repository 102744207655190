import { Tooltip } from "@mui/material";
import { HiXMark, HiOutlineInformationCircle, HiOutlineTrash, HiMiniTrash } from "react-icons/hi2";
import StepSetting from "./StepSetting";

const StepSettings = ({ step, handleStepUpdate, handleStepDeselect, handleStepDelete }) => {
    return (
        <div className="edubot_edit_page__side_pane">
            <div className="edubot_edit_page__side_pane__topbar">
                <div className="edubot_edit_page__side_pane__title"><strong>{step.step_template.name}</strong><Tooltip title={step.step_template.description} arrow><div className="information_circle"><HiOutlineInformationCircle/></div></Tooltip></div>    
                <div className="edubot_edit_page__side_pane__actions">
                    <Tooltip title="Delete Step" arrow><button className="delete" onClick={() => { handleStepDelete(step) }}><HiMiniTrash/></button></Tooltip>
                    <button onClick={handleStepDeselect}><HiXMark /></button>
                </div>
            </div>
            <div className="edubot_edit_page__side_pane__content">
                {step.config && Object.entries(step.config).map(([field, value]) => {
                    return <StepSetting step={step} field={field} value={value} handleStepUpdate={(value) => { handleStepUpdate(step._id, 'config', { ...step.config, [field]: value }) }}/>;
                })}
            </div>
        </div>
    )
}

export default StepSettings;