import React, { useEffect } from 'react';
import Preloader from '../Preloader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePasswordReset } from '../../reducers/authSlice';

const ResetPassword = () => {
    const { id: token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    useEffect(() => {
        if(auth.agent){
            navigate('/')
        }

        if(auth.error){
            navigate('/login')
        }

        dispatch(validatePasswordReset(token));
    }, [dispatch, token, auth.agent]);

    return (
        <Preloader fitToContent={true}/>
    );
}

export default ResetPassword;