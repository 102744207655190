const EduBotConnectorsListItem = ({ connector, connectorDetails }) => {
    return (
        <div className="connector_list_item">
            <div className="connector_list_item__icon"><img src={connectorDetails.logo}/></div>  
            <div className="connector_list_item__details">
                <div className="connector_list_item__name">{connectorDetails.name}</div>  
                <div className="connector_list_item__description">{connectorDetails.description}</div> 
            </div>
        </div>
    )
}

export default EduBotConnectorsListItem;