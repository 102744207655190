import React, { useState, useEffect } from "react";
import Preloader from "../Preloader";
import { getKnowledgeSets } from "../../services/knowedgeSetManager";
import { connect, useSelector } from "react-redux";
import { toTimestamp } from "../../services/dates";
import { NavLink } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const KnowledgeSetList = (props) => {
    const [loading, setLoading] = useState(true);
    const [knowledgeSets, setKnowledgeSets] = useState([]);

    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            const knowledgeSets = await getKnowledgeSets(auth.selectedChatbot.token);
            setKnowledgeSets(knowledgeSets);
            setLoading(false);
        };

        fetchData();
    }, [auth.selectedChatbot.token]);

    return loading ? (
        <Preloader />
    ) : (
        <div className="knowledge_set_list">
            {knowledgeSets.length > 0 ? (
                knowledgeSets.map((kb) => (
                    <NavLink to={`/knowledge-set-manager/${kb.id}`} className="knowledge_set_list_item" key={kb.id}>
                        <div className="knowledge_set_list_item__name"><strong>{kb.name}</strong></div>
                        <div className="knowledge_set_list_item__details">
                            <div className="knowledge_set_list_item__details__item">Version: {kb.latest_version.version_number}</div>
                        </div>
                        <div className="knowledge_set_list_item__details">
                            <div className="knowledge_set_list_item__details__item">Last modified: {toTimestamp(kb.updatedAt)}</div>
                        </div>
                    </NavLink>
                ))
            ) : (
                <span>No knowledge sets have been created. Please contact admin.</span>
            )}
        </div>
    );
};

export default KnowledgeSetList;
