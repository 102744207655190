import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Preloader = (props) => {
    const padding = props.padding || '1rem 0';
    const width = props.fitToContent ? '100%' : 'auto';
    const height = props.fitToContent ? '100%' : 'auto';
    const position = props.fitToContent ? 'absolute' : 'inherit';
    const left = props.fitToContent ? '0' : 'unset';
    const top = props.fitToContent ? '0' : 'unset';
    const size = (props.size || 25) + "px";
    return (
        <div className="preloader" style={{ padding, width, height, position, left, top }}>
            <CircularProgress style={{
                width: size,
                height: size
            }}/>
            {props.text && <div className="preloader__text mt-medium">{props.text}</div>}
        </div>
    );
};

export default Preloader;
