import React, { useState } from 'react';
import Preloader from '../Preloader';
import { TextField } from '@mui/material';

const EditAgentForm = (props) => {
    const [agent, setAgent] = useState(props.agent);
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setAgent((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        props.onSubmit(e, agent);
    }

    return (
        <form onSubmit={handleSubmit} method="POST">
            <div className="form-group__split">
                <div className="form-group">
                    <TextField label="First Name" inputProps={{ maxLength: 30 }} fullWidth disabled={props.submiting} type="text" name="first_name" value={agent.first_name} onChange={handleChange} placeholder="Enter your first name" required/>
                    {submitted && !agent.first_name && <div className="help-block">First name is required</div>}
                </div>
                <div className="form-group">
                    <TextField label="Last Name" inputProps={{ maxLength: 30 }} fullWidth disabled={props.submiting} type="text" name="last_name" value={agent.last_name} onChange={handleChange} placeholder="Enter your last name" required/>
                    {submitted && !agent.last_name && <div className="help-block">Last name is required</div>}
                </div>
            </div>
            <div className="form-group__split">
                <div className="form-group">
                    <TextField label="Email" fullWidth type="email" name="email" value={agent.email} placeholder="Enter your email" disabled/>
                    {submitted && !agent.email && <div className="help-block">Email is required</div>}
                </div>
            </div>
            <button className="btn" disabled={props.submiting}>{!props.submiting ? 'Save' : <Preloader color="white" size="14px" padding="0px 1rem"/>}</button>
        </form>
    );
}

export default EditAgentForm;
