import { setHelpRequestsCountValue, setHelpRequestsValue } from '../reducers/helpRequestsSlice';
import { handleResponse, serialize } from './misc';

const getHelpRequests = (authToken, limit, filters) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
        
            let queryString = '';
            queryString = limit ? '?limit=' + limit : '';
        
            queryString += filters ? '&' + serialize(filters) : '';
            
            fetch('/api/help-requests' + queryString, requestOptions).then(handleResponse).then((helpRequests) => {
                resolve(helpRequests);
            });
        }catch(e){
            reject(e);
        }
    });
}

const getHelpRequestsCount = (authToken, agentId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/help-requests/count?agent=' + agentId, requestOptions).then(handleResponse).then(({count}) => {
        return count;
    });
}

const getHelpRequest = (authToken, id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/help-requests/' + id, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
}

const searchHelpRequests = (authToken, term, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    queryString += filters ? '&' + serialize(filters) : '';

    return fetch('/api/help-requests/search/' + term + queryString, requestOptions).then(handleResponse).then((helpRequests) => {
        return helpRequests;
    });
}

const setHelpRequestCount = async (connectedProps) => {
    const helpRequestCount = await getHelpRequestsCount(connectedProps.auth.selectedChatbot.token, connectedProps.auth.agent.agent._id);
    connectedProps.dispatch(setHelpRequestsCountValue({ count: helpRequestCount }));
}

const setHelpRequests = async (dispatch, auth, helpRequests) => {
    let retrievedHelpRequests = [];
    if(!(helpRequests.searchTerm && helpRequests.searchTerm.length > 0)){
        retrievedHelpRequests = await getHelpRequests(auth.selectedChatbot.token, helpRequests.limit + 1, { ...helpRequests.filters, agent: auth.agent.agent._id });
    }else{
        retrievedHelpRequests = await searchHelpRequests(auth.selectedChatbot.token, helpRequests.searchTerm, helpRequests.limit + 1, { ...helpRequests.filters, agent: auth.agent.agent._id });
    }

    const hasMore = retrievedHelpRequests.length > helpRequests.limit;
    if(hasMore){
        retrievedHelpRequests.pop();
    }

    const helpRequestCount = await getHelpRequestsCount(auth.selectedChatbot.token, auth.agent.agent._id);

    dispatch(setHelpRequestsValue({
        helpRequests: retrievedHelpRequests,
        loadingHelpRequests: false,
        isSearched: (helpRequests.searchTerm && helpRequests.searchTerm.length > 0),
        hasMore,
        count: helpRequestCount
    }));
}

const resolveHelpRequest = (authToken, helpRequestId, agentId, comment = null) => {
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    if(comment){
        requestOptions = {
            ...requestOptions,
            body: JSON.stringify({ comment })
        };
    }

    return fetch('/api/help-requests/' + helpRequestId + '/resolve/' + agentId, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
};

const transferHelpRequest = (authToken, helpRequestId, queueId) => {
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/help-requests/' + helpRequestId + '/transfer/' + queueId, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
};

const deleteHelpRequest = (authToken, helpRequestId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/help-requests/' + helpRequestId, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
};

export {
    getHelpRequests,
    getHelpRequest,
    setHelpRequests,
    setHelpRequestCount,
    resolveHelpRequest,
    deleteHelpRequest,
    searchHelpRequests,
    transferHelpRequest,
    getHelpRequestsCount
};