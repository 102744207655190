import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isTablet } from '../../services/responsive';
import socket from '../../services/socket';
import { deleteConversaton, getConversation, getConversationMessages, getConversations, searchConversations } from '../../services/conversation';
import { getHelpRequests } from '../../services/helpRequest';
import { disconnectAgent, getLatestConversationMeeting, setUsersWaiting } from '../../services/meetings';
import { goToConversation } from '../../reducers/chatsSlice';
import moment from 'moment';
import { uploadMedia } from '../../services/upload';
import { sendMessage } from '../../services/humanHandoff';
import { newQuickReplies } from '../../services/quickReplies';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import { setCurrentMeeting } from '../../reducers/meetingSlice';
import ChatsList from './ChatsList';
import Chat from './Chat';
import ChatSidebar from './ChatSidebar';
import ConfirmModal from '../Base/ConfirmModal';
import MeetingRoom from '../Meetings/MeetingRoom';
import LoadingModal from '../Base/LoadingModal';
import Preloader from '../Preloader';

const ChatsPage = () => {
    const dispatch = useDispatch();
    const isFirstRender = useIsFirstRender();
    const [, forceRender] = useReducer(p => !p, false);
    
    // Get redux states
    const auth = useSelector(state => state.auth);
    const chats = useSelector(state => state.chats);
    const meetings = useSelector(state => state.meetings);

    // Set local states
    const [conversations, setConversations] = useState(null);
    const [loadingConversation, setLoadingConversation] = useState(false);
    const [loadingConversations, setLoadingConversations] = useState(true);
    const [hasMoreConversations, setHasMoreConversations] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(false);
    const [loadingMoreConversations, setLoadingMoreConversations] = useState(false);
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [termSearched, setTermSearched] = useState('');
    const [conversationToBeDeleted, setConversationToBeDeleted] = useState(null);
    const [deletingConversation, setDeletingConversation] = useState(false);
    const [sidebarExpanded, setSidebarExpanded] = useState(true);
    const [mobileSidebarExpanded, setMobileSidebarExpanded] = useState(false);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [currentMessages, setCurrentMessages] = useState(null);
    const [sortBy, setSortBy] = useState('desc');
    const [filters, setFilters] = useState({
        agentChats: undefined,
        previousChats: undefined,
        agentView: auth.agent.agent._id,
        startDate: undefined,
        endDate: undefined,
        queue: undefined
    });
    
    // Set refs as no need to re-render on change
    const currentConversationId = useRef(null);
    const currentConversationRef = useRef(currentConversation);
    const chatsLimitRef = useRef(10);
    const chatsOffsetRef = useRef(10);
    const messageLimitRef = useRef(10);
    const messageOffsetRef = useRef(10);
    const filtersRef = useRef(filters);
    const sortByRef = useRef(sortBy);

    // Initial render
    useEffect(() => {
        if(isTablet()){
            setSidebarExpanded(false);
        }

        // If go to conversation is set, set loading conversation to true
        if(chats.goToConversation){
            setLoadingConversation(true);
        }

        // Load conversations initially
        handleSetConversations(filters, sortBy);

        // Socket listener for conversationsUpdated
        socket.on('conversationsUpdated', ({ id, conversationId, userId }) => {
            if(auth.selectedChatbot._id === id){
                handleSetConversations(filtersRef.current, sortByRef.current);

                // Check to see if current conversation is updated.
                if(currentConversationRef.current && (currentConversationRef.current._id === conversationId || currentConversationRef.current.user_id === userId)){
                    handleSetCurrentConversation(currentConversationRef.current.conversation_id);
                }
            }
        });

        // Socket listener for messagesUpdated
        socket.on('messagesUpdated', ({ id, conversationId }) => {
            if(auth.selectedChatbot._id === id){
                // Check to see if current conversation messages are updated.
                // NOTE: Messages socket emits a conversation_id and not conversation._id
                if(currentConversationRef.current && currentConversationRef.current.conversation_id === conversationId){
                    handleSetCurrentMessages(currentConversationRef.current.conversation_id);
                }
            }
        });

        return () => {
            socket.off('conversationsUpdated');
            socket.off('messagesUpdated');
        }
    }, []);

    // On conversations updated effect
    useEffect(() => {
        if(conversations){
            // If current conversation is not set, set current conversation
            // NOTE: This is only for initial render
            if(!currentConversation){
                let convoId = null;

                if(chats.goToConversation){
                    convoId = chats.goToConversation;
                }else if(conversations && conversations.length > 0 && !isMobile()){
                    convoId = conversations[0].conversation_id;
                }

                // If conversationId is set, set current conversation
                if(convoId){
                    setLoadingConversation(true);
                    handleSetCurrentConversation(convoId);
                }
            }

            // Set loading conversations to false if conversations are loaded
            if(loadingConversations && conversations){
                setLoadingConversations(false);
            }

            // Set loading more conversations to false if more conversations are loaded
            if(loadingMoreConversations){
                setLoadingMoreConversations(false);
            }

            // Set searching to false if search is done
            if(searching){
                setSearching(false);
            }

            // If deleting conversation is set, set deleting conversation to false
            if(deletingConversation){
                setDeletingConversation(false);
            }
        }
    }, [conversations])

    // On current conversation updated effect
    useEffect(() => {
        currentConversationRef.current = currentConversation;

        // If current conversation is set and conversation is loading, proceed to load messages.
        // NOTE: This is only for initial render and when conversastion is clicked.
        if(currentConversation && loadingConversation){
            handleSetCurrentMessages(currentConversation.conversation_id);
        }
    }, [currentConversation])

    // Side effect to handle if go to conversation is set after initial render
    useEffect(() => {
        if(chats.goToConversation && conversations && currentConversationId.current !== chats.goToConversation){
            setLoadingConversation(true);
            handleSetCurrentConversation(chats.goToConversation);
        }
    }, [chats.goToConversation])

    // On current messages updated effect
    useEffect(() => {
        // If current messages are updated, set loading conversation to false
        if(currentMessages && loadingConversation){
            setLoadingConversation(false);
        }

        // Set loading more messages to false if more messages are loaded
        if(currentMessages && loadingMoreMessages){
            setLoadingMoreMessages(false);
        }
    }, [currentMessages])

    // On filter change effect
    useEffect(() => {
        // Ensure that this effect does not run on initial render
        if(!isFirstRender){
            handleSetConversations(filters, sortBy);
        }
    }, [filters, sortBy])

    // On search iniated effect
    useEffect(() => {
        if(searching && isSearched){
            handleSetConversations(filters, sortBy);
        }
    }, [searching, isSearched]);

    // On term searched change 
    useEffect(() => {
        if(!termSearched){
            setTermSearched('');
            handleSetConversations(filters, sortBy);
        }
    }, [termSearched]);

    // Update filters ref
    useEffect(() => {
        filtersRef.current = filters;
    }, [filters]);

    // Update sortBy ref
    useEffect(() => {
        sortByRef.current = sortBy;
    }, [sortBy]);

    // Main method to get conversations and set conversations state
    const handleSetConversations = async (conversationFilters, conversationsSortBy) => {
        let convos = [];

        // If not searching, get conversations, else search conversations.
        if(!isSearched){
            let currentFilters = conversationFilters;
            
            // If show all history is enabled, add filter for current agent
            if(!auth.selectedChatbot.settings.show_all_history){
                currentFilters.agentChats = auth.agent.agent._id;
            }

            convos = await getConversations(
                auth.selectedChatbot.token,
                chatsLimitRef.current + 1,
                currentFilters,
                conversationsSortBy
            );
        }else{
            convos = await searchConversations(
                auth.selectedChatbot.token,
                searchTerm,
                chatsLimitRef.current + 1,
                conversationsSortBy
            );
        }

        // Check if there are more conversations
        const hasMore = convos.length > chatsLimitRef.current;
        if(hasMore){
            convos.pop();
        }
        setHasMoreConversations(hasMore);

        // Set conversations
        setConversations(convos);
    }

    // Main method to load the current conversation
    const handleSetCurrentConversation = async (conversationId) => {
        if(conversationId){
            currentConversationId.current = conversationId;

            let conversation = await getConversation(auth.selectedChatbot.token, conversationId);
            const helpRequests = await getHelpRequests(auth.selectedChatbot.token, 1, { conversation: conversationId });
            const latestMeeting = await getLatestConversationMeeting(auth.selectedChatbot.token, conversation._id);

            // Only set current conversation if conversationId is still the same
            if(currentConversationId.current === conversationId){
                conversation.helpRequests = helpRequests;
                conversation.latestMeeting = latestMeeting;

                setCurrentConversation(conversation);
            }
        }else{
            // If conversationId is null, reset current conversation and messages
            currentConversationId.current = null;
            setCurrentConversation(null);
            setCurrentMessages(null);
        }

        // Set go to conversation to null in global state
        dispatch(goToConversation({ conversation: null }));
    }

    // Main method to load the current messages
    const handleSetCurrentMessages = async (conversationId) => {
        if(conversationId){
            const messages = await getConversationMessages(auth.selectedChatbot.token, conversationId, messageLimitRef.current + 1);

            // Check if current conversation is still the same
            if(conversationId === currentConversationId.current){
                // Check if there are more messages
                const hasMore = messages.length > messageLimitRef.current;
                if(hasMore){
                    messages.pop();
                }
                setHasMoreMessages(hasMore);

                // Sort messages by createdAt
                const sortedMessages = messages.sort((a, b) => moment(a.createdAt) - moment(b.createdAt));
                setCurrentMessages(sortedMessages);
            }
        }
    }

    // Main method to handle click on conversation in sidebar.
    const handleConversationClick = (conversationId) => {
        if(conversationId !== currentConversationId.current){
            messageLimitRef.current = messageOffsetRef.current;
            forceRender();

            setLoadingConversation(true);
            handleSetCurrentConversation(conversationId);
        }
    }

    const handleSendMessage = async (messageText, publicMessage, mediaFile) => {
        let media = null;

        // Upload media if no URL is set else use the URL
        if(mediaFile && !mediaFile.url){
            const uploadResponse = await uploadMedia(auth.selectedChatbot.token, mediaFile);
            if(uploadResponse.url){
                media = {
                    url: uploadResponse.url,
                    name: mediaFile.name,
                    type: mediaFile.type
                };
            }
        }else if(mediaFile && mediaFile.url){
            media = mediaFile;
        }

        const message = {
            conversation_id: currentConversation.conversation_id,
            sender: 'agent',
            agent_sender: auth.agent.agent._id,
            agent_sender_object: auth.agent.agent,
            internal: !publicMessage,
            message_data: {
                text: messageText,
                media: media ? [media] : null
            }
        }

        // Update current messages state.
        setCurrentMessages((prevCurrentMessages) => ([
            ...prevCurrentMessages,
            {
                ...message,
                sending: true
            }
        ]));

        // Send message
        sendMessage(auth.selectedChatbot.token, message, currentConversation.channel);
    }

    const handleAddToQuickReplies = async (message, mediaFile) => {
        let media = null;

        // Upload media if no URL is set else use the URL
        if(mediaFile && !mediaFile.url){
            const uploadResponse = await uploadMedia(auth.selectedChatbot.token, mediaFile);
            if(uploadResponse.url){
                media = {
                    url: uploadResponse.url,
                    name: mediaFile.name,
                    type: mediaFile.type
                };
            }
        }else if(mediaFile && mediaFile.url){
            media = mediaFile;
        }

        const messageData = {
            media: media ? [media] : [],
            text: message
        }

        // Add to quick replies
        await newQuickReplies(auth.selectedChatbot.token, {
            message_data: messageData, 
            private: true, 
            owner: auth.agent.agent._id 
        });
    }

    const handleLoadMoreConversations = () => {
        setLoadingMoreConversations(true);
        chatsLimitRef.current += chatsOffsetRef.current;
        handleSetConversations(filters, sortBy);
    }

    const handleLoadMoreMessages = () => {
        setLoadingMoreMessages(true);
        messageLimitRef.current += messageOffsetRef.current;
        handleSetCurrentMessages(currentConversation.conversation_id);
    }

    const handleSetFilter = (key, value) => {
        setLoadingConversations(true);
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value
        }));
    }

    const handleSetSort = (value) => {
        setLoadingConversations(true);
        setSortBy(value);
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();

        if(searchTerm.trim().length > 0){
            setSearching(true);
            setIsSearched(true);
            setLoadingConversations(true);
        }else{
            // Clear search if search term is empty
            clearSearch();
        }
    }

    const clearSearch = () => {
        setSearching(false);
        setSearchTerm('');
        setIsSearched(false);
        setTermSearched(false);
        setLoadingConversations(true);
    }

    const handleDeleteTrigger = (conversation) => {
        setConversationToBeDeleted(conversation);
    }

    const handleDeleteConversation = async () => {
        const conversation = conversationToBeDeleted;
        setDeletingConversation(true);
        setConversationToBeDeleted(null);

        // Delete conversation
        await deleteConversaton(auth.selectedChatbot.token, conversation.conversation_id);
        handleSetCurrentConversation(null);
        handleSetConversations(filters, sortBy);
    }

    const handleMeetingDisconnect = async () => {
        const meeting = meetings.currentMeeting;

        dispatch(setCurrentMeeting(null));

        await disconnectAgent(meeting._id, auth.agent.agent._id);
        await setUsersWaiting(meeting._id, false);
    }

    const handleChatBackClick  = () => {
        handleSetCurrentConversation(null);
    }    

    const handleSidebarTrigger = () => {
        setSidebarExpanded((prevSidebarExpanded) => !prevSidebarExpanded);
    }

    const handleMobileSidebarTrigger = () => {
        setMobileSidebarExpanded((prevMobileSidebarExpanded) => !prevMobileSidebarExpanded);
    }

    return (
        <div className="chats_app">
            <ChatsList 
                currentConversationId={currentConversationId.current}
                handleSearchSubmit={handleSearchSubmit} 
                searching={searching} 
                termSearched={termSearched} 
                searchTerm={searchTerm} 
                isSearched={isSearched} 
                handleSearchChange={handleSearchChange} 
                clearSearch={clearSearch}
                filters={filters}
                sortBy={sortBy} 
                loadingConversations={loadingConversations} 
                currentAgent={auth.agent.agent} 
                handleSetFilter={handleSetFilter} 
                handleSetSort={handleSetSort}
                handleLoadMoreConversations={handleLoadMoreConversations} 
                loadingMoreConversations={loadingMoreConversations} 
                hasMoreConversations={hasMoreConversations} 
                handleConversationClick={handleConversationClick} 
                currentConversation={currentConversation} 
                conversations={conversations}
                showAllHistory={auth.selectedChatbot.settings.show_all_history}
            />
            {!loadingConversation ? 
                (currentConversation ? 
                    <Chat 
                        loadingConversation={loadingConversation}
                        currentConversation={currentConversation} 
                        handleSetCurrentConversation={handleSetCurrentConversation}
                        currentMessages={currentMessages} 
                        setCurrentMessages={handleSetCurrentMessages}
                        handleSendMessage={handleSendMessage}
                        loadingMoreMessages={loadingMoreMessages}
                        handleLoadMoreMessages={handleLoadMoreMessages}
                        handleChatBackClick={handleChatBackClick}
                        handleMobileSidebarTrigger={handleMobileSidebarTrigger}
                        handleAddToQuickReplies={handleAddToQuickReplies}
                        hasMoreMessages={hasMoreMessages}
                    /> : ''
                ) : <div className="chats_preloader"><Preloader size="25"/></div>}
            <ChatSidebar  
                loadingConversation={loadingConversation}
                currentConversation={currentConversation} 
                handleSetCurrentConversation={handleSetCurrentConversation}
                handleDeleteTrigger={handleDeleteTrigger}
                sidebarExpanded={sidebarExpanded}
                mobileSidebarExpanded={mobileSidebarExpanded}
                handleMobileSidebarTrigger={handleMobileSidebarTrigger}
                handleSidebarTrigger={handleSidebarTrigger}
                currentAgent={auth.agent.agent}
            />
            {conversationToBeDeleted && <ConfirmModal isOpen={conversationToBeDeleted ? true : false} onConfirm={handleDeleteConversation} onRequestClose={() => { handleDeleteTrigger(undefined) }} confirmText="Delete" title="Delete Chat" description={<span>You are about to delete this chat permanently for all agents on the portal. All notes and history for this chat will be lost.<br/><br/>Are you sure you want to do this?</span>}/>}
            {meetings.currentMeeting && <MeetingRoom minimizable={true} displayName={auth.agent.agent.first_name + ' ' + auth.agent.agent.last_name} meeting={meetings.currentMeeting} agent={auth.agent.agent} onDisconnect={handleMeetingDisconnect}/>}
            {deletingConversation && <LoadingModal isOpen={deletingConversation} text="Deleting conversation"/>}
        </div>
    );
}

export default ChatsPage;