import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import CompanyList from './CompanyList';

const CompanySelectPage = () => {
    const auth = useSelector(state => state.auth);
    
    return (
        <div>
            {!auth.agent ? <Navigate to="/"/> : (
                <div>
                    {auth.selectedCompany ? <Navigate to="/"/> : (
                        <div className="company_select_page">
                            <div className="company_select_page__container">
                                <div className="container">
                                    <div>
                                        <h2>Select your portal</h2>
                                        <CompanyList/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CompanySelectPage;