import { HiCheckCircle, HiOutlinePlus } from "react-icons/hi2";
import ConnectorsBadge from "../Connectors/ConnectorsBadge";

const SectionTemplateList = ({ sectionTemplates, connectors, addedSections, onSectionAdd }) => {
    return (
        <div className="module_template_list">
            {sectionTemplates.map((sectionTemplate) => {
                const added = addedSections && addedSections.find(section => sectionTemplate.template_id === section.section_template_id) ? true : false;
                return (
                    <div key={sectionTemplate.id} className="module_template_list__item">
                        <div className="module_template_list__item_icon" dangerouslySetInnerHTML={{__html: sectionTemplate.icon}}/>
                        <div className="module_template_list__item_content">
                            <div className="module_template_list__item_name"><b>{sectionTemplate.name}</b></div>
                            <div className="module_template_list__item_description">{sectionTemplate.description}</div>
                        </div>
                        <div className="module_template_list__item_bottom_bar">
                            <div className="module_template_list__item_actions">
                                {added ? <button className="btn" disabled={true}><HiCheckCircle/>Added</button> : <button className="btn" onClick={() => { onSectionAdd(sectionTemplate) }}><HiOutlinePlus/>Add</button>}
                            </div>
                            <ConnectorsBadge connectors={connectors.filter(connector => sectionTemplate.connector_dependencies?.includes(connector.connector_id))}/>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default SectionTemplateList;