import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import DashboardSelectedComponent from './DashboardSelectedComponent';
import Menu from './Menu/Menu';
import TopBar from './TopBar';
import { agentService } from '../services/agents';
import { getPendingHumanHandoffs, getTransferedHumanHandoffs, getInvitesHumanHandoffs } from '../services/humanHandoff';
import { setInternalChats, getChat } from '../services/internalChats';
import { setHelpRequestCount } from '../services/helpRequest';
import { setMeetings } from '../services/meetings';
import HumanHandoffNotifcation from './Notifications/HumanHandoffNotification';
import InternalChat from './InternalChat/InternalChat';
import { setCurrentMessages } from '../services/internalChats';
import HumanHandoffTransfer from './Notifications/HumanHandoffTransfer';
import HumanHandoffInvite from './Notifications/HumanHandoffInvite';
import _ from 'lodash';
import socket from '../services/socket';
import { setCurrentAgent, setCurrentChatbot } from '../reducers/authSlice';
import { setNotifications } from '../reducers/notificationsSlice';
import { updateSelectedChat } from '../reducers/internalChatsSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const Dashboard = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const internalChats = useSelector((state) => state.internalChats);

    const internalChatsRef = useRef(internalChats);
    
    useEffect(() => {
        const handleAgentsUpdate = ({ id }) => {
            if (
                auth &&
                auth.agent &&
                auth.agent.agent &&
                auth.agent.agent._id === id
            ) {
                agentService
                    .setAgent(auth.agent.token, auth.agent.agent._id)
                    .then((agent) => {
                        dispatch(setCurrentAgent(agent));
                    });
            }
            setInternalChats(internalChats, auth, dispatch);
            if (
                internalChats.selectedChat &&
                internalChats.selectedChat.chat &&
                internalChats.selectedChat.participants &&
                internalChats.selectedChat.chat.participants.find(
                    (participant) => participant._id === id
                )
            ) {
                getChat(
                    internalChats.selectedChat.chat._id,
                    auth.selectedChatbot.token
                ).then((chat) => {
                    dispatch(updateSelectedChat(chat));
                });
            }
        };

        const handleHeartbeat = () => {
            // Respond to server heartbeat to keep the connection alive
            socket.emit('heartbeat');
        };

        const handleChatbotUpdate = ({ chatbot }) => {
            delete chatbot._v;
            delete chatbot.updatedAt;
            delete chatbot.owner;

            let selectedChatbot = {...auth.selectedChatbot};
            delete selectedChatbot._v;
           
            delete selectedChatbot.updatedAt;
            delete selectedChatbot.owner;

            if (
                selectedChatbot._id === chatbot._id &&
                !_.isEqual(selectedChatbot, chatbot)
            ) {
                dispatch(setCurrentChatbot(chatbot));
            }
        };

        const handleNotificationsUpdate = ({ id }) => {
            if (auth.selectedChatbot._id === id) {
                updateNotifications();
            }
        };

        const handleInternalMessageUpdate = ({ id, conversation }) => {
            if (
                auth.selectedChatbot._id === id &&
                internalChatsRef.current &&
                internalChatsRef.current.selectedChat &&
                internalChatsRef.current.selectedChat.chat &&
                internalChatsRef.current.selectedChat.chat._id === conversation
            ) {
                setCurrentMessages(internalChatsRef.current, auth, dispatch);
            }
        };

        const handleInternalChatUpdate = ({ id }) => {
            if (auth.selectedChatbot._id === id && internalChatsRef.current) {
                setInternalChats(internalChatsRef.current, auth, dispatch);
            }
        };

        const handleHelpRequestUpdate = ({ id }) => {
            if (auth.selectedChatbot._id === id) {
                setHelpRequestCount(props);
            }
        };

        const handleMeetingUpdate = ({ id }) => {
            if (auth.selectedChatbot._id === id) {
                setMeetings(props);
            }
        };

        socket.on('agentsUpdate', handleAgentsUpdate);
        socket.on('heartbeat', handleHeartbeat);
        socket.on('chatbotUpdate', handleChatbotUpdate);
        socket.on('notifcationsUpdate', handleNotificationsUpdate);
        socket.on('internalMessageUpdate', handleInternalMessageUpdate);
        socket.on('internalChatUpdate', handleInternalChatUpdate);
        socket.on('helpRequestUpdate', handleHelpRequestUpdate);
        socket.on('meetingUpdate', handleMeetingUpdate);
        socket.emit('join', auth.agent.agent._id);

        const statusTimer = setInterval(() => {
            socket.emit('join', auth.agent.agent._id);
        }, 2000);

        updateNotifications();
        setInternalChats(internalChats, auth, dispatch);
        setMeetings(props);
        setHelpRequestCount(props);

        return () => {
            socket.off('chatbotUpdate', handleChatbotUpdate);
            socket.off('agentsUpdate', handleAgentsUpdate);
            socket.off('notifcationsUpdate', handleNotificationsUpdate);
            socket.off('internalMessageUpdate', handleInternalMessageUpdate);
            socket.off('internalChatUpdate', handleInternalChatUpdate);
            socket.off('helpRequestUpdate', handleHelpRequestUpdate);
            socket.off('meetingUpdate', handleMeetingUpdate);
            socket.off('heartbeat', handleHeartbeat);
            clearInterval(statusTimer);
        };
    }, []);

    useEffect(() => {
        internalChatsRef.current = internalChats;
    }, [internalChats])

    const updateNotifications = async () => {
        const humanHandoffs = await getPendingHumanHandoffs(
            auth.selectedChatbot.token,
            auth.agent.agent._id
        );
        const humanHandoffTransfers = await getTransferedHumanHandoffs(
            auth.selectedChatbot.token,
            auth.agent.agent._id
        );
        const humanHandoffInvites = await getInvitesHumanHandoffs(
            auth.selectedChatbot.token,
            auth.agent.agent._id
        );
        const humanHandoffNotifcations = humanHandoffTransfers.map((humanHandoff) => (
            <HumanHandoffTransfer key={humanHandoff._id} humanHandoff={humanHandoff} />
        ));
        const notifications = humanHandoffNotifcations
            .concat(
                humanHandoffs.map((humanHandoff) => (
                    <HumanHandoffNotifcation key={humanHandoff._id} humanHandoff={humanHandoff} />
                ))
            )
            .concat(
                humanHandoffInvites.map((humanHandoff) => (
                    <HumanHandoffInvite humanHandoff={humanHandoff} />
                ))
            );
        dispatch(setNotifications(notifications));
    };

    const mobileMenuTrigger = () => {
        setMobileMenuOpen(prevMobileMenuOpen => !prevMobileMenuOpen);
    };

    // Redirect user to chats page when go to conversation is actioned and user is not on the chats page
    const navigate = useNavigate();
    const location = useLocation();
    const chats = useSelector((state) => state.chats);
    useEffect(() => {
        if(chats.goToConversation){
            if(location.pathname !== '/chats'){
                navigate('/chats');
            }
        }
    }, [chats.goToConversation])

    return (
        <div className="dashboard">
            <Menu mobileMenuOpen={mobileMenuOpen} mobileMenuTrigger={mobileMenuTrigger} />
            <div className="dashboard_content">
                <TopBar mobileMenuTrigger={mobileMenuTrigger} />
                <DashboardSelectedComponent>{props.component}</DashboardSelectedComponent>
                <InternalChat />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Dashboard);